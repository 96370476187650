import { Box } from '@mui/material';
import React from 'react';
import { api } from '../../../../api/apiChart';
import { Bar } from 'react-chartjs-2';
import Chart from './generic/chart-init';


const generateColors = (num) => {
    const color2 = { 'start': [100, 70, 190], 'end': [200, 180, 255] };
    const color1 = { 'start': [0, 160, 250], 'end': [150, 230, 255] };
    const color3 = { 'start': [250, 100, 50], 'end': [220, 190, 170] };
    const color4 = { 'start': [30, 150, 80], 'end': [110, 250, 170] };
    let result_colors = [];

    let shades_num = Math.ceil(num / 4);
    for (let i = 0; i < shades_num; i++) {
        let temp_color1 = 'rgb(' + Math.round(color1.start[0] + (color1.end[0] - color1.start[0]) * i / (shades_num)).toString() + ', ' +
            Math.round(color1.start[1] + (color1.end[1] - color1.start[1]) * i / (shades_num)).toString() + ', ' +
            Math.round(color1.start[2] + (color1.end[2] - color1.start[2]) * i / (shades_num)).toString() + ')';

        let temp_color2 = 'rgb(' + Math.round(color2.start[0] + (color2.end[0] - color2.start[0]) * i / (shades_num)).toString() + ', ' +
            Math.round(color2.start[1] + (color2.end[1] - color2.start[1]) * i / (shades_num)).toString() + ', ' +
            Math.round(color2.start[2] + (color2.end[2] - color2.start[2]) * i / (shades_num)).toString() + ')';

        let temp_color3 = 'rgb(' + Math.round(color3.start[0] + (color3.end[0] - color3.start[0]) * i / (shades_num)).toString() + ', ' +
            Math.round(color3.start[1] + (color3.end[1] - color3.start[1]) * i / (shades_num)).toString() + ', ' +
            Math.round(color3.start[2] + (color3.end[2] - color3.start[2]) * i / (shades_num)).toString() + ')';

        let temp_color4 = 'rgb(' + Math.round(color4.start[0] + (color4.end[0] - color4.start[0]) * i / (shades_num)).toString() + ', ' +
            Math.round(color4.start[1] + (color4.end[1] - color4.start[1]) * i / (shades_num)).toString() + ', ' +
            Math.round(color4.start[2] + (color4.end[2] - color4.start[2]) * i / (shades_num)).toString() + ')';

        result_colors.push(temp_color1);
        result_colors.push(temp_color2);
        result_colors.push(temp_color3);
        result_colors.push(temp_color4);
    }

    return result_colors;
}

const getAxisTitle = (section, querystring) => {
    let title = 'number of items';
    switch (section) {
        case 'comps':
            title = (querystring.mode == 'sum') ? 'Total fundraising, $ M.' : 'Number of companies';
            break;
        case 'funds':
            title = (querystring.mode == 'count') ? 'Number of rounds' : 'Total invetmets sum, $ M.';
            break;
        default:
            console.warn('getAxisTitle: unknown object type');
    }
    return title;
}


const StatisticsChart = ({
    industry,
    form,
    dataBarChartClick,
    setDataBarChart,
    setDataBarChartClick,
    barClick,
    dashboard,
    formSubmit }) => {

    React.useEffect(() => {
        Chart.register({
            id: 'bar_content',
            afterEvent: barClick
        });
        api.chartDataCompaniesStats({ cat1: form.cat1, cat2: form.cat2, mode: form.typeOfValue, country: form.country, q: form.q })
            .then(res => {
                let chart_data = [];

                res.chart.data.forEach(el => {
                    if (el.y === industry) {
                        if (form.cat1 === 'year') {
                            if (el.x > '1990') {
                                chart_data.push(el)
                            }
                        } else {
                            chart_data.push(el)
                        }
                    }
                })
                // if (chart_data.x_id > 1999) {
                let bar_content_options = {
                    label_filter_id: res.chart.params['x_cat_id'],
                    label_filter_map: {},
                    dataset_filter_id: res.chart.params['y_cat_id'],
                    dataset_filter_map: {},
                    filter_url: '/dashboards/api/companies-list/',
                    filter_query: { cat1: form.cat1, cat2: form.cat2, mode: form.typeOfValue, country: form.country, q: form.q },
                    filter_type: 'comps'
                };

                let labels = [];
                let datasets = [];
                let temp_datasets = {};


                for (let i = 0; i < chart_data.length; i++) {
                    if (!labels.includes(chart_data[i].x)) {
                        labels.push(chart_data[i].x);
                        bar_content_options.label_filter_map[chart_data[i].x] = chart_data[i].x_id;
                    }

                    let y_id = chart_data[i].y_id > '' ? chart_data[i].y_id : chart_data[i].y;
                    if (!temp_datasets.hasOwnProperty(y_id)) {
                        temp_datasets[y_id] = { title: chart_data[i].y, points: {} };
                        bar_content_options.dataset_filter_map[chart_data[i].y] = chart_data[i].y_id;
                    }

                    temp_datasets[y_id].points[chart_data[i].x] = chart_data[i].val;
                }
                //labels.sort();
                let secondary_cats = Object.keys(temp_datasets);
                secondary_cats.sort();
                if (temp_datasets.hasOwnProperty('')) {
                    temp_datasets[""].title = res.chart.params['x_cat_title'];
                }

                const bar_colors = generateColors(secondary_cats.length);
                for (let i = 0; i < secondary_cats.length; i++) {
                    let dataset = {};
                    dataset.label = temp_datasets[secondary_cats[i]].title;
                    dataset.backgroundColor = bar_colors[i];
                    dataset.borderColor = bar_colors[i];
                    dataset.data = [];
                    for (let j = 0; j < labels.length; j++) {
                        dataset.data.push(temp_datasets[secondary_cats[i]].points[labels[j]]);
                    }
                    datasets.push(dataset);
                }
                let dataCharts = {
                    labels: labels,
                    datasets: datasets
                }
                setDataBarChart(dataCharts);
                setBarContentOptions(bar_content_options);
                if (dataBarChartClick.datasets.length == 0) {
                    setDataBarChartClick(dataCharts)
                }
                // }
            })
            .catch(err => { console.log(err) })
    }, [form]);
    const [barContentOptions, setBarContentOptions] = React.useState({ filter_type: 'comps', filter_query: '' })

    const chartRef = React.useRef();
    const legendRef = React.useRef();

    let options = {
        indexAxis: 'x',
        interaction: {
            intersect: true,
            mode: 'point'
        },
        plugins: {
            htmlLegend: {
                containerID: 'legend_block',
            },
            legend: {
                fullWidth: false,
                position: 'bottom',
                display: false,
                labels: {
                    font: {
                        size: 14
                    },
                    color: '#B2B5BB'
                }
            },
            bar_content: barContentOptions
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    type: "category",
                    labels: dataBarChartClick.labels,
                    color: '#B2B5BB',
                    display: true,
                    font: {
                        size: 14
                    }
                },
                ticks: {
                    color: '#B2B5BB'
                }
            },
            y: {
                ticks: {
                    color: '#B2B5BB'
                },
                title: {
                    color: '#B2B5BB',
                    text: getAxisTitle(barContentOptions.filter_type, barContentOptions.filter_query),
                    display: true,
                    font: {
                        size: 14
                    }
                }
            }
        }
    }


    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'row';
            listContainer.style.flexWrap = 'wrap';
            listContainer.style.justifyContent = 'center';
            listContainer.style.margin = 0;
            listContainer.style.padding = 0;

            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);

            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach(item => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                // Color box
                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.fillStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.height = '10px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '25px';

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.fontSize = '12px'
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        }
    };
    let pluginss = [htmlLegendPlugin];

    console.log(dataBarChartClick, options)

    return (
        <Box>
            <Box sx={{ height: '50vh' }}>
                <Bar sx={{ width: '100%' }}
                    options={options}
                    ref={chartRef}
                    data={dataBarChartClick}
                    plugins={pluginss}
                />

            </Box>
            <Box ref={legendRef} id='legend_block'></Box>
        </Box>
    )
}

export default StatisticsChart;