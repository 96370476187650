import React from "react";
import { Box } from "@mui/material";
import { Helmet } from 'react-helmet-async';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LanguageIcon from '@mui/icons-material/Language';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import stethoscope from '../../../assets/svg/stethoscope.svg';
import syringe from '../../../assets/svg/syringe.svg';
import stethoscopePink from '../../../assets/svg/stethoscopePink.svg';
import syringeGreen from '../../../assets/svg/syringeGreen.svg';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RenderField from "./renderFields";
import { api } from "../../api/apiCovidCharts";
import MainLayout from "../../layouts";

const items = [
    {
        textMenu: 'Covid-19 Data Tracker',
        linkMenu: 'Covid19DataTracker',
        iconMenu: <TrendingUpIcon sx={{ fill: '#d0d2d6', fontSize: '1.5rem' }} />,
        active: true
    },
    {
        textMenu: 'Regional Safety Ranking',
        linkMenu: 'RegionalSafetyRanking',
        iconMenu: <LanguageIcon sx={{ fill: '#d0d2d6', fontSize: '1.5rem' }} />,
        active: false
    },
    // {
    //     textMenu: 'Risk & Safety Assessment',
    //     linkMenu: 'RiskSafetyAssessment',
    //     iconMenu: <ShieldOutlinedIcon sx={{ fill: '#d0d2d6', fontSize: '1.5rem' }} />,
    //     active: false
    // },
    {
        textMenu: 'Government Efficiency',
        linkMenu: 'GovernmentEfficiency',
        iconMenu: <OutlinedFlagIcon sx={{ fill: '#d0d2d6', fontSize: '1.5rem' }} />,
        active: false
    },
    {
        textMenu: 'Economy Resilience',
        linkMenu: 'EconomyResilience',
        iconMenu: <AttachMoneyOutlinedIcon sx={{ fill: '#d0d2d6', fontSize: '1.5rem' }} />,
        active: false
    },
    {
        textMenu: 'Healthcare Management',
        linkMenu: 'HealthcareManagement',
        iconMenu: <img alt='Healthcare Management' width='23px' src={stethoscope} />,
        active: false
    },
    {
        textMenu: 'Quarantine Efficiency',
        linkMenu: 'QuarantineEfficiency',
        iconMenu: <HomeOutlinedIcon sx={{ fill: '#d0d2d6', fontSize: '1.5rem' }} />,
        active: false
    },
    {
        textMenu: 'Vaccination Rate',
        linkMenu: 'VaccinationRate',
        iconMenu: <img alt='Vaccination Rate' width='23px' src={syringe} />,
        active: false
    },
]

const parameters = [
    {
        titleParams: 'Countries',
        counts: 223,
        iconParams: <LanguageIcon sx={{ fill: '#7B61FF', fontSize: '1.5rem' }} />,
        bgColor: '#7367F01A'
    },
    {
        titleParams: 'Parameters',
        counts: 8,
        iconParams: <SettingsOutlinedIcon sx={{ fill: '#00CFE8', fontSize: '1.5rem' }} />,
        bgColor: '#00CFE81A'
    },
    {
        titleParams: 'Total cases',
        counts: 1111,
        iconParams: <img alt='Total cases' width='23px' src={stethoscopePink} />,
        bgColor: '#DB6AB51A'
    },
    {
        titleParams: 'Of fully vaccinated',
        counts: 1,
        iconParams: <img alt='Of fully vaccinated' width='23px' src={syringeGreen} />,
        bgColor: '#28C7AA1A'
    },
]


const CovidDashboard = () => {

    const [itemCovid, setItemsCovid] = React.useState();
    const [activeLink, setActiveLink] = React.useState('Covid19DataTracker');
    const [dataCovidHeader, setDataCovidHeader] = React.useState(parameters);

    React.useEffect(() => {
        setItemsCovid(items)
    }, [])

    const handleClickOnLink = (item) => {
        const arrItems = itemCovid;
        arrItems.forEach(i => {
            i.active = false;
        });

        arrItems.forEach(i => {
            if (i.linkMenu === item.linkMenu) {
                i.active = true;
                setActiveLink(item.linkMenu)
            }
        });

        setItemsCovid([...arrItems])
    }

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Helmet>
                <title>Covid dashboard</title>
            </Helmet>
            <MainLayout
                itemPage={itemCovid}
                handleClickOnLink={handleClickOnLink}
                parameters={dataCovidHeader}
                boolCovidDash={true}>
                <RenderField
                    parameters={parameters}
                    setDataCovidHeader={setDataCovidHeader}
                    type={activeLink} />
            </MainLayout>
        </Box>
    )
}

export default CovidDashboard;