import React from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layouts';

import RenderField from './renderField';
import { Helmet } from 'react-helmet-async';



const MegaDashboard = () => {

    const activeLink= 'GlobalDashboard';


    return (
        <Box>
            <Helmet>
                <title>DeepTech Platform</title>
            </Helmet>
            <MainLayout sx={{minHeight: '100vh'}}
                title="DeepTech Big Data Analytics Platform"
                boolCovidDash={false}
                globaldash={true} >
                <RenderField type={activeLink} />
            </MainLayout>
        </Box>
    )
}


export default MegaDashboard;