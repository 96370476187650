import { Box, CircularProgress, Typography } from "@mui/material";

export const Loader = () => {
    return (
        <Box sx={{
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: '50%'
        }}>
            <Box sx={{
                background: '#161d31',
                borderRadius: '7px',
                padding: '1.5rem',
                boxShadow: '8px 5px 20px 0px rgb(0 0 0 / 13%)',
                display: 'flex',
                alignItems: 'center'
            }}>
                <CircularProgress color="secondary" />
                <Typography sx={{pl:'1rem'}}>Loading</Typography>
            </Box>
        </Box >
    )
}