import {
    Box,
    Divider,
    Link,
    Typography
} from "@mui/material";
import DropdownDashboard from "./dropdownDashboard";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import circle from '../../assets/circlebtn.svg';
import logo from '../../assets/image/dka_with_text_white_logo1.png';
import { useNavigate } from "react-router-dom";

const OldDesign = ({
    handleContactActive,
    activeMenu,
    setActiveMenu,
    contactActive,
    classes,
    handleClickOnLink,
    items,
    itemsListAsia,
    handleClickOnLinkMenu,
    createdDash,
    continent,
    industry,
    spacetech,
    title }) => {

    const navigate = useNavigate();

    return (
        <Box sx={{ position: 'fixed', left: 0, width: activeMenu ? { xl: '13.4%', xs: "16.6%", md: "15%" } : '6%' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '20px',
            }}>
                {activeMenu && <Box sx={{ mr: '1rem' }}>
                    <Link onClick={() => navigate('/')}>
                        <img width='100%' src={logo} />
                    </Link>
                </Box>}
                {!createdDash ? <Box sx={{ cursor: 'pointer' }} onClick={() => setActiveMenu(!activeMenu)}>
                    <img src={circle} />
                </Box> : ''}
            </Box>
            {!createdDash || spacetech ?
                <DropdownDashboard
                    activeMenu={activeMenu}
                    itemsListAsia={itemsListAsia}
                    handleClickOnLinkMenu={handleClickOnLinkMenu}
                    spacetech={spacetech}
                /> :
                <Box>
                    <Divider sx={{
                        background: '#7A838F',
                        opacity: "0.2",
                        height: '1px',
                        m: '0.5rem 5%',
                        width: '90%'
                    }} />
                    <Link sx={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => handleClickOnLinkMenu('DigitalMain')}>
                        <Typography
                            sx={{
                                p: '1rem',
                                color: '#FFFFFF !important',
                                fontFamily: 'Montserrat',
                                fontSize: '14px',
                                fontWeight: '400',
                                '&:hover': {
                                    background: 'rgba(0, 0, 0, 0.08)',
                                    color: 'rgb(238, 238, 238)'
                                }
                            }}>{!industry && !continent ? title : industry ? continent ? `${industry} industry in ${continent[0].toUpperCase() + continent.slice(1)}`
                                : `${industry} industry`
                                : continent[0].toUpperCase() + continent.slice(1)}</Typography>
                    </Link>
                </Box>}
            <Divider sx={{
                background: '#7A838F',
                opacity: "0.2",
                height: '1px',
                m: '0.5rem 5%',
                width: '90%'
            }} />
            <Box sx={{
                width: '100%',
            }}>
                {items && items.map((i, key) => (
                    <Box key={i.title} mt={i.title !== 'none' ? createdDash ? 4 : 4 : 0}>
                        {activeMenu && i.title != 'none' ? <Typography sx={{
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            color: '#25A18E',
                            mb: '10px',
                            textOverflow: 'ellipsis',
                            pl: createdDash ? '1.5rem' : '2rem',
                        }}>{i.title}</Typography> : ''}
                        {i.links.map(k => (
                            <Box key={k.titleLink} className={!k.active ? '' : createdDash ? classes.activeCreateDash : classes.activeLink}
                                sx={{
                                    display: 'flex',
                                    p: createdDash ? '0.5rem 1rem' : '0.5rem 2rem',
                                    color: createdDash ? 'rgb(238, 238, 238)' : 'rgb(238, 238, 238, 0.7)',
                                    cursor: 'pointer',
                                    transition: 'padding-left 0.25s ease-in-out',
                                    '&:hover': {
                                        background: 'rgba(0, 0, 0, 0.08)',
                                        color: 'rgb(238, 238, 238)',
                                        p: '0.5rem 2.4rem',
                                        transition: 'padding-left 0.25s ease-in-out'
                                    },
                                    textOverflow: 'ellipsis',
                                    justifyContent: activeMenu ? 'left' : 'center',
                                    maxWidth: '100%'
                                }}
                                onClick={() => handleClickOnLink(k)}>
                                {k.urlImg}
                                {activeMenu &&
                                    <Box
                                        sx={{
                                            display: "flex",
                                            textOverflow: 'ellipsis',
                                            width: '90%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                pl: "0.4rem",
                                                margin: "auto",
                                                ml: '0.5rem',
                                                fontSize: '14px',
                                                fontFamily: 'Montserrat',
                                                fontWeight: '500',
                                                color: '#FFFFFF !important',
                                                whiteSpace: createdDash ? 'nowrap' : '',
                                                overflow: createdDash ? 'hidden' : '',
                                                textOverflow: createdDash ? 'ellipsis' : ''
                                            }}
                                        >
                                            {k.titleLink}
                                        </Typography>
                                    </Box>}
                            </Box>

                        ))}
                        {createdDash && key === (items.length - 1) ? "" : <Divider sx={{
                            background: '#7A838F',
                            opacity: "0.2",
                            height: '1px',
                            m: '0.5rem 5%',
                            width: '90%'
                        }} />}
                    </Box>

                ))}
                {createdDash ? "" : <Box className={contactActive ? classes.activeLink : ''}
                    sx={{
                        display: 'flex',
                        p: '0.5rem 2rem',
                        color: '#d0d2d6',
                        cursor: 'pointer',
                        mb: '2rem',
                        transition: 'padding-left 0.25s ease-in-out',
                        '&:hover': {
                            p: '0.5rem 2.4rem',
                            transition: 'padding-left 0.25s ease-in-out'
                        },
                        justifyContent: activeMenu ? 'left' : 'center'
                    }}
                    onClick={() => {
                        handleClickOnLink('contacts');
                        handleContactActive()
                    }}>
                    <MailOutlineIcon />
                    {activeMenu && <Typography sx={{ ml: '0.5rem' }}>Contacts</Typography>}
                </Box>}
            </Box>
        </Box>
    )
}


export default OldDesign;