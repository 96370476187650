import { Box, Grid, Typography, CircularProgress } from "@mui/material"
import React from "react";
import search from '../../../../../assets/searchNew.svg';
import { api } from "../../../../api/apiSpaceTech";
import MainDarkBox from "../../../../mainDarkBox";
import PaginationDark from "../../../../pagination";
import TableVenturePartners from "./tableVenturePartners";




const VenturePartners = () => {
    const [activeProgress, setActiveProgress] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [typeCompanyList, setTypeCompanyList] = React.useState(true);
    const [valueSearch, setValueSearch] = React.useState('');
    const [countsCompanies, setCountsCompanies] = React.useState(0);
    const [companiesArr, setCompaniesArr] = React.useState([]);
    const [companiesArrSearch, setCompaniesArrSearch] = React.useState([]);
    const [companyList, setCompanyList] = React.useState();


    const firstPage = () => {
        setPage(1);
    }

    const prevPage = () => {
        if (page !== 1)
            setPage(page - 1);
    }

    const nextPage = () => {
        if (page !== Math.ceil(companiesArr.length / 20))
            setPage(page + 1);
    }

    const handleChange = (e, value) => {
        setPage(value)
    }

    const handleChangeSearch = (e) => {
        setValueSearch(e.target.value)
    }

    React.useEffect(() => {
        api.getVenturePartners()
            .then(res => {
                setCountsCompanies(res.length)
                setCompaniesArr(res)
            })
            .catch(err => { console.log(err) })
    }, [])

    React.useEffect(() => {
        if (valueSearch) {
            let companiesSeach = []
            companiesArr.forEach(el => {
                if (el.company.indexOf(valueSearch) !== -1) {
                    companiesSeach.push(el)
                }
            })

            setCompaniesArrSearch([...companiesSeach])
        } else {
            setCompaniesArrSearch([])
        }
    }, [valueSearch])


    React.useEffect(() => {
        let companiesOnPage = [];
        for (let i = (20 * page) - 20; i < 20 * page; i++) {
            if (companiesArrSearch.length !== 0) {
                setCountsCompanies(companiesArrSearch.length)
                if (companiesArrSearch[i])
                    companiesOnPage.push(companiesArrSearch[i])
            } else {
                if (valueSearch) {
                    setCountsCompanies(companiesArrSearch.length)
                    if (companiesArrSearch[i])
                        companiesOnPage.push(companiesArrSearch[i])
                } else {
                    setCountsCompanies(companiesArr.length)
                    if (companiesArr[i])
                        companiesOnPage.push(companiesArr[i])
                }
            }
        }
        setCompanyList([...companiesOnPage])
    }, [page, companiesArr, companiesArrSearch])


    return (
        <Box sx={{ pt: '1rem' }}>
            <Box sx={{ margin: '0 1rem' }}>
                <MainDarkBox bgcolor='rgb(35, 48, 68);'>
                    <Grid container mt={2}>
                        <Grid item xs={12} md={10} sx={{ pl: { xs: '0px', md: '30px' } }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ background: 'rgb(64, 122, 214, 0.2)', width: '48px', borderRadius: '50%', mr: '20px', height: '48px', cursor: 'pointer' }}
                                >
                                    <Box sx={{ width: '24px', margin: 'auto', padding: '12px 0px 8px' }}>
                                        <img width='100%' src={search} />
                                    </Box>
                                </Box>
                                <Box sx={{
                                    mt: '5px', width: '85%',
                                    '.inputSearch:focus': {
                                        borderColor: 'rgb(64, 122, 214) !important'
                                    }
                                }}>
                                    <input value={valueSearch} onChange={handleChangeSearch} className="inputSearch" type="text" placeholder='Search...' />
                                    <Typography sx={{ fontSize: '0.857rem', color: '#b4b7bd', mt: '5px' }}>{countsCompanies && countsCompanies} organizations found</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {activeProgress &&
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <CircularProgress sx={{ color: '#7367f0' }} />
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </MainDarkBox>
            </Box>
            {!companyList ? <Box sx={{ display: 'flex', justifyContent: 'center', m: '1rem' }}>
                <CircularProgress color="secondary" />
            </Box> :
                <Box sx={{ margin: '1rem' }}>
                    <TableVenturePartners companyList={companyList} page={page} bgcolor='rgb(35, 48, 68)' />
                </Box>}
            {companyList && <PaginationDark
                page={page}
                count={Math.ceil(countsCompanies / 20)}
                firstPage={firstPage}
                prevPage={prevPage}
                handleChange={handleChange}
                nextPage={nextPage}
                bgcolor='rgb(35, 48, 68);'
            />}
        </Box >
    )
}


export default VenturePartners