import React from 'react'
import { Box, Grid, Typography, Link, Divider } from '@mui/material';
import DialogBox from '../dialog';
import { makeStyles } from '@mui/styles';

const DialogCompanies = ({ handleClose, companyDetails, marketing, legal, financialServices, software, hardware, progress, bgcolor }) => {

    const [fields, setFields] = React.useState([])

    const useStyles = makeStyles({
        '@global': {
            '.MuiDialog-paperFullScreen': {
                backgroundColor: 'rgb(35, 48, 68) !important'
            },
        }
    });
    const classes = useStyles();


    let news = [
        {
            title: "What happened",
            lowellTitle: "Lowell Group",
            date: "15.03.2022",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            title: "Title",
            lowellTitle: "Lowell Group",
            date: "14.03.2022",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
            title: "Title What happened",
            lowellTitle: "Lowell Group",
            date: "13.03.2022",
            text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        }
    ]

    React.useEffect(() => {
        let data = [];
        if (companyDetails) {
            for (let i in companyDetails.fields) {
                data.push(i)
            }
            setFields([...data])
        }
    }, [companyDetails])
    return (
        <Box>
            <DialogBox title="Company Details" handleClose={handleClose} bgcolor={bgcolor}>
                {companyDetails &&
                    <Box>
                        <Grid container spacing={4} >
                            <Grid item xs={12} md={12}>
                                <Typography mb={2} sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>{companyDetails.title}</Typography>
                                <Box sx={{ border: '1px solid #3b4253', borderRadius: '4px' }}>
                                    {fields.length > 0 && fields.map(el => {
                                        if (companyDetails.fields[el].value !== null && companyDetails.fields[el].value) {
                                            let valueFields = companyDetails.fields[el];
                                            valueFields.value += ''
                                            console.log(valueFields.value, valueFields.value.indexOf('http'))
                                            if (el !== 'description' && el !== 'logo') {
                                                if (valueFields.value.indexOf('http') !== -1) {
                                                    return (
                                                        <Box>
                                                            <Divider sx={{ background: '#3b4253' }} />
                                                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                                                <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>{valueFields.title}</Typography>
                                                                <Link href={companyDetails.fields[el].value} target="_blank" sx={{ textDecoration: 'none' }}>
                                                                    <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{valueFields.value}</Typography>
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    )
                                                } else {
                                                    return (
                                                        <Box>
                                                            <Divider sx={{ background: '#3b4253' }} />
                                                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                                                <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>{valueFields.title}</Typography>
                                                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{valueFields.value}</Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                            }
                                        }
                                    })}
                                </Box>
                                <Typography mt={2} sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.description.value}</Typography>
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <Typography mb={2} sx={{ color: '#D0D2D6', fontSize: '1.286rem' }}>Company News</Typography>
                                {news && news.map((data) => (
                                    <Box sx={{ mb: "1rem" }}
                                        key={data.title}>
                                        <Box sx={{ p: "1.5rem", backgroundColor: "#40485A", borderRadius: "4px" }}>
                                            <Typography sx={{ color: '#FFFFFF', fontSize: '16px', width: "50%", display: "inline-block" }}>{data.title}</Typography>
                                            <Typography sx={{ color: '#D0D2D6', fontSize: '12px', width: "50%", textAlign: "right", display: "inline-block", opacity: "0.5" }}>{data.date}</Typography>
                                            <Typography sx={{ color: '#D0D2D6', fontSize: '14px', opacity: "0.5" }}>{data.lowellTitle}</Typography>
                                            <Divider sx={{ m: "0.5rem 0", borderColor: "#293145", opacity: "0.6" }} />
                                            <Typography sx={{ color: '#D0D2D6', fontSize: '14px', width: "100%" }}>{data.text}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Grid> */}
                        </Grid>
                    </Box>}
            </DialogBox >
        </Box >
    )
}
export default DialogCompanies