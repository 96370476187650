import { Box } from '@mui/material';
import React from 'react';

const SelectMindmap = ({mapNumber, mapData}) => {
    const renderMap = (mapNumber) => {
                return mapData[mapNumber]['urlMindmap']
    }

    return (
        <Box sx={{p: '0'}}>
            <iframe width='100%' height='100%' style={{height: `calc(${window.innerHeight}px - 6rem)`}} src={ renderMap(mapNumber) } frameBorder="0" />
        </Box>
    )
}

export default SelectMindmap;