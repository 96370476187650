import React from 'react';
import { Box, Typography, InputBase } from '@mui/material';

import comingSoon from '../../../../assets/coming-soon-dark.svg'
import { styled } from '@mui/material/styles';

const TextInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        color: '#b4b7bd',
        position: 'relative',
        backgroundColor: '#283046',
        border: '1px solid #404656',
        fontSize: 16,
        width: '300px',
        padding: '10px 12px',
        '&:focus': {
            borderColor: '#213f66',
        },
    },
}));

const Contacts = () => {

    return (
        <Box sx={{ p: { md: '4rem', xl: '5rem', xs: '1rem' } }}>
            <Typography sx={{
                fontFamily: '"Montserrat", Helvetica, Arial, serif',
                fontWeight: '500',
                lineHeight: '1.2',
                fontSize: '1.714rem',
                color: '#d0d2d6',
                mb: '1rem',
                textAlign: 'center'
            }}>
                Contact us
            </Typography>
            <Typography sx={{
                fontSize: '1rem',
                fontFamily: '"Montserrat", Helvetica, Arial, serif',
                lineHeight: '1.5rem',
                color: '#d0d2d6',
                mb: '3rem',
                textAlign: 'center'
            }}>
                For all enquiries, please write to us using email below!
            </Typography>
            <Box component="form" sx={{ display: 'flex', justifyContent: 'center' }}>
                <TextInput defaultValue="info@dka.global" id="text-input" readOnly />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: { md: '450px' } }}>
                <img width='80%' sx={{ verticalAlign: 'middle' }} src={comingSoon} alt="Email us" />
            </Box>
        </Box>
    )
}

export default Contacts;