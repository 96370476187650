import { Box, Typography, Divider } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";
import RadialChart from './../charts/radialBar';
import OneBar from './../charts/oneBar';

const RadialBarBlock = ({ radialBarBlockData }) => {

  return (
    <MainDarkBox>
      <Box>
      <Box sx={{height: "200px"}}>
        <Typography sx={{ fontSize: "16px", fontFamily: "Montserrat", color: "#FFFFFF", textAlign: "center"}}> {radialBarBlockData.name} </Typography>
        <RadialChart series = {radialBarBlockData.series}/>
      </Box>
        <Divider sx={{ borderWidth: "1px", ml: "-1.5rem", mr: "-1.5rem"}} light={false}/>
      <Box sx={{height: "450px"}}>
        <OneBar data = {radialBarBlockData.data[0]} color = "#7B61FF"/>
        <OneBar data = {radialBarBlockData.data[1]} color = "#DB6AB5"/>
        <OneBar data = {radialBarBlockData.data[2]} color = "#00CFE8"/>
        <OneBar data = {radialBarBlockData.data[3]} color = "#FF9F43"/>
        <OneBar data = {radialBarBlockData.data[4]} color = "#28C7AA"/>
      </Box>
      </Box>
    </MainDarkBox>
  );
};

export default RadialBarBlock;