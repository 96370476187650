import React from 'react'
import { Box, Grid, Typography, Link, Divider } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MatcingTool from './matcingTool';
import NewsList from './newsList';
import DialogBox from '../../../../dialog';


const DialogCompanies = ({ handleClose, companyDetails, marketing, legal, financialServices, software, hardware, progress, bgcolor }) => {

    return (
        <Box>
            <DialogBox title="Company Details" handleClose={handleClose} bgcolor={bgcolor}>
                {companyDetails &&
                    <Box>
                        <Grid container spacing={4} >
                            <Grid item xs={12} md={bgcolor ? 12 : 6}>
                                <Typography mb={2} sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>{companyDetails.title}</Typography>
                                <Box sx={{ border: '1px solid #3b4253', borderRadius: '4px' }}>
                                    {companyDetails.fields.link.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>Website</Typography>
                                        <Link href={companyDetails.fields.link.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px', }}>{companyDetails.fields.link.value}
                                            </Typography>
                                        </Link>
                                    </Box>}
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TWITTER PAGE</Typography>
                                        <Link href={companyDetails.fields.c_tw.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.c_tw.value}</Typography>
                                        </Link>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    {companyDetails.fields.c_em.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>CONTACT EMAIl</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.c_em.value}</Typography>
                                    </Box>}
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>LINKEDIN PAGE</Typography>
                                        <Link href={companyDetails.fields.c_li.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.c_li.value}</Typography>
                                        </Link>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>YEAR FOUNDED</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.year.value}</Typography>
                                    </Box>
                                    {companyDetails.fields.region.value && <Divider sx={{ background: '#3b4253' }} />}
                                    {companyDetails.fields.region.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>REGION</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.region.value}</Typography>
                                    </Box>}
                                    {companyDetails.fields.country.value && <Divider sx={{ background: '#3b4253' }} />}
                                    {companyDetails.fields.country.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>COUNTRY</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.country.value}</Typography>
                                    </Box>}
                                    {companyDetails.fields.city.value && <Divider sx={{ background: '#3b4253' }} />}
                                    {companyDetails.fields.city.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>CITY / STATE</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.city.value}</Typography>
                                    </Box>}
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>BUSINESS STATE</Typography>
                                        {/* <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.business_state.value}</Typography> */}
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>ESTIMATED NUMBER OF EMPLOYEES </Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.staff.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>AI/ML CONFERENCE TALKS </Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.p_conf.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>PATENTS / APPLICATIONS</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.p_pat.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TOTAL FUNDING </Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.fund_total.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TOTAL FUNDING (RANGE) </Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.fund_range.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>FUNDING STATUS</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.fund_last.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>NUMBER OF PIPELINES</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.pips.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>IPO STATUS</Typography>
                                        <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.ipo_status.value}</Typography>
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>PUBLIC</Typography>
                                        {/* <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{`${companyDetails.fields.public.value}`}</Typography> */}
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                    <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                        <Typography sx={{ color: bgcolor ? '#25A18E' : '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>UK LONGEVITY</Typography>
                                        {/* <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{`${companyDetails.fields.uk_longevity.value}`}</Typography> */}
                                    </Box>
                                    <Divider sx={{ background: '#3b4253' }} />
                                </Box>
                                <Typography mt={2} sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.description.value}</Typography>
                            </Grid>
                            {!bgcolor && <Grid item xs={12} md={6}>
                                <MatcingTool
                                    hardware={hardware}
                                    software={software}
                                    financialServices={financialServices}
                                    legal={legal}
                                    marketing={marketing}
                                    progress={progress}
                                />
                            </Grid>}

                            <NewsList companyDetails={companyDetails}/>

                        </Grid>
                    </Box>}
            </DialogBox>
        </Box >
    )
}
export default DialogCompanies