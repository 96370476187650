import React from 'react'
import { Box, Grid, Typography, Link, Divider, CircularProgress  } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


const DialogDetails = ({ items, handleClose }) => {
    return (
        <Box>
            <Box sx={{ position: 'relative', background: '#161d31', width: '100%' }}>
                <Typography sx={{ color: '#b4b7bd', padding: '10px 0px 10px 20px', fontSize: '1.286rem' }}>Company Details</Typography>
                <Box sx={{
                    position: 'absolute',
                    top: '3px',
                    right: '3px',
                    background: '#283046',
                    boxshadow: '0 3px 8px 0 rgb(11 10 25 / 49%)',
                    width: '34px',
                    height: '34px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    transition: 'all 0.15s ease 0.1s',
                    '&:hover': {
                        top: '6px',
                        right: '6px',
                    }
                }}
                    onClick={handleClose}>
                    <ClearIcon sx={{ fill: '#b4b7bd', fontSize: '18px', margin: '8px 0px 0px 8px' }} />
                </Box>
            </Box>
            {items ?
                <Box sx={{ background: '#283046', padding: '25px 35px' }}>
                    <Typography mb={2} sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>{items.title}</Typography>
                    <Typography mt={2} mb={5} sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.description.value}</Typography>
                    <Box sx={{ border: '1px solid #3b4253', borderRadius: '4px' }}>
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>Website</Typography>
                            <Link href={items.fields.link.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px', }}>{items.fields.link.value}
                                </Typography>
                            </Link>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TWITTER PAGE</Typography>
                            <Link href={items.fields.c_tw.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.c_tw.value}</Typography>
                            </Link>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>CONTACT EMAIl</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.c_em.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>LINKEDIN PAGE</Typography>
                            <Link href={items.fields.c_li.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.c_li.value}</Typography>
                            </Link>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>YEAR FOUNDED</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.year.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>REGION</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.region.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>COUNTRY</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.country.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>CITY / STATE</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.city.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>BUSINESS STATE</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.business_state.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>ESTIMATED NUMBER OF EMPLOYEES </Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.staff.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>AI/ML CONFERENCE TALKS </Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.p_conf.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>PATENTS / APPLICATIONS</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.p_pat.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TOTAL FUNDING </Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.fund_total.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TOTAL FUNDING (RANGE) </Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.fund_range.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>FUNDING STATUS</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.fund_last.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>NUMBER OF PIPELINES</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.pips.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>IPO STATUS</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.ipo_status.value}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>PUBLIC</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{`${items.fields.public.value}`}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                            <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>UK LONGEVITY</Typography>
                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{`${items.fields.uk_longevity.value}`}</Typography>
                        </Box>
                        <Divider sx={{ background: '#3b4253' }} />
                    </Box>
                </Box> :
                <Box sx={{ background: '#283046', padding: '25px 35px' }}>
                    <CircularProgress  color="secondary" />
                </Box>}
        </Box >
    )
}
export default DialogDetails