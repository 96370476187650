import React, { Suspense } from 'react';
import { Box, Grid } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";
const Chart = React.lazy(() => import('react-apexcharts'))

const StarChart = ({ series }) => {
  const options = {
    chart: {
      foreColor: "#373d3f",
      toolbar: {
        show: false,
      },
      height: 350,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    stroke: {
      colors: ["#6A7693", "#00CFE8"],
      width: 2,
    },
    fill: {
      colors: ["#6A7693", "#00CFE8"],
      opacity: 0.4,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: [
        "Vaccination Rate",
        ["Economy", "Resilience"],
        ["Government", "Efficiency"],
        ["Healthcare", "Management"],
        ["Quarantine", "Efficiency"],
      ],
      labels: {
        show: true,
        rotateAlways: true,
        style: {
          colors: ["#D0D2D6", "#D0D2D6", "#D0D2D6", "#D0D2D6", "#D0D2D6"],
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
      },
    },
    yaxis: {
      show: false,
      tickAmount: 4,
      min: 0,
      max: 100,
    },
    plotOptions: {
      radar: {
        offsetY: 24,
        size: 100,
        polygons: {
          strokeColors: "#353B4C",
          strokeWidth: 2,
          connectorColors: "#353B4C",
          fill: {
            colors: ["#171D2F"],
          },
        },
      },
    },

    legend: {
      show: true,
      showForNullSeries: false,
      showForZeroSeries: false,
      inverseOrder: true,
      floating: true,
      position: "top",
      horizontalAlign: "left",
      offsetX: -20,
      offsetY: 15,
      fontSize: "16px",
      fontFamily: "Montserrat",
      labels: {
        colors: ["#D0D2D6"],
      },
      markers: {
        strokeWidth: 2,
        strokeColor: ["#6A7693", "#00CFE8"],
        fillColors: ["#293145", "#293145"],
        width: 6,
        height: 6,
        radius: 6,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
  };

  return (
    <Grid item xs={12} md={6} sx={{ pt: "1rem", pl: { md: "1rem", xs: "0" } }}>
      <MainDarkBox>
        <Box sx={{ m: "-1.5rem", height: "292px" }}>
          <Suspense fallback={<div>...loading</div>}>
            <Chart
              options={options}
              series={series}
              type="radar"
              height={292}
            />
          </Suspense>
        </Box>
      </MainDarkBox>
    </Grid>
  );
};

export default StarChart;
