import { Box } from "@mui/material"
import React from 'react';
import Chart from 'react-apexcharts';


const BarGraph = ({ data }) => {
    let dataGraph = [], labels = [];
    data.forEach(el => {
        dataGraph.push(el.val)
        labels.push(el.x)
    });
    const series = [{ data: dataGraph }]

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        grid: {
            show: false,
        },
        legend: {
            show: false
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#0743F533', '#25A18E33', '#E9453733'],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 0,
            colors: ['#fff']
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#fff'
                }
            },
            categories: labels,
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#fff'
                }
            },
        },

    }

    return (
        <Box sx={{ height: { md: '275px', xl: '350px' } }}>
            <Chart options={options} series={series} type="bar" height='100%' />
        </Box>
    )
}

export default BarGraph;