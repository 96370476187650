import React, { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    Box,
    Grid,
    Typography
} from '@mui/material';


import MainDarkBox from '../../../../mainDarkBox';
import TableStats from './tableStats';
import { api } from '../../../../api/apiCreatedDash';
import Graphs from './graphs';

import RegionalDistributionBySector from '../../../../../assets/json/creaatedDashboard/spaceTech/regional_distribution_by_sector.json'
import CompaniesRegionalDistribution from '../../../../../assets/json/creaatedDashboard/spaceTech/space_regional_distribution.json'
import CompaniesCumulativeCapitalization from '../../../../../assets/json/creaatedDashboard/spaceTech/space_cumulative_capitalization.json'
import PublicTradedCompanies from '../../../../../assets/json/creaatedDashboard/spaceTech/publicly_traded_companies_by_subsector.json'
import SelectBox from './selectBox';

var countries = require('country-data-list').countries

const MapsBlock = React.lazy(() => import('./mapsBlock'))


const DigitalMain = ({ sector, location }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [statParams, setStatParams] = React.useState();
    const [company, setCompany] = React.useState();
    const [selectValue, setSelectValue] = React.useState('Space Observation');

    const [graphPieData, setGraphPieData] = React.useState(RegionalDistributionBySector['Space Observation']);
    const [dataGraphs4, setDataGraph4] = React.useState();
    const [dataGraphs5, setDataGraph5] = React.useState();
    const [dataGraphs6, setDataGraph6] = React.useState();

    const industry = searchParams.get('Industry')
    const continent = searchParams.get('Continent');

    React.useEffect(() => {
        if (industry && sector) {
            const industries = industry;
            const sub_industries = sector;
            api.getCompanies({ industries, sub_industries })
                .then(res => {
                    setCompany(res.data)
                })
                .catch(err => { console.log(err) })
        }
    }, [sector])


    React.useEffect(() => {
        let locationData = [];
        console.log(location)
        let dataGraph = []
        if (location) {
            location.forEach(el => {
                locationData.push(countries[el].name);
                console.log(countries[el].name)
            })

            CompaniesRegionalDistribution.chart.data.forEach(el => {
                locationData.forEach(i => {
                    if (el.x === i) {
                        dataGraph.push({ x: el.x, val: el.val })
                    }
                    if (el.x === 'UK' && i === 'United Kingdom') {
                        dataGraph.push({ x: el.x, val: el.val })
                    }
                    // console.log(i)
                })
            })
            setDataGraph4([...dataGraph]);
        } else {
            setDataGraph4([...CompaniesRegionalDistribution.chart.data])
        }
        setDataGraph5(CompaniesCumulativeCapitalization);
        setDataGraph6(PublicTradedCompanies);
    }, [location])

    React.useEffect(() => {
        setGraphPieData(RegionalDistributionBySector[selectValue])
    }, [selectValue])

    let labels = []

    for (let i in RegionalDistributionBySector) {
        labels.push(i)
    }

    React.useEffect(() => {
        let cat2;
        if (location) {
            cat2 = "country"
        }
        api.getStats({ cat1: "deeptech-sub-industry", cat2, industry, sector })
            .then(res => {
                let data = [];
                let datas = [];
                let dataRes = res.chart.data;
                if (location) {
                    location.forEach(el => {
                        dataRes.forEach(i => {
                            if (i.y_id === el) {
                                datas.push(i)
                            }
                        })
                    })
                    dataRes = datas;
                }
                let ddd = []
                sector.forEach(i => {
                    let counts = 0;
                    dataRes.forEach(el => {
                        if (el.x === i) {
                            ddd.push(el)
                            counts += el.val
                        }
                    })
                    if (counts !== 0) {
                        data.push({ x: i, val: counts })
                    }
                });
                setStatParams([...data])
            })
            .catch(err => { console.log(err) })
    }, [sector, location, industry])

    return (
        <Box>
            <Grid container sx={{ color: '#fff', p: '1rem 1rem' }} spacing={2}>
                {industry && continent !== 'undefined' ?
                    <Grid item container xs={12} md={12} xl={12} spacing={2}>
                        <Grid item xs={12} md={4} xl={4}>
                            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                <Typography sx={{
                                    fontSize: { md: '16px', xl: "20px" },
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>Companies by Sectors</Typography>
                                <TableStats statParams={statParams} />
                            </MainDarkBox>
                        </Grid>
                        <Grid item xs={12} md={8} xl={8}>
                            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                <Typography sx={{
                                    fontSize: { md: '16px', xl: "20px" },
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>Map</Typography>
                                <Box>
                                    <Suspense fallback={<div>...loading</div>}>
                                        <MapsBlock continentCountry={continent}></MapsBlock>
                                    </Suspense>
                                </Box>
                            </MainDarkBox>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                <Typography sx={{
                                    fontSize: { md: '16px', xl: "20px" },
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>Companies Regional Distribution</Typography>
                                <Graphs type='barHorizontal' data={dataGraphs4} />
                            </MainDarkBox>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                <Typography sx={{
                                    fontSize: { md: '16px', xl: "20px" },
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>Companies Cumulative Capitalization ($B)</Typography>
                                <Graphs type='line' data={dataGraphs5} color={["#0743F5"]} />
                            </MainDarkBox>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                <Typography sx={{
                                    fontSize: { md: '16px', xl: "20px" },
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>Publicly Traded Companies by Sub-sector</Typography>
                                <Graphs type='line' data={dataGraphs6} />
                            </MainDarkBox>
                        </Grid>
                    </Grid>
                    :
                    industry ?
                        <Grid item container xs={12} md={12} xl={12} spacing={2}>
                            <Grid item xs={12} md={8} xl={8}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography sx={{
                                        fontSize: { md: '16px', xl: "20px" }
                                    }}>Companies and Technologies</Typography>
                                    <TableStats statParams={statParams} />
                                    {/* <TableSubIndustry industry={industry} company={company} sector={sector} statParams={statParams} /> */}
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography sx={{
                                        fontSize: { md: '16px', xl: "20px" },
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>Companies Regional Distribution (by Sector)</Typography>
                                    <SelectBox labels={labels} selectValue={selectValue} setSelectValue={setSelectValue} />
                                    <Graphs type='sector' data={graphPieData} />
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography sx={{
                                        fontSize: { md: '16px', xl: "20px" },
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>Companies Regional Distribution</Typography>
                                    <Graphs type='barHorizontal' data={dataGraphs4} />
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography sx={{
                                        fontSize: { md: '16px', xl: "20px" },
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>Companies Cumulative Capitalization ($B)</Typography>
                                    <Graphs type='line' data={dataGraphs5} color={["#0743F5"]} />
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography sx={{
                                        fontSize: { md: '16px', xl: "20px" },
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>Publicly Traded Companies by Sub-sector</Typography>
                                    <Graphs type='line' data={dataGraphs6} />
                                </MainDarkBox>
                            </Grid>
                        </Grid>
                        :
                        <Grid item container xs={12} md={12} xl={12} spacing={2}>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography sx={{
                                        fontSize: { md: '16px', xl: "20px" },
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>Map</Typography>
                                    <Box>
                                        <Suspense fallback={<div>...loading</div>}>
                                            <MapsBlock continentCountry={continent}></MapsBlock>
                                        </Suspense>
                                    </Box>
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography>GraphNotInd</Typography>
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography></Typography>
                                    <Graphs type='bar' data={dataGraphs4} />
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography>GraphNotInd</Typography>
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography>GraphNotInd</Typography>
                                </MainDarkBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                    <Typography>TableNotInd</Typography>
                                </MainDarkBox>
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Box >)
}

export default DigitalMain;