import { Box, Typography, Grid } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const OneBarTwoColors = ({ color, data, dataAverage, country }) => {


    let barColor = "";
    if(data >= dataAverage){
        barColor = "#2A99FF";
    }
    else{
        barColor = "#DB6A6A";
    }

    const options = {
      grid: {
        show: false,
      },
      colors: barColor,
      chart: {
        offsetY: -10,
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 6,
          distributed: false,
          colors: {
            backgroundBarColors: ["#7366f01f"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 6,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      xaxis: {
        categories: [""],
        max: 100,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: undefined,
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
    };

  return (
    <Box sx={{ pb: "1rem", height: "40px", background: color}}>
      <Grid item container>
        <Grid item xs={2} >
          <Box sx={{ m: "1rem", verticalAlign: "middle",}}>
            <Typography
                sx={{
                  color: `#D0D2D6 !important`,
                  pt: "0.3rem",
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                }}
                >{country}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={10} >
      <Box sx={{ml: {md:"-1rem", xs:"1rem"}, path: {clipPath: "inset(0% 0% 0% 0% round 6px)"}}}>
        <Suspense fallback={<div>...loading</div>}>
          <Chart
            options={options}
            series={[
                {
                  name: "Economy Resilience",
                  data: [data],
                },
              ]}
            type="bar"
            height={65}
            width={'98%'}
          />
        </Suspense>
      </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OneBarTwoColors;