import { Box } from "@mui/material";
import Covid19DataTracker from "./fieldContainer/covid19DataTracker";
import EconomyResilience from "./fieldContainer/economyResilience";
import GovernmentEfficiency from "./fieldContainer/governmentEfficiency";
import HealthcareManagement from "./fieldContainer/healthcareManagement";
import QuarantineEfficiency from "./fieldContainer/quarantineEfficiency";
import RegionalSafetyRanking from "./fieldContainer/regionalSafetyRanking";
import RiskSafetyAssessment from "./fieldContainer/riskSafetyAssessment";
import VaccinationRate from "./fieldContainer/vaccinationRate";


const RenderField = ({ type, setDataCovidHeader, parameters }) => {

    const renderField = (type) => {
        switch (type) {
            case 'Covid19DataTracker':
                return (
                    <Covid19DataTracker parameters={parameters}
                        setDataCovidHeader={setDataCovidHeader}/>
                )
            case 'RegionalSafetyRanking':
                return (
                    <RegionalSafetyRanking parameters={parameters}
                        setDataCovidHeader={setDataCovidHeader} />
                )
            case 'RiskSafetyAssessment':
                return (
                    <RiskSafetyAssessment />
                )
            case 'GovernmentEfficiency':
                return (
                    <GovernmentEfficiency />
                )
            case 'EconomyResilience':
                return (
                    <EconomyResilience />
                )
            case 'HealthcareManagement':
                return (
                    <HealthcareManagement />
                )
            case 'QuarantineEfficiency':
                return (
                    <QuarantineEfficiency />
                )
            case 'VaccinationRate':
                return (
                    <VaccinationRate />
                )
            default:
                break;
        }
    }

    return (
        <Box>
            {renderField(type)}
        </Box>
    )
}


export default RenderField;