import { Grid, Box, Typography } from "@mui/material";

const Footer = () => {

    return(
        <Grid container >
            <Box sx={{background:"#253042", width:"100%", p:"1.5rem"}}>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '14px', letterSpacing: "0.02em" }}>COPYRIGHT © 2022Deep Knowledge Group</Typography>
            </Box>
        </Grid>
    )
}

export default Footer;