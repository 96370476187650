import { Box, Grid } from "@mui/material";

import DeepAnalytics from './fieldContainer/deepAnalytics';
import Footer from './fieldContainer/footer';


const renderField = () => {

    return(
        <Box>
            <Grid container sx={{p: "1.5rem"}}>
                <Grid item container xs={12}>
                    <DeepAnalytics />
                </Grid>
            </Grid>
            <Footer />
        </Box>
    )
}

export default renderField;