
import { Box } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';


const PolarAreaChart = ({ series, labels }) => {

    const [options, setOptions] = React.useState({
        chart: {
        },
        stroke: {
            colors: ['#fff']
        },
        fill: {
            opacity: 0.8
        },
    })

    const option = {
        chart: {
        },
        stroke: {
            show: true,
            colors: ['#bfbfbf'],
        },
        fill: {
            opacity: 0.8
        },
        labels: labels,
        legend: {
            position: 'bottom',
            labels: {
                colors: ['#FFF', '#FFF', '#FFF']
            }
        },
        yaxis: {
            show: true,
            tickAmount: 6,
            min: 0,
            max: 100,
            labels: {
                show: true,
                style: {
                    colors: ['#FFF', '#FFF', '#FFF', '#FFF', '#FFF', '#FFF'],
                },
            },
        }
    }

    React.useEffect(() => {
        setOptions(option)
    }, [labels])

    return (
        <Box>
            <Chart options={options} series={series} type='polarArea' />
        </Box >
    )
}

export default PolarAreaChart;