import React, { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    Box,
    Grid,
    Select,
    Typography
} from '@mui/material';

import MainDarkBox from '../../../../mainDarkBox';
import TableSubIndustry from './tableSubIndustry';
import { api } from '../../../../api/apiCreatedDash';
import Graphs from './graphs';


import spaceTechIntro from '../../../../../assets/image/spaceTech/space_dash_mindmap.png'
import spaceDashReportCover from '../../../../../assets/image/spaceTech/space_dash_report_cover.png'

import RegionalDistributionBySector from '../../../../../assets/json/creaatedDashboard/spaceTech/regional_distribution_by_sector.json'
import CompaniesRegionalDistribution from '../../../../../assets/json/creaatedDashboard/spaceTech/space_regional_distribution.json'
import CompaniesCumulativeCapitalization from '../../../../../assets/json/creaatedDashboard/spaceTech/space_cumulative_capitalization.json'
import PublicTradedCompanies from '../../../../../assets/json/creaatedDashboard/spaceTech/publicly_traded_companies_by_subsector.json'
import SelectBox from './selectBox';





const DigitalMain = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [company, setCompany] = React.useState();
    const [selectValue, setSelectValue] = React.useState('Space Observation');

    const [graphPieData, setGraphPieData] = React.useState(RegionalDistributionBySector['Space Observation']);
    const [dataGraphs4, setDataGraph4] = React.useState();
    const [dataGraphs5, setDataGraph5] = React.useState();
    const [dataGraphs6, setDataGraph6] = React.useState();

    const industry = searchParams.get('Industry')
    const sector = searchParams.get('Sector')
    const continent = searchParams.get('Continent');

    React.useEffect(() => {
        if (industry && sector) {
            const industries = industry;
            const sub_industries = sector;
            api.getCompanies({ industries, sub_industries })
                .then(res => {
                    setCompany(res.data)
                })
                .catch(err => { console.log(err) })
        }
    }, [])


    React.useEffect(() => {
        setDataGraph4(CompaniesRegionalDistribution.chart.data)
        setDataGraph5(CompaniesCumulativeCapitalization)
        setDataGraph6(PublicTradedCompanies)
    })

    React.useEffect(() => {
        setGraphPieData(RegionalDistributionBySector[selectValue])
    }, [selectValue])

    let labels = []

    for (let i in RegionalDistributionBySector) {
        labels.push(i)
    }


    return (
        <Box>
            <Grid container sx={{ color: '#fff', p: '1rem 1rem' }} spacing={2}>
                <Grid item xs={12} md={4} >
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{
                            fontSize: { md: '16px', xl: "20px" },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>Companies Regional Distribution (by Sector)</Typography>
                        <SelectBox labels={labels} selectValue={selectValue} setSelectValue={setSelectValue} />
                        <Graphs type='sector' data={graphPieData} />
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={4} >
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{
                            fontSize: { md: '16px', xl: "20px" },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>Companies Cumulative Capitalization ($B)</Typography>
                        <Graphs type='line' data={dataGraphs5} color={["#0743F5"]} />
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={4} >
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{
                            fontSize: { md: '16px', xl: "20px" },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>Publicly Traded Companies by Sub-sector</Typography>
                        <Graphs type='line' data={dataGraphs6} />
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={4} >
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{ fontSize: { md: '16px', xl: "20px" } }}>Mindmap</Typography>
                        <Box sx={{ height: { md: '240px', xl: '350px' }, pt: '20px'  }}>
                            <img width='100%' src={spaceTechIntro} alt='Mindmap' />
                        </Box>
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={4} >
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{ fontSize: { md: '16px', xl: "20px" } }}>Companies Regional Distribution</Typography>
                        <Graphs type='barHorizontal' data={dataGraphs4} />
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={4} >
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{ fontSize: { md: '16px', xl: "20px" } }}>Investment Digest</Typography>
                        <Box sx={{ height: { md: '240px', xl: '350px' }, pt: '20px' }}>
                            <img width='100%' src={spaceDashReportCover} alt='Investment Digest' />
                        </Box>
                    </MainDarkBox>
                </Grid>
            </Grid>
        </Box >)
}

export default DigitalMain;