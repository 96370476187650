import React from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import MainDarkBox from '../mainDarkBox'

const PaginationDark = ({ firstPage, prevPage, count, page, handleChange, nextPage, bgcolor }) => {

    return (
        <Box sx={{ margin: '0 1rem' }}>
            <MainDarkBox bgcolor={bgcolor}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{
                        background: bgcolor ? '#314054' : '#242b3d',
                        color: '#b4b7bd',
                        padding: '4px 8px',
                        borderRadius: '5rem 0 0 5rem',
                        fontSize: '1rem',
                        '&:hover': { color: bgcolor ? 'rgb(64, 122, 214)' : '#7367f0' },
                        cursor: 'pointer'
                    }} onClick={firstPage}>First</Typography>
                    <Typography sx={{
                        background: bgcolor ? '#314054' : '#242b3d',
                        color: '#b4b7bd',
                        padding: '4px 12px',
                        fontSize: '1rem',
                        '&:hover': { color: bgcolor ? 'rgb(64, 122, 214)' : '#7367f0' },
                        cursor: 'pointer'
                    }}
                        onClick={prevPage}>Previous</Typography>
                    <Pagination hideNextButton={true} hidePrevButton={true} count={count} page={page} onChange={handleChange}
                        sx={{
                            background: bgcolor ? '#314054' : '#242b3d',
                            fontSize: '1rem',
                            '.MuiPaginationItem-root':
                            {
                                color: '#b4b7bd',
                                margin: 0,
                                '&:hover': { color: bgcolor ? 'rgb(64, 122, 214)' : '#7367f0' }
                            },
                            '.Mui-selected':
                            {
                                background: bgcolor ? '#314054' : '#7367f0 !important', color: '#fff',
                                '&:hover': {
                                    background: bgcolor ? '#314054' : '#7367f0', color: '#fff',
                                }
                            }
                        }} />
                    <Typography sx={{
                        background: bgcolor ? '#314054' : '#242b3d',
                        color: '#b4b7bd', padding: '4px 12px',
                        borderRadius: '0 5rem 5rem 0',
                        fontSize: '1rem',
                        '&:hover': { color: bgcolor ? 'rgb(64, 122, 214)' : '#7367f0' },
                        cursor: 'pointer'
                    }} onClick={nextPage}>Next</Typography>
                </Box>
            </MainDarkBox>
        </Box>
    )
}

export default PaginationDark;