

import axios from 'axios';
import qs from "qs";


let urlBase = `${process.env.REACT_APP_URL_NEW}`
if (process.env.REACT_APP_URL.indexOf(window.location.host) != -1) {
    urlBase = `${process.env.REACT_APP_URL}`
}
const instanceCovid = axios.create({
    baseURL: urlBase,
});

export const api = {
    dataCovid: async ({ iso3 }) => {
        return (
            await instanceCovid.get(`covid/api/data/covid-data`, {
                params: { iso3 },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
    dataCovidLow: async ({ iso3 }) => {
        return (
            await instanceCovid.get(`covid/api/data/data-low`, {
                params: { iso3 },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
    dataMax: async ({ dateStart, dateEnd }) => {
        return (
            await instanceCovid.get(`covid/api/data/max-data`, { params: { dateStart, dateEnd } })
        ).data;
    }
}