import React from 'react';
import { Box, Grid, Typography, Popover, RadioGroup, FormControlLabel, Radio, CircularProgress, Drawer, Pagination } from '@mui/material';
import MainDarkBox from '../../../../mainDarkBox'
import { api } from '../../../../api/apiChart'
import search from '../../../../../assets/searchNew.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import default_company_image from '../../../../../assets/image/default_company_image.png'
import ListItems from './listItems';
const Competitors = () => {
    const [valueSearch, setValueSearch] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dropDownFilters, setDropDownFilters] = React.useState('companies')
    const [activeProgress, setActiveProgress] = React.useState(true);
    const [investors, setInvestors] = React.useState();
    const [competitors, setCompetitors] = React.useState();
    const [investorsOfCompetitors, setInvestorsOfCompetitors] = React.useState();
    const [portfolio, setPortfolio] = React.useState();
    const [portfolioOfCompetitors, setPortfolioOfCompetitors] = React.useState()
    const [portfolioCompetitors, setPortfolioCompetitors] = React.useState()
    const [firstItem, setFirstItem] = React.useState()

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDropDown = (e) => {
        setDropDownFilters(e.target.value)
        itemCompanies(e.target.value)
    };
    const requstValSearch = () => {
        setActiveProgress(true)
        itemCompanies()
    }
    const detailCompany = (slug, e) => {
         api.companiDetails({ slug: slug})
        .then(res => {
            setFirstItem({
                title: res.fields.title.value,
                description: res.fields.description.value,
                logo: res.fields.logo.value,
            })
            if (dropDownFilters == 'investors' || e == 'investors') {
                show_company_related_list(slug, 'portfolio', 'portfolio-list');
                show_company_related_list(slug, 'portfolio_competitors_investors', 'portfolio-competitors-investors-list');
                show_company_related_list(slug, 'portfolio_competitors', 'portfolio-competitors-list');
            } else {
                show_company_related_list(slug, 'investors', 'investors-list');
                show_company_related_list(slug, 'competitors', 'competitors-list');
                show_company_related_list(slug, 'competitors_investors', 'competitors-investors-list');
            }
            setActiveProgress(false)
        })
        .catch(err => {
            console.log(err)
        })
    }
    const itemCompanies = (e, slug) => {
        api.competitorsMain({ section: e ? e : dropDownFilters, dashboard: 'asia', title: valueSearch })
            .then(res => {
                setFirstItem({
                    title: res.data[0].title,
                    description: res.data[0].description,
                    logo: res.data[0].logo,
                })
                setActiveProgress(false)
                let slugMain
                if (slug) {
                    slugMain = slug
                } else {
                    slugMain = res.data[0].slug
                }
                if (dropDownFilters == 'investors' || e == 'investors') {
                    show_company_related_list(slugMain, 'portfolio', 'portfolio-list');
                    show_company_related_list(slugMain, 'portfolio_competitors_investors', 'portfolio-competitors-investors-list');
                    show_company_related_list(slugMain, 'portfolio_competitors', 'portfolio-competitors-list');
                } else {
                    show_company_related_list(slugMain, 'investors', 'investors-list');
                    show_company_related_list(slugMain, 'competitors', 'competitors-list');
                    show_company_related_list(slugMain, 'competitors_investors', 'competitors-investors-list');
                }
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    }
    React.useEffect(() => {
        itemCompanies()
    }, []);
    function show_company_related_list(slug, mode, container_id) {
        api.competitors({ company: slug, dashboard: 'asia', mode: mode })
            .then(res => {
                switch (container_id) {
                    case 'portfolio-list':
                        return (
                            setPortfolio(res.companies)
                        );
                    case 'portfolio-competitors-investors-list':
                        return (
                            setPortfolioCompetitors(res.companies)
                        );
                    case 'portfolio-competitors-list':
                        return (
                            setPortfolioOfCompetitors(res.companies)
                        );
                    case 'investors-list':
                        return (
                            setInvestors(res.companies)
                        );
                    case 'competitors-list':
                        return (
                            setCompetitors(res.companies)
                        );
                    case 'competitors-investors-list':
                        return (
                            setInvestorsOfCompetitors(res.companies)
                        );
                    default:
                        break;
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Box sx={{ m: '0 1rem', pt: '1rem' }}>
            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                <Grid container mt={2}>
                    <Grid item xs={12} md={6} sx={{ pl: { xs: '0px', md: '30px' } }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ background: 'rgb(64, 122, 214, 0.2)', width: '48px', borderRadius: '50%', mr: '20px', height: '48px', cursor: 'pointer' }}
                                onClick={() => requstValSearch()}
                            >
                                <Box sx={{ width: '24px', margin: 'auto', padding: '12px 0px 8px' }}>
                                    <img width='100%' src={search} />
                                </Box>
                            </Box>
                            <Box sx={{ mt: '5px', width: '85%' }}>
                                <input value={valueSearch} onChange={e => setValueSearch(e.target.value)} className="inputSearch" type="text" placeholder='Select Company' />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                background: '#213f66',
                                color: '#fff',
                                padding: '0.486rem 1.5rem',
                                fontSize: '1rem',
                                borderRadius: '0.358rem',
                                alignItems: 'center',
                                cursor: 'pointer',
                                mt: '5px',
                                '&:hover': {
                                    boxShadow: '0 8px 25px -8px #31517c'
                                }
                            }}
                                onClick={handleClick}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{dropDownFilters == 'companies' ? 'Companies' : 'Investors'}</Typography>
                                <KeyboardArrowDownIcon sx={{ fill: '#fff', fontSize: '20px', ml: '5px' }} />
                            </Box>
                            <Popover
                                sx={{
                                    '.MuiPopover-paper': {
                                        background: '#1d3049',
                                        boxShadow: '0 4px 24px 0 rgb(0 0 0 / 24%)',
                                        marginTop: '0.5rem',
                                        borderRadius: '0.358rem',
                                    },
                                }}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Box >
                                    <RadioGroup value={dropDownFilters} onChange={(e) => {
                                        handleDropDown(e)
                                    }}
                                        aria-label="position" name="position" defaultValue="top" sx={{ display: 'flex', alignItems: 'start' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '10px 60px 10px 10px',
                                            '&:hover': { background: 'rgba(115, 103, 240, 0.12)' }
                                        }}>
                                            <FormControlLabel
                                                className='app'
                                                sx={{ '.MuiFormControlLabel-label': { color: '#b4b7bd', fontSize: '14px', '&:hover': { color: '#7367f0' } } }}
                                                value="top"
                                                control={<Radio value="companies" sx={{ display: 'none' }} />}
                                                label="Companies"
                                                labelPlacement="start"
                                                onClick={handleClose}
                                            />

                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '10px 80px 10px 10px',
                                            '&:hover': { background: 'rgba(115, 103, 240, 0.12)' }
                                        }}>
                                            <FormControlLabel
                                                className='app'
                                                sx={{ '.MuiFormControlLabel-label': { color: '#b4b7bd', fontSize: '14px', '&:hover': { color: '#7367f0' } } }}
                                                value="start"
                                                control={<Radio value="investors" sx={{ display: 'none' }} />}
                                                label="investors"
                                                labelPlacement="start"
                                                onClick={handleClose}
                                            />
                                        </Box>
                                    </RadioGroup>
                                </Box>
                            </Popover>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {activeProgress &&
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CircularProgress sx={{ color: 'rgb(64, 122, 214)' }} />
                            </Box>
                        }
                    </Grid>
                </Grid>
                {firstItem &&
                    <Grid mt={2} container>
                        <Grid item xs={12} md={2}>
                            <Box sx={{ width: { xs: '50%', md: '70%' }, margin: '0 auto' }}>
                                <img width='100%' src={firstItem.logo ? `/files/${firstItem.logo}` : default_company_image} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography sx={{ fontSize: '1.286rem', color: '#d0d2d6', fontWeight: 500 }}>{firstItem.title}</Typography>
                            <Typography sx={{ fontSize: '14px', color: '#b4b7bd' }}>{firstItem.description}</Typography>
                        </Grid>
                    </Grid>}
            </MainDarkBox>
            <ListItems
                investors={investors}
                competitors={competitors}
                investorsOfCompetitors={investorsOfCompetitors}
                portfolio={portfolio}
                portfolioOfCompetitors={portfolioOfCompetitors}
                portfolioCompetitors={portfolioCompetitors}
                dropDownFilters={dropDownFilters}
                detailCompany={detailCompany}
            />
        </Box>
    )
}

export default Competitors;