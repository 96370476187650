import { Box, Typography } from "@mui/material";
import React from "react";
import ChartBlock from "./chartBlock";



const TextUnderMap = ({
    location1,
    dataOne,
    dataTwo,
    dataThree,
    dataBlockGraph1,
    dataBlockGraph2,
    dataBlockGraph3,
    heigthGraph
}) => {

    const underMapText = [
        {
            id: 1,
            series: dataBlockGraph1,
            colorStroke: '#7B61FF',
            text1: 'reports highest number of new infections in the world, passing',
            text2: 'reported cases per day.',
            location: location1[0],
            data: dataOne,
        },
        {
            id: 2,
            series: dataBlockGraph2,
            colorStroke: '#00CFE8',
            text1: 'reached highest percent of people fully vaccinated that is',
            text2: '% of country population',
            location: location1[1],
            data: dataTwo,
        },
        {
            id: 3,
            series: dataBlockGraph3,
            colorStroke: '#DB6AB5',
            text1: 'reports highest mortality rate leading the world, having about',
            text2: 'deaths each day',
            location: location1[2],
            data: dataThree,
        }
    ]


    return (
        <Box>
            {underMapText && underMapText.map(el => (
                <Box key={el.id} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ minWidth: '35%' }}>
                        <ChartBlock series={el.series}
                            height={heigthGraph}
                            colorStroke={el.colorStroke}
                            toGradientStrokeColor='#29314533' />
                    </Box>
                    <Typography variant='a' sx={{ color: '#ff0', fontSize: { xs: '0.7rem', md: '0.9rem' }, textAlign: 'justify' }}><Typography variant='a' sx={{ fontWeight: '600' }}>{el.location}</Typography>
                        <Typography sx={{ color: '#D0D2D6' }} variant='a'> {el.text1}
                            <Typography variant='a' sx={{ fontWeight: '600' }}> {el.data}
                            </Typography> {el.text2}
                        </Typography>
                    </Typography>
                </Box>
            ))}
        </Box>
    )
}

export default TextUnderMap;