import { Grid } from "@mui/material";

const CubeSVG = ({ color }) => {

  return (
    <Grid item xs={12} md={12}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 36 36"
        width="42"
        height="42"
      >
        <path
          fill="none"
          stroke="#D0D2D6"
          d="M24 12.33C24 11.62 23.62 10.96 23 10.6C22.3 10.2 16.7 7 16 6.6C15.38 6.25 14.62 6.25 14 6.6C13.3 7 7.7 10.2 7 10.6C6.38 10.96 6 11.62 6 12.33C6 13.13 6 19.53 6 20.33C6 21.05 6.38 21.71 7 22.06C7.7 22.46 13.3 25.66 14 26.06C14.62 26.42 15.38 26.42 16 26.06C16.7 25.66 22.3 22.46 23 22.06C23.62 21.71 24 21.05 24 20.33C24 18.73 24 13.13 24 12.33Z"
          id="dm85Faj4a"
        ></path>
        <path
          fill="none"
          stroke="#D0D2D6"
          d="M6.27 11.29L15 16.34L23.73 11.29"
          id="fKXmch69T"
        ></path>
        <path
          fill="none"
          stroke="#D0D2D6"
          d="M15 26.41L15 16.33"
          id="alhLWZL79"
        ></path>
        <path
          fill="none"
          stroke={color}
          d="M28.83 10.2C28.83 9.1 28.25 8.08 27.3 7.54C26.22 6.92 17.61 2 16.54 1.39C15.59 0.84 14.41 0.84 13.46 1.39C12.39 2 3.78 6.92 2.7 7.54C1.75 8.08 1.17 9.1 1.17 10.2C1.17 11.42 1.17 21.26 1.17 22.49C1.17 23.59 1.75 24.6 2.7 25.15C3.78 25.77 12.39 30.68 13.46 31.3C14.41 31.85 15.59 31.85 16.54 31.3C17.61 30.68 26.22 25.77 27.3 25.15C28.25 24.6 28.83 23.59 28.83 22.49C28.83 20.03 28.83 11.42 28.83 10.2Z"
          id="a3gl0LxzzW"
        ></path>
      </svg>
    </Grid>
  );
};

export default CubeSVG;