import React from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";

import DialogsFilters from "./dialogsFilter";
import LineChart from "../charts/lineCharts";
import WorldMap from "../maps";

import filter from "../../../../../assets/filter.svg"
import stethoscopePink from '../../../../../assets/svg/stethoscopePink.svg';
import syringeGreen from '../../../../../assets/svg/syringeGreen.svg';
import TextUnderMap from "./textUnderMap";
import getCountryISO3 from 'country-iso-2-to-3'
import { Loader } from "../../../../loader";
import { api } from "../../../../api/apiCovidCharts";


const Covid19DataTracker = ({ parameters, setDataCovidHeader }) => {

    const [selectCountry, setSelectCountry] = React.useState('OWID_WRL');
    const [maxDataApi, setMaxDataApi] = React.useState()
    const [covidData, setCovidData] = React.useState();
    const [covidDataLow, setCovidDataLow] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [filterData, setFilterData] = React.useState({ showAs: 'person', startDate: '', endDate: '' });
    const [covidDataDate, setCovidDataDate] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [dataNewCasesMax, setDataNewCasesMax] = React.useState(0);
    const [dataFullyVac, setDataFullyVac] = React.useState(0);
    const [dataNewDeathMax, setDataNewDeathMax] = React.useState(0)
    const [location, setLocation] = React.useState('OWID_WRL');
    const [dataBlockGraph1Arr, setDataBlockGraph1Arr] = React.useState([{
        name: '',
        data: []
    }]);
    const [dataBlockGraph2Arr, setDataBlockGraph2Arr] = React.useState([{
        name: '',
        data: []
    }]);
    const [dataBlockGraph3Arr, setDataBlockGraph3Arr] = React.useState([{
        name: '',
        data: []
    }]);
    const [labels, setLabels] = React.useState('')

    const [location1, setLocation1] = React.useState(['loading...', 'loading...', 'loading...']);
    const [blocksGraphData, setBlocksGraphData] = React.useState()

    const [keys, setKeys] = React.useState()

    const [dataLineChart1, setDataLineChart1] = React.useState(
        [
            {
                name: "",
                data: []
            }
        ]);
    const [dataLineChart2, setDataLineChart2] = React.useState([
        {
            name: "",
            data: []
        }
    ]);

    React.useEffect(() => {
        let start = filterData.startDate;
        let end = filterData.endDate;
        api.dataMax({ dateStart: start, dateEnd: end })
            .then(res => {
                setMaxDataApi(res)
            })
            .catch(err => { console.log(err) })
    }, [filterData])

    React.useEffect(() => {
        api.dataCovid({ iso3: selectCountry })
            .then(res => {
                setCovidDataLow(res)
            })
            .catch(err => { console.log(err) })
    }, [selectCountry])

    React.useEffect(() => {
        api.dataCovid({ iso3: selectCountry })
            .then(res => {
                setCovidData(res)
            })
            .catch(err => { console.log(err) })
    }, [selectCountry])

    React.useEffect(() => {
        if (keys) {
            api.dataCovid({ iso3: keys })
                .then(res => {
                    setBlocksGraphData(res)
                })
                .catch(err => { console.log(err) })
        }
    }, [keys])

    React.useEffect(() => {
        if (blocksGraphData) {
            let data1 = [], data2 = [], data3 = [];
            blocksGraphData.forEach(el => {
                if (el.key === keys[0]) {
                    el.data.forEach(i => {
                        if (i.new_cases) {
                            data1.push(i.new_cases)
                        }
                    })
                }
                if (el.key === keys[1]) {
                    el.data.forEach(i => {
                        if (i.people_fully_vaccinated) {
                            data2.push(i.people_fully_vaccinated)
                        }
                    })
                }
                if (el.key === keys[2]) {
                    el.data.forEach(i => {
                        if (i.new_deaths) {
                            data3.push(i.new_deaths)
                        }
                    })
                }
            })
            const seriesGraph1 = {
                name: 'new_cases',
                data: data1
            }
            const seriesGraph2 = {
                name: 'vactination',
                data: data2
            }
            const seriesGraph3 = {
                name: 'new_deaths',
                data: data3
            }
            setDataBlockGraph1Arr([seriesGraph1])
            setDataBlockGraph2Arr([seriesGraph2])
            setDataBlockGraph3Arr([seriesGraph3])
        }
    }, [blocksGraphData])

    React.useEffect(() => {
        if (maxDataApi) {
            const peopleVac = maxDataApi.people_fully_vaccinated;
            const dataNewCases = maxDataApi.new_cases;
            const dataNewDeath = maxDataApi.new_deaths;

            setLocation1([dataNewCases.location, peopleVac.location, dataNewDeath.location]);

            const fullyVac = ((peopleVac.data.people_fully_vaccinated / maxDataApi.people_fully_vaccinated.population) * 100).toFixed(2)
            if (dataNewCases.data.new_cases > 1000000) {
                setDataNewCasesMax((dataNewCases.data.new_cases / 1000000).toFixed(1) + 'M');
            } else if (dataNewCases.data.new_cases > 1000) {
                setDataNewCasesMax((dataNewCases.data.new_cases / 1000).toFixed(1) + 'H');
            }
            if (dataNewDeath.data.new_deaths > 1000000) {
                setDataNewDeathMax((dataNewDeath.data.new_deaths / 1000000).toFixed(1) + 'M');
            } else if (dataNewDeath.data.new_deaths > 1000) {
                setDataNewDeathMax((dataNewDeath.data.new_deaths / 1000).toFixed(1) + 'H');
            }
            setDataFullyVac(fullyVac);
            setLoading(false);
            setKeys([dataNewCases.key, peopleVac.key, dataNewDeath.key])
        }


    }, [maxDataApi]);

    React.useEffect(() => {
        parameters[0].counts = 223;
        parameters[1].counts = 8;
        if (covidData) {
            let dataCovidCountry = covidData.data;
            let lengthData = covidData.data.length;
            if (parameters[2].titleParams === 'Total cases') {
                parameters.forEach((i) => {
                    if (i.titleParams === 'Total cases') {
                        let totalCases = covidData.data[lengthData - 1].total_cases;
                        if (totalCases > 1000000) {
                            totalCases = totalCases / 1000000;
                            totalCases = totalCases.toFixed(1)
                            totalCases = totalCases + ' M'
                        } else if (totalCases > 1000) {
                            totalCases = totalCases / 1000
                            totalCases = totalCases.toFixed(1)
                            totalCases = totalCases + ' H'
                        }
                        i.counts = totalCases
                    } else if (i.titleParams === 'Of fully vaccinated') {
                        let ofFully = "";
                        let num = 1;
                        if (lengthData !== 0){
                          while (num <= lengthData && ofFully === "") { 
                            if(covidData.data[lengthData - num].people_fully_vaccinated / covidData.population){
                                ofFully = covidData.data[lengthData - num].people_fully_vaccinated / covidData.population;
                            }
                            num = num + 1;
                          }
                        }
                        if (ofFully){
                            i.counts = (ofFully * 100).toFixed(2) + ' %'
                        }
                        else{
                            i.counts = 'NaN %'
                        }
                    }
                })
            } else {
                parameters.forEach((i, key) => {
                    if (key === 2) {
                        i.titleParams = 'Total cases';
                        i.counts = covidData.data[lengthData - 1].total_cases;
                        i.iconParams = <img alt='Total cases' width='23px' src={stethoscopePink} />
                        i.bgColor = '#DB6AB51A'
                    } else if (key === 3) {
                        i.titleParams = 'Of fully vaccinated';
                        i.counts = covidData.data[lengthData - 1].total_cases;
                        i.iconParams = <img alt='Of fully vaccinated' width='23px' src={syringeGreen} />
                        i.bgColor = '#28C7AA1A'
                    }
                })
            }
            setDataCovidHeader([...parameters]);
        }
    }, [covidData])

    React.useEffect(() => {
        if (covidDataLow) {
            let dataCovidCountry = covidDataLow.data;
            setCovidDataDate(covidDataLow.data)
            let numbers = 0;
            let label = [];
            let dataNewCases = [];
            let dataTotalCases = [];
            let dataNewDeath = [];
            let dataPeopleVaccinated = [];
            let dataPeopleFullyVaccinated = [];
            let dataTotalBoosters = [];
            dataCovidCountry.forEach(i => {
                if (new Date(i.date) > new Date(filterData.startDate) && new Date(i.date) < new Date(filterData.endDate)) {
                    label.push(i.date.substr(0, i.date.indexOf('T')));
                    if (filterData.showAs === 'person') {
                        if (i.new_cases < 0) {
                            dataNewCases.push(i.new_cases * (-1));
                        } else {
                            dataNewCases.push(i.new_cases);
                        }
                        dataTotalCases.push(i.total_cases);
                        if (i.new_deaths < 0) {
                            dataNewDeath.push(i.new_deaths * (-1));
                        } else {
                            dataNewDeath.push(i.new_deaths);;
                        }

                        
                        if(!i.people_vaccinated || i.people_vaccinated < 0.01) {
                            if(numbers > 0) {
                                dataPeopleVaccinated.push(dataPeopleVaccinated[numbers-1])
                            }
                            else {
                                dataPeopleVaccinated.push(0)
                            }
                        }
                        else {
                            dataPeopleVaccinated.push(i.people_vaccinated);
                        }
                        if(!i.people_fully_vaccinated || i.people_fully_vaccinated < 0.01) {
                            if(numbers > 0) {
                                dataPeopleFullyVaccinated.push(dataPeopleFullyVaccinated[numbers-1])
                            }
                            else {
                                dataPeopleFullyVaccinated.push(0)
                            }
                        }
                        else {
                            dataPeopleFullyVaccinated.push(i.people_fully_vaccinated);
                        }
                        if(!i.total_boosters || i.total_boosters < 0.01) {
                            if(numbers > 0) {
                                dataTotalBoosters.push(dataTotalBoosters[numbers-1])
                            }
                            else {
                                dataTotalBoosters.push(0)
                            }
                        }
                        else {
                            dataTotalBoosters.push(i.total_boosters);
                        }
                    } else {
                        if (i.new_cases < 0) {
                            dataNewCases.push(((i.new_cases / covidDataLow.population) * 100 * (-1)).toFixed(2));
                        } else {
                            dataNewCases.push(((i.new_cases / covidDataLow.population) * 100).toFixed(2));
                        }
                        dataTotalCases.push(((i.total_cases / covidDataLow.population) * 100).toFixed(2));
                        dataNewDeath.push(((i.new_deaths / covidDataLow.population) * 100).toFixed(2));
                            if(!i.people_vaccinated || i.people_vaccinated < 0.01) {
                                if(numbers > 0) {
                                    dataPeopleVaccinated.push(dataPeopleVaccinated[numbers-1])
                                }
                                else {
                                    dataPeopleVaccinated.push(0)
                                }
                            }
                            else {
                                dataPeopleVaccinated.push(((i.people_vaccinated / covidDataLow.population) * 100).toFixed(2));
                            }
                            if(!i.people_fully_vaccinated || i.people_fully_vaccinated < 0.01) {
                                if(numbers > 0) {
                                    dataPeopleFullyVaccinated.push(dataPeopleFullyVaccinated[numbers-1])
                                }
                                else {
                                    dataPeopleFullyVaccinated.push(0)
                                }
                            }
                            else {
                                dataPeopleFullyVaccinated.push(((i.people_fully_vaccinated / covidDataLow.population) * 100).toFixed(2));
                            }
                            if(!i.total_boosters || i.total_boosters < 0.01) {
                                if(numbers > 0) {
                                    dataTotalBoosters.push(dataTotalBoosters[numbers-1])
                                }
                                else {
                                    dataTotalBoosters.push(0)
                                }
                            }
                            else {
                                dataTotalBoosters.push(((i.total_boosters / covidDataLow.population) * 100).toFixed(2));
                            }
                    }
                    numbers += 1;
                }
            })

            let dataSets1 = [
                {
                    name: 'New cases',
                    data: dataNewCases
                },
                {
                    name: 'Total cases',
                    data: dataTotalCases
                },
                {
                    name: 'New deaths',
                    data: dataNewDeath
                }
            ]
            let dataSets2 = [
                {
                    name: '1 dose',
                    data: dataPeopleVaccinated
                },
                {
                    name: 'Full dose',
                    data: dataPeopleFullyVaccinated
                },
                {
                    name: 'Buster dose',
                    data: dataTotalBoosters
                }
            ]
            setLabels(label)
            setDataLineChart1(dataSets1);
            setDataLineChart2(dataSets2);
            setLoading(false);
        }
    }, [covidDataLow, filterData]);


    const handleClickMap = (e, code) => {
        setLoading(true)
        setSelectCountry(getCountryISO3(code))
        setLocation(getCountryISO3(code))
    }

    const handleClose = () => {
        setOpen(false);
    }
    const handleClickFilters = () => {
        setOpen(!open);
    }

    const heigthGraph = 100

    const optionsMap = {
        map: "world_mill",
        content: {
            height: 1223.9059488513599,
            insets: [
                {
                    bbox: [
                        {
                            x: 651826.6317576179,
                            y: -7378296.907634238
                        },
                        {
                            x: 1673096.1659230748,
                            y: -5989477.065138949
                        }
                    ],
                    height: 1223.9059488513599,
                    left: 0,
                    top: 0,
                    width: 900
                }
            ]
        },
        regionStyle: {
            hover: {
                "fill-opacity": 0.8,
                cursor: 'pointer'
            },
            selected: {
                fill: "#7B61FF"
            },
            initial: {
                fill: "#D0D2D6",
            },
        },
        regionsSelectable: true,
        regionsSelectableOne: true,
        backgroundColor: "#283046",
        containerStyle: {
            width: "100%",
            height: "100%",
        },
        zoomOnScroll: true,
        onRegionTipShow: function (event, label, code) {
            label.splice(1, 1);
            label.splice(2, 1);
        },
        onRegionClick: (e, code, label) => {
            handleClickMap(e, code)
        }
    };

    return (
        <Box sx={{ color: '#fff' }}>
            {!covidData ? <Loader /> : ''}
            {loading ? <Loader /> : ''}
            <Grid container sx={{ p: '1rem' }}>
                <Grid item xs={12} md={7} sx={{ p: '1rem', boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)' }}>
                    <MainDarkBox>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                Covid-19 disease
                            </Box>
                            <Box sx={{ cursor: 'pointer', p: '0 1rem' }} onClick={handleClickFilters}>
                                <img width='21px' alt='filter' src={filter} />
                            </Box>
                        </Box>
                        <Box>
                            <LineChart showAs={filterData.showAs} labelsData={labels} chartData={dataLineChart1} graphColors={['#7B61FF', '#00CFE8', '#DB6AB5']}></LineChart>
                        </Box>
                        <Box>
                            <Box>
                                Covid-19 vaccination
                            </Box>
                            <LineChart showAs={filterData.showAs} labelsData={labels} chartData={dataLineChart2} graphColors={['#DFC04F', '#28C7AA', '#2A99FF']}></LineChart>
                        </Box>
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={5} sx={{ p: '1rem' }}>
                    <MainDarkBox>
                        <WorldMap options={optionsMap} />
                        <TextUnderMap
                            location1={location1}
                            dataOne={dataNewCasesMax}
                            dataTwo={dataFullyVac}
                            dataThree={dataNewDeathMax}
                            dataBlockGraph1={dataBlockGraph1Arr}
                            dataBlockGraph2={dataBlockGraph2Arr}
                            dataBlockGraph3={dataBlockGraph3Arr}
                            heigthGraph={heigthGraph} />
                    </MainDarkBox>
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ '.MuiDialog-paper': { borderRadius: '0px' }, '.MuiDialog-paperFullScreen': { width: '50%', height: '50%' } }}
            >
                <DialogsFilters
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    setLocation={setLocation}
                    handleClose={handleClose}
                    setFilterData={setFilterData}
                    covidDataDate={covidDataDate}
                />
            </Dialog>
        </Box>
    )
}

export default Covid19DataTracker;