import React from 'react'
import MainDarkBox from '../mainDarkBox';
import { Box, Grid, Typography, Divider, IconButton, Dialog, Button, TableHead, TableRow, TableCell, Checkbox, TableSortLabel, TableContainer, Table, TableBody, Link, Avatar } from '@mui/material';
import DialogCompanies from './dialog';
import { api } from '../api/apiChart'
import default_company_image from '../../assets/image/default_company_image.png'
import {
    Add as AddIcon,
    Archive as ArchiveIcon,
    FilterList as FilterListIcon,
    RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import { NavLink } from 'react-router-dom';


const CompanyTableNew = ({ companyList, bgcolor, page, handleCompanyDetailNewPage, isNew }) => {
    const [open, setOpen] = React.useState(false);
    const [companyDetails, setCompanyDetails] = React.useState();
    const [marketing, setMarketing] = React.useState();
    const [legal, setLegal] = React.useState();
    const [financialServices, setFinancialServices] = React.useState();
    const [software, setSoftware] = React.useState();
    const [hardware, setHardware] = React.useState();
    const [progress, setProgress] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setMarketing(null)
        setLegal(null)
        setFinancialServices(null)
        setSoftware(null)
        setHardware(null)
        setOpen(false);
    };
    const handleCompanyDetails = (slug) => {
        handleClickOpen()
        setProgress(true)
        api.companiDetails({ slug: slug })
            .then(res => {
                setCompanyDetails(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const headCells = companyList?.data[0]?.year ?
        [
            { id: "customer", alignment: "left", label: "COMPANY NAME" },
            { id: "id", alignment: "right", label: "ID" },
            { id: "year", alignment: "right", label: "YEAR" },
            { id: "Technologies", alignment: "left", label: "Technologies" },
            { id: "actions", alignment: "right", label: "Actions" },
        ]
        :
        [
            { id: "customer", alignment: "left", label: "COMPANY NAME" },
            { id: "id", alignment: "right", label: "ID" },
            { id: "Technologies", alignment: "left", label: "Technologies" },
            { id: "actions", alignment: "right", label: "Actions" },
        ];
    return (
        <Box sx={{ '.MuiTableCell-root': { borderBottom: '1px solid rgb(81, 81, 81)' } }}>
            <TableContainer sx={{
                backgroundColor: 'rgb(35, 48, 68)',
                '.MuiTableCell-root': {
                    color: 'rgba(255, 255, 255, 0.95)'
                }
            }}>
                <Table
                    sx={{ p: '0 1rem', }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                            </TableCell>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.alignment}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companyList && companyList.data.map((item, key) => (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={item.id}
                            >
                                <TableCell padding="1">
                                    {(page * 20) - (19 - key)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ height: 'auto', backgroundColor: item.logo ? 'rgb(64, 122, 214)' : 'rgba(64, 122, 214, 0)', color: '#FFF' }} alt={item.title} src={item.logo ? `/files/${item.logo}` : default_company_image} variant="square">
                                        </Avatar>
                                        <Box ml={3}>
                                            <Typography>
                                                {item.title}
                                            </Typography>
                                            <Box component={Link}
                                                href={item.link}
                                                target='_blank'
                                                underline='hover'
                                                sx={{
                                                    color: 'rgba(255, 255, 255, 0.95)',
                                                    ':hover': { color: 'rgb(64, 122, 214)' }
                                                }}>
                                                {item.link}
                                            </Box>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align="right">#{item.id}</TableCell>
                                {companyList?.data[0]?.year && <TableCell align="right">{item.year}</TableCell>}
                                <TableCell
                                // component={Link}
                                // target="_blank"
                                // underline='hover'
                                // href={item.link}
                                >{item.Technologies && item.Technologies.join(', ')}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => handleCompanyDetails(item.slug)}
                                        aria-label="details"
                                        size="large"
                                    >
                                        <RemoveRedEyeIcon sx={{ color: 'rgba(255, 255, 255, 0.95)' }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                fullScreen
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ '.MuiDialog-paper': { boxShadow: 'none', borderRadius: '0px' }, '.MuiDialog-paperFullScreen': { width: '90%', height: isNew ? 'auto' : '80%' } }}
            >
                <DialogCompanies
                    handleClose={handleClose}
                    companyDetails={companyDetails}
                    bgcolor={bgcolor}
                />
            </Dialog>
        </Box >
    )
}
export default CompanyTableNew