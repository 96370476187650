import { Box } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const BlockChartNew = ({options, series, height }) => {
  
  

  return (
    <Box>
      <Suspense fallback={<div>...loading</div>}>
      <Chart options={options} series={series} type="area" height={height} />
      </Suspense>
    </Box>
  );
};

export default BlockChartNew;