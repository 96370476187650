import { Box, CircularProgress, Divider, Link, Typography } from "@mui/material";
import DialogBox from "../../../../dialog";


const DialogDetailsStatistics = ({ handleCloseDetail, companyDetail }) => {

    const items = companyDetail;

    return (
        <Box sx={{ height: '100%' }}>
            <DialogBox title='Company Details'  handleClose={handleCloseDetail}>
                {items ?
                    <Box sx={{ background: '#283046', padding: '25px 35px' }}>
                        <Typography mb={2} sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>{items.title}</Typography>
                        <Typography mt={2} mb={5} sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.description.value}</Typography>
                        <Box sx={{ border: '1px solid #3b4253', borderRadius: '4px' }}>
                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>Website</Typography>
                                <Link href={items.fields.link.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                    <Typography sx={{ color: '#b4b7bd', fontSize: '14px', }}>{items.fields.link.value}
                                    </Typography>
                                </Link>
                            </Box>
                            {items.fields.c_tw.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.c_tw.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TWITTER PAGE</Typography>
                                <Link href={items.fields.c_tw.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                    <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.c_tw.value}</Typography>
                                </Link>
                            </Box>}
                            {items.fields.c_em.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.c_em.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>CONTACT EMAIl</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.c_em.value}</Typography>
                            </Box>}
                            {items.fields.c_li.value &&<Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.c_li.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>LINKEDIN PAGE</Typography>
                                <Link href={items.fields.c_li.value} target="_blank" sx={{ textDecoration: 'none' }}>
                                    <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.c_li.value}</Typography>
                                </Link>
                            </Box>}
                            <Divider sx={{ background: '#3b4253' }} />
                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>YEAR FOUNDED</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.year.value}</Typography>
                            </Box>
                            {items.fields.region.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.region.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>REGION</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.region.value}</Typography>
                            </Box>}
                            {items.fields.country.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.country.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>COUNTRY</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.country.value}</Typography>
                            </Box>}
                            {items.fields.city.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.city.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>CITY / STATE</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.city.value}</Typography>
                            </Box>}
                            {items.fields.business_state.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.business_state.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>BUSINESS STATE</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.business_state.value}</Typography>
                            </Box>}
                            {items.fields.staff.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.staff.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>ESTIMATED NUMBER OF EMPLOYEES </Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.staff.value}</Typography>
                            </Box>}
                            {items.fields.p_conf.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.p_conf.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>AI/ML CONFERENCE TALKS </Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.p_conf.value}</Typography>
                            </Box>}
                            {items.fields.p_pat.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.p_pat.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>PATENTS / APPLICATIONS</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.p_pat.value}</Typography>
                            </Box>}
                            {items.fields.fund_total.value && <Divider sx={{ background: '#3b4253' }} />}
                            {items.fields.fund_total.value && <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TOTAL FUNDING </Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.fund_total.value}</Typography>
                            </Box>}
                            <Divider sx={{ background: '#3b4253' }} />
                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>TOTAL FUNDING (RANGE) </Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.fund_range.value}</Typography>
                            </Box>
                            <Divider sx={{ background: '#3b4253' }} />
                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>FUNDING STATUS</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.fund_last.value}</Typography>
                            </Box>
                            <Divider sx={{ background: '#3b4253' }} />
                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>NUMBER OF PIPELINES</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.pips.value}</Typography>
                            </Box>
                            <Divider sx={{ background: '#3b4253' }} />
                            <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                <Typography sx={{ color: '#7367F0', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>IPO STATUS</Typography>
                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{items.fields.ipo_status.value}</Typography>
                            </Box>
                        </Box>
                    </Box> :
                    <Box sx={{ background: '#283046', padding: '25px 35px' }}>
                        <CircularProgress color="secondary" />
                    </Box>}
            </DialogBox>
        </Box>
    )
}

export default DialogDetailsStatistics;