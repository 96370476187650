import { Box, Typography, Grid, } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";
import BlockChart from './../charts/blockChart';
import icon from "../../../../../assets/svg/stethoscopeViolet.svg"

const HistoricalCurveBlock = ({ covidData, dataBlockGraph1Arr }) => {

  let allCases = 0;

  if(covidData){
    covidData.data.forEach((i) => {
      if(i.new_cases){
        allCases += i.new_cases
      }
    });
  }

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{ pt: "1rem", pr: { md: "1rem", xs: "0" } }}
    >
      <Grid item xs={12}>
        <MainDarkBox>
          <Box
            sx={{
              display: "flex",
              background: "#7367f01f",
              p: "10px",
              borderRadius: "50%",
              width: "24px",
            }}
          >
            <img src={icon} alt="icon" />
          </Box>
          {covidData && (
            <Typography
              sx={{
                fontSize: "24px",
                fontFamily: "Montserrat",
                pt: "1rem",
                color: "#D0D2D6",
                fontWeight: "600",
              }}
            >
              {" "}
              {allCases /
                1000}
              k{" "}
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat",
              color: "#D0D2D6",
            }}
          >
            {" "}
            Covid-19 Historical Curve{" "}
          </Typography>
          <BlockChart dataBlockGraph1Arr={dataBlockGraph1Arr} />
        </MainDarkBox>
      </Grid>
    </Grid>
  );
};

export default HistoricalCurveBlock;