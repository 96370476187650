import React, { Suspense } from 'react';
import { Box } from "@mui/material";
const Chart = React.lazy(() => import('react-apexcharts'))

const LineChart = ({ chartData, labelsData, graphColors, showAs }) => {

    const lineChartOne = React.useRef();
    let ticks = 8;
    if (window.innerWidth > 900) {
        ticks = 13
    }
    if (window.innerWidth > 1550) {
        ticks = 17
    }


    const options = {
        height: '50',
        chart: {
            toolbar: {
                show: false,
            },

            type: 'line',
            zoom: {
                enabled: false
            }
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: (value) => {
                    if(showAs === 'persent') {
                        return value + '%'
                    }
                    else{
                        return value
                    }
                },
            },  
        },
        colors: graphColors,
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: '1'
        },
        legend: {
            position: 'top',
            labels: {
                colors: '#D0D2D6'
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#D0D2D6'
                },
                formatter: (value) => {
                    if(showAs === 'persent') {
                        return value + '%'
                    }
                    else{
                        return value
                    }
                },
            },
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#D0D2D6'
                }
            },
            tickAmount: ticks,
            categories: labelsData,
        }
    };

    return (
        <Box>
            <Suspense fallback={<div>...loading</div>}>
                <Chart
                    series={chartData}
                    options={options}
                    height='350'
                    type="line" />
            </Suspense>
        </Box>
    )
}

export default LineChart;