import React from 'react'
import { Box, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


const DialogBox = ({ children, title, handleClose, bgcolor }) => {

    return (
        <Box>
            <Box sx={{ position: 'relative', background: bgcolor ? 'rgb(20 33 50)' : '#161d31', width: '100%' }}>
                <Typography sx={{ color: '#b4b7bd', padding: '10px 0px 10px 20px', fontSize: '1.286rem' }}>{title}</Typography>
                <Box sx={{
                    position: 'absolute',
                    top: '3px',
                    right: '3px',
                    background: '#283046',
                    boxshadow: '0 3px 8px 0 rgb(11 10 25 / 49%)',
                    width: '34px',
                    height: '34px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    transition: 'all 0.15s ease 0.1s',
                    '&:hover': {
                        top: '6px',
                        right: '6px',
                    }
                }}
                    onClick={handleClose}>
                    <ClearIcon sx={{ fill: '#b4b7bd', fontSize: '18px', margin: '8px 0px 0px 8px' }} />
                </Box>
            </Box>
            <Box sx={{ background: bgcolor ? 'rgb(33 43 58)': '#283046', padding: '25px 35px' }}>
                {children}
            </Box >
        </Box >
    )
}
export default DialogBox