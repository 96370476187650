import { Box, Typography, Divider, Grid } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import OneBarTwoColors from '../charts/oneBarTwoColors';
import MainDarkBox from "../../../../mainDarkBox";

const TopContries = ({ dataAverage, series, pageName }) => {

  return (
    <Grid
      item
      xs={12}
      md={7}
      sx={{ p: { md: "2rem 0 2rem 2rem", xs: "1rem 0 1rem 0" } }}
    >
      <MainDarkBox>
        <Box sx={{ height: "300px" }}>
          <Box sx={{ ml: "-0.5rem", mr: "-0.5rem" }}>
            <Typography
              color="#D0D2D6 !important"
              sx={{
                mt: "-0.5rem",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "500",
              }}
            >
              Top-Countries by {pageName}
            </Typography>

            <Box sx={{ mt: "0.5rem" }}>
              <Typography
                color="#2A99FF !important"
                sx={{
                  ml: "-0.5rem",
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  display: "inline-block",
                }}
              >
                <CircleIcon sx={{ height: "10px" }} />
                Above Average
              </Typography>
              <Typography
                color="#DB6A6A !important"
                sx={{
                  ml: "1rem",
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  display: "inline-block",
                }}
              >
                <CircleIcon sx={{ height: "10px" }} />
                Below the Average
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              borderWidth: "1px",
              ml: "-1.5rem",
              mr: "-1.5rem",
              mt: "0.5rem",
            }}
            light={false}
          />

          <Box
            sx={{
              overflowY: "auto",
              height: "250px",
              ml: "-1.5rem",
              mr: "-1.5rem",
            }}
          >
            {series.map((data) => (
              <OneBarTwoColors
                key={data.country}
                country={data.country}
                color={data.color}
                data={data.data}
                dataAverage={dataAverage}
              />
            ))}
          </Box>
        </Box>
      </MainDarkBox>
    </Grid>
  );
};

export default TopContries;