import React from 'react';
import {
    Box,
    Avatar,
    Typography,
    NativeSelect,
    InputBase,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Divider,
    Button,
    styled,
    Paper
} from '@mui/material';



const selectedCountry = [
    { value: "AF", label: 'Afghanistan' },
    { value: "AM", label: 'Armenia' },
    { value: "AZ", label: 'Azerbaijan' },
    { value: "BH", label: 'Bahrain' },
    { value: "BD", label: 'Bangladesh' },
    { value: "BT", label: 'Bhutan' },
    { value: "BN", label: 'Brunei' },
    { value: "KH", label: 'Cambodia' },
    { value: "CN", label: 'China' },
    { value: "CY", label: 'Cyprus' },
    { value: "GE", label: 'Georgia' },
    { value: "HK", label: 'Hong Kong S.A.R.' },
    { value: "IN", label: 'India' },
    { value: "ID", label: 'Indonesia' },
    { value: "IR", label: 'Iran' },
    { value: "IQ", label: 'Iraq' },
    { value: "IL", label: 'Israel' },
    { value: "JP", label: 'Japan' },
    { value: "JO", label: 'Jordan' },
    { value: "KZ", label: 'Kazakhstan' },
    { value: "KW", label: 'Kuwait' },
    { value: "KG", label: 'Kyrgyzstan' },
    { value: "LA", label: 'Laos' },
    { value: "LB", label: 'Lebanon' },
    { value: "MO", label: 'Macao S.A.R' },
    { value: "MY", label: 'Malaysia' },
    { value: "MV", label: 'Maldives' },
    { value: "MN", label: 'Mongolia' },
    { value: "NP", label: 'Nepal' },
    { value: "OM", label: 'Oman' },
    { value: "PK", label: 'Pakistan' },
    { value: "PS", label: 'Palestine' },
    { value: "PH", label: 'Philippines' },
    { value: "QA", label: 'Qatar' },
    { value: "KR", label: 'South Korea' },
    { value: "SA", label: 'Saudi Arabia' },
    { value: "SG", label: 'Singapore' },
    { value: "LK", label: 'Sri Lanka' },
    { value: "SY", label: 'Syria' },
    { value: "TW", label: 'Taiwan' },
    { value: "TJ", label: 'Tajikistan' },
    { value: "TH", label: 'Thailand' },
    { value: "TL", label: 'East Timor' },
    { value: "TR", label: 'Turkey' },
    { value: "AE", label: 'United Arab Emirates' },
    { value: "UZ", label: 'Uzbekistan' },
    { value: "VN", label: 'Vietnam' },
    { value: "YE", label: 'Yemen' },
    { value: "TM", label: 'Turkmenistan' },
    { value: "KP", label: 'North Korea' }
]


const FormStatistics = ({
    countryForSelect,
    defaultVal,
    bgcolorSelect,
    handleFormChange,
    form,
    filtersCategoryStatistics,
    handleSubmitForm,
    handleResetForm }) => {

    const TextInput = styled(InputBase)(() => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            color: '#b4b7bd',
            position: 'relative',
            backgroundColor: bgcolorSelect ? bgcolorSelect : '#283046',
            border: '1px solid #404656',
            fontSize: 16,
            padding: '10px 12px',
            '&:focus': {
                borderColor: bgcolorSelect ? 'rgb(64, 122, 214)' : '#7367f0',
            },
        },
    }));

    return (
        <Box component="form">
            <Box sx={{ mb: '1rem' }}>
                <Box sx={{ display: 'flex' }} id="inputLabel">
                    <InputLabel sx={{ color: '#d0d2d6' }} shrink htmlFor="Main-category">
                        Main category
                    </InputLabel>
                    <Typography sx={{ color: '#ea5455', display: 'contents' }}>*</Typography>
                </Box>
                <Select
                    defaultValue={defaultVal ? defaultVal : 'longevity-sector'}
                    variant='outlined'
                    id="Main-category"
                    name='cat1'
                    color="primary"
                    labelId='inputLabel'
                    value={form.mainCategory}
                    onChange={handleFormChange}
                    sx={{
                        '.MuiSelect-outlined': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#283046' },
                        width: '100%',
                        color: '#b4b7bd',
                        background: bgcolorSelect ? bgcolorSelect : '#283046',
                    }}
                >
                    {filtersCategoryStatistics && filtersCategoryStatistics.map(i => (
                        <MenuItem key={i.value} sx={{
                            width: '100%',
                            color: '#b4b7bd',
                            background: bgcolorSelect ? '#1d3049' : '#283046',
                            '.MuiList-root': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                            '&:hover': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                            '.Mui-selected': { backgroundColor: bgcolorSelect ? bgcolorSelect : 'rgb(0, 160, 250)' }
                        }} value={i.value}>{i.title}</MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ mb: '1rem' }}>
                <InputLabel sx={{ color: '#d0d2d6' }} id='secondary' shrink htmlFor="secondary-category">
                    Secondary category
                </InputLabel>
                <Select
                    defaultValue=""
                    color="primary"
                    sx={{
                        '.MuiPopover-paper': { background: '#1d3049' ? bgcolorSelect : '#283046' },
                        width: '100%',
                        color: '#b4b7bd',
                        background: bgcolorSelect ? bgcolorSelect : '#283046',
                    }}
                    value={form.secondCategory}
                    onChange={handleFormChange}
                    name='cat2'
                    inputProps={{ "aria-label": "Without label" }}
                >
                    <MenuItem sx={{
                        width: '100%',
                        color: '#b4b7bd',
                        background: bgcolorSelect ? '#1d3049' : '#283046',
                        '.MuiList-root': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                        '&:hover': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                        '& .Mui-selected': { backgroundColor: bgcolorSelect ? bgcolorSelect : 'rgb(0, 160, 250)' }
                    }} selected value="">
                        <em>None</em>
                    </MenuItem>
                    {filtersCategoryStatistics && filtersCategoryStatistics.map(i => (
                        <MenuItem key={i.value} sx={{
                            width: '100%',
                            color: '#b4b7bd',
                            background: bgcolorSelect ? '#1d3049' : '#283046',
                            '.MuiList-root': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                            '&:hover': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                            '& .Mui-selected': { backgroundColor: bgcolorSelect ? bgcolorSelect : 'rgb(0, 160, 250)' }
                        }} value={i.value}>{i.title}</MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ mb: '1rem' }}>
                <FormLabel sx={{ color: '#d0d2d6', fontSize: '0.8rem' }} id="controlled-radio-buttons-group">Type of value</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="typeOfValue"
                    color="primary"
                    value={form.typeOfValue}
                    onChange={handleFormChange}
                    sx={{
                        '.Mui-checked': {
                            color: bgcolorSelect ? 'rgb(64, 122, 214) !important' : '#7367F0'
                        }
                    }}
                >
                    <FormControlLabel sx={{ color: '#d0d2d6' }} value="count" color="secondary" control={<Radio color="secondary" />} label="Number of companies" />
                    <FormControlLabel sx={{ color: '#d0d2d6' }} value="sum" color="secondary" control={<Radio color="secondary" />} label="Total fundraising, $ M." />
                </RadioGroup>
            </Box>
            <Divider sx={{
                background: '#3b4253',
                height: '1px',
                m: '0.5rem 0'
            }} />
            <Typography variant='h5' sx={{ color: '#d0d2d6' }}>Filters</Typography>
            <Box sx={{ mb: '1rem' }}>
                <InputLabel sx={{ color: '#d0d2d6' }} id='secondary' shrink htmlFor="secondary-category">
                    Сountry
                </InputLabel>
                <Select
                    defaultValue=""
                    color="primary"
                    sx={{
                        '& .MuiMenuItem-root': { background: '#283046' },
                        width: '100%',
                        color: '#b4b7bd',
                        background: bgcolorSelect ? bgcolorSelect : '#283046',
                    }}
                    value={form.country}
                    onChange={handleFormChange}
                    name='country'
                    inputProps={{ "aria-label": "Without label" }}
                >
                    <MenuItem sx={{
                        width: '100%',
                        color: '#b4b7bd',
                        background: bgcolorSelect ? '#1d3049' : '#283046',
                        '.MuiList-root': { backgroundColor: bgcolorSelect ? '#1d3049' : '#1a2646' },
                        '&:hover': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                        '& .Mui-selected': { backgroundColor: bgcolorSelect ? bgcolorSelect : 'rgb(0, 160, 250)' }
                    }} value="" selected>
                        <em>-- Select country to filter --</em>
                    </MenuItem>
                    {!countryForSelect ? selectedCountry && selectedCountry.map(i => (
                        <MenuItem key={i.value} sx={{
                            width: '100%',
                            color: '#b4b7bd',
                            background: bgcolorSelect ? '#1d3049' : '#283046',
                            '.MuiList-root': { backgroundColor: bgcolorSelect ? '#1d3049' : '#1a2646' },
                            '&:hover': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                            '& .Mui-selected': { backgroundColor: bgcolorSelect ? bgcolorSelect : 'rgb(0, 160, 250)' }
                        }} value={i.value}>{i.label}</MenuItem>
                    )) :
                        countryForSelect && countryForSelect.map(i => (
                            <MenuItem key={i.value} sx={{
                                width: '100%',
                                color: '#b4b7bd',
                                background: bgcolorSelect ? '#1d3049' : '#283046',
                                '.MuiList-root': { backgroundColor: bgcolorSelect ? '#1d3049' : '#1a2646' },
                                '&:hover': { backgroundColor: bgcolorSelect ? bgcolorSelect : '#1a2646' },
                                '& .Mui-selected': { backgroundColor: bgcolorSelect ? bgcolorSelect : 'rgb(0, 160, 250)' }
                            }} value={i.value}>{i.title}</MenuItem>
                        ))}
                </Select>
            </Box>
            <Box sx={{ mb: '1rem' }}>
                <InputLabel sx={{ color: '#d0d2d6' }} id='secondary' shrink htmlFor="secondary-category">
                    Secondary category
                </InputLabel>
                <TextInput
                    defaultValue=""
                    color={bgcolorSelect ? 'primary' : "secondary"}
                    sx={{
                        '.MuiMenuItem-root': { background: bgcolorSelect ? bgcolorSelect : '#283046' },
                        width: '100%',
                        color: '#b4b7bd',
                        background: '#283046',
                    }}
                    value={form.q}
                    onChange={handleFormChange}
                    name='q'
                    inputProps={{ "aria-label": "Without label" }}
                >
                </TextInput>
            </Box>
            <Divider sx={{
                background: '#3b4253',
                height: '1px',
                mt: '1rem',
                mb: '1.5rem'
            }} />
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button onClick={handleResetForm} sx={{
                    color: bgcolorSelect ? 'rgb(64, 122, 214)' : '#7367f0'
                }}>Reset</Button>
                <Button
                    onClick={handleSubmitForm}
                    sx={{
                        borderColor: bgcolorSelect ? 'rgb(64, 122, 214)' : '#ff9f43',
                        backgroundColor: bgcolorSelect ? 'rgb(64, 122, 214)' : '#ff9f43',
                        color: bgcolorSelect ? 'rgb(255, 255, 255)' : '#fff',
                        '&:hover': {
                            boxShadow: bgcolorSelect ? '0 8px 25px -8px rgb(64, 122, 214)' : '0 8px 25px -8px #ff9f43',
                            backgroundColor: bgcolorSelect ? 'rgb(64, 122, 214)' : '#ff9f43',
                        }
                    }}>Build Chart</Button>
            </Box>
        </Box >
    )
}

export default FormStatistics;