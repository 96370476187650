import './App.css';
import DashboardAsia from './components/pages/dashboardAsia';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import theme from './theme'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import AuthGuard from './components/auth'
import CovidDashboard from './components/pages/covidDashboard';
import MegaDashboard from './components/pages/megaDashboard';
import CreatedDash from './components/pages/createdDash';
import SpaceTechDashboard from './components/pages/spaceTechDashboard';
import AllDashboards from './components/pages/allDashboards';
import Params from './components/pages/parameters';

function App() {


  return (
    <div className="App">
      <CookiesProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <Routes>
                <Route exact path="/block-params" element={<Params />} />
              </Routes>
              <AuthGuard>
                <Routes>
                  <Route exact path="/" element={<MegaDashboard />} />
                  <Route exact path="/asia-digital-dashboard" element={<DashboardAsia />} />
                  <Route exact path="/covid-19-data" element={<CovidDashboard />} />
                  <Route exact path="/created-dashboard" element={<CreatedDash />} />
                  <Route exact path="/spacetech" element={<SpaceTechDashboard />} />
                  <Route exact path="/all" element={<AllDashboards />} />
                </Routes>
              </AuthGuard>
            </HelmetProvider>
          </ThemeProvider>
        </BrowserRouter>
      </CookiesProvider>
    </div>
  );
}

export default App;
