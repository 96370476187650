import { Accordion, AccordionSummary, Grid, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainDarkBox from "../../../../mainDarkBox";
import OneBarChartStacked from './../charts/oneBarChartStacked';
import React from "react";
import dataJson from "../../../../../assets/json/economyRegionalSafetyRanking.json"
import AccordionTypography from './accordionTypography';

const LegendBlock = ({countrySelect}) => {

  const [blockHeight, setBlockHeight] = React.useState('335px')
  const [blockMargin, setBlockMargin] = React.useState('0rem')
    
  const [series3, setSeries3] = React.useState([
    {
    city: "",
    vaccinationRate: 0,
    economyResilience: 0,
    governmentEfficiency: 0,
    quarantineEfficiency: 0,
    healthcareManagement: 0,
    color: "",
    labelColor: ""
  }]);

  function activateLasers() {
    if (blockHeight === "335px"){
      if (window.innerWidth < 900){
        setBlockHeight("220px")
      }
      else{
        setBlockHeight("266px")
      }
    }
    else{
      setTimeout(() => setBlockHeight("335px"), 50)
    }
    if (blockMargin === "0rem"){
      setBlockMargin("-1rem")
    }
    else{
      setTimeout(() => setBlockMargin("0rem"), 50)
    }
  }

  React.useEffect(() => {

    let citiesData = [];
    
    let vaccinationRateTempCity = 0;
    let economyResilienceTempCity = 0;
    let governmentEfficiencyTempCity = 0;
    let quarantineEfficiencyTempCity = 0;
    let healthcareManagementTempCity = 0;
    let color = "";
    let labelColor = "";

    dataJson.forEach(i => {
      vaccinationRateTempCity = parseFloat(i["Vaccination Rate"])
      economyResilienceTempCity = parseFloat(i["Economy Resilience"]);
      governmentEfficiencyTempCity = parseFloat(i["Government Efficiency"]);
      quarantineEfficiencyTempCity = parseFloat(i["Quarantine Efficiency"]);
      healthcareManagementTempCity = parseFloat(i["Healthcare Management"]);
      if (i.Country === countrySelect){
        color = "\#7367f01f"
        labelColor = "\#7B61FF"
      }
      else {
        color = ""
        labelColor = "\#D0D2D6"
      }
      citiesData.push(
        {
        city: i.City,
        vaccinationRate: vaccinationRateTempCity.toFixed(2),
        economyResilience: economyResilienceTempCity.toFixed(2),
        governmentEfficiency: governmentEfficiencyTempCity.toFixed(2),
        quarantineEfficiency: quarantineEfficiencyTempCity.toFixed(2),
        healthcareManagement: healthcareManagementTempCity.toFixed(2),
        color: color,
        labelColor: labelColor
      }
      );
      vaccinationRateTempCity = 0;
      economyResilienceTempCity = 0;
      governmentEfficiencyTempCity = 0;
      quarantineEfficiencyTempCity = 0;
      healthcareManagementTempCity = 0;
    })

    citiesData.sort((a, b) => (parseFloat(a.vaccinationRate) + parseFloat(a.economyResilience) + parseFloat(a.governmentEfficiency) + parseFloat(a.quarantineEfficiency) + parseFloat(a.healthcareManagement)) < (parseFloat(b.vaccinationRate) + parseFloat(b.economyResilience) + parseFloat(b.governmentEfficiency) + parseFloat(b.quarantineEfficiency) + parseFloat(b.healthcareManagement))? 1 : -1)

    setSeries3(citiesData)

  },[countrySelect])

  return (
    <Grid item xs={12} md={6} sx={{ p: { md: "1rem", xs: "0 1rem 0 1rem" } }}>
      <Grid item xs={12}>
        <MainDarkBox>
          <Box sx={{ m: "-1.5rem", height: "383px" }}>
            <Accordion>
              <AccordionSummary
                onClick={activateLasers}
                expandIcon={<ExpandMoreIcon sx={{ color: "#D0D2D6" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    color: "#D0D2D6",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                  }}
                >
                  Legend
                </Typography>
              </AccordionSummary>

              <AccordionTypography />
            </Accordion>
            <Box
              sx={{ mt: blockMargin, overflowY: "auto", height: blockHeight }}
            >
              {series3.map((data) => (
                <OneBarChartStacked
                  key={data.city}
                  country={data.city}
                  labelColor={data.labelColor}
                  color={data.color}
                  vaccinationRate={data.vaccinationRate}
                  economyResilience={data.economyResilience}
                  governmentEfficiency={data.governmentEfficiency}
                  quarantineEfficiency={data.quarantineEfficiency}
                  healthcareManagement={data.healthcareManagement}
                />
              ))}
            </Box>
          </Box>
        </MainDarkBox>
      </Grid>
    </Grid>
  );
};

export default LegendBlock;