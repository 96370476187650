import { Box, ButtonBase } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";
import CategoryRadialBar from '../charts/categoryRadialBar';

const OneCategoryBlock = ({ handleClick, selectBorders, colors, names, values, series, i }) => {

  return (
    <MainDarkBox>
      <Box
        sx={{
          border: selectBorders,
          borderRadius: 2,
          borderColor: "#7B61FF",
          m: "-1.5rem",
          p: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonBase
          onClick={() => {
            handleClick(i);
          }}
          sx={{ borderRadius: 2 }}
          disableRipple={true}
        >
          <CategoryRadialBar
            color={colors}
            name={names}
            value={values}
            data={series}
          />
        </ButtonBase>
      </Box>
    </MainDarkBox>
  );
};

export default OneCategoryBlock;