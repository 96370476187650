import React from 'react';
import { Box } from '@mui/material';
import Companies from '../companies';

const Investors = ({ dashboard, location, sector, industry, continent }) => {

    return (
        <Box>
            <Companies section={'investors'} industry={industry} dashboard={dashboard} location={location} continent={continent} sector={sector} />
        </Box>
    )
}

export default Investors;