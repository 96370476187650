import { Box } from "@mui/material";

import Page from "./fivePagesComponents/index"


const VaccinationRate = () => {

    return(
        <Box>
            <Page pageName="Vaccination Rate"/>
        </Box>
    )
}

export default VaccinationRate;