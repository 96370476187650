import { Box, Grid } from "@mui/material";
import React, { Suspense } from 'react';
import MainDarkBox from "../../../../mainDarkBox";
const Chart = React.lazy(() => import('react-apexcharts'))

const DoubleRadialChart = ({ series, countrySelect }) => {


  let title = [];
  let procent = 0;

  if(series[0] > series[1]){
    title = ["Higher than", "average"]
    procent = (series[0] - series[1]).toFixed(0)
  }
  else{
    title = ["Lower than", "average"]
    procent = (series[1] - series[0]).toFixed(0)
  }


  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      type: "radialBar",
      offsetY: 10,
    },
    colors: [
      function ({ dataPointIndex }) {
        if (dataPointIndex === 0) {
          return "#00CFE8";
        } else {
          return "#6A7693";
        }
      },
    ],
    plotOptions: {
      radialBar: {
        offsetY: 10,
        hollow: {
          margin: 5,
          size: "50%",
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
        },
        track: {
          show: true,
          background: "rgba(186, 191, 199, 0.12)",
          strokeWidth: "97%",
          opacity: 1,
          margin: 8,
        },
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            show: true,
            fontSize: '16px',
            fontFamily: "Montserrat",
            fontWeight: 400,
            color: "#5E5873",
            offsetY: 10,
            formatter: function (val) {
              let num = 0
              if (typeof val === "string"){
                num = parseFloat(val).toFixed(0)
                return num + "%"
              }
              else {
                return val
              }
            }
          },
          total: {
            show: true,
            label: procent + "%",
            color: '#7367F0',
            formatter: function (w) {
              return title;
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [countrySelect, "World"],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      floating: true,
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        width: 9,
        height: 9,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: ["#00CFE8", "#6A7693"],
        radius: 9,
        offsetY: 0
      },
    },
    fill: {
      colors: ["#00CFE8", "#6A7693"],
    },
  };

  return (
    <Grid item xs={12} md={5} sx={{ p: { md: "2rem 0 2rem 2rem", xs: "0" } }}>
      <MainDarkBox>
        <Box sx={{ height: "316px" }}>
          <Box sx={{ m: "-1.5rem" }}>
            <Suspense fallback={<div>...loading</div>}>
              <Chart
                options={options}
                series={series}
                type="radialBar"
                height={360}
              />
            </Suspense>
          </Box>
        </Box>
      </MainDarkBox>
    </Grid>
  );
};

export default DoubleRadialChart;