import React from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layouts';

import GridViewIcon from '@mui/icons-material/GridView';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import RenderField from './renderField';
import { Helmet } from 'react-helmet-async';

const items = {
    menuItem: [
        {
            urlMenu: '',
            title: 'Asia Digital Ecosystem Big Data Analytics',
            titleLink: 'AsiaDigitalMain',
            active: true
        }
    ],
    itemsList: [{
        title: 'Reports',
        links: [{
            urlImg: <DescriptionIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Downloadable',
            href: '#',
            active: false
        }, {
            urlImg: <GridViewIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Mindmaps',
            href: '#',
            active: false
        },]
    },
    {
        title: 'Data',
        links: [{
            urlImg: <WorkOutlineIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Companies',
            href: '#',
            active: false
        }, {
            urlImg: <AttachMoneyIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Investors',
            href: '#',
            active: false
        }, {
            urlImg: <AttachMoneyIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Financial Deals',
            href: '#',
            active: false
        },]
    },
    {
        title: 'Tools',
        links: [{
            urlImg: <BarChartIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Statistics',
            href: '#',
            active: false
        }, {
            urlImg: <ViewColumnIcon sx={{ fill: '#d0d2d6' }} />,
            titleLink: 'Competitors',
            href: '#',
            active: false
        },]
    },]
}



const DashboardAsia = () => {

    const [contactActive, setContactActive] = React.useState(false);
    const [itemsAsia, setItemsAsia] = React.useState();
    const [itemsListAsia, setItemsListAsia] = React.useState();
    const [activeLink, setActiveLink] = React.useState('AsiaDigitalMain');

    React.useEffect(() => {
        setItemsAsia(items.itemsList);
        setItemsListAsia(items.menuItem);
    }, []);

    const handleClickOnLinkMenu = (item) => {
        const arrItems = itemsListAsia;
        setContactActive(false);
        arrItems.forEach(i => {
            i.active = false;
        });

        const arrItemsAsia = itemsAsia;
        setContactActive(false);
        arrItemsAsia.forEach(i => {
            i.links.forEach(k => {
                k.active = false;
            })
        });

        arrItems.forEach(i => {
            if (i.title == item.title) {
                i.active = true;
                setActiveLink(item.titleLink)
            }
        });

        setItemsAsia([...arrItemsAsia])
        setItemsListAsia([...arrItems])
    }

    const handleClickOnLink = (item) => {
        const arrItems = itemsAsia;
        setContactActive(false);
        arrItems.forEach(i => {
            i.links.forEach(k => {
                k.active = false;
            })
        });

        arrItems.forEach(i => {
            i.links.forEach(k => {
                if (k.titleLink == item.titleLink) {
                    k.active = true;
                    setActiveLink(item.titleLink)
                }
            })
        });

        setItemsAsia([...arrItems])
    }

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Helmet>
                <title>Asia Digital Ecosystem Big Data Analytics Dashboard</title>
            </Helmet>
            <MainLayout contactActive={contactActive}
                setContactActive={setContactActive}
                handleClickOnLink={handleClickOnLink}
                handleClickOnLinkMenu={handleClickOnLinkMenu}
                items={itemsAsia}
                itemsListAsia={itemsListAsia}
                title="Asia Digital Ecosystem Big Data Analytics Dashboard"
                setActiveLink={setActiveLink}
                boolCovidDash={false} >
                <RenderField type={activeLink} />
            </MainLayout>
        </Box>
    )
}

export default DashboardAsia;