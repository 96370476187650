import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';


const bytesDisplay = (bytes_number) => {
    let power = Math.floor(Math.log2(bytes_number) / 10);
    let result = bytes_number.toString() + " B";
    switch (power) {
        case 1:
            result = Math.round(bytes_number / 1024).toString() + " kB";
            break;
        case 2:
            result = Math.round(bytes_number / (1024 * 1024)).toString() + " MB";
            break;
        case 3:
            result = Math.round(bytes_number / (1024 * 1024 * 1024)).toString() + " GB";
            break;
    }
    return result;
}


const DownloadableGrid = ({ data, createdDash }) => {

    return (
        <Box>
            <Grid container sx={{ color: '#fff', p: '0 1rem' }} spacing={2}>
                {data && data.map(i => (
                    <Grid key={i.id} item xl={3} md={3} xs={12}>
                        <Link href={i.file_link} target="_blank" underline="none" rel="noreferrer">
                            <Box sx={{ background: createdDash ? 'rgb(35, 48, 68)' : '#283046', borderRadius: '7px', height: '100%' }}>
                                <img width='100%' src={i.image} alt="" />
                                <Box sx={{ p: '1rem .5rem' }}>
                                    <Typography sx={{
                                        letterSpacing: '1px',
                                        color: createdDash ? 'rgb(64, 122, 214)' : '#7367f0',
                                        fontSize: '.8rem'
                                    }}
                                    >{i.title}</Typography>
                                </Box>
                                <Box sx={{
                                    p: '1rem .5rem',
                                    borderTop: '1px solid #3b4253',
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}>
                                    <Typography sx={{
                                        letterSpacing: '1px',
                                        color: createdDash ? '#bfc3cc' : '#676d7d',
                                        fontSize: '.5rem'
                                    }}
                                    >
                                        [{bytesDisplay(i.file_size)}] updated: {i.date}
                                    </Typography>
                                    <Box sx={{
                                        backgroundColor: 'rgba(115, 103, 240, 0.12)',
                                        color: createdDash ? '#67b8f0' : '#7367f0',
                                        borderRadius: '10rem',
                                        p: '0 .5rem',
                                        width: '50%'
                                    }}>
                                        <Typography sx={{
                                            letterSpacing: '1px',
                                            p: '.5rem 0',
                                            fontSize: '.5rem',
                                            textAlign: 'center'
                                        }}
                                        >{i.file_access == 'open' ? 'Public report' : 'Private report'}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Box >
    )
}

export default DownloadableGrid;