import { AccordionDetails, Typography } from "@mui/material";
import React from "react";
import CircleIcon from '@mui/icons-material/Circle';

const AccordionTypography = ( ) => {

  return (
    <AccordionDetails sx={{ mt: "-0.5rem", mb: "-0.5rem" }}>
      <Typography
        color="#00CFE8 !important"
        sx={{
          mr: "1rem",
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: "500",
          display: "inline-block",
        }}
      >
        <CircleIcon sx={{ height: "8px" }} />
        Economy Resilience
      </Typography>
      <Typography
        color="#7B61FF !important"
        sx={{
          mr: "1rem",
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: "500",
          display: "inline-block",
        }}
      >
        <CircleIcon sx={{ height: "8px" }} />
        Government Efficiency
      </Typography>
      <Typography
        color="#DB6AB5 !important"
        sx={{
          mr: "1rem",
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: "500",
          display: "inline-block",
        }}
      >
        <CircleIcon sx={{ height: "8px" }} />
        Healthcare Management
      </Typography>
      <Typography
        color="#DFC04F !important"
        sx={{
          mr: "1rem",
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: "500",
          display: "inline-block",
        }}
      >
        <CircleIcon sx={{ height: "8px" }} />
        Quarantine Efficiency
      </Typography>
      <Typography
        color="#28C7AA !important"
        sx={{
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: "500",
          display: "inline-block",
        }}
      >
        <CircleIcon sx={{ height: "8px" }} />
        Vaccination Rate
      </Typography>
    </AccordionDetails>
  );
};

export default AccordionTypography;