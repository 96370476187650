import React from 'react';
import { Box, Typography, Dialog, InputBase, Grid } from '@mui/material';
import MainDarkBox from '../../../../mainDarkBox';
import { styled } from '@mui/material/styles';
import StatisticsChart from '../charts/statisticsChart';
import DialogCompanies from './dialogCompanies';
import DialogDetailsStatistics from './dialogDetailsStatistics';
import { api } from '../../../../api/apiChart';
import FormStat from './formStat';


const InputLabelForm = styled(InputBase)(() => ({
    'label + &': {
        marginTop: '1rem',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #3b4253',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        width: '100%',
        maxWidth: '100%',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#7367f0',
        },
    },
}));

const filtersCategoryStatistics = [
    { value: 'year', title: 'Foundation Year' },
    // { value: 'longevity-sector', title: 'Longevity Sector' },
    // { value: 'longevity-subsector', title: 'Longevity Subsector' },
    { value: 'fund_range', title: 'Funding distribution' },
    { value: 'fund_last', title: 'Funding status' },
    { value: 'fund_rev', title: 'Estimated Revenue' },
    { value: 'country', title: 'Country of Headquarters' },
    { value: 'staff', title: 'Number of Employees' }
]

const Statistics = ({ dashboard, industry }) => {


    const [open, setOpen] = React.useState(false);
    const [anchorElDetail, setAnchorElDetail] = React.useState(null);
    const [barClickParams, setBarClickParams] = React.useState(null);
    const [barClickParamsLabel, setBarClickParamsLabel] = React.useState(null);
    const [barDataSetsParamsLabel, setBarDataSetsParamsLabel] = React.useState(null);
    const [barDataSetsParams, setBarDataSetsParams] = React.useState(null);
    const [companyDetail, setCompanyDetail] = React.useState();
    const [page, setPage] = React.useState(1);
    const [companyList, setCompanyList] = React.useState();

    const [formSubmit, setFormSubmit] = React.useState(true)


    const [form, setForm] = React.useState(
        { cat1: 'year', cat2: 'deeptech-industry', typeOfValue: 'count', country: '', q: '' });

    const handleFormChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const handleSubmitForm = () => {
        // setFormSubmit(!formSubmit)
        setDataBarChartClick(dataBarChart);
    }

    const handleResetForm = () => {
        setDataBarChartClick({
            labels: '1',
            datasets: []
        })
        setForm({ cat1: 'year', cat2: 'deeptech-industry', typeOfValue: 'count', country: '', q: '' });
    }

    const handleClose = () => {
        setOpen(false);
    };


    const handleCloseDetail = () => {
        setAnchorElDetail(null)
    }
    const openNew = Boolean(anchorElDetail);

    const getCompanies = (numPage) => {
        let params
        let category = ''
        let yearVal,
            fund_revVal,
            fund_lastVal,
            fund_rangeVal,
            countryVal,
            category_29Val,
            staffVal
        if (barDataSetsParamsLabel) {
            switch (barDataSetsParamsLabel) {
                case 'year':
                    yearVal = barDataSetsParams;
                    break;

                case 'fund_rev':
                    fund_revVal = barDataSetsParams
                    break;

                case 'fund_last':
                    fund_lastVal = barDataSetsParams
                    break;

                case 'fund_range':
                    fund_rangeVal = barDataSetsParams
                    break;

                case 'country':
                    countryVal = barDataSetsParams
                    break;

                case 'staff':
                    staffVal = barDataSetsParams
                    break;

                case 'category_29':
                    category_29Val = barDataSetsParams
                    break;

                default:
            }
        }
        if (barClickParams) {
            category = barClickParams;
        }
        switch (barClickParamsLabel) {
            case 'year':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: category,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: staffVal,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'fund_rev':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    fund_rev: category,
                    year: yearVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    staff: staffVal,
                    category_2: category_29Val,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'fund_last':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: staffVal,
                    fund_last: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'fund_range':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: staffVal,
                    fund_range: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'country':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    staff: staffVal,
                    category_2: category_29Val,
                    country: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'staff':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'category_28':
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category,
                    staff: staffVal,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            default:
                params = {
                    dashboard: dashboard,
                    page: numPage,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_1: category,
                    category_2: category_29Val,
                    staff: staffVal,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q,
                }
        }
        api.companiList(params)
            .then(res => {
                setCompanyList(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const barClick = (chart_obj, args, opts) => {
        if (args.event.type == 'click') {
            let elems = chart_obj.getElementsAtEventForMode(args.event, 'point', { intersect: true }, true);
            if (elems.length > 0) {
                let filters = [];
                let idFilterLabel = opts['label_filter_id'];
                if (idFilterLabel) {
                    let label = chart_obj.data.labels[elems[0].index];
                    if (opts['label_filter_map'])
                        filters.push({ idFilterLabel: opts['label_filter_map'][label] ? opts['label_filter_map'][label] : label });
                    else
                        filters.push({ idFilterLabel: label });

                    setOpen(true);
                    setBarClickParams(filters[0]['idFilterLabel'])
                    setBarClickParamsLabel(idFilterLabel)
                }

                if (opts['dataset_filter_id']) {
                    let dataset_title = chart_obj.data.datasets[elems[0].datasetIndex].label;
                    if (opts['dataset_filter_map'])
                        filters.push(opts['dataset_filter_id'] + '=' + encodeURIComponent(opts['dataset_filter_map'][dataset_title] ? opts['dataset_filter_map'][dataset_title] : dataset_title));
                    else
                        filters.push(opts['dataset_filter_id'] + '=' + encodeURIComponent(dataset_title));

                    setBarDataSetsParamsLabel(opts['dataset_filter_id']);
                    setBarDataSetsParams(opts['dataset_filter_map'][dataset_title])
                }
                if (opts['filter_query'] && opts['filter_query'] > '')
                    filters.push(opts['filter_query']);
                filters.push({ per_page: 50 });
                getCompanies()
            }
        }
    }

    const [dataBarChart, setDataBarChart] = React.useState({
        labels: '1',
        datasets: [{
            label: 'Number of companies',
            borderColor: '#000',
            data: 1,
            fill: true,
            backgroundColor: '#000',
        }]
    });
    const [dataBarChartClick, setDataBarChartClick] = React.useState({
        labels: '1',
        datasets: []
    });

    return (
        <Box sx={{ p: '1rem' }}>
            <Typography variant='h4' sx={{ color: '#d0d2d6' }}>Build statistics chart of:</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} xl={4}>
                    <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                        <Typography sx={{
                            color: '#ff9f43',
                            fontWeight: '500',
                            fontSize: '1.1rem',
                            lineHeight: '1.45',
                            fontFamily: '"Montserrat", Helvetica, Arial, serif'
                        }}>Companies
                        </Typography>
                        <FormStat
                            bgcolorSelect='#213f66'
                            filtersCategoryStatistics={filtersCategoryStatistics}
                            handleFormChange={handleFormChange}
                            form={form}
                            setForm={setForm}
                            handleSubmitForm={handleSubmitForm}
                            handleResetForm={handleResetForm} />
                    </MainDarkBox>
                </Grid>
                <Grid item xs={12} md={8} xl={8}>
                    <Box sx={{ background: 'rgb(35, 48, 68)', borderRadius: '7px', p: '1.5rem' }}>
                        <StatisticsChart
                            formSubmit={formSubmit}
                            industry={industry}
                            form={form}
                            dataBarChartClick={dataBarChartClick}
                            setDataBarChart={setDataBarChart}
                            setDataBarChartClick={setDataBarChartClick}
                            barClick={barClick}
                            dashboard={dashboard} />
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ '.MuiDialog-paper': { boxShadow: 'none', borderRadius: '0px' }, '.MuiDialog-paperFullScreen': { width: '90%', height: '80%' } }}
            >
                <DialogCompanies
                    setCompanyList={setCompanyList}
                    companyList={companyList}
                    getCompanies={getCompanies}
                    setPage={setPage}
                    page={page}
                    barClickParams={barClickParams}
                    barClickParamsLabel={barClickParamsLabel}
                    barDataSetsParamsLabel={barDataSetsParamsLabel}
                    barDataSetsParams={barDataSetsParams}
                    form={form}
                    handleClose={handleClose}
                    setCompanyDetail={setCompanyDetail}
                    setAnchorElDetail={setAnchorElDetail}
                ></DialogCompanies>
            </Dialog>
            <Dialog
                fullScreen
                open={openNew}
                keepMounted
                onClose={handleCloseDetail}
                aria-describedby="alert-dialog-slide-description"
                sx={{ '.MuiDialog-paper': { boxShadow: 'none', borderRadius: '0px' }, '.MuiDialog-paperFullScreen': { width: '60%', height: '80%' } }}
            >
                <DialogDetailsStatistics
                    handleCloseDetail={handleCloseDetail}
                    companyDetail={companyDetail}
                ></DialogDetailsStatistics>
            </Dialog>
        </Box >
    )
}

export default Statistics;