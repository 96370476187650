import { Box } from '@mui/material';
import React from 'react';
import MegaDash from './fieldConteiner/megadash';

const RenderField = (type) => {
    const renderField = (type) => {
        switch (type.type) {
            case 'GlobalDashboard':
                return (
                    <MegaDash />
                );

            default:
                break;
        }
    }

    return (
        <Box sx={{height: '100%'}} >
            {renderField(type)}
        </Box>
    )
}

export default RenderField;