import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/image/dka_with_text_white_logo2.png';
import CovidHeader from './covidHeader';


const useStyles = makeStyles({
    activeLink: {
        background: 'rgb(30, 41, 58)',
        boxShadow: '0 0 10px 1px rgb(30, 41, 58 / 70%)',
        borderRadius: '4px',
        color: 'rgb(238, 238, 238)',
        margin: '0 0',
        padding: '1.5rem 0',
    },
});


const CovidLayout = ({ children, itemPage, handleClickOnLink, parameters }) => {

    const navigate = useNavigate();

    const classes = useStyles();

    return (
        <Box sx={{ position: 'fixed', left: 0, width: { xl: '13.4%', xs: "16.6%", md: "15%" } }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '20px',
            }}>
                <Box sx={{ mr: '1rem' }}>
                    <Link onClick={() => navigate('/')}>
                        <img alt='LOGO' width='100%' src={logo} />
                    </Link>
                </Box>
            </Box>
            <Box>
                {itemPage && itemPage.map(i => (
                    <Box key={i.textMenu} className={i.active ? classes.activeLink : ''}
                        sx={{
                            display: 'flex',
                            p: '1.5rem 0',
                            color: 'rgb(238, 238, 238, 0.7)',
                            cursor: 'pointer',
                            transition: 'padding-left 0.25s ease-in-out',
                            '&:hover': {
                                p: '1.5rem 0',
                                transition: 'padding-left 0.25s ease-in-out'
                            },
                            justifyContent: 'left'
                        }}
                        onClick={() => handleClickOnLink(i)}>
                        <Box pl={2}>{i.iconMenu}</Box>
                        <Typography sx={{ ml: '0.5rem', fontSize: '14px' }}>{i.textMenu}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default CovidLayout;