import { Box } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const BlockChart = ({dataBlockGraph1Arr}) => {
  
  const options = {
    grid: {
      show: false,
    },

    chart: {
      toolbar: {
        show: false,
      },
      height: "100%",
      type: "area",
    },

    dataLabels: {
      enabled: false,
    },

    stroke: {
      show: true,
      colors: ["#7466F0"],
      curve: "smooth",
      width: 2,
    },

    tooltip: {
      enabled: false,
    },

    fill: {
      colors: ["#7466F0"],
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#293145"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },

    yaxis: {
      show: false,
      min: 6,
    },

    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  let series = [
    {
      name: "series1",
      data: dataBlockGraph1Arr,
    },
  ];

  return (
    <Box sx={{ m: "-2rem", mt: "1rem" }}>
      <Suspense fallback={<div>...loading</div>}>
      <Chart options={options} series={series} type="area" height={128} />
      </Suspense>
    </Box>
  );
};

export default BlockChart;