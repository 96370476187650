import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";


import defaultLogo from '../../../../../assets/image/default_company_image.png'



const CompanyCardInfo = ({ company, dropDownFilters, id }) => {

    return (
        <TableContainer sx={{ background: 'rgb(35, 48, 68)', width: '100%', display: 'table' }}>
            <Table>
                <TableBody sx={{
                    width: '100%',
                    '& td': { borderBottom: 'none', }
                }}>
                    <TableRow sx={{ width: '100%' }}>
                        <TableCell sx={{
                            color: '#D0D2D6',
                            width: '100%',
                            height: '130px',
                            background: id % 2 === 0 ? 'rgb(33 42 62)' : 'rgb(26 33 53)'
                        }}>
                            <Box sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                '& img': { maxHeight: '80px', maxWidth: '100px' }
                            }}>
                                <img width='100%' src={company.logo ? `${process.env.REACT_APP_URL_NEW}files/${company.logo}` : defaultLogo} />
                                <Typography sx={{ textAlign: 'center' }}>{company.title}</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                    {dropDownFilters && dropDownFilters.map((el, i) => {
                        if (el.active) {
                            return (
                                <TableRow key={el.title} sx={{ width: '100%' }} >
                                    <TableCell sx={{
                                        minWidth: '100%',
                                        color: '#D0D2D6',
                                        height: el.id === 'staff' ? { xs: '100px', xl: '48px' } : '34px',
                                        background: (id + (i + 1)) % 2 === 0 ? 'rgb(33 42 62)' : 'rgb(26 33 53)'
                                    }}>
                                        <Typography sx={{
                                            color: company[el.id] === null ? '#506380' : '#D0D2D6',
                                            fontSize: '1rem'
                                        }}>{company[el.id] === null ? 'No information' : company[el.id]}
                                        </Typography>
                                    </TableCell>
                                </TableRow>)
                        } else { return (null) }
                    })}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default CompanyCardInfo;