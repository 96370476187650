import { Grid } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";
import SelectBlock from "./selectBlock.js"
import CountryScore from "./countryScore"
import Ranking from "./ranking"
import ByIndicatorGroup from "./byIndicatorGroup"

const LeftBlock = ({ country, countrySelect, handleCountryChange, countrySelectData, categoriesData, categoriesList }) => {

  return (

    <Grid item container xs={12} md={2.5} >
      <Grid item xs={12} sx={{ textAlign: "center", mt: {xs:"1rem", md:"2rem"} }}>
        <SelectBlock
          country={country}
          countrySelect={countrySelect}
          handleCountryChange={handleCountryChange}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: {xs:"1rem", md:"2rem"} }}>
        <MainDarkBox>
          <CountryScore score={countrySelectData.countryScore} />
        </MainDarkBox>
      </Grid>

      <Grid item xs={12} sx={{ mt: {xs:"1rem", md:"2rem"} }}>
        <MainDarkBox>
          <Ranking rank={countrySelectData.rank} />
        </MainDarkBox>
      </Grid>

      <Grid item xs={12} sx={{ mt: {xs:"1rem", md:"2rem"}, mb: {xs: "1rem", md:"0"}, height: {md:"760px"}}}>
        <MainDarkBox>
          <ByIndicatorGroup
            countrySelect={countrySelect}
            categoriesData={categoriesData}
            categoriesList={categoriesList}
          />
        </MainDarkBox>
      </Grid>
    </Grid>
  );
};

export default LeftBlock;