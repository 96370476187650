import { Box } from "@mui/material";

import Page from "./fivePagesComponents/index"


const EconomyResilience = () => {

    return(
        <Box>
            <Page pageName="Economy Resilience"/>
        </Box>
    )
}

export default EconomyResilience;