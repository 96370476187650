import axios from 'axios';
import qs from "qs";

let urlBase = `${process.env.REACT_APP_URL_NEW}`
if (process.env.REACT_APP_URL.indexOf(window.location.host) != -1) {
    urlBase = `${process.env.REACT_APP_URL}`
}
const instance = axios.create({
    baseURL: urlBase,
});

export const api = {
    getParams: async ({ params }) => {
        return (
            await instance.get('/dashboards/api/dashboard-stats/', {
                params: { params, requ: 'app' },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
}