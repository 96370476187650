import React from 'react'
import { Box, Grid, Typography, Autocomplete, Divider, TextField, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { api } from '../../../../api/apiChart'
import { makeStyles } from '@mui/styles';

const options = [
    '--all sectors--',
    'AI for Longevity',
    'Advanced Cosmetics',
    'Clinical Data Management',
    'Deep Dentistry',
    'Deep Diagnostics',
    'Gene Therapy',
    'Geroscience',
    'InsurTech',
    'Mental Health',
    'NeuroTech',
    'P4 Medicine',
    'PharmTech',
    'Regenerative Medicine',
    'Telemedicine',
    'Wellness&Fitness'
];
const optionsInvestors = [
    '--all sectors--',
];
const itemsInput = [
    {
        title: 'Foundation Year',
        value: [],
        name: 'year',
        options: [{ title: '1781' }, { title: '1803' }, { title: '1816' }, { title: '1834' }, { title: '1854' }, { title: '1858' },
        { title: '1861' }, { title: '1870' }, { title: '1873' }, { title: '1874' }, { title: '1875' }, { title: '1878' }
            , { title: '1879' }, { title: '1884' }, { title: '1885' }, { title: '1888' }, { title: '1889' }, { title: '1890' }, { title: '1891' }, { title: '1892' }, { title: '1893' }, { title: '1895' },
        { title: '1899' }, { title: '1901' }, { title: '1902' }
            , { title: '1904' }, { title: '1905' }, { title: '1906' }, { title: '1907' }, { title: '1908' }, { title: '1909' }, { title: '1910' }, { title: '1912' }, { title: '1914' }, { title: '1915' }, { title: '1916' }, { title: '1917' }, { title: '1918' }
            , { title: '1919' }, { title: '1920' }, { title: '1921' }, { title: '1922' }, { title: '1923' }, { title: '1925' }, { title: '1926' }, { title: '1927' }, { title: '1929' }, { title: '1931' }, { title: '1932' }, { title: '1933' }, { title: '1934' }
            , { title: '1935' }, { title: '1936' }, { title: '1937' }, { title: '1938' }, { title: '1939' }, { title: '1941' }, { title: '1943' }, { title: '1944' }, { title: '1945' }, { title: '1946' }, { title: '1947' }, { title: '1948' }, { title: '1949' }
            , { title: '1950' }, { title: '1951' }, { title: '1952' }, { title: '1953' }, { title: '1954' }, { title: '1955' }, { title: '1956' }, { title: '1957' }, { title: '1958' }, { title: '1959' }, { title: '1960' }, { title: '1961' }, { title: '1962' }
            , { title: '1963' }, { title: '1964' }, { title: '1965' }, { title: '1966' }, { title: '1967' }, { title: '1968' }, { title: '1969' }, { title: '1970' }, { title: '1971' }, { title: '1972' }, { title: '1973' }, { title: '1974' }, { title: '1975' }
            , { title: '1976' }, { title: '1977' }, { title: '1978' }, { title: '1979' }, { title: '1980' }, { title: '1981' }, { title: '1982' }, { title: '1983' }, { title: '1984' }, { title: '1985' }, { title: '1986' }, { title: '1987' }, { title: '1988' }
            , { title: '1989' }, { title: '1990' }, { title: '1991' }, { title: '1992' }, { title: '1993' }, { title: '1994' }, { title: '1995' }, { title: '1996' }, { title: '1997' }, { title: '1998' }, { title: '1999' }, { title: '2000' }, { title: '2001' }
            , { title: '2002' }, { title: '2003' }, { title: '2004' }, { title: '2005' }, { title: '2006' }, { title: '2007' }, { title: '2008' }, { title: '2009' }, { title: '2010' }, { title: '2011' }, { title: '2012' }, { title: '2013' }, { title: '2014' }
            , { title: '2015' }, { title: '2016' }, { title: '2017' }, { title: '2018' }, { title: '2019' }, { title: '2020' }],
        stateName: 'year'
    },
    {
        title: 'Number of employees',
        value: [],
        name: 'staff',
        options: [
            { title: '1-10', value: 'c_00001_00010' },
            { title: '11-50', value: 'c_00011_00050' },
            { title: '51-100', value: 'c_00051_00100' },
            { title: '101-250', value: 'c_00101_00250' },
            { title: '251-500', value: 'c_00251_00500' },
            { title: '501-1000', value: 'c_00501_01000' },
            { title: '1001-5000', value: 'c_01001_05000' },
            { title: '5001-10000', value: 'c_05001_10000' },
            { title: 'more than 10000', value: 'c_10001_max' }
        ],
        stateName: 'staff'
    },
    {
        title: 'Funding Status',
        value: [],
        name: 'fund_last',
        options: [
            { title: '22.09.2000' },
            { title: 'Early Stage Venture' },
            { title: 'IPO' },
            { title: 'Late Stage Venture' },
            { title: 'M&A' },
            { title: 'N/A' },
            { title: 'Private Equity' },
            { title: 'Seed' }
        ],
        stateName: 'fund_last'
    },
    {
        title: 'Total Funding (Range)',
        value: [],
        name: 'fund_range',
        options: [
            { title: '< $1MM', value: 'f_000_001' },
            { title: '$1MM to $5MM', value: 'f_001_005' },
            { title: '$5MM to $10MM', value: 'f_005_010' },
            { title: '$10MM to $25MM', value: 'f_010_025' },
            { title: '$25MM to $50MM', value: 'f_025_050' },
            { title: '$50MM to $100MM', value: 'f_050_100' },
            { title: '$100MM to $250MM', value: 'f_100_250' },
            { title: '$250MM to $500MM', value: 'f_250_500' },
            { title: 'over $500MM', value: 'f_000_001' }
        ],
        stateName: 'fund_range'
    },
    {
        title: 'IPO Status',
        value: [],
        name: 'ipo_status',
        options: [
            { title: 'Delisted' },
            { title: 'Private' },
            { title: 'Public' }
        ],
        stateName: 'ipo_status'
    },
    {
        title: 'Country of Headquarters',
        value: [],
        name: 'country',
        options: [
            { title: 'United Arab Emirates' }, { title: 'Australia' }, { title: 'Bangladesh' }, { title: 'Bahrain' }, { title: 'Switzerland' }, { title: 'China' }, { title: 'Germany' },
            { title: 'Estonia' }, { title: 'Finland' }, { title: 'France' }, { title: 'United Kingdom' }, { title: 'Hong Kong' }, { title: 'Indonesia' }, { title: 'Ireland' }, { title: 'Israel' }, { title: 'India' },
            { title: 'Italy' }, { title: 'Japan' }, { title: 'South Korea' }, { title: 'Kuwait' }, { title: 'Lebanon' }, { title: 'Malaysia' }, { title: 'Philippines' }, { title: 'Saudi Arabia' },
            { title: 'Singapore' }, { title: 'Thailand' }, { title: 'Turkey' }, { title: 'Taiwan' }, { title: 'United States' }
        ],
        stateName: 'country'
    },
    {
        title: 'Estimated Revenue',
        value: [],
        name: 'fund_rev',
        options: [
            { title: 'Less than $1M' }, { title: '$50M to $100M' }, { title: '$500M to $1B' }, { title: '$1M to $10M' }, { title: '$1B to $10B' }, { title: '$10M to $50M' }, { title: '$10B+' }, { title: '$100M to $500M' }
        ],
        stateName: 'fund_rev'
    },
]

const Filters = ({ handleCloseDrawer, title, discription, handleFilters, handleCategoty28, allFilters, section, dashboard }) => {
    const useStyles = makeStyles({
        '@global': {
            '.MuiAutocomplete-paper': {
                backgroundColor: 'rgb(35, 48, 68) !important'
            },
            '.MuiAutocomplete-option:hover': {
                backgroundColor: 'rgba(64, 122, 214, 0.2) !important',
                color: 'rgb(64, 122, 214)!important'
            },
            '.MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: 'rgb(64, 122, 214) !important'
            }
        }
    });
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState('--all sectors--');
    const [renderFiedls, setRenderFields] = React.useState();
    React.useEffect(() => {
        if (section === 'investors') {
            api.filtersInvestorsNew({ section: 'investors', dashboard: dashboard, fields: ['year', 'fund_range', 'fund_last', 'staff', 'fund_rev', 'country', 'ipo_status'], sorting: ['fund_total', 'staff'] })
                .then(res => {
                    itemsInput[0].options = res.filters.year.value
                    itemsInput[1].options = res.filters.staff.value
                    itemsInput[2].options = res.filters.fund_last.value
                    itemsInput[3].options = res.filters.fund_range.value
                    itemsInput[4].options = res.filters.ipo_status.value
                    itemsInput[5].options = res.filters.country.value
                    itemsInput[6].options = res.filters.fund_rev.value
                    setRenderFields([...itemsInput])
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            api.filtersNew({ section: 'companies', dashboard: dashboard, fields: ['year', 'fund_range', 'fund_last', 'staff', 'fund_rev', 'country', 'ipo_status'], sorting: ['fund_total', 'staff'] })
                .then(res => {
                    console.log(res)
                    itemsInput[0].options = res.filters.year.value
                    itemsInput[1].options = res.filters.staff.value
                    itemsInput[2].options = res.filters.fund_last.value
                    itemsInput[3].options = res.filters.fund_range.value
                    itemsInput[4].options = res.filters.ipo_status.value
                    itemsInput[5].options = res.filters.country.value
                    itemsInput[6].options = res.filters.fund_rev.value
                    setRenderFields([...itemsInput])
                })
                .catch(err => {
                    console.log(err)
                })
        }

    }, []);
    const handleValueInput = (e, item) => {
        let arrRenderFields = renderFiedls
        arrRenderFields.forEach(element => {
            if (element.title == item.title) {
                element.value = e.target.value
            }
        });
        setRenderFields([...arrRenderFields])
    }
    const handleValueInputSelect = (newValue, name) => {
        let arrRenderFields = renderFiedls
        arrRenderFields.forEach(element => {
            if (element.name == name) {
                element.value = newValue
            }
        });
        setRenderFields([...arrRenderFields])
    }

    return (
        <Box sx={{ backgroundColor: 'rgb(35, 48, 68)', minWidth: '100%', maxWidth: '400px' }}>
            <Grid container justifyContent='space-between' p={2}>
                <Grid item xs={11}>
                    <Typography sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>{title}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CloseIcon onClick={handleCloseDrawer} sx={{ fill: '#b4b7bd', cursor: 'pointer' }} />
                    </Box>
                </Grid>
                <Typography sx={{ color: '#b4b7bd', fontSize: '1rem' }}>{discription}</Typography>
            </Grid>
            {/* <Divider sx={{ background: '#3b4253' }} /> */}
            {/* <Box p={2} mb={1}>
                <Typography sx={{ color: '#b4b7bd', fontSize: '1rem', mb: '15px' }}>Longevity Sector</Typography>
                <Box sx={{ width: '90%', margin: ' 0 auto' }}>
                    <Autocomplete
                        value={allFilters.category_28}
                        onChange={(event, newValue) => {
                            handleCategoty28(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        fullWidth={true}
                        id="controllable-states-demo"
                        options={options}
                        sx={{
                            '.MuiAutocomplete-groupLabel': {
                                background: '#000'
                            },
                            '.MuiAutocomplete-root': { border: '1px solid #3B4253', padding: '0px' }
                        }}
                        clearIcon={''}
                        popupIcon={<KeyboardArrowDownIcon sx={{ fill: '#b4b7bd' }} />}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                sx={{
                                    '.MuiOutlinedInput-root': { border: '1px solid #3B4253', padding: '0px', color: '#b4b7bd', },

                                }}
                                variant="outlined"
                                color="primary"
                            />}
                    />
                </Box>
            </Box> */}
            {renderFiedls && renderFiedls.map(item => (
                <Box>
                    <Divider sx={{ background: '#3b4253', m: '10px 0px' }} />
                    <Box key={item.title} sx={{ padding: ' 0 1rem' }}>
                        <Typography sx={{ color: '#b4b7bd', fontSize: '1rem', mb: '15px' }}>{item.title}</Typography>
                        <Box sx={{ width: '90%', margin: ' 0 auto' }}>
                            <Autocomplete
                                multiple={true}
                                value={item.value}
                                onChange={(event, newValue) => {
                                    handleValueInputSelect(newValue, item.name);
                                    handleFilters(item.stateName, newValue)
                                }}
                                getOptionLabel={(option) => option.title ? option.title : option[1]}
                                fullWidth={true}
                                options={item.options}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.title ? option.title : option[1]}
                                            {...getTagProps({ index })}
                                            sx={{ background: 'rgba(64, 122, 214, 0.12)', color: 'rgb(64, 122, 214)', height: '26px', borderRadius: '4px' }}
                                            deleteIcon={<ClearIcon sx={{ fill: 'rgb(64, 122, 214)', fontSize: '12px !important' }} />}
                                        />
                                    ))
                                }
                                sx={{ '.MuiAutocomplete-root': { border: '1px solid #3B4253', padding: '0px' } }}
                                popupIcon={''}
                                clearIcon={''}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        sx={{
                                            '.MuiOutlinedInput-root': { border: '1px solid #3B4253', padding: '0px', color: '#b4b7bd', },

                                        }}
                                        variant="outlined"
                                        color="primary"
                                    />
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}
export default Filters