import { Box, MenuItem, Typography } from "@mui/material";
import React from "react";
import CircleIcon from '@mui/icons-material/Circle';




const ListIndustry = ({ jsonIndustryDataArr, handleClickMenuItem }) => {

    return (
        <Box sx={{
            overflowY: { xs: 'auto', xl: 'hidden' },
            height: 'calc(90% + 2rem)'
        }}>
            {jsonIndustryDataArr && jsonIndustryDataArr.map(i => (
                <MenuItem
                    dense={false}
                    // disabled={i !== 'SpaceTech' ? true : false}
                    onClick={() => handleClickMenuItem(i)}
                    key={i} sx={{
                        minHeight: 'calc(5.66% + 0.11rem) !important',

                        width: '100%',
                        color: '#b4b7bd',
                        background: '#283046',
                        '.MuiList-root': { backgroundColor: '#1a2646' },
                        '&:hover': { backgroundColor: '#1a2646' },
                        '& .Mui-selected': {
                            backgroundColor: 'rgb(0, 160, 250)'
                        },
                    }} value={i}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Box sx={{
                            xs: { width: '60%' },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{i}</Box>
                        {/* {i !== 'SpaceTech' ? <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Typography sx={{
                                pr: '5px',
                                color: '#8680C4'
                            }}>
                                coming soon
                            </Typography>
                            <CircleIcon sx={{
                                fill: '#8680C4',
                                borderRadius: '50%',
                                fontSize: '13px'
                            }} />
                        </Box> : ''} */}
                    </Box>
                </MenuItem>))}
        </Box>
    )


}


export default ListIndustry;