import { Box, Typography } from "@mui/material";
import React from "react";
import { api } from "../../../../api/apiSpaceTech";
import LineChart from "./lineChart";





const PortfolioStockData = ({ ticker, purchaseDateValue, sellDateValue, clearBool, setClearBool, valueTicker }) => {

  const [labels, setLabels] = React.useState([])
  const [series, setSeries] = React.useState([])


  React.useEffect(() => {
    if (clearBool) {
      setLabels([]);
      setSeries([]);
      setClearBool(false)
    }
  }, [clearBool])

  React.useEffect(() => {
    let month
    if (purchaseDateValue.getMonth() > 9) {
      month = purchaseDateValue.getMonth() + 1
    } else {
      month = `0${purchaseDateValue.getMonth() + 1}`
    }
    const purchaseDate = `${purchaseDateValue.getFullYear()}-${month}-${purchaseDateValue.getDate()}`

    if (sellDateValue.getMonth() > 9) {
      month = sellDateValue.getMonth() + 1
    } else {
      month = `0${sellDateValue.getMonth() + 1}`
    }
    const sellDate = `${sellDateValue.getFullYear()}-${month}-${sellDateValue.getDate()}`;
    let graphSeries = [];

    valueTicker && valueTicker.forEach(el => {
      api.getInvestmentHistoricalData({ ticker: el, purchaseDate, sellDate })
        .then(res => {
          if (ticker) {
            if (!labels || labels.length < res.dates.length) {
              setLabels(res.dates)
            }
            let data = [];
            res.price.forEach(el => {
              data.push(Math.round(parseFloat(el)))
            });
            const name = el.substring(el.indexOf(':') + 1, el.length)
            graphSeries.push({ name: name, data: data })
            setSeries([...graphSeries])
          }
        })
        .catch(err => { console.log(err) })
    })

    // if (ticker) {
    //   api.getInvestmentHistoricalData({ ticker, purchaseDate, sellDate })
    //     .then(res => {
    //       if (ticker) {
    //         if (!labels || labels.length < res.dates.length) {
    //           setLabels(res.dates)
    //         }
    //         let data = [];
    //         res.price.forEach(el => {
    //           data.push(Math.round(parseFloat(el)))
    //         });
    //         const name = ticker.substring(ticker.indexOf(':') + 1, ticker.length)
    //         if (series.length > 0) {
    //           let dataArr = series;
    //           dataArr.forEach((el, key) => {
    //             console.log(el.name === name)
    //             if (el.name === name) {
    //               console.log(dataArr[key])
    //               dataArr.splice(key, 1);
    //               setSeries([...dataArr])
    //             } else {
    //               dataArr.push({ name: name, data: data })
    //               setSeries([...dataArr])
    //             }
    //           })
    //         } else {
    //           setSeries([{ name: name, data: data }])
    //         }
    //       }
    //     })
    //     .catch(err => { console.log(err) })
    // }
  }, [ticker, purchaseDateValue, sellDateValue, valueTicker])

  return (
    < Box
      sx={{
        width: "inherit",
        background: "rgb(35, 48, 68)",
        borderRadius: "7px",
        p: "1.5rem",
        boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
      }
      }>
      <Box>
        <Typography>Selected Companies Stock Data</Typography>
        {series && ticker && <LineChart series={series} labels={labels} colors='#000' />}
      </Box>
    </Box >
  )
}

export default PortfolioStockData;