import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts";

const CategoryRadialBar = ({ color, name, value, data }) => {

    let series = [0];

    if (data){
      data = parseFloat(data).toFixed(2);
    }

    series = [parseFloat(data).toFixed(1)];

    const options = {
      chart: {
        toolbar: {
          show: false,
        },
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -125,
          endAngle: 125,
          offsetX: 0,
          track: {
            background: "rgba(186, 191, 199, 0.12)",
            strokeWidth: "10%",
            opacity: 1,
            margin: 17,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 10,
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "36px",
              color: "#D0D2D6",
              formatter: function (val) {
                  return val + "%";
              },
            },
          },
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        lineCap: "round",
      },
      fill: {
        colors: color,
      },
      labels: ["Average Results"],
    };

  return (
    <Box sx={{ m: "-1.5rem", height: "200px", }}>
      <Box sx={{ textAlign: "center", mt: "0.5rem", height: "40px" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: "500",
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box sx={{ mt: "1rem", height: "160px" }}>
        {data && value === "%" && (
          <Chart
            options={options}
            series={series}
            type="radialBar"
            height={200}
          />
        )}
        {data && value !== "%" && (
          <Box
            sx={{
              textAlign: "center",
              pr: "1rem",
              pl: "1rem"
            }}
          >
            <Typography
              sx={{
                fontSize: "30px",
                fontFamily: "Montserrat",
                fontWeight: "500",
              }}
            >
              {data}
            </Typography>
            <Typography
              sx={{
                fontSize: "30px",
                fontFamily: "Montserrat",
                fontWeight: "500",
              }}
            >
              {value}
            </Typography>
          </Box>
        )}
        {!data && (
          <Box
            sx={{
              textAlign: "center",
              pr: "1rem",
              pl: "1rem"
            }}
          >
          <Typography
            sx={{
              fontSize: "36px",
              fontFamily: "Montserrat",
              fontWeight: "500",
            }}
          >
            No
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              fontFamily: "Montserrat",
              fontWeight: "500",
            }}
          >
            data
          </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CategoryRadialBar;