import { Box, Grid, Typography, Divider } from "@mui/material";
import spaceTechGraph from '../../../../assets/image/allDashboards/spaceTechGraph.png'
import ImgBlock from './imgBlock';
import { useNavigate } from 'react-router-dom';

const SpaceTechAnalytics = ({ ColorButton }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/spacetech", { replace: true })
    }

    return(
        <Grid item container xs={12} >
            <Grid item xs={12} >
                <Box>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '28px', width:"50%", textAlign:"left", display: "inline-block" }}>Deep Knowledge Analytics</Typography>
                    <Box sx={{ width:"50%", display: "inline-block", textAlign: "right" }}>
                        <ColorButton variant="contained" onClick={handleClick} >Visit Website</ColorButton>
                    </Box>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '14px', width:"50%", textAlign:"left", opacity: "0.4" }}>1 dashboard</Typography>
                </Box>
            <Divider sx={{borderColor: "#FFFFFF", opacity: "0.16", p: "0.5rem 0" }}/>
            </Grid>
            <Grid item container xs={12} >
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0.75rem", md:"0.75rem", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"SpaceTech Big Data Analytics"} imageSrc={spaceTechGraph} pathname="/spacetech" />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SpaceTechAnalytics;