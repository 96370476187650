import { Box, Typography } from "@mui/material";
import React from 'react';
import Chart from 'react-apexcharts';
import CircleIcon from '@mui/icons-material/Circle';

const LineGraph = ({ data, colors }) => {
  const ref = React.useRef()
  console.log(ref)
  let labels = [];

  let graphData = []

  let series = [];
  let legend = [];

  colors = ["#0743F5", "#E94537"];

  if (data) {

    if (data.length >= 520) {
      graphData = [
        {
          data: [],
        },
      ]
      let number = 65;
      data.forEach(el => {
        if (number % 65 === 0) {
          graphData[0]["data"].push(parseFloat(el.value).toFixed(0))
          labels.push(el.date);
        }
        number += 1;
      });
      series = graphData;
      colors = ["#0743F5"];
    }
    else if (colors === ["#25A18E"]) {

    }
    else {
      graphData = [
        {
          data: [],
        },
        {
          data: [],
        },
      ]
      data.forEach(el => {
        graphData[0]["data"].push(parseFloat(el["Share (%*100 for graph)"]).toFixed(2))
        graphData[1]["data"].push(parseFloat(el["Funding"]).toFixed(2))
        labels.push(el["Sector"]);
      });
      series = graphData;
      colors = ["#0743F5", "#E94537"];
      legend = ["Funding", "Share (%*100 for graph)"];
    }

  }

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    colors: colors,

    grid: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },

    stroke: {
      show: true,
      colors: colors,
      curve: "smooth",
      width: 2,
    },

    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = series[seriesIndex][dataPointIndex];
        const label = w.globals.categoryLabels[dataPointIndex];
        const color = w.config.colors[seriesIndex].substring(0, 7);
        return `<Box style='padding: 0.4rem 0.5rem; background: ${color}'> ${label}: ${data}</Box>`
      },
    },

    fill: {
      colors: colors,
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#1E2A3A", "#1E2A3A"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
    },

    labels: labels,

    xaxis: {
      labels: {
        show: true,
        rotate: -60,
        rotateAlways: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "8px",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <Box sx={{ height: { md: "300px", xl: "400px", xs: "300px" } }}>
      {legend.length > 0 ? (
        <Box>
          <Typography
            sx={{
              mr: "1rem",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: "400",
              display: "inline-block",
            }}
          >
            <CircleIcon sx={{ height: "8px", color: "#E94537" }} />
            {legend[0]}
          </Typography>
          <Typography
            sx={{
              mr: "1rem",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: "400",
              display: "inline-block",
            }}
          >
            <CircleIcon sx={{ height: "8px", color: "#0743F5" }} />
            {legend[1]}
          </Typography>
        </Box>
      ) : (
        <Box></Box>
      )}
      {/* sx={{ m: "-2rem", mt: "1rem" }} md 200 xl 300 xs 400*/}
      <Box sx={{ height: { md: "300px", xl: "400px", xs: "300px" } }}>
        <Chart ref={ref} options={options} series={series} type="area" height={"100%"} />
      </Box>
    </Box>
  );
};

export default LineGraph;

