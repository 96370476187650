import { Box } from "@mui/system";
import React, { Suspense } from 'react';
// import { VectorMap } from "react-jvectormap";

import '../../../../../assets/jvectormap.css';

function WorldMap({ options }) {

    const refMap = React.useRef()

    const VectorMap = React.lazy(() => import('react-jvectormap').then(module=>({default:module.VectorMap})))

    return (
        <Box sx={{ height: '400px' }}>
            <Suspense fallback={<div>Loading...</div>}>
                <VectorMap {...options}
                    ref={(map) => {
                        if (refMap.current) {
                            refMap.current.tip.remove();
                        }
                        if (map) {
                            refMap.current = map.$mapObject
                        }
                    }}
                />
            </Suspense>

        </Box>
    );
}

export default WorldMap;
