import React from 'react';
import { Box, Grid, Typography, Divider, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from './header';

import Drawer, {
    DrawerContainer,
    MainContentContainer,
} from "react-swipeable-drawer";
import OldDesign from './oldDesign';
import CovidLayout from './covidLayout';
import CovidHeader from './covidHeader';

const useStyles = makeStyles({
    activeLink: {
        background: 'rgb(30, 41, 58)',
        boxShadow: '0 0 10px 1px rgb(30, 41, 58 / 70%)',
        borderRadius: '4px',
        color: 'rgb(238, 238, 238)',
        margin: '0 1rem',
        padding: '0.5rem 1rem',
    },
    activeCreateDash: {
        backgroundColor: 'rgb(30, 41, 58)',
        boxShadow: '0 0 10px 1px rgb(30, 41, 58 / 70%)',
        borderRadius: '4px',
        color: 'rgb(238, 238, 238)',
    }
});

const MainLayout = ({ children,
    items,
    handleClickOnLink,
    contactActive,
    setContactActive,
    title,
    itemsListAsia,
    handleClickOnLinkMenu,
    setActiveLink,
    itemPage,
    parameters,
    boolCovidDash,
    globaldash,
    allDashes,
    createdDash,
    handleClickArrowBack,
    continent,
    industry,
    spacetech }) => {

    const classes = useStyles();
    const [activeMenu, setActiveMenu] = React.useState(true)

    const handleContactActive = () => {
        setContactActive(true);
        setActiveLink('Contacts')
    }
    return (
        <Box>
            <Box sx={{ minHeight: '100vh', display: { xs: 'none', md: 'block' } }}>
                <Grid container sx={{ minHeight: '100vh' }}>
                    {globaldash || allDashes
                        ?
                        ''
                        :
                        <Grid item xl={1.6} xs={2} md={activeMenu ? 1.8 : 1}
                            sx={{ backgroundColor: createdDash ? 'rgb(35, 48, 68)' : '#283046', display: { xs: 'none', md: 'block' }, minHeight: '100vh', maxWidth: activeMenu ? '16%' : '6% !important' }}>
                            {boolCovidDash ? <CovidLayout
                                itemPage={itemPage}
                                handleClickOnLink={handleClickOnLink}
                                parameters={parameters} /> :
                                <OldDesign
                                    handleContactActive={handleContactActive}
                                    activeMenu={activeMenu}
                                    setActiveMenu={setActiveMenu}
                                    contactActive={contactActive}
                                    classes={classes}
                                    handleClickOnLink={handleClickOnLink}
                                    items={items}
                                    handleClickOnLinkMenu={handleClickOnLinkMenu}
                                    itemsListAsia={itemsListAsia}
                                    createdDash={createdDash}
                                    title={title}
                                    continent={continent}
                                    industry={industry}
                                    spacetech={spacetech} />}
                        </Grid>}
                    <Grid item xl={globaldash || allDashes ? 12 : 10.4} xs={12} md={globaldash || allDashes ? 12 : activeMenu ? 10.2 : 11}
                        sx={{ backgroundColor: createdDash ? 'rgb(27, 38, 53)' : '#161d31', maxWidth: globaldash || allDashes ? '100%' : activeMenu ? '83%' : '94% !important', flexBasis: globaldash || allDashes ? '100%' : activeMenu ? '83%' : '94% !important' }}>
                        {!createdDash ? <Box sx={{ position: 'fixed', top: 0, zIndex: 2, width: globaldash || allDashes ? '100%' : activeMenu ? boolCovidDash ? {md:'85%', xl:'86.7%'} : {md:'85%', xl:'86.7%'} : '94%' }}>
                            {boolCovidDash ? <CovidHeader parameters={parameters} /> : <Header title={title} globaldash={globaldash} allDashes={allDashes} />}
                        </Box> :
                            <Box sx={{ width: '100%' }}>
                                <Header title={title} continent={continent} industry={industry} createdDash={createdDash} handleClickArrowBack={handleClickArrowBack} spacetech={spacetech} allDashes={allDashes} />
                            </Box>}
                        {boolCovidDash ?
                            <Box sx={{ pt: { xs: '240px', xl: '128px' } }}>
                                {children}
                            </Box> :
                            <Box sx={{ flex: '1 1 0%', height: globaldash ? 'calc(100% - 180px)' : 'auto', mt: globaldash ? { xs: '80px', md: '120px' } : !createdDash ? { xs: '128px', md: '94px' } : '4rem' }}>
                                {children}
                            </Box>}
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Drawer position="left" size={80}>
                    {({
                        position,
                        size,
                        swiping,
                        translation,
                        mainContentScroll,
                        toggleDrawer,
                        handleTouchStart,
                        handleTouchMove,
                        handleTouchEnd,
                    }) => (
                        <div>
                            <DrawerContainer
                                position={position}
                                size={size}
                                swiping={swiping}
                                translation={translation}
                                toggleDrawer={toggleDrawer}
                                handleTouchStart={handleTouchStart}
                                handleTouchMove={handleTouchMove}
                                handleTouchEnd={handleTouchEnd}
                                drawerContent=
                                {boolCovidDash ? <CovidLayout
                                    itemPage={itemPage}
                                    handleClickOnLink={handleClickOnLink}
                                    parameters={parameters} /> :
                                    <OldDesign
                                        handleContactActive={handleContactActive}
                                        activeMenu={activeMenu}
                                        setActiveMenu={setActiveMenu}
                                        contactActive={contactActive}
                                        classes={classes}
                                        handleClickOnLink={handleClickOnLink}
                                        items={items}
                                        handleClickOnLinkMenu={handleClickOnLinkMenu}
                                        itemsListAsia={itemsListAsia}
                                        createdDash={createdDash}
                                        title={title}
                                        continent={continent}
                                        industry={industry}
                                        spacetech={spacetech} />}
                            />
                            <MainContentContainer
                                translation={translation}
                                mainContentScroll={mainContentScroll}
                            >
                                <Box
                                    sx={{ backgroundColor: createdDash ? 'rgb(27, 38, 53)' : '#161d31' }}>
                                    {!createdDash ? <Box sx={{ position: 'fixed', top: 0, zIndex: 2, width: globaldash ? '100%' : boolCovidDash ? { xs: '100%' } : activeMenu ? boolCovidDash ? '83.3%' : '100%' : '94%' }}>
                                        {boolCovidDash ? <CovidHeader parameters={parameters} /> : <Header title={title} globaldash={globaldash} allDashes={allDashes} />}
                                    </Box> :
                                        <Box sx={{ width: '100%' }}>
                                            <Header title={title} continent={continent} industry={industry} createdDash={createdDash} handleClickArrowBack={handleClickArrowBack} allDashes={allDashes} />
                                        </Box>}
                                    {boolCovidDash ?
                                        <Box sx={{ pt: { xs: '240px', xl: '94px' } }}>
                                            {children}
                                        </Box> :
                                        <Box sx={{ pt: globaldash ? { xs: '80px', md: '114px' } : { xs: createdDash ? '64px' : '128px', md: '94px' } }}>
                                            {children}
                                        </Box>}
                                </Box>
                            </MainContentContainer>
                        </div>
                    )}
                </Drawer>
            </Box>
        </Box >
    )
}

export default MainLayout;