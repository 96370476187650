import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import React from "react";

const Auth = () => {

    const { loginWithRedirect } = useAuth0();
    const { isLoading, isAuthenticated } = useAuth0();

    React.useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            loginWithRedirect();
        }
    },[isAuthenticated, isLoading])

    return (
        <Box>

        </Box>
    )
}

export default Auth;