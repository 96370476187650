import React from 'react'
import { Box, Grid, Typography, Link, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { api } from '../../../../api/apiChart'

const NewsList = ( {companyDetails} ) => {

    const [newsList, setNewsList] = React.useState({articles : [{}]});
    const [newsPageNumber, setNewsPageNumber] = React.useState(0);

    React.useEffect(() => {
        setNewsPageNumber(0)
        api.companyNewsList({
            name: companyDetails.title.replace(/\s+/g, ''),
        })
        .then(res => {
            setNewsList(res)
        })
        .catch(err => {
            console.log(err)
        })
    }, [companyDetails]);

    const handlePageUp = () => {
        if(newsPageNumber + 3 < newsList.articles.length){
            setNewsPageNumber(newsPageNumber + 3)
        }
    };

    const handlePageDown = () => {
        if(newsPageNumber - 3 >= 0){
            setNewsPageNumber(newsPageNumber - 3)
        }
    };

    return (
        <Grid item xs={12} md={6}>
            <Typography sx={{ color: '#D0D2D6', fontSize: '1.286rem', width:'85%', display: "inline-block", pb:"1rem" }}>Company News</Typography>
            <Box sx={{ cursor: 'pointer', width:'5%', display: "inline-block" }} onClick={handlePageDown}>
                <ArrowBackIosNewIcon sx={{ color: "#D0D2D6" }} />
            </Box>
            <Box sx={{ width:'5%', display: "inline-block" }}>
            </Box>
            <Box sx={{ cursor: 'pointer', width:'5%', display: "inline-block" }} onClick={handlePageUp}>
                <ArrowForwardIosIcon sx={{ color: "#D0D2D6" }} />
            </Box>
            {newsList.articles[newsPageNumber] ? 
            <Box sx={{mb:"1rem"}} >
                <Box sx={{p: "1.5rem", backgroundColor: "#40485A", borderRadius: "4px"}}>
                    <Box sx={{display: "flex"}}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '16px', width:"80%", display: "inline-block" }}>{newsList.articles[newsPageNumber].title}</Typography>
                        <Typography sx={{ color: '#D0D2D6', fontSize: '12px', width:"20%", textAlign:"right", display: "inline-block", opacity: "0.5" }}>{newsList.articles[newsPageNumber].publish_date}</Typography>
                    </Box>
                    <Link href={newsList.articles[newsPageNumber].link} target="_blank" sx={{ textDecoration: 'none' }}>
                        <Typography sx={{ color: '#D0D2D6', fontSize: '14px', opacity: "0.5" }}>{newsList.articles[newsPageNumber].link}</Typography>
                    </Link>
                    <Divider sx={{m: "0.5rem 0", borderColor: "#293145", opacity: "0.6"}}/>
                    <Typography sx={{ color: '#D0D2D6', fontSize: '14px', width:"100%" }}>{newsList.articles[newsPageNumber].description}</Typography>
                </Box>
            </Box> : ""
            }
            {newsList.articles[newsPageNumber + 1] ? 
            <Box sx={{mb:"1rem"}} >
                <Box sx={{p: "1.5rem", backgroundColor: "#40485A", borderRadius: "4px"}}>
                    <Box sx={{display: "flex"}}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '16px', width:"80%", display: "inline-block" }}>{newsList.articles[newsPageNumber + 1].title}</Typography>
                        <Typography sx={{ color: '#D0D2D6', fontSize: '12px', width:"20%", textAlign:"right", display: "inline-block", opacity: "0.5" }}>{newsList.articles[newsPageNumber + 1].publish_date}</Typography>
                    </Box>
                    <Link href={newsList.articles[newsPageNumber + 1].link} target="_blank" sx={{ textDecoration: 'none' }}>
                        <Typography sx={{ color: '#D0D2D6', fontSize: '14px', opacity: "0.5" }}>{newsList.articles[newsPageNumber + 1].link}</Typography>
                    </Link>
                    <Divider sx={{m: "0.5rem 0", borderColor: "#293145", opacity: "0.6"}}/>
                    <Typography sx={{ color: '#D0D2D6', fontSize: '14px', width:"100%" }}>{newsList.articles[newsPageNumber + 1].description}</Typography>
                </Box>
            </Box> : ""
            }
            {newsList.articles[newsPageNumber + 2] ? 
            <Box sx={{mb:"1rem"}} >
                <Box sx={{p: "1.5rem", backgroundColor: "#40485A", borderRadius: "4px"}}>
                    <Box sx={{display: "flex"}}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '16px', width:"80%", display: "inline-block" }}>{newsList.articles[newsPageNumber + 2].title}</Typography>
                        <Typography sx={{ color: '#D0D2D6', fontSize: '12px', width:"20%", textAlign:"right", display: "inline-block", opacity: "0.5" }}>{newsList.articles[newsPageNumber + 2].publish_date}</Typography>
                    </Box>
                    <Link href={newsList.articles[newsPageNumber + 1].link} target="_blank" sx={{ textDecoration: 'none' }}>
                        <Typography sx={{ color: '#D0D2D6', fontSize: '14px', opacity: "0.5" }}>{newsList.articles[newsPageNumber + 2].link}</Typography>
                    </Link>
                    <Divider sx={{m: "0.5rem 0", borderColor: "#293145", opacity: "0.6"}}/>
                    <Typography sx={{ color: '#D0D2D6', fontSize: '14px', width:"100%" }}>{newsList.articles[newsPageNumber + 2].description}</Typography>
                </Box>
            </Box> : ""
            }
        </Grid>
    )
}
export default NewsList