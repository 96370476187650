import { Box, Typography } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const OneBar = ({ data, color }) => {

    const options = {
      grid: {
        show: false,
      },
      colors: color,
      chart: {
        offsetX: -10,
        offsetY: -20,
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 6,
          distributed: false,
          colors: {
            backgroundBarColors: ["#7366f01f"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 6,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      xaxis: {
        categories: [""],
        max: 100,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: undefined,
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
    };

  return (
      <Box sx = {{height: "70px"}}>
    <Typography sx={{ ml: "1rem", mt: "1rem", fontSize: "12px", fontFamily: "Montserrat", color: "#D0D2D6"}}> {data.name} </Typography>
    <Box sx={{ minHeight: "0px", height: "30px", path: {clipPath: "inset(0% 0% 0% 0% round 6px)"}}}>

    <Suspense fallback={<div>...loading</div>}>
      <Chart
        options={options}
        series = {[{
          data: data.series
        }]}
        type="bar"
        height={57}
      />
    </Suspense>
    </Box>
    </Box>
  );
};

export default OneBar;