import React from "react";

import { Box, Grid, Typography, Popover, FormControlLabel, Checkbox, FormGroup, Divider, TableContainer, TableBody, TableCell, TableRow, Table } from '@mui/material';
import MainDarkBox from "../../../../mainDarkBox";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CompanyCardInfo from "./companyCardInfo";

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { api } from "../../../../api/apiCreatedDash";


let countPush = 0
const Comparison = ({ industry, location }) => {

    const fieldArr = [{ title: "Country", id: 'country', active: true },
    { title: "Year founded", id: 'year', active: true },
    { title: "Estimated number of employees", id: 'staff', active: true },
    { title: "Region", id: 'region', active: true },
    { title: "Business State", id: 'business_state', active: true },
    { title: "Funding Status", id: 'fund_last', active: true },
    { title: "Total funding", id: 'fund_total', active: true }
    ]


    const [dropDownField, setDropDownField] = React.useState(fieldArr);
    const [dropDownCompany, setDropDownCompany] = React.useState();
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [activeProgress, setActiveProgress] = React.useState(false);

    const [activeFilters, setActiveFilters] = React.useState(false);
    const [arrayCompanies, setArrayCompanies] = React.useState([]);
    const [selectComp, setSelectComp] = React.useState([])


    React.useEffect(() => {
        if (location === 'undefined'){
            location = undefined;
        }
        api.getCompanies({ industries: industry, location })
            .then(res => {
                let compArr = [];
                res.data.forEach(el => {
                    let company = { title: el.title, slug: el.slug, active: false };
                    compArr.push(company);
                })
                setArrayCompanies(compArr)
            })
            .catch(err => { console.log(err) })
    }, [industry])

    const companyInfo = (slug, i) => {
        api.companiDetails({ slug })
            .then(res => {
                const fields = res.fields
                selectComp[i] = {
                    title: res.title,
                    slug: res.slug,
                    logo: fields.logo.value,
                    country: fields.country.value,
                    year: fields.year.value,
                    staff: fields.staff.value,
                    region: fields.region.value,
                    business_state: fields.business_state.value,
                    fund_last: fields.fund_last.value,
                    fund_total: fields.fund_total.value
                }
                setSelectComp([...selectComp])
            })
            .catch(err => { console.log(err) })
    }

    const handleDropDownCompany = (e) => {
        let boolSelectedCompany = false;
        setActiveProgress(true)

        selectComp.forEach((el, key) => {
            if (el.slug === e.target.value) {
                selectComp.splice(key, 1);
                boolSelectedCompany = true;
                countPush = selectComp.length - 1;
                setSelectComp([...selectComp])
            }
        })

        let titleDelete;
        if (selectComp.length === 4 && !boolSelectedCompany) {
            titleDelete = selectComp[countPush].title;
        }
        arrayCompanies.forEach(el => {
            if (el.slug === e.target.value) {
                el.active = !el.active
            }
            if (el.title === titleDelete) {
                el.active = !el.active
            }
        })

        if (!boolSelectedCompany) {
            companyInfo(e.target.value, countPush)
            if (countPush === 3) {
                countPush = 0;
            } else {
                countPush++;
            }
        }
        setArrayCompanies([...arrayCompanies])
    };

    const handleDropDown = (e) => {
        setActiveProgress(true)
        dropDownField.forEach(el => {
            if (el.title == e.target.value) {
                el.active = !el.active
            }
        })
        setDropDownField([...dropDownField])
    };

    const handleCloseCompany = () => {
        setAnchorEl1(null);
    };

    const handleClickCompany = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleUpFilterMenu = () => {
        setActiveFilters(!activeFilters)
    }

    const handleDeleteCompanies = (element, key) => {
        selectComp.splice(key, 1)
        countPush = selectComp.length;
        arrayCompanies.forEach(el => {
            if (element.title === el.title) {
                el.active = !el.active;
            }
        })
        setArrayCompanies([...arrayCompanies])
        setSelectComp([...selectComp])
    }

    const handleDeleteAllSelectCompanies = () => {
        arrayCompanies.forEach(el => {
            el.active = false;
        })
        countPush = 0;
        setArrayCompanies([...arrayCompanies]);
        setSelectComp([])
    }



    const openCompany = Boolean(anchorEl1);
    const ids = openCompany ? 'simpl-popover' : undefined;
    return (
        <Box sx={{ pt: '1rem' }}>
            <Box sx={{ margin: '0 1rem' }}>
                <MainDarkBox bgcolor='rgb(35, 48, 68)' padBot={activeFilters || selectComp.length !== 0 ? true : false}>
                    <Grid container>
                        <Grid item xs={12} md={5} >
                            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                                <Typography sx={{
                                    p: '0.4rem 1rem',
                                    fontWeight: '500',
                                    fontSize: '18px',
                                    lineHeight: '24px'
                                }}>Company Comparison</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11.5} md={6.5}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    background: '#213f66',
                                    color: '#fff',
                                    padding: '0.486rem 1.5rem',
                                    fontSize: '1rem',
                                    borderRadius: '0.358rem',
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                    width: { xs: '100%', md: '50%' },
                                    justifyContent: 'space-between'
                                }}
                                    onClick={handleClickCompany}>
                                    <Typography sx={{
                                        ml: '3px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>Select Company</Typography>
                                    <KeyboardArrowDownIcon sx={{ fill: '#fff', fontSize: '20px', ml: '5px' }} />
                                </Box>
                                <Popover
                                    sx={{
                                        '.MuiPopover-paper': {
                                            background: 'rgb(35, 48, 68)',
                                            boxShadow: '0 4px 24px 0 rgb(0 0 0 / 24%)',
                                            marginTop: '0.5rem',
                                            borderRadius: '0.358rem',
                                        },
                                    }}
                                    id={ids}
                                    open={openCompany}
                                    anchorEl={anchorEl1}
                                    onClose={handleCloseCompany}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box >
                                        <FormGroup value={dropDownCompany} onChange={(e) => {
                                            handleDropDownCompany(e)
                                        }}
                                            aria-label="position" name="position" defaultValue="top" sx={{ display: 'flex', alignItems: 'start' }}>
                                            {arrayCompanies && arrayCompanies.map((el, i) => (
                                                <FormControlLabel
                                                    key={el.title}
                                                    className='app'
                                                    sx={{
                                                        width: '100%',
                                                        background: el.active ? 'rgb(115 176 255 / 12%)' : '',
                                                        justifyContent: 'flex-end',
                                                        ml: '0 !important',
                                                        padding: '10px 70px 10px 10px',
                                                        '.MuiFormControlLabel-label': {
                                                            color: '#b4b7bd',
                                                            '&:hover': { color: '#7367f0' }
                                                        }
                                                    }}
                                                    value="top"
                                                    control={<Checkbox id={`${i}`} name={el.title} value={el.slug} sx={{ display: 'none' }} />}
                                                    label={el.title}
                                                    labelPlacement="start"
                                                />
                                            ))}
                                        </FormGroup>
                                    </Box>
                                </Popover>
                            </Box>
                        </Grid>

                        <Grid item xs={0.5} md={0.5}>
                            <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', cursor: 'pointer' }}
                                onClick={handleUpFilterMenu}>
                                <FilterAltOutlinedIcon sx={{
                                    width: '32px',
                                    height: '32px',
                                    color: !activeFilters ? '#FFF' : 'rgb(64, 122, 214)',
                                    ':hover': {
                                        color: 'rgba(64, 122, 214, 0.8)'
                                    }
                                }} />
                            </Box>
                        </Grid>
                        {activeFilters && <Grid item xs={12}>
                            <Divider sx={{ pt: '0.4rem', m: '0 -1.5rem', borderColor: '#12213b' }} />
                            <FormGroup
                                value={dropDownCompany}
                                onChange={(e) => {
                                    handleDropDown(e)
                                }}
                                aria-label="position"
                                name="position"
                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-evenly' }}>
                                {dropDownField && dropDownField.map(el => (
                                    <FormControlLabel
                                        key={el.title}
                                        className='app'
                                        sx={{
                                            justifyContent: 'flex-end',
                                            ml: '0 !important',
                                            '.MuiFormControlLabel-label': {
                                                color: '#b4b7bd',
                                                fontSize: '12.5px',
                                                '&:hover': { color: '#7367f0' }
                                            }
                                        }}
                                        value="top"
                                        control={<Checkbox value={el.title} checked={el.active} />}
                                        label={el.title}
                                        labelPlacement="end"
                                    />
                                ))}
                            </FormGroup>
                        </Grid>}
                        {selectComp.length !== 0 &&
                            <Grid item xs={12}>
                                <Divider sx={{ pt: '0.4rem', m: '0 -1.5rem', borderColor: '#12213b' }} />
                                <Box sx={{
                                    pt: { xs: '1rem' },
                                    width: { xs: '100%', md: 'auto' },
                                }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
                                            {selectComp && selectComp.map((i, key) => (
                                                <Box key={key}
                                                    sx={{
                                                        pr: '0.6rem',
                                                        pb: '0.6rem'
                                                    }}>
                                                    <Box sx={{
                                                        background: '#BABFC729',
                                                        borderRadius: '4px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <Typography sx={{
                                                            fontSize: '0.6rem',
                                                            color: '#FFFFFF',
                                                            pr: '4px',
                                                            p: '0.4rem 1rem',
                                                            maxWidth: '100px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        >{i.title ? i.title : 'NONE'}</Typography>
                                                        <Divider sx={{ border: '0.1px solid #293145' }} orientation="vertical" flexItem />
                                                        <ClearIcon onClick={() => handleDeleteCompanies(i, key)} sx={{
                                                            p: '0 0.4rem',
                                                            fontSize: '18px',
                                                            fill: '#b4b7bd',
                                                            cursor: 'pointer',
                                                            ':hover': {
                                                                fill: '#7367f0 !important'
                                                            }
                                                        }} />
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Box>
                                            <Box
                                                sx={{
                                                    pr: '0.6rem',
                                                    pb: '0.6rem'
                                                }}>
                                                <Box onClick={handleDeleteAllSelectCompanies}
                                                    sx={{
                                                        background: '#C1575729',
                                                        borderRadius: '4px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        color: '#C15757',
                                                        '&:hover': {
                                                            background: '#ff424229',
                                                            color: '#ff2424'
                                                        }
                                                    }}>
                                                    <Typography sx={{
                                                        fontSize: '0.6rem',
                                                        pr: '4px',
                                                        p: '0.4rem 1rem',
                                                        maxWidth: '100px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    >Delete All</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>}
                    </Grid>
                </MainDarkBox>
            </Box>
            {selectComp.length !== 0 && <Box sx={{ p: '1rem' }}>
                <Grid container sx={{ background: '#29314533' }}>
                    <Grid item xs={2.4} md={2.4} >
                        <TableContainer sx={{ background: 'rgb(35, 48, 68)', display: 'table' }}>
                            <Table>
                                <TableBody sx={{
                                    width: '100%',
                                    '& td': { borderBottom: 'none', }
                                }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#D0D2D6', background: 'rgb(26 33 53)' }}>
                                            <Typography sx={{
                                                color: '#D0D2D6',
                                                fontSize: '1rem',
                                                height: '130px'
                                            }}>
                                                Company Comparison
                                                Table
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {dropDownField && dropDownField.map((el, i) => {
                                        if (el.active) {
                                            return (
                                                <TableRow key={el.title} >
                                                    <TableCell sx={{
                                                        color: '#D0D2D6',
                                                        height: el.id === 'staff' ? { xs: '100px', xl: '48px' } : '34px',
                                                        background: i % 2 === 0 ? 'rgb(33 42 62)' : 'rgb(26 33 53)',
                                                    }}>
                                                        <Typography sx={{
                                                            color: '#D0D2D6',
                                                            fontSize: '1rem'
                                                        }}>{el.title}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>)
                                        } else { return (null) }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {selectComp[0] && <Grid item xs={2.4} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} company={selectComp[0]} id={0} />
                    </Grid>}
                    {selectComp[1] && <Grid item xs={2.4} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} company={selectComp[1]} id={1} />
                    </Grid>}
                    {selectComp[2] && <Grid item xs={2.4} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} company={selectComp[2]} id={2} />
                    </Grid>}
                    {selectComp[3] && <Grid item xs={2.4} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} company={selectComp[3]} id={3} />
                    </Grid>}
                </Grid>
                {/* <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} />
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} />
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} />
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} />
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <CompanyCardInfo dropDownFilters={dropDownField} />
                    </Grid>
                </Grid> */}
            </Box>}
        </Box >
    )
}


export default Comparison;