
import { Box, Typography } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const DoubleBars = ({ data, name }) => {

  
  
    var options = {
      grid: {
        show: false,
      },
      colors: [function({ dataPointIndex }) {
        if (dataPointIndex === 0) {
          return "#00CFE8";
        } else {
          return "#6A7693";
        }
      }],
      chart: {
        offsetX: -10,
        offsetY: -20,
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 6,
          distributed: false,
          colors: {
            backgroundBarColors: ["rgba(115, 103, 240, 0.12)"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 12,
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
      },
      stroke: {
        show: false,
      },
      xaxis: {
        categories: [""],
        max: 100,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: undefined,
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
          show: false,
      }
    };

  return (
    <Box sx = {{ mr: "-1rem", ml: "-1rem" }}>
    <Typography sx={{ ml: "1rem", mt: "1rem", fontSize: "11px", fontFamily: "Montserrat", color: "#D0D2D6"}}> {name} </Typography>
      <Box
        sx={{
          minHeight: "0px",
          height: "60px",
          path: { clipPath: "inset(0% 0% 0% 0% round 12px)" },
        }}
      >
        <Suspense fallback={<div>...loading</div>}>
        <Chart
          options={options}
          series={[
            {
              data: data || [],
            },
          ]}
          type="bar"
          height={90}
          width={"100%"}
        />
        </Suspense>
      </Box>
    </Box>
  );
};

export default DoubleBars;