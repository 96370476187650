import { Box } from "@mui/material"
import DownloadableGrid from "../../../downloadableGrid";


import spaceTechIndustry2021Q2LandscapeOverview from '../../../../assets/image/createdDashboard/spaceTech/downloadable/spacetech-report.png'
import spaceTechIndustry2021Q2LandscapeOverviewPDF from '../../../../assets/pdf/createdDash/spaceTech/SpaceTech-Industry-2021-Report.pdf'

import digestSpaceTechIndustry2021Q2 from '../../../../assets/image/createdDashboard/spaceTech/downloadable/spacetech-digest.png'
import digestSpaceTechIndustry2021Q2PDF from '../../../../assets/pdf/createdDash/spaceTech/SpaceTech-Investment-Digest-2021.pdf'

import spaceTechGovernmentActivity2021 from '../../../../assets/image/createdDashboard/spaceTech/downloadable/spacetech-goverment.png'
import spaceTechGovernmentActivity2021PDF from '../../../../assets/pdf/createdDash/spaceTech/SpaceTech-Government-Activity-Overview-2021.pdf'

import spaceMedicineandHumanLongevityinSpace from '../../../../assets/image/createdDashboard/spaceTech/downloadable/spacetech-medicine.png'
import spaceMedicineandHumanLongevityinSpacePDF from '../../../../assets/pdf/createdDash/spaceTech/Space-Medicine-and-Human-Longevity-in-Space-2021.pdf'

import investmentDigestSpaceTechIndustryFullReport from '../../../../assets/image/createdDashboard/spaceTech/downloadable/spacetech-investment-digest.png'
import investmentDigestSpaceTechIndustryFullReportPDF from '../../../../assets/pdf/createdDash/spaceTech/SpaceTech-Investment-Digest-2021-Q3.pdf'

import spaceTechIndustryOverview2021 from '../../../../assets/image/createdDashboard/spaceTech/downloadable/spacetech-industry-overview.png'
import spaceTechIndustryOverview2021PDF from '../../../../assets/pdf/createdDash/spaceTech/SpaceTech-Industry-Overview-2021-Q3.pdf'

import givingBirthinSpace2021 from '../../../../assets/image/createdDashboard/spaceTech/downloadable/giving-birth-in-space.png'
import givingBirthinSpace2021PDF from '../../../../assets/pdf/createdDash/spaceTech/Giving-Birth-in-Space-2021.pdf'

import sрасеtechinSwitzerlandCaseStudy from '../../../../assets/image/createdDashboard/spaceTech/downloadable/blockchain_in_space_case_study.png'
import sрасеtechinSwitzerlandCaseStudyPDF from '../../../../assets/pdf/createdDash/spaceTech/blockchain_in_space_case_study.pdf'

import blockchaininSpaceCaseStudy from '../../../../assets/image/createdDashboard/spaceTech/downloadable/sрасеtech_in_switzerland_case_study.png'
import blockchaininSpaceCaseStudyPDF from '../../../../assets/pdf/createdDash/spaceTech/sрасеtech_in_switzerland_case_study.pdf'

const Downloadable = () => {

    const data = [
        {
            id: 1,
            title: "SpaceTech Industry 2021 Q2 Landscape Overview",
            image: spaceTechIndustry2021Q2LandscapeOverview,
            date: "2021-09-23",
            file_access: "open",
            file_link: spaceTechIndustry2021Q2LandscapeOverviewPDF,
            file_size: 33609824
        },
        {
            id: 2,
            title: "Investment Digest SpaceTech Industry 2021 / Q2",
            image: digestSpaceTechIndustry2021Q2,
            date: "2021-09-23",
            file_access: "open",
            file_link: digestSpaceTechIndustry2021Q2PDF,
            file_size: 33609824
        },
        {
            id: 3,
            title: "SpaceTech Government Activity 2021 / Q2 Landscape Overview",
            image: spaceTechGovernmentActivity2021,
            date: "2021-09-23",
            file_access: "open",
            file_link: spaceTechGovernmentActivity2021PDF,
            file_size: 33609824
        },
        {
            id: 4,
            title: "Space Medicine and Human Longevity in Space",
            image: spaceMedicineandHumanLongevityinSpace,
            date: "2021-09-23",
            file_access: "open",
            file_link: spaceMedicineandHumanLongevityinSpacePDF,
            file_size: 44946068
        },
        {
            id: 5,
            title: "Investment Digest SpaceTech Industry 2021/Q3 Full Report",
            image: investmentDigestSpaceTechIndustryFullReport,
            date: "2021-09-23",
            file_access: "open",
            file_link: investmentDigestSpaceTechIndustryFullReportPDF,
            file_size: 33609824
        },
        {
            id: 6,
            title: "SpaceTech Industry Overview 2021 Q3",
            image: spaceTechIndustryOverview2021,
            date: "2021-11-30",
            file_access: "open",
            file_link: spaceTechIndustryOverview2021PDF,
            file_size: 7026585
        },
        {
            id: 7,
            title: "givingBirthinSpace2021",
            image: givingBirthinSpace2021,
            date: "2021-11-30",
            file_access: "open",
            file_link: givingBirthinSpace2021PDF,
            file_size: 27062421
        },
        {
            id: 8,
            title: "Blockchain in Space Case Study",
            image: blockchaininSpaceCaseStudy,
            date: "2021-11-30",
            file_access: "open",
            file_link: blockchaininSpaceCaseStudyPDF,
            file_size: 1301493
        },
        {
            id: 9,
            title: "Sрасеtech in Switzerland Case Study",
            image: sрасеtechinSwitzerlandCaseStudy,
            date: "2021-11-30",
            file_access: "open",
            file_link: sрасеtechinSwitzerlandCaseStudyPDF,
            file_size: 1301493
        }
    ]
    return (
        <Box sx={{pt: '1rem'}}>
            <DownloadableGrid data={data} createdDash={true}/>
        </Box>
    )
}


export default Downloadable