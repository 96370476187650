import React from 'react';
import { Box} from '@mui/material';
import Companies from './companies';

const Investors = () => {

    return(
        <Box>
            <Companies section={'investors'}/>
        </Box>
    )
}

export default Investors;