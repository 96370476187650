import axios from 'axios';
import qs from "qs";

let urlBase = `${process.env.REACT_APP_URL_NEW}`
if (process.env.REACT_APP_URL.indexOf(window.location.host) != -1) {
    urlBase = `${process.env.REACT_APP_URL}`
}
const instance = axios.create({
    baseURL: urlBase,
});

export const api = {
    chartDataCompaniesStats: async ({ dashboard, section, cat1, cat2, mode, q, country }) => {
        return (
            await instance.get(`/dashboards/api/companies-stats`, { params: { dashboard, section, cat1, cat2, mode, q, country, requ: 'app' } })
        ).data;
    },
    chartDataFundingsStats: async ({ dashboard, section, cat1, mode, country }) => {
        return (
            await instance.get(`/dashboards/api/fundings-stats`, { params: { dashboard, section, cat1, mode, country, requ: 'app' } })
        ).data;
    },
    companiList: async ({ section, dashboard, page, per_page, sort, category_1, category_2, cat1, cat2, mode, q, country, year, fund_range, fund_last, fund_rev, staff, category_28, category_29, ipo_status, industry, sector }) => {
        return (
            await instance.get('/dashboards/api/companies-list', {
                params: { section, dashboard, page, per_page, sort, category_1, category_2, cat1, cat2, mode, q, country, year, fund_range, fund_last, fund_rev, staff, category_28, category_29, ipo_status, 'deeptech-industry': industry, 'deeptech-sub-industry': sector, requ: 'app' },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
    companiDetails: async ({ slug }) => {
        return (
            await instance.get(`/dashboards/api/company-details`, { params: { slug, requ: 'app' } })
        ).data;
    },
    competitors: async ({ company, dashboard, mode }) => {
        return (
            await instance.get('/dashboards/api/get-company-competitors/', { params: { company, dashboard, mode, requ: 'app' } })
        ).data;
    },
    competitorsMain: async ({ section, dashboard, title }) => {
        return (
            await instance.get('/dashboards/api/companies-list/', { params: { section, dashboard, title, requ: 'app' } })
        ).data;
    },
    dashboardStats: async (params) => {
        return (
            await instance.get(`/dashboards/api/dashboard-stats?${params}&requ=app`)
        ).data;
    },
    filters: async () => {
        return (
            await instance.get(`/dashboards/api/companies-filters?section=companies&dashboard=asia&fields=year&fields=fund_range&fields=fund_last&fields=staff&fields=fund_rev&fields=longevity-sector&fields=longevity-subsector&fields=country&fields=ipo_status&sorting=fund_total&sorting=staff&requ=app`)
        ).data;
    },
    filtersInvestors: async () => {
        return (
            await instance.get(`/dashboards/api/companies-filters?section=investors&dashboard=asia&fields=year&fields=fund_range&fields=fund_last&fields=staff&fields=fund_rev&fields=longevity-sector&fields=longevity-subsector&fields=country&fields=ipo_status&sorting=fund_total&sorting=staff&requ=app`)
        ).data;
    },
    filtersNew: async ({ section, dashboard, fields, sorting }) => {
        return (
            await instance.get(`/dashboards/api/companies-filters`,
                {
                    params: { section, dashboard, fields, sorting, requ: 'app' },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: 'repeat' })
                    }
                })
        ).data;
    },
    filtersInvestorsNew: async ({ section, dashboard, fields, sorting }) => {
        return (
            await instance.get(`/dashboards/api/companies-filters`,
                {
                    params: { section, dashboard, fields, sorting, requ: 'app' },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: 'repeat' })
                    }
                })
        ).data;
    },
    companyNewsList: async ({ name }) => {
        return (
            await instance.get(`https://deeptech-338512.oa.r.appspot.com/api/articles/${name}`, {
                params: { name, requ: 'app' },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
    financialDealsList: async () => {
        return (
            await instance.get(`https://deeptech-338512.oa.r.appspot.com/api/deals`, {
                params: { requ: 'app' },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
}