import React from "react";
import { Box, Typography } from "@mui/material";
import BarChart from "./barChart";




const DailyIncomePercentage = ({ investmentData }) => {

    const [series, setSeries] = React.useState([{ data: [0] }])
    const [labels, setLabels] = React.useState([])

    React.useEffect(() => {
        if (investmentData) {
            setSeries([{ data: investmentData['Daily Income percentage Chart'].Frequency }]);
            let label = [];
            investmentData['Daily Income percentage Chart']['Return Percentage'].forEach(el => {
                label.push(`${el}`)
            })
            setLabels([...label])
        }
    }, [investmentData])

    return (
        <Box
            sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
            }}
        >
            <Box sx={{ height: 'calc(100% - 2rem)' }}>
                <Typography>Daily Income Percentage Chart</Typography>
                {investmentData && <BarChart series={series} labels={labels} />}
            </Box>
        </Box>
    )
}

export default DailyIncomePercentage;