import { Box, Grid, Typography } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const OneBarChartStacked = ({country, vaccinationRate, economyResilience, governmentEfficiency, quarantineEfficiency, healthcareManagement, color, labelColor}) => {

  const options = {
    grid: {
      show: false,
    },

    colors: ["#00CFE8", "#7B61FF", "#DB6AB5", "#DFC04F", "#28C7AA"],

    chart: {
      offsetY: -10,
      toolbar: {
        show: false,
      },
      type: "bar",
      stacked: true,
      sparkline: {
        enabled: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 8,
        distributed: false,
        colors: {
          backgroundBarColors: ["#7366f01f"],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 8,
        },
      },
    },

    stroke: {
      show: false,
    },

    xaxis: {
        max: 500,
      categories: [country],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    yaxis: {
      labels: {
        show: false,
      },
      title: {
        text: undefined,
      },
    },

    tooltip: {
      enabled: false,
    },

    fill: {
      opacity: 1,
    },

    legend: {
      show: false,
    },
  };

  return (
    <Box sx={{ pb: "1rem", height: "40px", background: color}}>
      <Grid item container>
        <Grid item xs={2} >
          <Box sx={{ m: "1rem", verticalAlign: "middle",}}>
            <Typography
                sx={{
                  color: `${labelColor} !important`,
                  pt: "0.3rem",
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                }}
                >{country}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={10} >
      <Box sx={{ml: {md:"-1rem", xs:"1rem"}}}>
        <Suspense fallback={<div>...loading</div>}>
          <Chart
            options={options}
            series={[
                {
                  name: "Economy Resilience",
                  data: [economyResilience],
                },
                {
                  name: "Government Efficiency",
                  data: [governmentEfficiency],
                },
                {
                  name: "Healthcare Management",
                  data: [healthcareManagement],
                },
                {
                  name: "Quarantine Efficiency",
                  data: [quarantineEfficiency],
                },
                {
                  name: "Vaccination Rate",
                  data: [vaccinationRate],
                },
              ]}
            type="bar"
            height={65}
            width={'98%'}
          />
      </Suspense>
      </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OneBarChartStacked;
