import React from "react";
import { Box } from "@mui/material";
import Chart from "react-apexcharts";


const LineChartStrict = ({ name, series, years }) => {

    const options = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: "#7B61FF",
        width: 2,
      },
      title: {
        text: name,
        align: "left",
        style: {
          color: "#B4B7BD",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
      grid: {
        borderColor: "#171D30",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: years,
        labels: {
          show: true,
          style: {
            colors: "#B4B7BD",
            fontSize: "11px",
            fontFamily: "Montserrat",
            fontWeight: 400,
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "#B4B7BD",
            fontSize: "11px",
            fontFamily: "Montserrat",
            fontWeight: 400,
          },
        },
      },
    };

    return (
        <Box>
            <Chart
                series={[{
                    name: "Desktops",
                    data: series
                }]}
                options={options}
                height='400'
                type="line" />
        </Box>
    )
}

export default LineChartStrict;