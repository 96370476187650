import { Box, Grid, Typography, Select, MenuItem, InputBase } from "@mui/material";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React from "react";
import FormControl from '@mui/material/FormControl';
import { api } from "../../../../api/apiSpaceTech";
import PortfolioChartBlock from "./portfolioChartBlock";
import PortfolioSummary from "./portfolioSummary";
import PortfolioStockData from "./portfolioStockData";
import FormBlock from "./formBlock";
import PolarAreaChart from "./polarAreaChart";
import DailyIncomePercentage from "./dailyIncomePecentage";
import { makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const parametersCubeSvg = [
  { NameCube1: 'Performance', value1: 0, NameCube2: 'Treynor Measure', value2: 0, color: '#6B68DE' },
  { NameCube1: 'Maximal return', value1: 0, NameCube2: 'Value at Risk', value2: 0, color: '#15DDFF' },
  { NameCube1: 'Downside deviation', value1: 0, NameCube2: 'Sterling Ratio', value2: 0, color: '#7EFA97' },
  { NameCube1: 'Sharpe Ratio', value1: 0, NameCube2: 'Sortino Ratio', value2: 0, color: '#C74E7C' },
  { NameCube1: 'Ulcer Performance Index', value1: 0, NameCube2: 'Expected shortfall', value2: 0, color: '#FF8AFF' },
  { NameCube1: 'Portfolio Standard Deviation', value1: 0, NameCube2: 'Mean Absolute Deviation', value2: 0, color: '#FF7FB9' },
  { NameCube1: 'Skewness', value1: 0, NameCube2: 'kurtosis', value2: 0, color: '#FFC572' }
]

const PortfolioConstructor = () => {
  const TextInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      color: '#b4b7bd',
      position: 'relative',
      backgroundColor: '#213f66',
      border: '1px solid #404656',
      height: "16px",
      fontSize: 16,
      padding: '10px 12px',
      '&:focus': {
        borderColor: 'rgb(64, 122, 214) !important',
      },
      '& .MuiInputBase-input:focus': {
        borderColor: 'rgb(64, 122, 214) !important',
      }
    },
  }));

  const ColorButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: "14px",
    padding: '11px 21px',
    height: '38px',
    fontFamily: "Montserrat, Helvetica, Arial, serif",
    color: theme.palette.getContrastText("#7367f0"),
    backgroundImage: 'linear-gradient(47deg, #314054, #213f66)',
    '&:hover': {
      backgroundColor: "#7367f0",
    },
  }));

  const useStyles = makeStyles({
    '@global': {
      '.Mui-selected': {
        backgroundColor: 'rgb(64, 122, 214) !important',
        color: '#FFF!important'
      },
      '.Mui-selected:hover': {
        backgroundColor: 'rgba(64, 122, 214, 0.2) !important',
        color: 'rgb(64, 122, 214)!important'
      },
      'MuiMenuItem-root:hover': {
        backgroundColor: 'rgba(64, 122, 214, 0.2) !important',
        color: 'rgb(64, 122, 214)'
      },
      '.MuiInputBase-input:focus': {
        borderColor: 'rgb(64, 122, 214) !important',
      }
    }
  });
  const classes = useStyles();


  const [value, setValue] = React.useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 3,
      new Date().getDate()
    )
  );
  const [value2, setValue2] = React.useState(new Date());
  const [arrTickers, setArrTickers] = React.useState();
  const [portfolioSummary, setPortfolioSummary] = React.useState(parametersCubeSvg);
  const [tick, setTick] = React.useState();
  const [netIncome, setNetIncome] = React.useState();
  const [netIncomePercent, setNetIncomePercent] = React.useState();
  const [seriesPie, setSeriesPie] = React.useState([]);
  const [labelsPie, setLabelsPie] = React.useState([])
  const [lastValue, setLastValue] = React.useState();
  const [clearBool, setClearBool] = React.useState(false);
  const [valueTicker, setValueTicker] = React.useState([]);
  const [investmentData, setInvestmentData] = React.useState();
  const [form, setForm] = React.useState({
    amount: 10000,
    portfolio_type: 'equal_weights',
    risk_percentage: '',
    profit_percentage: '',
    ef: 5
  });
  const [defaultValueCustom, setDefaultValueCustom] = React.useState([])
  const [formAll, setFormAll] = React.useState();


  React.useEffect(() => {
    let ticker
    let ticks = valueTicker.join(',')
    if (lastValue) {
      const tickers = lastValue
      if (tick) {
        ticker = tick + ',' + tickers
      } else {
        ticker = tickers
      }
      setTick(ticker);
    }
    let month
    if (value.getMonth() > 9) {
      month = value.getMonth() + 1
    } else {
      month = `0${value.getMonth() + 1}`
    }
    const purchaseDate = `${value.getFullYear()}-${month}-${value.getDate()}`

    if (value2.getMonth() > 9) {
      month = value2.getMonth() + 1
    } else {
      month = `0${value2.getMonth() + 1}`
    }
    const sellDate = `${value2.getFullYear()}-${month}-${value2.getDate()}`;
    let formGet = form
    formGet['tickers'] = ticks
    formGet['purchaseDate'] = purchaseDate;
    formGet['sellDate'] = sellDate;

    if (form.portfolio_type === 'custom') {
      let val = [];
      let customWeights = []
      if (defaultValueCustom) {
        valueTicker.forEach(el => {
          val.push({ ticker: el, value: (100 / valueTicker.length).toFixed(2) })
        })
        setDefaultValueCustom([...val])
        val.forEach(el => {
          customWeights.push(`${el.ticker}:${el.value}`)
        })
        formGet['custom_weights'] = `{${customWeights.join(',')}}`
      } else {
        defaultValueCustom.forEach(el => {
          customWeights.push(`${el.ticker}:${el.value}`)
        })
        formGet['custom_weights'] = `{${customWeights.join(',')}}`
      }
    }

    setFormAll({ ...formGet })

  }, [form, value, value2, lastValue])

  React.useEffect(() => {
    if (formAll) {
      if (formAll['tickers']) {
        api.getInvestment(formAll)
          .then(res => {
            setInvestmentData(res)
          })
          .catch(err => { console.log(err) })
      } else {
        handleReset()
      }
    }
  }, [formAll])

  React.useEffect(() => {
    if (investmentData) {
      setNetIncome(investmentData['Net Income']);
      setNetIncomePercent(investmentData['Net Income %']);
      let seriesDataPie = [], labelsDataPie = [];
      for (let i in investmentData['Wages pie char']) {
        seriesDataPie.push(investmentData['Wages pie char'][i].toFixed(2))
        labelsDataPie.push(i)
      }
      setSeriesPie([...seriesDataPie]);
      setLabelsPie([...labelsDataPie])
      portfolioSummary.forEach(el => {
        if (investmentData["Portfolio Summary"][el.NameCube1] === 'Not exist') {
          el.value1 = 0;
        } else {
          el.value1 = investmentData["Portfolio Summary"][el.NameCube1]
        }
        if (investmentData["Portfolio Summary"][el.NameCube2] === 'Not exist') {
          el.value2 = 0;
        } else {
          el.value2 = investmentData["Portfolio Summary"][el.NameCube2]
        }
      })
      setPortfolioSummary([...portfolioSummary])
    }
  }, [investmentData])

  const handleFormChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLastValue(event.target.value[event.target.value.length - 1])
    setValueTicker(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleCustomChange = (e) => {
    let data = [];
    let customWeights = [];
    defaultValueCustom.forEach(el => {
      if (el.ticker === e.target.id) {
        el.value = e.target.value
      } else {
        el.value = (100 - e.target.value).toFixed(2)
      }
    })
    defaultValueCustom.forEach(el => {
      customWeights.push(`${el.ticker}:${el.value}`)
    })
    formAll['custom_weights'] = `{${customWeights.join(',')}}`
    setFormAll({ ...formAll })
    setDefaultValueCustom([...defaultValueCustom])
  }


  const handleReset = () => {
    setClearBool(true)
    setLastValue()
    setValueTicker([])
    setTick()
    setNetIncome('');
    setNetIncomePercent('');
    setSeriesPie([])
    setLabelsPie([])
    setInvestmentData()
    portfolioSummary.forEach(el => {
      el.value1 = 0;
      el.value2 = 0;
    })
    setPortfolioSummary([...portfolioSummary])
  }

  React.useEffect(() => {
    api.getPartfolioTickers({ dashboard: 'Spacetech' })
      .then(res => {
        setArrTickers(res)
      })
      .catch(err => { console.log(err) })
  }, [])


  return (
    <Box>
      <Box>
        <Grid container sx={{ p: "1.5rem" }}>
          <Grid item container xs={12} md={12}>
            <Grid item xs={12} md={4}
              sx={{ pr: { md: "0.75rem" }, height: { md: "714px" } }}
            >
              <Box
                sx={{
                  height: "inherit",
                  width: "inherit",
                  background: "rgb(35, 48, 68)",
                  borderRadius: "7px",
                  p: "1.5rem",
                  boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Select Companies
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl sx={{ m: '8px 0', width: '99%' }}>
                      <Select
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": { borderColor: "#404656" },
                          "& .MuiMenuItem-root": { background: "#283046" },
                          width: "100%",
                          color: "#b4b7bd",
                          background: "#213f66",
                        }}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={valueTicker}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {arrTickers && arrTickers.map((el, key) => (
                          <MenuItem
                            sx={{
                              width: "100%",
                              color: "#b4b7bd",
                              background: "#1d3049",
                              ".MuiList-root": { backgroundColor: "#1a2646" },
                              "&:hover": { backgroundColor: "#1a2646" },
                              "& .Mui-selected": {
                                backgroundColor: "rgb(0, 160, 250)",
                              },
                            }}
                            key={key}
                            name={el.substring(el.indexOf(':') + 1, el.length)}
                            value={el}
                          // style={getStyles(el.substring(0, el.indexOf(':')), personName, theme)}
                          >
                            {el.substring(el.indexOf(':') + 1, el.length)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <ColorButton onClick={handleReset} variant="contained">Reset</ColorButton>
                  </Box>
                </Box>
                {form.portfolio_type === 'custom' &&
                  <Box>
                    <Typography>Portfolio weight</Typography>
                    {defaultValueCustom && defaultValueCustom.map(el => (
                      <Box key={el.ticker}>
                        <Typography sx={{ width: '100%', pt: '1rem' }}>{el.ticker}</Typography>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}>
                          <InputBase
                            onChange={handleCustomChange}
                            id={el.ticker}
                            value={el.value}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              pb: "0rem",
                              width: "30%",
                              '& .MuiInputBase-input': {
                                borderRadius: 1,
                                color: '#b4b7bd',
                                position: 'relative',
                                backgroundColor: '#213f66',
                                border: '1px solid #404656',
                                height: "16px",
                                fontSize: 16,
                                padding: '10px 12px',
                                '&:focus': {
                                  borderColor: '#7367f0',
                                },
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                }
              </Box>
            </Grid>
            <Grid item xs={12} md={4}
              sx={{
                pl: { md: "0.75rem" },
                pr: { md: "0.75rem" },
                pt: { xs: "1.5rem", md: "0rem" }
              }}>
              <FormBlock
                value={value}
                setValue={setValue}
                value2={value2}
                setValue2={setValue2}
                form={form}
                handleFormChange={handleFormChange} />
            </Grid>
            <Grid item xs={12} md={4}
              sx={{ pl: { md: "0.75rem" }, height: { md: "714px" }, pt: { xs: "1.5rem", md: "0rem" } }}
            >
              <Box sx={{
                height: "inherit",
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
              }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "50%",
                      alignItems: "center",
                      alignContent: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Net Income
                    </Typography>
                    <TextInput
                      value={netIncome}
                      sx={{
                        p: "1rem",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "50%",
                      alignItems: "center",
                      alignContent: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Net Income %
                    </Typography>
                    <TextInput
                      value={netIncomePercent}
                      sx={{
                        p: "1rem",
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                  }}
                >
                  Portfolio Weights Distribution
                </Typography>
                <PolarAreaChart series={seriesPie} labels={labelsPie} />
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={12}
            sx={{ mt: "1.5rem", height: "400px" }} >
            <PortfolioChartBlock investmentData={investmentData} />
          </Grid>
          <Grid item container xs={12} md={12} sx={{ mt: "1.5rem" }}>
            <PortfolioSummary parametersCubeSvg={portfolioSummary} />
          </Grid>
          <Grid item container xs={12} md={12}
            sx={{ mt: "1.5rem", height: "417px" }}>
            <DailyIncomePercentage investmentData={investmentData} />
          </Grid>
          <Grid item container xs={12} md={12}
            sx={{ mt: "1.5rem", height: "417px" }}>
            <PortfolioStockData
              valueTicker={valueTicker}
              ticker={lastValue && lastValue}
              purchaseDateValue={value}
              sellDateValue={value2}
              clearBool={clearBool}
              setClearBool={setClearBool} />
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}


export default PortfolioConstructor;