import React, { Suspense } from 'react';
import { Box, Grid, Typography, Button, Select, MenuItem, Divider } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';

import MainDarkBox from '../../../../mainDarkBox';
import Parameters from '../../../../layouts/parameters';

import jsonIndustryData from '../../../../../assets/json/jsonIndustryData.json';
import ClearIcon from '@mui/icons-material/Clear';
import BriefCase from '../../../../../assets/svg/briefcase.svg'
import GridViewIcon from '@mui/icons-material/GridView';
import cube from '../../../../../assets/cubeBlue.svg';
import fundingRounds from '../../../../../assets/fundingRoundsNew.svg'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import CircleIcon from '@mui/icons-material/Circle';


import SectorsRectangle from './sectorsRectangle';
import ListIndustry from './listIndustry';
import { api } from '../../../../api/apiMegadash';

const parametersData = [
    {
        titleParam: 'Industries',
        icon: <img src={BriefCase} width="25" alt='svg' />,
        color: '#0743F51F',
        counts: 18
    },
    {
        titleParam: 'Locations',
        icon: <LocationOnOutlinedIcon sx={{ fill: '#6DEDE3', fontSize: '2.2rem' }} />,
        color: '#6DEDE31F',
        counts: 123
    },
    {
        titleParam: 'Technologies',
        icon: <img src={fundingRounds} width="25" alt='svg' />,
        color: 'rgba(37, 161, 142, 0.12)',
        counts: 1594
    },
    {
        titleParam: 'Companies & Investors',
        icon: <GridViewIcon sx={{ fill: '#6A63CE', fontSize: '2.2rem' }} />,
        color: 'rgba(106, 99, 206, 0.12)',
        counts: 246
    },
    {
        titleParam: 'Indicators',
        icon: <img src={cube} width="25" alt='svg' />,
        color: 'rgba(7, 67, 245, 0.12)',
        counts: 6574
    },
    {
        titleParam: 'Data Points',
        icon: <GridViewIcon sx={{ fill: '#6DEDE3', fontSize: '2.2rem' }} />,
        color: '#6DEDE31F',
        counts: 4892093
    },
]

let sectorArr = [];

const WorldMap = React.lazy(() => import('./worldMap'));


const MegaDash = () => {

    const [selectIndustry, setSelectIndustry] = React.useState('');
    const [selectContinent, setSelectContinent] = React.useState('Global');
    const [selectSector, setSelectSector] = React.useState([]);
    const [selectCountry, setSelectCountry] = React.useState();
    const [jsonIndustryDataArr, setJsonIndustryDataArr] = React.useState();
    const [sectorDataArr, setSectorDataArr] = React.useState();
    const [sectorIndustryDataArr, setSectorIndustryDataArr] = React.useState();
    const [parametersDataState, setParametersDataState] = React.useState(parametersData);
    const [checkState, setCheckState] = React.useState()


    React.useEffect(() => {
        const params = ['companies', 'investors', 'indicators']
        api.getParams({ params })
            .then(res => {
                parametersData.forEach(el => {
                    if (el.titleParam == 'Indicators ') {
                        el.counts = res.data.indicators;
                    } else if (el.titleParam == 'Companies & Investors') {
                        el.counts = res.data.investors + res.data.companies;
                    }
                    setParametersDataState([...parametersData])
                })
            })
            .catch(err => { console.log(err) })
    }, [])


    React.useEffect(() => {

        let arrayData = [];
        for (let industryParam in jsonIndustryData) {
            arrayData.push(industryParam)
        }
        arrayData.sort()
        setJsonIndustryDataArr(arrayData)
    }, [])

    const navigate = useNavigate()

    const handleIndustryChange = (event) => {
        let check = {}
        const data = jsonIndustryData[event.target.value].SubIndustryArray
        const dataSubIndustry = jsonIndustryData[event.target.value].SubIndustry
        setSectorDataArr([...data])
        setSectorIndustryDataArr(dataSubIndustry)
        setSelectIndustry(event.target.value);
        setSelectSector([]);
        sectorArr = [];
        for (let i in jsonIndustryData[event.target.value].SubIndustry) {
            sectorArr.push(i)
        }
        data.forEach(el => {
            check[el] = false
        })
        setCheckState(check)
        setSelectSector([...sectorArr])
    }

    const handleClickMenuItem = (key) => {
        let check = {}
        const data = jsonIndustryData[key].SubIndustryArray
        const dataSubIndustry = jsonIndustryData[key].SubIndustry
        setSectorDataArr([...data])
        setSectorIndustryDataArr(dataSubIndustry)
        setSelectIndustry(key);
        setSelectSector([]);
        sectorArr = [];
        for (let i in jsonIndustryData[key].SubIndustry) {
            sectorArr.push(i)
        }
        data.forEach(el => {
            check[el] = false
        })
        setCheckState(check)
        setSelectSector([...sectorArr])
    }

    const handleDeleteIndystry = () => {
        setSelectIndustry('')
        setSelectSector([]);
        sectorArr = [];
    }

    const handleDeleteSector = (title) => {
        let arraySector = [];
        sectorArr.forEach((i) => {
            if (i !== title) {
                arraySector.push(i)
            }
        })
        sectorArr = arraySector;
        let objCheck = checkState
        for (let check in objCheck) {
            if (title === check) {
                objCheck[check] = false
            }
        }
        setCheckState({ ...objCheck })
        setSelectSector([...sectorArr])
    }

    const handleDeleteRegion = () => {
        // setSelectContinent('Global');
        // setSelectCountry('Global');
    }

    const handleClickBtn = () => {
        navigate({
            pathname: `/created-dashboard`,
            search: `?${createSearchParams({
                Industry: selectIndustry,
                Sector: selectSector.join(','),
                Country: selectCountry ? selectCountry : undefined,
                Continent: selectContinent ? selectContinent === 'European Union' ? 'Europe' : selectContinent !== 'Global' ? selectContinent : undefined : undefined
            })}`
        }, { replace: true });
    }

    return (
        <Box sx={{ height: '100%' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '10px',
                m: '1rem',
                borderRadius: '4px',
                backgroundColor: '#283046',
                alignItems: 'center',
                color: '#d0d2d6'
            }}>
                <Parameters parametersDataState={parametersDataState} megadash={true} />
            </Box>
            <Box sx={{ height: selectIndustry || selectCountry || (selectContinent && selectContinent !== 'Global') ? { xl: 'calc(80% - 30px)', md: selectIndustry ? 'calc(80% - 65px)' : 'calc(78% - 30px)' } : { xl: 'calc(100% - 10px)', md: 'calc(90% + 60px)' } }}>
                <Grid container sx={{
                    color: '#fff',
                    p: '0 1rem',
                    height: '100%',
                    '& .MuiGrid-item': { height: 'calc(100% - 1rem)' }
                }} spacing={2}>
                    <Grid item xs={12} md={4}>
                        {selectIndustry && <Select
                            displayEmpty
                            color="secondary"
                            sx={{
                                '& .MuiMenuItem-root': {
                                    background: '#283046',
                                },
                                width: '100%',
                                color: '#b4b7bd',
                                background: '#283046',
                                height: '10%'
                            }}
                            value={selectIndustry}
                            onChange={handleIndustryChange}
                            name='industry'
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <em>Select industry</em>;
                                }
                                return selected;
                            }}>
                            <MenuItem
                                sx={{
                                    width: '100%',
                                    color: '#b4b7bd',
                                    background: '#283046',
                                    '.MuiList-root': { backgroundColor: '#1a2646' },
                                    '&:hover': { backgroundColor: '#1a2646' },
                                    '& .Mui-selected': { backgroundColor: 'rgb(0, 160, 250)' }
                                }} disabled value="">Select industry</MenuItem>
                            {jsonIndustryDataArr && jsonIndustryDataArr.map(i => (
                                <MenuItem key={i} sx={{
                                    width: '100%',
                                    color: '#b4b7bd',
                                    background: '#283046',
                                    '.MuiList-root': { backgroundColor: '#1a2646' },
                                    '&:hover': { backgroundColor: '#1a2646' },
                                    '& .Mui-selected': { backgroundColor: 'rgb(0, 160, 250)' }
                                }} value={i}
                                // disabled={i !== 'SpaceTech' ? true : false}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <Box sx={{
                                            xs: { width: '60%' },
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}>{i}</Box>
                                        {/* {i !== 'SpaceTech' ? <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Typography sx={{
                                                pr: '5px',
                                                color: '#8680C4'
                                            }}>
                                                coming soon
                                            </Typography>
                                            <CircleIcon sx={{
                                                fill: '#8680C4',
                                                borderRadius: '50%',
                                                fontSize: '13px'
                                            }} />
                                        </Box> : ''} */}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>}
                        {!selectIndustry ?
                            <ListIndustry jsonIndustryDataArr={jsonIndustryDataArr} handleClickMenuItem={handleClickMenuItem} />
                            : ''}
                        {selectIndustry ?
                            <Box sx={{
                                background: '#283046',
                                borderRadius: '7px',
                                p: '1.5rem',
                                mt: '1rem',
                                boxShadow: '8px 5px 20px 0px rgb(0 0 0 / 13%)',
                                height: { xs: '400px', md: 'calc(80% - 2rem)' }
                            }}>
                                {checkState &&
                                    < SectorsRectangle
                                        checkState={checkState}
                                        setCheckState={setCheckState}
                                        selectIndustry={selectIndustry}
                                        sectorDataArr={sectorDataArr}
                                        sectorIndustryDataArr={sectorIndustryDataArr}
                                        setSelectSector={setSelectSector}
                                        selectSector={selectSector}
                                        sectorArr={sectorArr} />
                                }
                            </Box>
                            : ''}
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Box sx={{
                            background: '#283046',
                            borderRadius: '7px',
                            p: '2.5rem 1.5rem 1.5rem 1.5rem',
                            boxShadow: '8px 5px 20px 0px rgb(0 0 0 / 13%)',
                            height: { md: 'calc(90% - 2rem);' }
                        }}>
                            <Suspense fallback={<div>...loading</div>}>
                                <WorldMap setSelectCountry={setSelectCountry}
                                    setSelectContinent={setSelectContinent}
                                    selectContinent={selectContinent}
                                    industry={selectIndustry} />
                            </Suspense>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {selectIndustry || selectCountry || (selectContinent && selectContinent !== 'Global') ?
                <Box sx={{ p: { xl: '0 1rem', md: '0 1rem', xs: '1rem 1rem' }, height: { xs: 'auto', xl: 'calc(20% - 4rem)' }, minHeight: '100px' }}>
                    <MainDarkBox autoHeigth={true}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {selectIndustry ? <Box>
                                <Typography sx={{ pb: '0.5rem' }}>Industry</Typography>
                                <Box sx={{ background: '#BABFC729', borderRadius: '4px', display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{
                                        color: '#FFFFFF',
                                        pr: '4px',
                                        p: '0.4rem 1rem',
                                        maxWidth: '180px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>{selectIndustry}</Typography>
                                    <Divider sx={{ border: '0.1px solid #293145' }} orientation="vertical" flexItem />
                                    <ClearIcon onClick={handleDeleteIndystry}
                                        sx={{
                                            p: '0 0.4rem',
                                            fontSize: '18px',
                                            fill: '#b4b7bd',
                                            cursor: 'pointer',
                                            ':hover': {
                                                fill: '#7367f0 !important'
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> : ''}
                            {selectSector.length !== 0 ? <Box sx={{
                                pt: { xs: '1rem' },
                                width: { xs: '100%', md: 'auto' },
                            }}>
                                <Typography sx={{ pb: '0.5rem' }}>Sectors</Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selectSector && selectSector.map((i, key) => {
                                        if (key <= 4) {
                                            return (
                                                <Box key={i} sx={{
                                                    pr: '0.6rem',
                                                    pb: '0.6rem'
                                                }}>
                                                    <Box sx={{
                                                        background: '#BABFC729',
                                                        borderRadius: '4px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <Typography sx={{
                                                            color: '#FFFFFF',
                                                            pr: '4px',
                                                            p: '0.4rem 1rem',
                                                            maxWidth: '100px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        >{i}</Typography>
                                                        <Divider sx={{ border: '0.1px solid #293145' }} orientation="vertical" flexItem />
                                                        <ClearIcon onClick={selectSector.length > 1 ? () => handleDeleteSector(i) : ''} sx={{
                                                            p: '0 0.4rem',
                                                            fontSize: '18px',
                                                            fill: '#b4b7bd',
                                                            cursor: 'pointer',
                                                            ':hover': {
                                                                fill: '#7367f0 !important'
                                                            }
                                                        }} />
                                                    </Box>
                                                </Box>)
                                        } else if (key === 5) {
                                            return (
                                                <Box key={i} sx={{
                                                    pr: '0.6rem',
                                                    pb: '0.6rem'
                                                }}>
                                                    <Box sx={{
                                                        background: '#BABFC729',
                                                        borderRadius: '4px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <Typography sx={{
                                                            color: '#FFFFFF',
                                                            pr: '4px',
                                                            p: '0.4rem 1rem',
                                                            maxWidth: '100px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {selectSector.length - 5} more
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    })}
                                </Box>
                            </Box>
                                : ''}
                            {selectContinent ? <Box>
                                <Typography sx={{ pb: '0.5rem' }}>Region</Typography>
                                <Box sx={{ background: '#BABFC729', borderRadius: '4px', display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{
                                        color: '#FFFFFF',
                                        pr: '4px',
                                        p: '0.4rem 1rem',
                                        maxWidth: '180px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>{selectContinent}</Typography>
                                    <Divider sx={{ border: '0.1px solid #293145' }} orientation="vertical" flexItem />
                                    <ClearIcon onClick={handleDeleteRegion}
                                        sx={{
                                            p: '0 0.4rem',
                                            fontSize: '18px',
                                            fill: '#b4b7bd',
                                            cursor: 'pointer',
                                            ':hover': {
                                                fill: '#7367f0 !important'
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> : ''}
                            <Box sx={{
                                pt: { xs: '1.5rem' },
                                width: { xs: '100%', md: 'auto' },
                            }}>
                                <Button
                                    sx={{
                                        background: '#0743F5',
                                        width: { xs: '100%', md: 'auto' },
                                        ':hover': {
                                            background: '#0743f580'
                                        }
                                    }}
                                    disabled={selectSector.length !== 0 && (selectSector.length !== 0 || selectContinent) || selectContinent !== "Global" ? false : true}
                                    variant="contained"
                                    onClick={handleClickBtn}
                                >View dashboard</Button>
                            </Box>
                        </Box>
                    </MainDarkBox>
                </Box > : ''
            }
        </Box >
    )
}

export default MegaDash;