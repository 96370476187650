import { Box } from "@mui/material";
import React, { Suspense } from 'react';
const Chart = React.lazy(() => import('react-apexcharts'))

const RadialBar = ({ series }) => {

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      type: "radialBar",
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 5,
          size: '40%',
          position: 'front',
      },
        track: {
          show: true,
          background: "#171D2F",
          strokeWidth: "97%",
          opacity: 1,
        },
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -10,
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#FFFFFF",
            formatter: function (value) {
              let num = value.toFixed(1);
              return [num, "out of 100"];
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 25, 50, 75],
        colorStops: [
          {
            offset: 0,
            color: "#ea5456",
            opacity: 1,
          },
          {
            offset: 20,
            color: "#e95558",
            opacity: 1,
          },
          {
            offset: 70,
            color: "#7367f0",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#8e63ce",
            opacity: 1,
          },
        ],
      },
    },
    stroke: {
      dashArray: 12,
    },
  };

  return (
    <Box>
      <Suspense fallback={<div>...loading</div>}>
        <Chart
          options={options}
          series = {series}
          type="radialBar"
          height={250}
        />
      </Suspense>
    </Box>
  );
};

export default RadialBar;