import { Box, Divider, Typography } from "@mui/material"


import image1 from '../../../../assets/image/createdDashboard/spaceTech/metodology/methodology_1_core.png'
import image2 from '../../../../assets/image/createdDashboard/spaceTech/metodology/methodology_2_verge.png'
import image3 from '../../../../assets/image/createdDashboard/spaceTech/metodology/methodology_3_space.png'
import image4 from '../../../../assets/image/createdDashboard/spaceTech/metodology/methodology_4_classification.png'



const Metodology = () => {

    return (
        <Box>
            <Box>
                <Box sx={{ width: 'calc(100% - 2rem)', p: '1rem' }}>
                    <Box sx={{ background: 'rgb(35, 48, 68)', p: '1rem', borderRadius: '6px' }}>
                        <Typography variant='h4' sx={{
                            fontWeight: '500',
                            fontSize: '1.285rem',
                        }}>SpaceTech Analytics Methodology</Typography>
                    </Box>
                </Box>

                <Box sx={{ width: 'calc(100% - 2rem)', p: '1rem' }}>
                    <Box sx={{ background: 'rgb(35, 48, 68)', p: '1rem', borderRadius: '6px'  }}>
                        <p class="card-text">The analysis includes more than 12,000 SpaceTech companies that
                            were chosen according to original methodology. All the entities that were
                            analyzed for the SpaceTech Industry Landscape Overview were Boxided into three
                            main categories: Core Companies, Verge Companies, and Space-Applied Businesses
                            based on the following criteria:</p>
                        <p class="card-text">
                            <img src={image1} width='100%'
                                alt="Core companies" />
                        </p>
                    </Box>
                </Box>
                <Box sx={{ width: 'calc(100% - 2rem)', p: '1rem' }}>
                    <Box sx={{ background: 'rgb(35, 48, 68)', p: '1rem', borderRadius: '6px'  }}>
                        <p class="card-text">All the analyzed companies were selected by means of manual and
                            automated search from open web sources. The further sorting of the database was
                            executed both manually and with the use of algorithms. The methodology may
                            contain a slight inaccuracy due to the partially manual construction of the
                            database.</p>
                        <p class="card-text">
                            <img src={image2} width='100%'
                                alt="Verge companies" />
                        </p>
                    </Box>
                </Box>
                <Box sx={{ width: 'calc(100% - 2rem)', p: '1rem' }}>
                    <Box sx={{ background: 'rgb(35, 48, 68)', p: '1rem', borderRadius: '6px'  }}>
                        <p class="card-text">The largest share of the database consists of space-related
                            companies. All of the companies included are developing technologies that will
                            form the backbone of the rapidly growing space industry. Their technologies are
                            at different stages of development, from prototype to first experiments, to
                            being placed into orbit or on another planet.</p>
                        <p class="card-text">
                            <img src={image3} width='100%'
                                alt="Space-Applied companies" />
                        </p>
                    </Box>
                </Box>
                <Box sx={{ p: '0 1rem' }}>
                    <Divider sx={{ borderColor: '#3b4253' }} />
                </Box>
                <Box sx={{ width: 'calc(100% - 2rem)', p: '1rem', borderRadius: '6px'  }}>
                    <Box sx={{ background: 'rgb(35, 48, 68)', p: '1rem' }}>
                        <Typography variant='h4' sx={{
                            fontWeight: '500',
                            fontSize: '1.285rem',
                            mb: '1.53rem'
                        }}>SpaceTech Analytics Industry Classification</Typography>
                        <p class="card-text">
                            <img src={image4} width='100%'
                                alt="Core companies" />
                        </p>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default Metodology;