import { Box, Grid, Typography, Divider } from "@mui/material"
import MainDarkBox from '../../../../mainDarkBox'
import default_company_image from '../../../../../assets/image/default_company_image.png'

const ListItems = ({ investors, competitors, investorsOfCompetitors, portfolio, portfolioOfCompetitors, portfolioCompetitors, dropDownFilters, detailCompany }) => {

    return (
        <Grid container spacing={2} sx={{ mt: '2px' }}>
            <Grid item xs={12} md={4}>
                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                    <Typography sx={{ fontSize: '1.286rem', color: '#d0d2d6', fontWeight: 500 }}>{dropDownFilters === 'companies' ? 'Investors' : 'Portfolio'}</Typography>
                    {dropDownFilters === 'companies' ?
                        investors && investors.map(i => (
                            <Box key={i.id}>
                                <Divider sx={{ background: '#404656', m: '5px 0' }} />
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} >
                                        <img width='100%' src={i.logo ? i.logo : default_company_image} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{ fontSize: '14px', color: '#b4b7bd', padding: '0.72rem 2rem', textAlign: 'left', cursor: 'pointer' }}
                                            onClick={() => detailCompany(i.slug)}>{i.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>)) :
                        portfolio && portfolio.map(i => (
                            <Box key={i.id}>
                                <Divider sx={{ background: '#404656', m: '5px 0' }} />
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} >
                                        <img width='100%' src={i.logo ? i.logo : default_company_image} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{ fontSize: '14px', color: '#b4b7bd', padding: '0.72rem 2rem', textAlign: 'left', cursor: 'pointer' }}
                                            onClick={() => detailCompany(i.slug)}>{i.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>))
                    }
                </MainDarkBox>
            </Grid>
            <Grid item xs={12} md={4}>
                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                    <Typography sx={{ fontSize: '1.286rem', color: '#d0d2d6', fontWeight: 500 }}>Competitors</Typography>
                    {dropDownFilters === 'companies' ?
                        competitors && competitors.map(i => (
                            <Box key={i.id}>
                                <Divider sx={{ background: '#404656', m: '5px 0' }} />
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} >
                                        <img width='100%' src={i.logo ? i.logo : default_company_image} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{ fontSize: '14px', color: '#b4b7bd', padding: '0.72rem 2rem', textAlign: 'left', cursor: 'pointer' }}
                                            onClick={() => detailCompany(i.slug)}>{i.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>)) :
                        portfolioOfCompetitors && portfolioOfCompetitors.map(i => (
                            <Box key={i.id}>
                                <Divider sx={{ background: '#404656', m: '5px 0' }} />
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} >
                                        <img width='100%' src={i.logo ? i.logo : default_company_image} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{ fontSize: '14px', color: '#b4b7bd', padding: '0.72rem 2rem', textAlign: 'left', cursor: 'pointer' }}
                                            onClick={() => detailCompany(i.slug)}>{i.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>))
                    }
                </MainDarkBox>
            </Grid>
            <Grid item xs={12} md={4}>
                <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                    <Typography sx={{ fontSize: '1.286rem', color: '#d0d2d6', fontWeight: 500 }}>{dropDownFilters === 'companies' ? 'Investors of Competitors' : 'Portfolio of Competitors'}</Typography>
                    {dropDownFilters === 'companies' ?
                        investorsOfCompetitors && investorsOfCompetitors.map(i => (
                            <Box key={i.id}>
                                <Divider sx={{ background: '#404656', m: '5px 0' }} />
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} >
                                        <img width='100%' src={i.logo ? i.logo : default_company_image} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{ fontSize: '14px', color: '#b4b7bd', padding: '0.72rem 2rem', textAlign: 'left', cursor: 'pointer' }}
                                            onClick={() => detailCompany(i.slug)}>{i.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>)) :
                        portfolioCompetitors && portfolioCompetitors.map(i => (
                            <Box key={i.id}>
                                <Divider sx={{ background: '#404656', m: '5px 0' }} />
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} >
                                        <img width='100%' src={i.logo ? i.logo : default_company_image} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{ fontSize: '14px', color: '#b4b7bd', padding: '0.72rem 2rem', textAlign: 'left', cursor: 'pointer' }}
                                            onClick={() => detailCompany(i.slug)}>{i.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>))
                    }
                </MainDarkBox>
            </Grid>
        </Grid>
    )
}


export default ListItems;