import { Grid } from "@mui/material";
import RadialBarBlock from './radialBarBlock';
import dataJson from "../../../../../assets/json/economyRegionalSafetyRanking.json"
import React from "react";

const RadialBarsBlock = ({ countrySelect }) => {
    
  const [radialBarBlockData, setRadialBarBlockData] = React.useState([
    {
      name: "Government Efficiency",
      series: [0],
      data: [
        {
          name: "Coronavirus Domestic Economic Impact",
          series: [0],
        },
        {
          name: "Economic Impact Of Tourism Decrease",
          series: [0],
        },
        {
          name: "Economy Rescue Package",
          series: [0],
        },
        {
          name: "Economy Vitality",
          series: [0],
        },
        {
          name: "Market Attractiveness",
          series: [0],
        },
      ],
    },
    {
      name: "Economy Resilience",
      series: [0],
      data: [
        {
          name: "Wealth Indicator",
          series: [0],
        },
        {
          name: "Emergency Response Mechanism",
          series: [0],
        },
        {
          name: "E-Readiness",
          series: [0],
        },
        {
          name: "Public Involvement",
          series: [0],
        },
        {
          name: "Satisfaction With Government Performance",
          series: [0],
        },
      ],
    },
    {
      name: "Healthcare Management",
      series: [0],
      data: [
        {
          name: "Surveillance Practices",
          series: [0],
        },
        {
          name: "Health System Resources",
          series: [0],
        },
        {
          name: "Population Vulnerability",
          series: [0],
        },
        {
          name: "Public Health",
          series: [0],
        },
        {
          name: "E-Health Score",
          series: [0],
        },
      ],
    },
    {
      name: "Quarantine Efficiency",
      series: [0],
      data: [
        {
          name: "Healthcare Covid Response",
          series: [0],
        },
        {
          name: "Prevention Of Covid-19 Spread",
          series: [0],
        },
        {
          name: "Covid-19 Detection",
          series: [0],
        },
        {
          name: "City Preparedness",
          series: [0],
        },
        {
          name: "Post-Covid Strategy",
          series: [0],
        },
      ],
    },
    {
      name: "Vaccination Rate",
      series: [0],
      data: [
        {
          name: "Containment Policy",
          series: [0],
        },
        {
          name: "Affordability Of Covid-19 Vaccines",
          series: [0],
        },
        {
          name: "Deployment Of Covid-19 Vaccines",
          series: [0],
        },
        {
          name: "Vaccine Allocation",
          series: [0],
        },
        {
          name: "Vaccine Development And Production",
          series: [0],
        },
      ],
    },
  ])

  React.useEffect(() => {
    let vaccinationRateData = [];
    let economyResilienceData = [];
    let governmentEfficiencyData = [];
    let quarantineEfficiencyData = [];
    let healthcareManagementData = [];

    let coronavirusDomesticEconomicImpactData = [];
    let economyVitalityData = [];
    let economyRescuePackageData = [];
    let economicImpactOfTourismDecreaseData = [];
    let marketAttractivenessData = [];

    let wealthIndicatorData = [];
    let emergencyResponseMechanismData = [];
    let eReadinessData = [];
    let publicInvolvementData = [];
    let satisfactionWithGovernmentPerformanceData = [];

    let surveillancePracticesData = [];
    let healthSystemResourcesData = [];
    let populationVulnerabilityData = [];
    let publicHealthData = [];
    let eHealthScoreData = [];

    let healthcareCovidResponseData = [];
    let preventionOfCovid19SpreadData = [];
    let covid19DetectionData = [];
    let cityPreparednessData = [];
    let postCovidStrategyData = [];

    let containmentPolicyData = [];
    let affordabilityOfCovid19VaccinesData = [];
    let deploymentOfCovid19VaccinesData = [];
    let vaccineAllocationData = [];
    let vaccineDevelopmentAndProductionData = [];

    dataJson.forEach(i => {
      if (i.Country === countrySelect){
        vaccinationRateData.push(parseFloat(i["Vaccination Rate"]));
        economyResilienceData.push(parseFloat(i["Economy Resilience"]));
        governmentEfficiencyData.push(parseFloat(i["Government Efficiency"]));
        quarantineEfficiencyData.push(parseFloat(i["Quarantine Efficiency"]));
        healthcareManagementData.push(parseFloat(i["Healthcare Management"]));
        
        coronavirusDomesticEconomicImpactData.push(parseFloat(i["Coronavirus Domestic Economic Impact"]));
        economyVitalityData.push(parseFloat(i["Economy Vitality"]));
        economyRescuePackageData.push(parseFloat(i["Economy Rescue Package"]));
        economicImpactOfTourismDecreaseData.push(parseFloat(i["Economic Impact Of Tourism Decrease"]));
        marketAttractivenessData.push(parseFloat(i["Market Attractiveness"]));

        wealthIndicatorData.push(parseFloat(i["Wealth Indicator"]));
        emergencyResponseMechanismData.push(parseFloat(i["Emergency Response Mechanism"]));
        eReadinessData.push(parseFloat(i["E-Readiness"]));
        publicInvolvementData.push(parseFloat(i["Public Involvement"]));
        satisfactionWithGovernmentPerformanceData.push(parseFloat(i["Satisfaction With Government Performance"]));

        surveillancePracticesData.push(parseFloat(i["Surveillance Practices"]));
        healthSystemResourcesData.push(parseFloat(i["Health System Resources"]));
        populationVulnerabilityData.push(parseFloat(i["Population Vulnerability"]));
        publicHealthData.push(parseFloat(i["Public Health"]));
        eHealthScoreData.push(parseFloat(i["E-Health Score"]));

        healthcareCovidResponseData.push(parseFloat(i["Healthcare Covid Responce"]));
        preventionOfCovid19SpreadData.push(parseFloat(i["Prevention Of Covid-19 Spread"]));
        covid19DetectionData.push(parseFloat(i["Covid-19 Detection"]));
        cityPreparednessData.push(parseFloat(i["City Preparedness"]));
        postCovidStrategyData.push(parseFloat(i["Post-Covid Strategy"]));

        containmentPolicyData.push(parseFloat(i["Containment Policy"]));
        affordabilityOfCovid19VaccinesData.push(parseFloat(i["Affordability Of Covid-19 Vaccines"]));
        deploymentOfCovid19VaccinesData.push(parseFloat(i["Deployment Of Covid-19 Vaccines"]));
        vaccineAllocationData.push(parseFloat(i["Vaccine Allocation"]));
        vaccineDevelopmentAndProductionData.push(parseFloat(i["Vaccine Development And Production"]));
      }
    })
    let vaccinationRate = vaccinationRateData.reduce((a, b) => (a + b)) / vaccinationRateData.length;
    let economyResilience = economyResilienceData.reduce((a, b) => (a + b)) / economyResilienceData.length;
    let governmentEfficiency = governmentEfficiencyData.reduce((a, b) => (a + b)) / governmentEfficiencyData.length;
    let quarantineEfficiency = quarantineEfficiencyData.reduce((a, b) => (a + b)) / quarantineEfficiencyData.length;
    let healthcareManagement = healthcareManagementData.reduce((a, b) => (a + b)) / healthcareManagementData.length;

    let coronavirusDomesticEconomicImpact = coronavirusDomesticEconomicImpactData.reduce((a, b) => (a + b)) / coronavirusDomesticEconomicImpactData.length;
    let economyVitality = economyVitalityData.reduce((a, b) => (a + b)) / economyVitalityData.length;
    let economyRescuePackage = economyRescuePackageData.reduce((a, b) => (a + b)) / economyRescuePackageData.length;
    let economicImpactOfTourismDecrease = economicImpactOfTourismDecreaseData.reduce((a, b) => (a + b)) / economicImpactOfTourismDecreaseData.length;
    let marketAttractiveness = marketAttractivenessData.reduce((a, b) => (a + b)) / marketAttractivenessData.length;

    let wealthIndicator = wealthIndicatorData.reduce((a, b) => (a + b)) / wealthIndicatorData.length;
    let emergencyResponseMechanism = emergencyResponseMechanismData.reduce((a, b) => (a + b)) / emergencyResponseMechanismData.length;
    let eReadiness = eReadinessData.reduce((a, b) => (a + b)) / eReadinessData.length;
    let publicInvolvement = publicInvolvementData.reduce((a, b) => (a + b)) / publicInvolvementData.length;
    let satisfactionWithGovernmentPerformance = satisfactionWithGovernmentPerformanceData.reduce((a, b) => (a + b)) / satisfactionWithGovernmentPerformanceData.length;

    let surveillancePractices = surveillancePracticesData.reduce((a, b) => (a + b)) / surveillancePracticesData.length;
    let healthSystemResources = healthSystemResourcesData.reduce((a, b) => (a + b)) / healthSystemResourcesData.length;
    let populationVulnerability = populationVulnerabilityData.reduce((a, b) => (a + b)) / populationVulnerabilityData.length;
    let publicHealth = publicHealthData.reduce((a, b) => (a + b)) / publicHealthData.length;
    let eHealthScore = eHealthScoreData.reduce((a, b) => (a + b)) / eHealthScoreData.length;

    let healthcareCovidResponse = healthcareCovidResponseData.reduce((a, b) => (a + b)) / healthcareCovidResponseData.length;
    let preventionOfCovid19Spread = preventionOfCovid19SpreadData.reduce((a, b) => (a + b)) / preventionOfCovid19SpreadData.length;
    let covid19Detection = covid19DetectionData.reduce((a, b) => (a + b)) / covid19DetectionData.length;
    let cityPreparedness = cityPreparednessData.reduce((a, b) => (a + b)) / cityPreparednessData.length;
    let postCovidStrategy = postCovidStrategyData.reduce((a, b) => (a + b)) / postCovidStrategyData.length;

    let containmentPolicy = containmentPolicyData.reduce((a, b) => (a + b)) / containmentPolicyData.length;
    let affordabilityOfCovid19Vaccines = affordabilityOfCovid19VaccinesData.reduce((a, b) => (a + b)) / affordabilityOfCovid19VaccinesData.length;
    let deploymentOfCovid19Vaccines = deploymentOfCovid19VaccinesData.reduce((a, b) => (a + b)) / deploymentOfCovid19VaccinesData.length;
    let vaccineAllocation = vaccineAllocationData.reduce((a, b) => (a + b)) / vaccineAllocationData.length;
    let vaccineDevelopmentAndProduction = vaccineDevelopmentAndProductionData.reduce((a, b) => (a + b)) / vaccineDevelopmentAndProductionData.length;
    
    setRadialBarBlockData([
      {
        name: "Government Efficiency",
        series: [governmentEfficiency],
        data: [
          {
            name: "Coronavirus Domestic Economic Impact",
            series: [coronavirusDomesticEconomicImpact],
          },
          {
            name: "Economic Impact Of Tourism Decrease",
            series: [economicImpactOfTourismDecrease],
          },
          {
            name: "Economy Rescue Package",
            series: [economyRescuePackage],
          },
          {
            name: "Economy Vitality",
            series: [economyVitality],
          },
          {
            name: "Market Attractiveness",
            series: [marketAttractiveness],
          },
        ],
      },
      {
        name: "Economy Resilience",
        series: [economyResilience],
        data: [
          {
            name: "Wealth Indicator",
            series: [wealthIndicator],
          },
          {
            name: "Emergency Response Mechanism",
            series: [emergencyResponseMechanism],
          },
          {
            name: "E-Readiness",
            series: [eReadiness],
          },
          {
            name: "Public Involvement",
            series: [publicInvolvement],
          },
          {
            name: "Satisfaction With Government Performance",
            series: [satisfactionWithGovernmentPerformance],
          },
        ],
      },
      {
        name: "Healthcare Management",
        series: [healthcareManagement],
        data: [
          {
            name: "Surveillance Practices",
            series: [surveillancePractices],
          },
          {
            name: "Health System Resources",
            series: [healthSystemResources],
          },
          {
            name: "Population Vulnerability",
            series: [populationVulnerability],
          },
          {
            name: "Public Health",
            series: [publicHealth],
          },
          {
            name: "E-Health Score",
            series: [eHealthScore],
          },
        ],
      },
      {
        name: "Quarantine Efficiency",
        series: [quarantineEfficiency],
        data: [
          {
            name: "Healthcare Covid Response",
            series: [healthcareCovidResponse],
          },
          {
            name: "Prevention Of Covid-19 Spread",
            series: [preventionOfCovid19Spread],
          },
          {
            name: "Covid-19 Detection",
            series: [covid19Detection],
          },
          {
            name: "City Preparedness",
            series: [cityPreparedness],
          },
          {
            name: "Post-Covid Strategy",
            series: [postCovidStrategy],
          },
        ],
      },
      {
        name: "Vaccination Rate",
        series: [vaccinationRate],
        data: [
          {
            name: "Containment Policy",
            series: [containmentPolicy],
          },
          {
            name: "Affordability Of Covid-19 Vaccines",
            series: [affordabilityOfCovid19Vaccines],
          },
          {
            name: "Deployment Of Covid-19 Vaccines",
            series: [deploymentOfCovid19Vaccines],
          },
          {
            name: "Vaccine Allocation",
            series: [vaccineAllocation],
          },
          {
            name: "Vaccine Development And Production",
            series: [vaccineDevelopmentAndProduction],
          },
        ],
      },
    ]);
  },[countrySelect])

  // console.log(radialBarBlockData[0]);

  return (
    <Grid container sx={{ p: "0 1rem" }}>
      <Grid
        item
        xs={12}
        md={2.4}
        sx={{ p: { md: "0 1rem", xs: "0 1rem 1rem 1rem" } }}
      >
        <RadialBarBlock radialBarBlockData={radialBarBlockData[0]} />
      </Grid>
      <Grid
        item
        xs={12}
        md={2.4}
        sx={{ p: { md: "0 1rem", xs: "0 1rem 1rem 1rem" } }}
      >
        <RadialBarBlock radialBarBlockData={radialBarBlockData[1]} />
      </Grid>
      <Grid
        item
        xs={12}
        md={2.4}
        sx={{ p: { md: "0 1rem", xs: "0 1rem 1rem 1rem" } }}
      >
        <RadialBarBlock radialBarBlockData={radialBarBlockData[2]} />
      </Grid>
      <Grid
        item
        xs={12}
        md={2.4}
        sx={{ p: { md: "0 1rem", xs: "0 1rem 1rem 1rem" } }}
      >
        <RadialBarBlock radialBarBlockData={radialBarBlockData[3]} />
      </Grid>
      <Grid
        item
        xs={12}
        md={2.4}
        sx={{ p: { md: "0 1rem", xs: "0 1rem 1rem 1rem" } }}
      >
        <RadialBarBlock radialBarBlockData={radialBarBlockData[4]} />
      </Grid>
    </Grid>
  );
};

export default RadialBarsBlock;