import { Box, Grid, Typography, Select, MenuItem, TextField, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, InputBase } from "@mui/material"
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MainDarkBox from "../../../../mainDarkBox";
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CubeSVG from './cubeSVG';
import InfoIcon from '@mui/icons-material/Info';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PortfolioConstructor = () => {

  const [value, setValue] = React.useState(new Date());
  const [value2, setValue2] = React.useState(new Date());

  const ColorButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: "14px",
    padding: '11px 21px',
    height: '38px',
    fontFamily: "Montserrat, Helvetica, Arial, serif",
    color: theme.palette.getContrastText("#7367f0"),
    backgroundImage: 'linear-gradient(47deg, #314054, #213f66)',
    '&:hover': {
      borderColor: 'rgb(64, 122, 214)',
    },
    
  }));

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  console.log(personName)


  const TextInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      color: '#b4b7bd',
      position: 'relative',
      backgroundColor: '#213f66',
      border: '1px solid #404656',
      height: "16px",
      fontSize: 16,
      padding: '10px 12px',
      ':focus': {
        borderColor: 'rgb(64, 122, 214)',
      },
    },
  }));


  return (
    <Box sx={{
      '.MuiInputBase-input:focus': {
        borderColor: 'rgb(64, 122, 214) !important'
      }
    }}>
      <Box>
        <Grid container sx={{ p: "1.5rem" }}>
          <Grid item container xs={12} md={12}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ pr: { md: "0.75rem" }, height: { md: "714px" } }}
            >
              <Box
                sx={{
                  height: "inherit",
                  width: "inherit",
                  background: "rgb(35, 48, 68)",
                  borderRadius: "7px",
                  p: "1.5rem",
                  boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Select Companies
                    </Typography>
                  </Box>
                  {/* <Select
                    defaultValue=""
                    color="secondary"
                    sx={{
                      "& .MuiMenuItem-root": { background: "#283046" },
                      width: "100%",
                      color: "#b4b7bd",
                      background: "#283046",
                    }}
                    value={countrySelect}
                    onChange={handleCountryChange}
                    name="country"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {country &&
                      country.map((i) => (
                        <MenuItem
                          key={i}
                          sx={{
                            width: "100%",
                            color: "#b4b7bd",
                            background: "#283046",
                            ".MuiList-root": { backgroundColor: "#1a2646" },
                            "&:hover": { backgroundColor: "#1a2646" },
                            "& .Mui-selected": {
                              backgroundColor: "rgb(0, 160, 250)",
                            },
                          }}
                          value={i}
                        >
                          {i}
                        </MenuItem>
                      ))}
                  </Select> */}
                  <Box>
                    <FormControl sx={{
                      m: 1, width: 300,
                      '.MuiSelect-select': {
                        borderColor: "rgb(64, 122, 214)"
                      }
                    }}>
                      <Select
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": { borderColor: "rgb(64, 122, 214)" },
                          "& .MuiMenuItem-root": { background: "#283046" },
                          width: "100%",
                          color: "#b4b7bd",
                          background: "#213f66",
                        }}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        // input={
                        //   <OutlinedInput
                        //     sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: "#283046" } }}
                        //   />
                        // }
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem
                            sx={{
                              width: "100%",
                              color: "#b4b7bd",
                              background: "#1d3049",
                              ".MuiList-root": { backgroundColor: "#1a2646" },
                              "&:hover": { backgroundColor: "#1a2646" },
                              "& .Mui-selected": {
                                backgroundColor: "rgb(0, 160, 250)",
                              },
                            }}
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <ColorButton variant="contained">Reset</ColorButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                pl: { md: "0.75rem" },
                pr: { md: "0.75rem" },
                pt: { xs: "1.5rem", md: "0rem" }
              }}
            >
              <Box
                sx={{
                  height: "inherit",
                  width: "inherit",
                  background: "rgb(35, 48, 68)",
                  borderRadius: "7px",
                  p: "1.5rem",
                  boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Purchase Date
                    </Typography>
                  </Box>
                  <Box sx={{ p: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        openTo="year"
                        views={["year", "month", "day"]}
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            sx={{
                              svg: { color: "#D0D2D6" },
                              input: { color: "#D0D2D6", height: "5px", backgroundColor: "#213f66" },
                              width: "100%",
                              ".MuiOutlinedInput-notchedOutline": { borderColor: "#404656" },
                              backgroundColor: "#213f66"
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Sell Date
                    </Typography>
                  </Box>
                  <Box sx={{ p: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        openTo="year"
                        views={["year", "month", "day"]}
                        value={value2}
                        onChange={(newValue) => {
                          setValue2(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            sx={{
                              svg: { color: "#D0D2D6" },
                              input: { color: "#D0D2D6", height: "5px" },
                              width: "100%",
                              ".MuiOutlinedInput-notchedOutline": { borderColor: "#404656" },
                              backgroundColor: "#213f66",
                              '&:hover': {
                                borderColor: "#D0D2D6"
                              },
                              '&.MuiInputBase-root-MuiOutlinedInput-roo:hover': {
                                borderColor: "#D0D2D6"
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Amount
                    </Typography>
                  </Box>
                  <Box sx={{
                    p: "1rem",
                    '.MuiInputBase-input:focus': {
                      borderColor: 'rgb(64, 122, 214) !important'
                    }
                  }}>
                    <TextInput
                      defaultValue="10000"
                      color='primary'
                      sx={{
                        width: "100%",
                        '.MuiInputBase-input:focus': {
                          borderColor: 'rgb(64, 122, 214) !important'
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      R-Value
                    </Typography>
                  </Box>
                  <Box sx={{ p: "1rem" }}>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: "50%",
                        alignItems: "center",
                        alignContent: "center",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                        }}
                      >
                        Risk %
                      </Typography>
                      <TextInput
                        sx={{
                          p: "1rem",
                          pb: "0rem",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: "50%",
                        alignItems: "center",
                        alignContent: "center",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                        }}
                      >
                        Profit %
                      </Typography>
                      <TextInput
                        sx={{
                          p: "1rem",
                          pb: "0rem",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Portfolio Type
                    </Typography>
                  </Box>
                  <Box sx={{ p: "1rem" }}>
                    <FormControl sx={{
                      '.Mui-checked': {
                        color: 'rgb(64, 122, 214)'
                      }
                    }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="equally weighted"
                        name="radio-buttons-group"
                        sx={{
                          display: "inline-block",
                        }}
                      // value={value}
                      // onChange={handleChange}
                      >
                        <Box
                          sx={{
                            display: "inline-block",
                            width: "100%",
                            alignItems: "left",
                            alignContent: "left",
                            textAlign: "left",
                            justifyContent: "left",
                          }}
                        >
                          <FormControlLabel
                            value="equally weighted"
                            control={<Radio />}
                            label="Equally weighted"
                          />
                          <IconButton
                            title="Equal distribution of investments across all assets."
                          >
                            <InfoIcon sx={{ color: "#D0D2D6" }} />
                          </IconButton>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              width: "80%",
                            }}
                          >
                            <FormControlLabel
                              value="growth optimal portfolio(GMV)"
                              control={<Radio />}
                              label="Growth optimal portfolio(GMV)"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              width: "20%",
                            }}
                          >
                            <IconButton
                              title="Optimal growth GMV"
                            >
                              <InfoIcon sx={{ color: "#D0D2D6" }} />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              width: "80%",
                            }}
                          >
                            <FormControlLabel
                              value="growth optimal portfolio(EF)"
                              control={<Radio />}
                              label="Growth optimal portfolio(EF)"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              width: "20%",
                            }}
                          >
                            <IconButton
                              title="Optimal growth EF"
                            >
                              <InfoIcon sx={{ color: "#D0D2D6" }} />
                            </IconButton>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              alignContent: "center",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextInput
                              defaultValue="5"
                              sx={{
                                width: "50%",
                              }}
                            />
                            <IconButton
                              title="The risk-free rate"
                            >
                              <InfoIcon sx={{ color: "#D0D2D6" }} />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              width: "80%",
                            }}
                          >
                            <FormControlLabel
                              value="minimum variance"
                              control={<Radio />}
                              label="Minimum variance"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              width: "20%",
                            }}
                          >
                            <IconButton
                              title="Minimization of the standard deviation of the portfolio, which represents a risk."
                            >
                              <InfoIcon sx={{ color: "#D0D2D6" }} />
                            </IconButton>
                          </Box>
                        </Box>
                        <FormControlLabel
                          value="custom"
                          control={<Radio />}
                          label="Custom"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ pl: { md: "0.75rem" }, height: { md: "714px" }, pt: { xs: "1.5rem", md: "0rem" } }}
            >
              <Box
                sx={{
                  height: "inherit",
                  width: "inherit",
                  background: "rgb(35, 48, 68)",
                  borderRadius: "7px",
                  p: "1.5rem",
                  boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "50%",
                      alignItems: "center",
                      alignContent: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Net Income
                    </Typography>
                    <TextInput
                      sx={{
                        p: "1rem",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "50%",
                      alignItems: "center",
                      alignContent: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                      }}
                    >
                      Net Income %
                    </Typography>
                    <TextInput
                      sx={{
                        p: "1rem",
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                  }}
                >
                  Portfolio Weights Distribution
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            sx={{ mt: "1.5rem", height: "417px" }}
          >
            <Box
              sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
              }}
            >
              <Box>
                <Typography>Portfolio Chart</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item container xs={12} md={12} sx={{ mt: "1.5rem" }}>
            <Box
              sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
              }}
            >
              <Box>
                <Typography>Portfolio Summary</Typography>
              </Box>
              <Box>
                <Grid container sx={{ p: "1.5rem" }}>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#6B68DE"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Performance
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#6B68DE"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Treynor Measure
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#15DDFF"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Maximal return
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#15DDFF"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Value at Risk
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#7EFA97"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Downside Deviation
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#7EFA97"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Sterling Ratio
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#C74E7C"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Sharpe Ratio
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#C74E7C"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Sortino Ratio
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#FF8AFF"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Ulcer Performance Index
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#FF8AFF"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Expected shortfall
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#FF7FB9"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Portfolio Standard Deviation
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#FF7FB9"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Mean Absolute Deviation
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={1.7}>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#FFC572"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Skewness
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12}>
                      <Grid item container xs={3} md={3}>
                        <CubeSVG color={"#FFC572"} />
                      </Grid>
                      <Grid item container xs={9} md={9}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                            }}
                          >
                            Kurtosis
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            sx={{ mt: "1.5rem", height: "417px" }}
          >
            <Box
              sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
              }}
            >
              <Box>
                <Typography>Daily Income Percentage Chart</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            sx={{ mt: "1.5rem", height: "417px" }}
          >
            <Box
              sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
              }}
            >
              <Box>
                <Typography>Selected Companies Stock Data</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}


export default PortfolioConstructor;