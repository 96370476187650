import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InfoIcon from '@mui/icons-material/Info';
import { Box, FormControl, FormControlLabel, IconButton, InputBase, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';




const FormBlock = ({ value, setValue, value2, setValue2, form, handleFormChange }) => {

    return (
        <Box component="form"
            sx={{
                height: "inherit",
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
            }}>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                        }}>
                        Purchase Date
                    </Typography>
                </Box>
                <Box sx={{ p: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            openTo="year"
                            views={["year", "month", "day"]}
                            name='yearStart'
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    helperText={null}
                                    sx={{
                                        svg: { color: "#D0D2D6" },
                                        input: { color: "#D0D2D6", height: "5px", backgroundColor: "#213f66" },
                                        width: "100%",
                                        ".MuiOutlinedInput-notchedOutline": { borderColor: "#404656" },
                                        backgroundColor: "#213f66"
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                        }}>
                        Sell Date
                    </Typography>
                </Box>
                <Box sx={{ p: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            openTo="year"
                            views={["year", "month", "day"]}
                            name='yearEnd'
                            value={value2}
                            onChange={(newValue) => {
                                setValue2(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    helperText={null}
                                    sx={{
                                        svg: { color: "#D0D2D6" },
                                        input: { color: "#D0D2D6", height: "5px" },
                                        width: "100%",
                                        ".MuiOutlinedInput-notchedOutline": { borderColor: "#404656" },
                                        backgroundColor: "#213f66",
                                        '&:hover': {
                                            borderColor: "#D0D2D6"
                                        },
                                        '&.MuiInputBase-root-MuiOutlinedInput-roo:hover': {
                                            borderColor: "#D0D2D6"
                                        },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                        }}>
                        Amount
                    </Typography>
                </Box>
                <Box sx={{ p: "1rem" }}>
                    <InputBase
                        name='amount'
                        onChange={handleFormChange}
                        value={form.amount}
                        sx={{
                            width: "100%",
                            '& .MuiInputBase-input': {
                                borderRadius: 1,
                                color: '#b4b7bd',
                                position: 'relative',
                                backgroundColor: '#213f66',
                                border: '1px solid #404656',
                                height: "16px",
                                fontSize: 16,
                                padding: '10px 12px',
                                '&:focus': {
                                    borderColor: '#7367f0',
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                        }}
                    >
                        R-Value
                    </Typography>
                </Box>
                <Box sx={{ p: "1rem" }}>
                    <Box
                        sx={{
                            display: "inline-block",
                            width: "50%",
                            alignItems: "center",
                            alignContent: "center",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                            }}
                        >
                            Risk %
                        </Typography>

                        <InputBase
                            value={form.risk_percentage}
                            name='risk_percentage'
                            onChange={handleFormChange}
                            sx={{
                                p: "1rem",
                                pb: "0rem",
                                width: "100%",
                                '& .MuiInputBase-input': {
                                    borderRadius: 1,
                                    color: '#b4b7bd',
                                    position: 'relative',
                                    backgroundColor: '#213f66',
                                    border: '1px solid #404656',
                                    height: "16px",
                                    fontSize: 16,
                                    padding: '10px 12px',
                                    '&:focus': {
                                        borderColor: '#7367f0',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "inline-block",
                            width: "50%",
                            alignItems: "center",
                            alignContent: "center",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                            }}
                        >
                            Profit %
                        </Typography>
                        <InputBase
                            value={form.profit_percentage}
                            name='profit_percentage'
                            onChange={handleFormChange}
                            sx={{
                                p: "1rem",
                                pb: "0rem",
                                width: "100%",
                                '& .MuiInputBase-input': {
                                    borderRadius: 1,
                                    color: '#b4b7bd',
                                    position: 'relative',
                                    backgroundColor: '#213f66',
                                    border: '1px solid #404656',
                                    height: "16px",
                                    fontSize: 16,
                                    padding: '10px 12px',
                                    '&:focus': {
                                        borderColor: '#7367f0',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                        }}
                    >
                        Portfolio Type
                    </Typography>
                </Box>
                <Box sx={{ p: "1rem" }}>
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup
                            onChange={handleFormChange}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="equal_weights"
                            name="portfolio_type"
                            sx={{
                                display: "inline-block",
                            }}>
                            <Box
                                sx={{
                                    display: "inline-block",
                                    width: "100%",
                                    alignItems: "left",
                                    alignContent: "left",
                                    textAlign: "left",
                                    justifyContent: "left",
                                }}>
                                <FormControlLabel value="equal_weights" control={<Radio />} label="Equally weighted" />
                                <IconButton title="Equal distribution of investments across all assets.">
                                    <InfoIcon sx={{ color: "#D0D2D6" }} />
                                </IconButton>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: "inline-block",
                                        width: "80%",
                                    }}>
                                    <FormControlLabel value="growth_optimal" control={<Radio />} label="Growth optimal portfolio(GMV)" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "inline-block",
                                        width: "20%",
                                    }}>
                                    <IconButton title="Optimal growth GMV">
                                        <InfoIcon sx={{ color: "#D0D2D6" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: "inline-block",
                                        width: "80%",
                                    }}>
                                    <FormControlLabel value="growth_optimal_ef" control={<Radio />} label="Growth optimal portfolio(EF)" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "inline-block",
                                        width: "20%",
                                    }}>
                                    <IconButton title="Optimal growth EF" >
                                        <InfoIcon sx={{ color: "#D0D2D6" }} />
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        width: "50%",
                                        alignItems: "center",
                                        alignContent: "center",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        display: 'flex'
                                    }}>
                                    <InputBase
                                        defaultValue="5"
                                        value={form.ef}
                                        onChange={handleFormChange}
                                        name='ef'
                                        inputProps={{ "aria-label": "Without label" }}
                                        sx={{
                                            pb: "0rem",
                                            width: "100%",
                                            '& .MuiInputBase-input': {
                                                borderRadius: 1,
                                                color: '#b4b7bd',
                                                position: 'relative',
                                                backgroundColor: '#213f66',
                                                border: '1px solid #404656',
                                                height: "16px",
                                                fontSize: 16,
                                                padding: '10px 12px',
                                                '&:focus': {
                                                    borderColor: '#7367f0',
                                                },
                                            },
                                        }}
                                    />
                                    <IconButton title="The risk-free rate">
                                        <InfoIcon sx={{ color: "#D0D2D6" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: "inline-block",
                                        width: "80%",
                                    }}>
                                    <FormControlLabel value="min_variance" control={<Radio />} label="Minimum variance" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "inline-block",
                                        width: "20%",
                                    }}>
                                    <IconButton title="Minimization of the standard deviation of the portfolio, which represents a risk.">
                                        <InfoIcon sx={{ color: "#D0D2D6" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}

export default FormBlock;