

import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom';
import Auth from './auth'

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

  const onRedirectCallback = () => {
    navigate('')
  }

  console.log(window.location)

  return (
    <div>
    {window.location.pathname !==  '/block-params' ?
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}`}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth />
      {children}
    </Auth0Provider>
        : ''}
    </div>
  );
};

export default AuthGuard;
