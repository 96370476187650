import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Box, Typography, Button } from '@mui/material';


import UserMenu from '../userMenu';

import logo from '../../assets/image/deepTechPlatform.png'
import logo1 from '../../assets/image/dpi_logo_trimmed.png'

const Header = ({ title, createdDash, handleClickArrowBack, continent, industry, globaldash, allDashes, spacetech }) => {

    return (
        <Box sx={{
            position: createdDash ? 'fixed' : 'relative',
            width: createdDash ? { xs: 'calc(100% - 20px)', md: '83.6%', xl: '85.6%' } : "auto",
            zIndex: '1',
            display: 'flex',
            justifyContent: 'space-between',
            p: globaldash ? '0.5rem 1rem' : '10px',
            m: globaldash ? '0' : createdDash ? '' : '1rem',
            borderRadius: createdDash ? '' : '4px',
            backgroundColor: globaldash ? '#161d31' : createdDash ? 'rgb(20 33 50)' : '#283046',
            alignItems: 'center'
        }} >
            {globaldash ?
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& img': { width: { xs: '150px', md: '250px' } }
                }}>
                    <img width='250px' src={logo} alt='Logo' />
                    <Typography sx={{
                        color: '#FFFFFF',
                        fontSize: { xs: '16px', md: '24px' },
                        fontWeight: '400'
                    }}>DeepTech Big Data Analytics Platform</Typography>
                </Box>
                :
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: spacetech ? '60%' : '40%'
                }}>
                    {createdDash && !spacetech ? <ArrowBackIcon
                        onClick={handleClickArrowBack}
                        sx={{
                            width: '3rem',
                            fill: '#ffffff',
                            cursor: 'pointer'
                        }} /> : ''}
                    {allDashes ?
                        <Box sx={{ mr: '1rem' }}>
                            <img height='50px' src={logo1} />
                        </Box> : ''}
                    <Typography variant='h3' sx={{
                        fontFamily: '"Montserrat", Helvetica, Arial, serif',
                        letterSpacing: '0.01rem',
                        fontWeight: '500',
                        lineHeight: '1.2',
                        fontSize: { xs: '1rem', md: '1.5rem' },
                        color: createdDash ? '#FFFFFF' : '#d0d2d6'
                    }}>{!createdDash ? title
                        : !industry && !continent ? title : industry ? continent ? `${industry} industry, ${continent[0].toUpperCase() + continent.slice(1)}`
                            : `${industry} industry`
                            : continent[0].toUpperCase() + continent.slice(1)}</Typography>
                </Box>}
            {globaldash || allDashes ? '' : !createdDash ? <Button sx={{
                marginLeft: '20px',
                border: '1px solid #6B68DE',
                color: '#d0d2d6',
                width: '20%',
                textTransform: 'capitalize'
            }}>Analytics Reports</Button> :
                ''}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
            }}>
                {createdDash ?
                    <Button sx={{
                        mr: '1rem',
                        borderRadius: '50%',
                        overflow: 'visible',
                        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        color: 'inherit',
                        padding: '10px',
                        fontSize: '1.625rem',
                        minWidth: '40px',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08)'
                        }
                    }}>
                        <ShareOutlinedIcon sx={{
                            fill: '#FFFFFF',
                            cursor: 'pointer',

                        }} />
                    </Button> : ''}
                <UserMenu />
            </Box>
        </Box >
    )
}

export default Header;