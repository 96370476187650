import React from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layouts';

import GridViewIcon from '@mui/icons-material/GridView';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import RenderField from './renderField';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const items = {
    menuItem: [
        {
            urlMenu: '',
            title: 'SpaceTech Big Data Analytics',
            titleLink: 'DigitalMain',
            active: true
        }
    ],
    itemsList: [
        {
            title: 'Reports',
            links: [{
                urlImg: <DescriptionIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Downloadable',
                href: '#',
                active: false
            }, {
                urlImg: <DescriptionIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Metodology',
                href: '#',
                active: false
            }]
        },
        {
            title: 'Data',
            links: [{
                urlImg: <WorkOutlineIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Companies',
                href: '#',
                active: false
            }, {
                urlImg: <AttachMoneyIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Investors',
                href: '#',
                active: false
            }, {
                urlImg: <GroupOutlinedIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Venture Partners',
                href: '#',
                active: false
            }]
        },
        {
            title: 'Tools',
            links: [{
                urlImg: <BarChartIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Chart Builder',
                href: '#',
                active: false
            }, {
                urlImg: <WorkOutlineIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Portfolio Constructor',
                href: '#',
                active: false
            }, {
                urlImg: <ViewColumnIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Competitors',
                href: '#',
                active: false
            }, {
                urlImg: <DnsOutlinedIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Comparison',
                href: '#',
                active: false
            }, {
                urlImg: <GridViewIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Mindmaps',
                href: '#',
                active: false
            },]
        },
        {
            title: 'none',
            links: [{
                urlImg: <BarChartIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Contacts',
                href: '#',
                active: false
            }]
        }]
}



const SpaceTechDashboard = () => {


    const [contactActive, setContactActive] = React.useState(false);
    const [itemsAsia, setItemsAsia] = React.useState();
    const [itemsListAsia, setItemsListAsia] = React.useState();
    const [activeLink, setActiveLink] = React.useState('DigitalMain');

    React.useEffect(() => {
        setItemsListAsia(items.menuItem);
        setItemsAsia(items.itemsList);
    }, []);


    const handleClickOnLinkMenu = (item) => {
        const arrItems = itemsListAsia;
        setContactActive(false);
        arrItems.forEach(i => {
            i.active = false;
        });

        const arrItemsAsia = itemsAsia;
        setContactActive(false);
        arrItemsAsia.forEach(i => {
            i.links.forEach(k => {
                k.active = false;
            })
        });

        arrItems.forEach(i => {
            if (i.title == item.title) {
                i.active = true;
                setActiveLink(item.titleLink)
            }
        });

        setItemsAsia([...arrItemsAsia])
        setItemsListAsia([...arrItems])
    }

    const handleClickOnLink = (item) => {
        const arrItems = itemsAsia;
        setContactActive(false);
        arrItems.forEach(i => {
            i.links.forEach(k => {
                k.active = false;
            })
        });

        arrItems.forEach(i => {
            i.links.forEach(k => {
                if (k.titleLink == item.titleLink) {
                    k.active = true;
                    setActiveLink(item.titleLink)
                }
            })
        });

        setItemsAsia([...arrItems])
    }


    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Helmet>
                <title>
                    SpaceTech Big Data Analytics Dashboard
                </title>
            </Helmet>
            <MainLayout
                contactActive={contactActive}
                setContactActive={setContactActive}
                handleClickOnLink={handleClickOnLink}
                handleClickOnLinkMenu={handleClickOnLinkMenu}
                items={itemsAsia}
                itemsListAsia={itemsListAsia}
                title={'SpaceTech Big Data Analytics Dashboard'}
                setActiveLink={setActiveLink}
                boolCovidDash={false}
                createdDash={true}
                spacetech={true}>
                <RenderField type={activeLink} />
            </MainLayout>
        </Box >
    )
}

export default SpaceTechDashboard;