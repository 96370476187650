import React from "react";
import { Box } from "@mui/material";
import MindmapsGrid from "../../../mindmapsGrid";


import dataFromSpace from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-data-from-space.png'
import SpaceTechnologies from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-technologies.png'
import spaceTechServices from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-spacetech-services.png'
import RDHubsAssociations from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-hubs-associations-government-organizations.png'
import spaceTechRobotics from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-robotics.png'
import spaceTechExpertSystems from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-expert-systems.png'
import spaceTechCyberSecurity from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-cyber-security.png'
import spaceTechAdditiveManufacturing from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-additive-manufacturing.png'
import spaceTechNanotechnologies from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-nanotechnologies.png'
import spaceTechSmartMaterials from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-smart-materials.png'
import Drones from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-drones.png'
import satelliteCommunication from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-satellite-communication.png'
import spaceManufacturing from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-manufacturing.png'
import spaceDevelopment from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-development.png'
import spaceObservation from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-observation.png'
import spaceTechPubliclyTraded from '../../../../assets/image/createdDashboard/spaceTech/mindmaps/mindmap-space-publicly-traded.png'


const mindmapData = [
    {
        id: 1,
        title: 'Data from Space',
        imageMap: dataFromSpace,
        urlMindmap: 'https://mindmaps.aginganalytics.com/data-from-space'
    },
    {
        id: 2,
        title: 'Space Technologies',
        imageMap: SpaceTechnologies,
        urlMindmap: 'https://mindmaps.dka.global/space-technologies'
    },
    {
        id: 3,
        title: 'SpaceTech Services',
        imageMap: spaceTechServices,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-services'
    },
    {
        id: 4,
        title: 'R&D Hubs, Associations and Government Organizations',
        imageMap: RDHubsAssociations,
        urlMindmap: 'https://mindmaps.dka.global/hubs-rd-centres-by-type'
    },
    {
        id: 5,
        title: 'SpaceTech Robotics',
        imageMap: spaceTechRobotics,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-robotics'
    },
    {
        id: 6,
        title: 'SpaceTech Expert Systems',
        imageMap: spaceTechExpertSystems,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-expert-systems'
    },
    {
        id: 7,
        title: 'SpaceTech Cyber Security',
        imageMap: spaceTechCyberSecurity,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-cyber-security'
    },
    {
        id: 8,
        title: 'SpaceTech Additive Manufacturing',
        imageMap: spaceTechAdditiveManufacturing,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-additive-manufacturing'
    },
    {
        id: 9,
        title: 'SpaceTech Nanotechnologies',
        imageMap: spaceTechNanotechnologies,
        urlMindmap: 'https://mindmaps.dka.global/ai-in-asia-2020'
    },
    {
        id: 10,
        title: 'SpaceTech Smart Materials',
        imageMap: spaceTechSmartMaterials,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-smart-materials'
    },
    {
        id: 11,
        title: 'Drones',
        imageMap: Drones,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-drones'
    },
    {
        id: 12,
        title: 'Satellite Communication',
        imageMap: satelliteCommunication,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-satellite-communication'
    },
    {
        id: 13,
        title: 'Space Manufacturing',
        imageMap: spaceManufacturing,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-space-manufacturing'
    },
    {
        id: 14,
        title: 'Space Development',
        imageMap: spaceDevelopment,
        urlMindmap: 'https://mindmaps.aginganalytics.com/space-development'
    },
    {
        id: 15,
        title: 'Space Observation',
        imageMap: spaceObservation,
        urlMindmap: 'https://mindmaps.aginganalytics.com/space-observation'
    },
    {
        id: 16,
        title: 'SpaceTech Publicly Traded',
        imageMap: spaceTechPubliclyTraded,
        urlMindmap: 'https://mindmaps.aginganalytics.com/spacetech-publicly-traded'
    }
]

const Mindmap = () => {

    return (
        <Box sx={{ pt: '1rem' }}>
            <MindmapsGrid mindmapData={mindmapData} createdDash={true} />
        </Box>
    )
}


export default Mindmap;