import { Box, Grid, Typography, Divider } from "@mui/material";
import ukImage from '../../../../assets/image/allDashboards/uk-map1.png'
import euImage from '../../../../assets/image/allDashboards/eu-map.png'
import asiaImage from '../../../../assets/image/allDashboards/asia-map.png'
import africaImage from '../../../../assets/image/allDashboards/africa-map.png'
import swImage from '../../../../assets/image/allDashboards/sw-map.png'
import globalImage from '../../../../assets/image/allDashboards/gl-map.png'
import ImgBlock from './imgBlock';

const DeepKnowledgeAnalytics = ({ ColorButton }) => {

    return(
        <Grid item container xs={12} sx={{ pb:"1.5rem" }}>
            <Grid item xs={12} >
                <Box>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '28px', width:"50%", textAlign:"left", display: "inline-block" }}>Deep Knowledge Analytics</Typography>
                    <Box sx={{ width:"50%", display: "inline-block", textAlign: "right" }}>
                        <ColorButton variant="contained">Visit Website</ColorButton>
                    </Box>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '14px', width:"50%", textAlign:"left", opacity: "0.4" }}>6 dashboards</Typography>
                </Box>
            <Divider sx={{borderColor: "#FFFFFF", opacity: "0.16", p: "0.5rem 0" }}/>
            </Grid>
            <Grid item container xs={12} >
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0.75rem", md:"0.75rem", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"Digital UK Ecosystem"} imageSrc={ukImage}/>
                </Grid>
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0.75rem", md:"0.75rem", xs:"0"}, pl:{xl:"0.75rem", md:"0.75rem", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"Charities in Africa"} imageSrc={africaImage}/>
                </Grid>
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0.75rem", md:"0", xs:"0"}, pl:{xl:"0.75rem", md:"0.75rem", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"Asia Digital Ecosystem"} imageSrc={asiaImage}/>
                </Grid>
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0", md:"0.75rem", xs:"0"}, pl:{xl:"0.75rem", md:"0", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"Digital Europe Ecosystem"} imageSrc={euImage}/>
                </Grid>
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0.75rem", md:"0.75rem", xs:"0"}, pl:{xl:"0", md:"0.75rem", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"Switzerland DeepTech"} imageSrc={swImage}/>
                </Grid>
                <Grid item xl={3} md={4} xs={12} sx={{pt: "1.5rem", pr:{xl:"0.75rem", md:"0", xs:"0"}, pl:{xl:"0.75rem", md:"0.75rem", xs:"0"} }}>
                    <ImgBlock ColorButton={ColorButton} mapTitle={"Global Dashboard"} imageSrc={globalImage}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DeepKnowledgeAnalytics;