import { Box, Grid } from "@mui/material";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';

const DateBlock = ({ date, date2, handleSetDate, handleSetDate2 }) => {

  return (
    <Grid item container xs={12}>
      <Grid item xs={5.5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year"]}
            value={date}
            color="secondary"
            onChange={(newValue) => {
              handleSetDate(newValue);
            }}
            sx={{
              stroke: "",
              color: "#fff !important",
              backgroundColor: "#fff !important",
            }}
            renderInput={(params) => (
              <Box
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#293145",
                }}
              >
                <TextField
                  {...params}
                  helperText={null}
                  sx={{
                    svg: { color: "#D0D2D6" },
                    input: { color: "#D0D2D6" },
                    width: '100%'
                  }}
                />
              </Box>
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            height: "3rem",
          }}
        >
          -
        </Box>
      </Grid>

      <Grid item xs={5.5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year"]}
            value={date2}
            color="secondary"
            onChange={(newValue) => {
              handleSetDate2(newValue);
            }}
            sx={{
              stroke: "",
              color: "#fff !important",
              backgroundColor: "#fff !important",
            }}
            renderInput={(params) => (
              <Box
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#293145",
                }}
              >
                <TextField
                  {...params}
                  helperText={null}
                  sx={{
                    svg: { color: "#D0D2D6" },
                    input: { color: "#D0D2D6" },
                    width: '100%'
                  }}
                />
              </Box>
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default DateBlock;