import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

const TableStats = ({ statParams }) => {

    return (
        <TableContainer sx={{
            height: { xs: '400px', md: '250px', xl: '320px' },
            pt: "0.5rem",
            overflowY: 'auto'
        }}>
            <Table sx={{
                minWidth: 274,
            }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{
                        '& th': { color: '#FFFFFF', borderBottom: '1px solid #7a838f32', whiteSpace: 'nowrap', p: { xs: "0.7rem 0", md: "0.7rem 0", xl: "1rem 0" } }
                    }}>
                        <TableCell><Typography sx={{fontSize:"14px"}}>{`Industry Sector`}</Typography></TableCell>
                        <TableCell><Typography sx={{fontSize:"14px", textAlign:"center"}}>{`Companies Count`}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {statParams && statParams.map(i => (
                            <TableRow key={i.x} sx={{
                                '& td': { color: '#FFFFFF', borderBottom: '1px solid #7a838f32', p: { xs: "0.7rem 0", md: "0.6rem 0", xl: "0.7rem 0" } }
                            }}>
                                <TableCell><Typography sx={{fontSize:"12px"}}>{i.x}</Typography></TableCell>
                                <TableCell><Typography sx={{fontSize:"12px", textAlign:"center", textDecorationLine:"underline", opacity: "0.8"}}>{i.val}</Typography></TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableStats;