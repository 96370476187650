import axios from 'axios';
import qs from "qs";

let urlBase = `${process.env.REACT_APP_URL_NEW}`
if (process.env.REACT_APP_URL.indexOf(window.location.host) != -1) {
    urlBase = `${process.env.REACT_APP_URL}`
}
const instance = axios.create({
    baseURL: urlBase,
});

export const api = {
    getCompanies: async ({ industries, location, sub_industries }) => {
        return (
            await instance.get('/dashboards/api/companies-industry-filter/',
                {
                    params: { location, industries, sub_industries, requ: 'app' },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: 'repeat' })
                    },
                })
        ).data;
    },
    companiDetails: async ({ slug }) => {
        return (
            await instance.get(`/dashboards/api/company-details`, { params: { slug, requ: 'app' } })
        ).data;
    },
    getStats: async ({ cat1, cat2, industry, sector }) => {
        return (
            await instance.get('/dashboards/api/companies-stats/',
                {
                    params: { cat1, cat2, 'deeptech-industry': industry, 'deeptech-sub-industry': sector, requ: 'app' },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: 'repeat' })
                    },
                })
        ).data;
    }
}
