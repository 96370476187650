import { Grid } from "@mui/material";
import React from "react";
import StarChart from './../charts/starChart';
import SelectBlock from './selectBlock';
import HistoricalCurveBlock from './historicalCurveBlock';
import { api } from './../../../../api/apiCovidCharts/index';

const CountryBlock = ({countrySelect, handleCountryChange, country, iso3Select, series}) => {
    
    const [covidData, setCovidData] = React.useState()
    const [dataBlockGraph1Arr, setDataBlockGraph1Arr] = React.useState([0])

    React.useEffect(() => {
      api.dataCovid({ iso3: iso3Select })
      .then(res => {
          setCovidData(res)
      })
      .catch(err => { console.log(err) })
    }, [iso3Select]);

    React.useEffect(() => {
      if (covidData) {
          let countryCases = covidData.data;
          let newCases = [];
          countryCases.forEach(i => {
            if(new Date(i.date) > new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate())){
              if(i.new_cases){
                newCases.push(i.new_cases);
              }
            }
          })
          setDataBlockGraph1Arr(newCases);
      }
    }, [covidData]);

  return (
    <Grid item xs={12} md={6} sx={{ p: "1rem" }}>
      
      <SelectBlock countrySelect={countrySelect} handleCountryChange={handleCountryChange} country={country}/>

      <Grid item container sx={{ pt: { md: "1rem", xs: "0" } }}>
        <HistoricalCurveBlock covidData={covidData} dataBlockGraph1Arr={dataBlockGraph1Arr}/>
        <StarChart series={series} />
      </Grid>
    </Grid>
  );
};

export default CountryBlock;