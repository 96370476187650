import { Box, Typography } from "@mui/material"
import React from 'react';
import Chart from 'react-apexcharts';


const BarHorizontal = ({ data }) => {

    let dataGraph = [], labels = [];
    data.forEach(el => {
        dataGraph.push(el.val)
        labels.push(el.x)
    });
    const series = [{
        data: dataGraph,
    }]

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            stacked: true,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#0743F533', '#25A18E33', '#E9453733'],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 0,
            colors: ['#fff']
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#fff'
                }
            },
            categories: labels,
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#fff'
                }
            },
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const data = series[seriesIndex][dataPointIndex]
                const label = w.config.xaxis.categories[dataPointIndex]
                const color = w.config.colors[dataPointIndex].substring(0, 7)
                return `<Box style='padding: 0.4rem 0.5rem; background: ${color}'> ${label}: ${data}</Box>`
            }
        }
    }

    return (
        <Box sx={{ height: { md: '260px', xl: '370px' } }}>
            <Chart options={options} series={series} type="bar" height='100%' />
        </Box>
    )
}

export default BarHorizontal;