import { Box, Grid, Typography } from "@mui/material"
import CubeSVG from "./cubeSVG"



const PortfolioSummary = ({ parametersCubeSvg }) => {


    return (
        <Box
            sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
            }}
        >
            <Box>
                <Typography>Portfolio Summary</Typography>
            </Box>
            <Box>
                <Grid container sx={{ p: "1.5rem" }}>
                    {parametersCubeSvg && parametersCubeSvg.map(el => (
                        <Grid key={el.NameCube1} item container xs={12} md={1.7}>
                            <Grid item container xs={6} md={12}>
                                <Grid item container xs={3} md={3}>
                                    <CubeSVG color={el.color} />
                                </Grid>
                                <Grid item container xs={9} md={9}>
                                    <Grid item xs={12} md={12} sx={{pl: '2px'}}>
                                        <Typography
                                            sx={{
                                                fontSize: "10px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {el.value1}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "10px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {el.NameCube1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={6} md={12}>
                                <Grid item container xs={3} md={3}>
                                    <CubeSVG color={el.color} />
                                </Grid>
                                <Grid item container xs={9} md={9}>
                                    <Grid item xs={12} md={12} sx={{pl: '2px'}}>
                                        <Typography
                                            sx={{
                                                fontSize: "10px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {el.value2}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "10px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {el.NameCube2}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>))}
                </Grid>
            </Box>
        </Box>
    )
}


export default PortfolioSummary;