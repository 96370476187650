import { Box, Typography } from "@mui/material"
import { height } from "@mui/system";


const MainDarkBox = ({ children, title, bgcolor, autoHeigth }) => {
    return (

        <Box sx={{
            background: bgcolor ? bgcolor : '#283046',
            borderRadius: '7px', p: '1.5rem',
            boxShadow: '8px 5px 20px 0px rgb(0 0 0 / 13%)',
            height: autoHeigth ? '100%' :'auto'
        }}>
            {title ? <Typography sx={{
                textAlign: 'center',
                letterSpacing: '1px',
                color: '#d0d2d6'
            }}
            >{title}</Typography> : ''}
            <Box 
            sx={{height: '90%'}}
            >
                {children}
            </Box>
        </Box>
    )
}


export default MainDarkBox;