import { Box } from "@mui/material";
import React from 'react';
import Chart from 'react-apexcharts';



const BarChart = ({ series, labels }) => {

    // console.log(series)
    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // console.log(w)
                const data = series[seriesIndex][dataPointIndex];
                const label = w.globals.labels[dataPointIndex];
                return `<Box style='padding: 0.4rem 0.5rem; background: #000'> Return Percentage ${label}: Frequency = ${data}</Box>`
            },
        },
        // colors: ['2c13d1', '2c13d1', '2c13d1', '2c13d1', '2c13d1'],
        colors: ['#008FFB', '#008FFB', '#008FFB'],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 0,
            colors: ['2c13d1']
        },
        xaxis: {
            tickAmount: 30,
            labels: {
                style: {
                    colors: '#fff'
                }
            },
            categories: labels,
        },
        yaxis: {
            title: {
                text: 'Frequency',
                style: {
                    color: '#FFF',
                    fontSize: '14px',
                    fontWeight: 500,
                }
            },
            labels: {
                style: {
                    colors: '#fff'
                }
            },
        },
    }

    return (
        <Box sx={{ height: '100%' }}>
            <Chart options={options} series={series} type="bar" height={"100%"} />
        </Box>
    )
}

export default BarChart;