import React from 'react';
import { Box, Avatar, Typography, Popover, Button, Grid } from '@mui/material';

import SelectMindmap from './selectMindmap';



const MindmapsGrid = ({ mindmapData, createdDash, setIsSelected }) => {

    const [selectMindmap, setSelectMindmap] = React.useState(false)

    const mindMapMain = () => {
        return (
            <Grid container spacing={2} sx={{ p: '0 1rem' }}>
                {mindmapData && mindmapData.map(i => (
                    <Grid key={i.id} item xl={4} md={4} xs={12}
                        onClick={() => setSelectMindmap(i.id)} sx={{ height: '100%' }}>
                        <Box sx={{ background: createdDash ? 'rgb(35, 48, 68)' : '#283046', borderRadius: '7px', p: '1.5rem', height: '100%' }}>
                            <Typography sx={{
                                textAlign: 'center',
                                letterSpacing: '1px',
                                color: '#d0d2d6',
                                height: '50px'
                            }}
                            >{i.title}</Typography>
                            <Box sx={{
                                height: { md: '200px' },
                                '& img': {
                                    maxHeight: { md: '200px' },
                                }
                            }}>
                                <img width='100%' src={i.imageMap} alt={i.title} />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button sx={{
                                    marginTop: '10px',
                                    width: '90%',
                                    border: createdDash ? '1px solid #4176bc' : '1px solid #8b54e5',
                                    color: createdDash ? '#4176bc' : '#c6abe5'
                                }}
                                    onClick={() => {
                                        setSelectMindmap(i.id);
                                        setIsSelected(true)
                                    }}>Interactive</Button>
                            </Box>
                        </Box>
                    </Grid>
                ))
                }
            </Grid >
        )
    }


    return (
        <Box sx={{ height: '100%' }}>
            {selectMindmap ? <SelectMindmap mapNumber={selectMindmap - 1} mapData={mindmapData} /> : mindMapMain()}
        </Box>
    )
}

export default MindmapsGrid;