import React from 'react';
import { Box, Grid, Typography, Popover, RadioGroup, FormControlLabel, Radio, CircularProgress, Drawer } from '@mui/material';
import MainDarkBox from '../../../../mainDarkBox'
import search from '../../../../../assets/searchNew.svg';
import sort from '../../../../../assets/sort.svg';
import filter from '../../../../../assets/filterNew.svg';
import server from '../../../../../assets/server.svg'
import GridViewIcon from '@mui/icons-material/GridView';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Filters from './filters';
import { api } from '../../../../api/apiChart'
import CompanyCard from '../../../../companies/CompanyCard';
import CompanyTable from '../../../../companies/CompanyTable';
import PaginationDark from '../../../../pagination'
import CompanyInfoPage from './companyInfoPage';

const Companies = ({ section }) => {
    const [slug, setSlug] = React.useState();
    const [dropDownFilters, setDropDownFilters] = React.useState('fund_total')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activeProgress, setActiveProgress] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false)
    const [companyList, setCompanyList] = React.useState()
    const [page, setPage] = React.useState(1);
    const [typeCompanyList, setTypeCompanyList] = React.useState(true);
    const [valueSearch, setValueSearch] = React.useState('');
    const [allFilters, setAllFilters] = React.useState({
        category_28: '--all sectors--',
        category_29: '',
        year: '',
        staff: '',
        fund_last: '',
        fund_range: '',
        ipo_status: '',
        country: '',
        fund_rev: '',
    });
    const handleFilters = (stateName, value) => {
        const arrFilters = []
        value.forEach(i => {
            if (i.value) {
                arrFilters.push(i.value)
            } else if (i.title) {
                arrFilters.push(i.title)
            } else {
                arrFilters.push(i[0])
            }
        })
        setAllFilters({ ...allFilters, [stateName]: arrFilters });
    }
    const handleCategoty28 = (e) => {
        setAllFilters({ ...allFilters, ['category_28']: e })
    }
    React.useEffect(() => {
        if (allFilters.category_28 !== '' || allFilters.category_29 !== '' || allFilters.year !== '' || allFilters.staff !== '' || allFilters.fund_last !== '' ||
            allFilters.fund_range !== '' || allFilters.ipo_status !== '' || allFilters.country !== '' || allFilters.fund_rev !== '') {
            api.companiList({
                section: section == 'investors' ? 'investors' : 'companies',
                dashboard: 'spacetech-dashboard',
                page: page,
                per_page: 9,
                sort: dropDownFilters,
                q: valueSearch,
                category_28: allFilters.category_28 === '--all sectors--' ? '' : allFilters.category_28,
                category_29: allFilters.category_29,
                year: allFilters.year,
                staff: allFilters.staff,
                fund_last: allFilters.fund_last,
                fund_range: allFilters.fund_range,
                ipo_status: allFilters.ipo_status,
                country: allFilters.country,
                fund_rev: allFilters.fund_rev,
            })
                .then(res => {
                    setCompanyList(res)
                    setActiveProgress(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [allFilters]);

    const handleChange = (event, value) => {
        setActiveProgress(true)
        setPage(value);
        api.companiList({ section: section == 'investors' ? 'investors' : 'companies', dashboard: 'spacetech-dashboard', page: value, per_page: 9, sort: dropDownFilters, q: valueSearch })
            .then(res => {
                setCompanyList(res)
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDrawer = () => {
        setDrawer(false);
    };
    const handleOpenDrawer = () => {
        setDrawer(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDropDown = (e) => {
        setActiveProgress(true)
        setDropDownFilters(e.target.value)
        api.companiList({ section: section == 'investors' ? 'investors' : 'companies', dashboard: 'spacetech-dashboard', page: page, per_page: 9, sort: e.target.value, q: valueSearch })
            .then(res => {
                setCompanyList(res)
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    };
    const prevPage = () => {
        setActiveProgress(true)
        if (page !== 1) setPage(prev => prev - 1);
        api.companiList({ section: section == 'investors' ? 'investors' : 'companies', dashboard: 'spacetech-dashboard', page: page !== 1 && page - 1, per_page: 9, sort: dropDownFilters, q: valueSearch })
            .then(res => {
                setCompanyList(res)
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    };
    const nextPage = () => {
        setActiveProgress(true)
        setPage(prev => prev + 1);

        api.companiList({ section: section == 'investors' ? 'investors' : 'companies', dashboard: 'spacetech-dashboard', page: page + 1, per_page: 9, sort: dropDownFilters, q: valueSearch })
            .then(res => {
                setCompanyList(res)
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    };
    const firstPage = () => {
        setActiveProgress(true)
        setPage(1);
        api.companiList({ section: section == 'investors' ? 'investors' : 'companies', dashboard: 'spacetech-dashboard', page: 1, per_page: 9, sort: dropDownFilters, q: valueSearch })
            .then(res => {
                setCompanyList(res)
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    };
    React.useEffect(() => {
        getCompanies()
    }, []);
    const getCompanies = () => {
        api.companiList({ section: section == 'investors' ? 'investors' : 'companies', dashboard: 'spacetech-dashboard', page: page, per_page: 9, sort: dropDownFilters, q: valueSearch })
            .then(res => {
                setCompanyList(res)
                setActiveProgress(false)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const requstValSearch = () => {
        setActiveProgress(true)
        getCompanies()
    }

    const handleCompanyDetailNewPage = (slug) => {
        console.log(slug)
        setSlug(slug)
    }


    return (
        <Box>
            {
                slug ?
                    <Box>
                        <CompanyInfoPage setSlug={setSlug} slug={slug} dashboard='spacetech-dashboard' />
                    </Box>
                    :
                    <Box>

                        < Box sx={{ margin: '0 1rem' }
                        }>
                            <MainDarkBox bgcolor='rgb(35, 48, 68)'>
                                <Grid container mt={2}>
                                    <Grid item xs={12} md={6} sx={{ pl: { xs: '0px', md: '30px' } }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ background: 'rgb(64, 122, 214, 0.2)', width: '48px', borderRadius: '50%', mr: '20px', height: '48px', cursor: 'pointer' }}
                                                onClick={() => requstValSearch()}
                                            >
                                                <Box sx={{ width: '24px', margin: 'auto', padding: '12px 0px 8px' }}>
                                                    <img width='100%' src={search} />
                                                </Box>
                                            </Box>
                                            <Box sx={{ mt: '5px', width: '85%' }}>
                                                <input value={valueSearch} onChange={e => setValueSearch(e.target.value)} className="inputSearch" type="text" placeholder='Search...' />
                                                <Typography sx={{ fontSize: '0.857rem', color: '#b4b7bd', mt: '5px' }}>{companyList && companyList.count} organizations found</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box onClick={() => { setTypeCompanyList(!typeCompanyList) }} sx={{ background: 'rgba(0, 207, 232, 0.12) !important', width: '48px', borderRadius: '50%', mr: '20px', height: '48px', cursor: 'pointer' }}>
                                                <Box sx={{ width: '24px', margin: 'auto', padding: '12px 0px 8px' }}>
                                                    {typeCompanyList ?
                                                        <GridViewIcon sx={{ fill: '#00cfe8' }} /> :
                                                        <img width='100%' src={server} />}
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                background: '#213f66',
                                                color: '#fff',
                                                padding: '0.486rem 1.5rem',
                                                fontSize: '1rem',
                                                borderRadius: '0.358rem',
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}
                                                onClick={handleClick}>
                                                <img width='13px' src={sort} />
                                                <Typography sx={{ ml: '3px' }}>{dropDownFilters == 'fund_total' ? 'Total Funding' : 'Company Staff'}</Typography>
                                                <KeyboardArrowDownIcon sx={{ fill: '#fff', fontSize: '20px', ml: '5px' }} />
                                            </Box>
                                            <Popover
                                                sx={{
                                                    '.MuiPopover-paper': {
                                                        background: '#283046',
                                                        boxShadow: '0 4px 24px 0 rgb(0 0 0 / 24%)',
                                                        marginTop: '0.5rem',
                                                        borderRadius: '0.358rem',
                                                    },
                                                }}
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Box >
                                                    <RadioGroup value={dropDownFilters} onChange={(e) => {
                                                        handleDropDown(e)
                                                    }}
                                                        aria-label="position" name="position" defaultValue="top" sx={{ display: 'flex', alignItems: 'start' }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: '10px 70px 10px 10px',
                                                            '&:hover': { background: 'rgba(115, 103, 240, 0.12)' }
                                                        }}>
                                                            <FormControlLabel
                                                                className='app'
                                                                sx={{ '.MuiFormControlLabel-label': { color: '#b4b7bd', '&:hover': { color: '#7367f0' } } }}
                                                                value="top"
                                                                control={<Radio value="fund_total" sx={{ display: 'none' }} />}
                                                                label="Total Funding"
                                                                labelPlacement="start"
                                                                onClick={handleClose}
                                                            />
                                                        </Box>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: '10px 60px 10px 10px',
                                                            '&:hover': { background: 'rgba(115, 103, 240, 0.12)' }
                                                        }}>
                                                            <FormControlLabel
                                                                className='app'
                                                                sx={{ '.MuiFormControlLabel-label': { color: '#b4b7bd', '&:hover': { color: '#7367f0' } } }}
                                                                value="start"
                                                                control={<Radio value="staff" sx={{ display: 'none' }} />}
                                                                label="Company Staff"
                                                                labelPlacement="start"
                                                                onClick={handleClose}
                                                            />
                                                        </Box>
                                                    </RadioGroup>
                                                </Box>
                                            </Popover>
                                            <Box onClick={handleOpenDrawer} ml={2} mt={1} sx={{ cursor: 'pointer' }}>
                                                <img width='28px' src={filter} />
                                            </Box>
                                            <Drawer
                                                anchor="right"
                                                open={drawer}
                                                onClose={handleCloseDrawer}
                                                hideBackdrop={true}
                                                sx={{
                                                    display: { xs: 'none', md: 'block' },
                                                    '.MuiDrawer-paper': {
                                                        backgroundColor: 'rgb(35, 48, 68)'
                                                    }
                                                }}
                                            >
                                                <Filters
                                                    bgcolor='rgb(35, 48, 68)'
                                                    handleCloseDrawer={handleCloseDrawer}
                                                    discription="Use filters to find needed company quick"
                                                    title="Filters"
                                                    handleFilters={handleFilters}
                                                    handleCategoty28={handleCategoty28}
                                                    allFilters={allFilters}
                                                    section={section}
                                                />
                                            </Drawer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        {activeProgress &&
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <CircularProgress sx={{ color: 'rgb(64, 122, 214)' }} />
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                            </MainDarkBox>
                        </Box >
                        {
                            !companyList ? <Box sx={{ display: 'flex', justifyContent: 'center', m: '1rem' }}>
                                <CircularProgress color="primary" />
                            </Box> :
                                <Box sx={{ margin: '1rem' }}>
                                    {typeCompanyList ?
                                        <CompanyCard bgcolor='rgb(35, 48, 68)' companyList={companyList} dashboard='spacetech-dashboard' handleCompanyDetailNewPage={handleCompanyDetailNewPage} /> :
                                        <CompanyTable bgcolor='rgb(35, 48, 68)' companyList={companyList} page={page} dashboard='spacetech-dashboard' handleCompanyDetailNewPage={handleCompanyDetailNewPage} />
                                    }
                                </Box>
                        }
                        {
                            companyList && <PaginationDark
                                bgcolor='rgb(35, 48, 68)'
                                page={page}
                                count={companyList.pager.last}
                                firstPage={firstPage}
                                prevPage={prevPage}
                                handleChange={handleChange}
                                nextPage={nextPage}
                            />
                        }
                    </Box >
            }
        </Box>
    )
}
export default Companies;