import { Box, Grid } from "@mui/material";
import dataJson from "../../../../../assets/json/economyRegionalSafetyRanking.json"
import RadialBarsBlock from './radialBarsBlock';
import LegendBlock from './legendBlock';
import React from "react";
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import dataBaseIcon from '../../../../../assets/svg/database.svg';
import {byCountry} from 'country-code-lookup'
import CountryBlock from './countryBlock';

const RegionalSafetyRanking = ({parameters, setDataCovidHeader}) => {
    const [countrySelect, setCountrySelect] = React.useState('Argentina')
    const [iso3Select, setIso3Select] = React.useState('ARG')
    const [series, setSeries] = React.useState([
      {
        name: "Country",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Average",
        data: [0, 0, 0, 0, 0],
      },
    ]);

    const handleCountryChange = (event) => {
      setCountrySelect(event.target.value);
      setIso3Select(byCountry(event.target.value).iso3)
    }

    let country = []
    dataJson.forEach((i) => {
      country.push(i.Country);
    });

    const getUnique = (arr) => {
      let i = 0,
        current,
        length = arr.length,
        unique = [];
      for (i; i < length; i++) {
        current = arr[i];
        if (!~unique.indexOf(current)) {
          unique.push(current);
        }
      }
      return unique.sort();
    };

    country = getUnique(country);

    React.useEffect(() => {
      parameters.forEach((i, key) => {
              if (key === 0) {
                i.counts = "70+";
              } else if (key === 1) {
                i.counts = 114;
              } else if (key === 2) {
                i.counts = "8500+";
                i.titleParams = 'Data Points';
                i.iconParams = <img width='23px' src={dataBaseIcon} alt='Data Points' />
                i.bgColor = '#DB6AB51A'
              } else if (key === 3) {
                i.counts = 5;
                i.titleParams = 'Regions';
                i.iconParams = <OutlinedFlagIcon sx={{ fill: '#28C7AA', fontSize: '2rem' }} />
                i.bgColor = '#28C7AA1A'
              }
            })
            setDataCovidHeader([...parameters])
    }, []);

  React.useEffect(() => {
    let vaccinationRateData = [];
    let economyResilienceData = [];
    let governmentEfficiencyData = [];
    let quarantineEfficiencyData = [];
    let healthcareManagementData = [];

    let vaccinationRateDataAverage = [];
    let economyResilienceDataAverage = [];
    let governmentEfficiencyDataAverage = [];
    let quarantineEfficiencyDataAverage = [];
    let healthcareManagementDataAverage = [];

    dataJson.forEach(i => {
      vaccinationRateDataAverage.push(parseFloat(i["Vaccination Rate"]));
      economyResilienceDataAverage.push(parseFloat(i["Economy Resilience"]));
      governmentEfficiencyDataAverage.push(parseFloat(i["Government Efficiency"]));
      quarantineEfficiencyDataAverage.push(parseFloat(i["Quarantine Efficiency"]));
      healthcareManagementDataAverage.push(parseFloat(i["Healthcare Management"]));
      if (i.Country === countrySelect){
        vaccinationRateData.push(parseFloat(i["Vaccination Rate"]));
        economyResilienceData.push(parseFloat(i["Economy Resilience"]));
        governmentEfficiencyData.push(parseFloat(i["Government Efficiency"]));
        quarantineEfficiencyData.push(parseFloat(i["Quarantine Efficiency"]));
        healthcareManagementData.push(parseFloat(i["Healthcare Management"]));
      }
    })
    let vaccinationRate = vaccinationRateData.reduce((a, b) => (a + b)) / vaccinationRateData.length;
    let economyResilience = economyResilienceData.reduce((a, b) => (a + b)) / economyResilienceData.length;
    let governmentEfficiency = governmentEfficiencyData.reduce((a, b) => (a + b)) / governmentEfficiencyData.length;
    let quarantineEfficiency = quarantineEfficiencyData.reduce((a, b) => (a + b)) / quarantineEfficiencyData.length;
    let healthcareManagement = healthcareManagementData.reduce((a, b) => (a + b)) / healthcareManagementData.length;

    let vaccinationRateAverage = vaccinationRateDataAverage.reduce((a, b) => (a + b)) / vaccinationRateDataAverage.length;
    let economyResilienceAverage = economyResilienceDataAverage.reduce((a, b) => (a + b)) / economyResilienceDataAverage.length;
    let governmentEfficiencyAverage = governmentEfficiencyDataAverage.reduce((a, b) => (a + b)) / governmentEfficiencyDataAverage.length;
    let quarantineEfficiencyAverage = quarantineEfficiencyDataAverage.reduce((a, b) => (a + b)) / quarantineEfficiencyDataAverage.length;
    let healthcareManagementAverage = healthcareManagementDataAverage.reduce((a, b) => (a + b)) / healthcareManagementDataAverage.length;

    setSeries([
      {
        name: "Average",
        data: [vaccinationRateAverage, economyResilienceAverage, governmentEfficiencyAverage, quarantineEfficiencyAverage, healthcareManagementAverage],
      },
      {
        name: "Country",
        data: [vaccinationRate, economyResilience, governmentEfficiency, quarantineEfficiency, healthcareManagement],
      },
    ]);

  }, [countrySelect])

  return (
    <Box sx={{ color: "#fff" }}>
      <Grid container sx={{ p: "1rem" }}>
        
        <CountryBlock countrySelect={countrySelect} handleCountryChange={handleCountryChange} country={country} iso3Select={iso3Select} series={series}/>

        <LegendBlock countrySelect={countrySelect}/>
      </Grid>

      <RadialBarsBlock countrySelect={countrySelect} />
    </Box>
  );
};

export default RegionalSafetyRanking;