import { Box, Typography, Grid } from "@mui/material";
import { RiPulseLine } from 'react-icons/ri';
import { IconContext } from "react-icons";

const CountryScore = ({ score }) => {

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ justifyContent: "center", mt: "-0.75rem", mb: "-0.75rem" }}
    >
      <Box
        sx={{
          display: "flex",
          background: "rgba(255, 159, 67, 0.1)",
          p: "10px",
          borderRadius: "50%",
          width: "36px",
          height: "36px",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <IconContext.Provider value={{ color: "#FF9F43", size: "2rem" }}>
          <RiPulseLine />
        </IconContext.Provider>
      </Box>
      <Box sx={{width: "110px", pl: "0.5rem"}}>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            color: "#D0D2D6",
          }}
        >
          Country Score
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "Montserrat",
            fontWeight: "600",
            color: "#D0D2D6",
            display: "inline-block",
          }}
        >
          {score}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: "600",
            color: "#D0D2D6",
            display: "inline-block",
          }}
        >
          &nbsp;/&nbsp;100
        </Typography>
      </Box>
    </Grid>
  );
};

export default CountryScore;