import { Typography, Grid } from "@mui/material";
import DateBlock from './dateBlock';
import MainDarkBox from "../../../../mainDarkBox";
import LineChartStrict from '../charts/lineChartStrict';

const LineChartBlock = ({ date, date2, handleSetDate, handleSetDate2, name, series, years }) => {

    return (

    <Grid item container xs={12} md={6.5} sx={{ p:  {md:"0 0 2rem 2rem", xs: "0 0 1rem 0"} }}>
      
      <DateBlock date={date} date2={date2} handleSetDate={handleSetDate} handleSetDate2={handleSetDate2}/>

      <Grid item xs={12} sx={{ pt: {md:"2rem", xs:"1rem"} }}>
        <MainDarkBox>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: "500",
            }}
          >
            Choose parameter from the {window.innerWidth < 900 ? "bottom" : "right"} to see changes over time
          </Typography>
        </MainDarkBox>
      </Grid>

      <Grid item xs={12} sx={{ pt: {md:"2rem", xs:"1rem"} }}>
        <MainDarkBox>
          <LineChartStrict name={name} series={series} years={years}/>
        </MainDarkBox>
      </Grid>
    </Grid>
    );
};

export default LineChartBlock;