import { Box } from "@mui/material";

import Page from "./fivePagesComponents/index"


const HealthcareManagement = () => {

    return(
        <Box>
            <Page pageName="Healthcare Management"/>
        </Box>
    )
}

export default HealthcareManagement;