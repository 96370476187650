import { Box } from "@mui/material";
import BarGraph from "./barGraph";
import BarHorizontal from "./barHorizontal";
import LineGraph from "./lineGraph";
import SectorGraph from "./sectorGraph";



const Graphs = ({ type, data, color }) => {

    const graphRender = (type) => {

        switch (type) {
            case 'line':
                return <LineGraph data={data} colors={color} />

            case 'barHorizontal':
                if (data)
                    return <BarHorizontal data={data} />

            case 'sector':
                return <SectorGraph data={data} />

            case 'bar':
                if (data)
                    return <BarGraph data={data} />

            default:
                return;
        }

    }


    return (
        <Box>
            {graphRender(type)}
        </Box>
    )
}


export default Graphs;