import React from 'react'
import { Box, Typography, Dialog, CircularProgress, Grid } from '@mui/material';
import DialogDetails from './dialogDetails';
import { api } from '../../../../api/apiChart'

const MatcingTool = ({ progress, hardware, software, financialServices, legal, marketing }) => {
    const [open, setOpen] = React.useState(false);
    const [companyDetails, setCompanyDetails] = React.useState();
    const handleClickOpen = (slug) => {
        setOpen(true);
        api.companiDetails({ slug: slug })
            .then(res => {
                setCompanyDetails(res)
            })
            .catch(err => {
                console.log(err)
            })
    };
    const handleClose = () => {
        setCompanyDetails(null)
        setOpen(false);
    };

    return (
        <Box>
            <Typography mb={2} sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>Matching Tool</Typography>
            {progress && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color="secondary" />
            </Box>}
            {hardware && software && financialServices && legal && marketing ?
                <Box>
                    <Typography mb={1} sx={{ color: '#b4b7bd', fontSize: '1.07rem' }}>Marketing</Typography>
                    <Grid mb={1} container>
                        {marketing.data.map(item => (
                            <Grid item>
                                <Typography sx={{
                                    color: '#7367f0',
                                    fontSize: '14px',
                                    border: '1px solid #7367f0',
                                    padding: '0.786rem 1.5rem',
                                    m: '0 3px 3px 0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: 'rgba(115, 103, 240, 0.04)'
                                    }
                                }}
                                    onClick={() => handleClickOpen(item.slug)}>{item.title}</Typography>

                            </Grid>
                        ))}
                    </Grid>
                    <Typography mb={1} sx={{ color: '#b4b7bd', fontSize: '1.07rem' }}>Legal</Typography>
                    <Grid mb={1} container>
                        {legal.data.map(item => (
                            <Grid item>
                                <Typography sx={{
                                    color: '#7367f0',
                                    fontSize: '14px',
                                    border: '1px solid #7367f0',
                                    padding: '0.786rem 1.5rem',
                                    m: '0 3px 3px 0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: 'rgba(115, 103, 240, 0.04)'
                                    }
                                }}
                                    onClick={() => handleClickOpen(item.slug)}>{item.title}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography mb={1} sx={{ color: '#b4b7bd', fontSize: '1.07rem' }}>Financial services</Typography>
                    <Grid mb={1} container>
                        {financialServices.data.map(item => (
                            <Grid item>
                                <Typography sx={{
                                    color: '#7367f0',
                                    fontSize: '14px',
                                    border: '1px solid #7367f0',
                                    padding: '0.786rem 1.5rem',
                                    m: '0 3px 3px 0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: 'rgba(115, 103, 240, 0.04)'
                                    }
                                }}
                                    onClick={() => handleClickOpen(item.slug)}>{item.title}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography mb={1} sx={{ color: '#b4b7bd', fontSize: '1.07rem' }}>Software</Typography>
                    <Grid mb={1} container>
                        {software.data.map(item => (
                            <Grid item>
                                <Typography sx={{
                                    color: '#7367f0',
                                    fontSize: '14px',
                                    border: '1px solid #7367f0',
                                    padding: '0.786rem 1.5rem',
                                    m: '0 3px 3px 0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: 'rgba(115, 103, 240, 0.04)'
                                    }
                                }}
                                    onClick={() => handleClickOpen(item.slug)}>{item.title}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography mb={1} sx={{ color: '#b4b7bd', fontSize: '1.07rem' }}>Hardware</Typography>
                    <Grid mb={1} container>
                        {hardware.data.map(item => (
                            <Grid item>
                                <Typography sx={{
                                    color: '#7367f0',
                                    fontSize: '14px',
                                    border: '1px solid #7367f0',
                                    padding: '0.786rem 1.5rem',
                                    m: '0 3px 3px 0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: 'rgba(115, 103, 240, 0.04)'
                                    }
                                }}
                                    onClick={() => handleClickOpen(item.slug)}>{item.title}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box> : ''}
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ '.MuiDialog-paper': { boxShadow: 'none', borderRadius: '0px', width: '90%' } }}
            >
                <DialogDetails items={companyDetails} handleClose={handleClose} />
            </Dialog>
        </Box>
    )
}
export default MatcingTool