import React from 'react'
import MainDarkBox from '../mainDarkBox';
import { Box, Grid, Typography, Divider, Dialog, Button } from '@mui/material';
import DialogCompanies from '../pages/dashboardAsia/fieldConteiner/companies/dialog';
import { api } from '../api/apiChart'
const CompanyTable = ({ companyList, bgcolor, page, handleCompanyDetailNewPage }) => {
    console.log(companyList.data)
    const [open, setOpen] = React.useState(false);
    const [companyDetails, setCompanyDetails] = React.useState();
    const [marketing, setMarketing] = React.useState();
    const [legal, setLegal] = React.useState();
    const [financialServices, setFinancialServices] = React.useState();
    const [software, setSoftware] = React.useState();
    const [hardware, setHardware] = React.useState();
    const [progress, setProgress] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setMarketing(null)
        setLegal(null)
        setFinancialServices(null)
        setSoftware(null)
        setHardware(null)
        setOpen(false);
    };
    const handleCompanyDetails = (slug) => {
        handleClickOpen()
        setProgress(true)
        api.companiDetails({ slug: slug })
            .then(res => {
                setCompanyDetails(res)
                if (res.categories['Industry Groups'])
                    handleMatcingTool(res.categories['Industry Groups'])
            })
            .catch(err => {
                console.log(err)
            })
    }
    function generate_matching_list(main_category, list_name) {
        if (!main_category)
            return;

        let filters = {
            dashboard: 'asia',
            section: 'companies',
            per_page: '10',
            sort: '-fund_total',
            category_1: main_category
        };
        switch (list_name) {
            case 'marketing':
                filters['category_2'] = [
                    "Administrative Services",
                    "Advertising",
                    "Clothing and Apparel",
                    "Community and Lifestyle",
                    "Content and Publishing",
                    "Design",
                    "Events",
                    "Professional Services",
                    "Sales and Marketing"
                ];
                break;
            case 'legal':
                filters['category_2'] = 'Legal';
                break;
            case 'financial':
                filters['category_2'] = [
                    "Financial Services",
                    "Payments",
                    "Professional Services"
                ];
                break;
            case 'software':
                filters['category_2'] = [
                    "Apps",
                    "Artificial Intelligence",
                    "Data and Analytics",
                    "Information Technology",
                    "Internet Services",
                    "Mobile",
                    "Navigation and Mapping",
                    "Platforms",
                    "Privacy and Security",
                    "Professional Services",
                    "Software"
                ];
                break;
            case 'hardware':
                filters['category_2'] = [
                    "Consumer Electronics",
                    "Hardware",
                    "Manufacturing"
                ];
                break;
        }
        if (!filters['category_2'])
            return;
        api.companiList({ section: 'companies', dashboard: 'asia', per_page: 10, sort: '-fund_total', category_1: main_category, category_2: filters['category_2'] })
            .then(res => {
                switch (list_name) {
                    case 'marketing':
                        setMarketing(res)
                        break;
                    case 'legal':
                        setLegal(res)
                        break;
                    case 'financial':
                        setFinancialServices(res)
                        break;
                    case 'software':
                        setSoftware(res)
                        break;
                    case 'hardware':
                        setHardware(res)
                        setProgress(false)
                        break;
                }
            })
            .catch(err => {
                console.log(err)
            })
    };
    const handleMatcingTool = (items) => {
        let main_category
        const main_categories_list = [
            "Agriculture and Farming",
            "Biotechnology",
            "Commerce and Shopping",
            "Education",
            "Energy",
            "Financial Services",
            "Food and Beverage",
            "Government and Military",
            "Health Care",
            "Manufacturing",
            "Media and Entertainment",
            "Messaging and Telecommunications",
            "Natural Resources",
            "Real Estate",
            "Science and Engineering",
            "Sustainability",
            "Transportation",
            "Travel and Tourism"
        ];
        main_categories_list.forEach(i => {
            items.forEach(k => {
                if (i == k) main_category = k
            })
        })
        generate_matching_list(main_category, 'marketing');
        generate_matching_list(main_category, 'legal');
        generate_matching_list(main_category, 'financial');
        generate_matching_list(main_category, 'software');
        generate_matching_list(main_category, 'hardware');
    }
    return (
        <Box>
            <MainDarkBox bgcolor={bgcolor} >
                <Box sx={{ ml: '15px', pb: '30px' }}>
                    <Grid container spacing={2} alignItems="center" sx={{ background: bgcolor ? bgcolor : '#343d55', pb: '10px' }}>
                        <Grid item xs={1}>
                            <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>#</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>COMPANY NAME</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>DESCRIPTION</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{ color: '#d0d2d6', pr: '10px', fontSize: '0.857rem' }}>{bgcolor ? '' : 'LONGEVITY SECTOR'}</Typography>
                        </Grid>
                    </Grid>
                    {companyList.data && companyList?.data?.map((item, key) => (
                        <Box>
                            <Grid key={item.id} container spacing={2} alignItems="center">
                                <Grid item xs={1}>
                                    <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>{(page * 9) - (8 - key)}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>{item.title}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ color: '#b4b7bd', mt: '10px', fontSize: '1rem' }}>{item?.description?.length > 200 ? `${item.description.substring(0, 200)}...` : item.description}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button sx={{
                                        color: '#fff',
                                        transition: 'all 0.2s ease',
                                        backgroundImage: bgcolor ? 'linear-gradient(47deg, rgb(44, 85, 149), rgb(44, 85, 149))' : 'linear-gradient(47deg, #7367f0, #9e95f5)',
                                        mt: '4px',
                                        padding: '0.26rem 1.5rem',
                                        fontSize: '1rem',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            transform: 'translateY(-2px)'
                                        }
                                    }}
                                        onClick={handleCompanyDetailNewPage ? () => handleCompanyDetailNewPage(item.slug) : () => handleCompanyDetails(item.slug)}>Details</Button>

                                </Grid>
                            </Grid>
                            {key !== (companyList.data.length - 1) && <Divider sx={{ background: '#3b4253', m: '10px 0px' }} />}
                        </Box>
                    ))
                    }
                </Box>
            </MainDarkBox >
            <Dialog
                fullScreen
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ '.MuiDialog-paper': { boxShadow: 'none', borderRadius: '0px' }, '.MuiDialog-paperFullScreen': { width: '90%', height: '80%' } }}
            >
                <DialogCompanies
                    handleClose={handleClose}
                    companyDetails={companyDetails}
                    marketing={marketing}
                    legal={legal}
                    financialServices={financialServices}
                    software={software}
                    hardware={hardware}
                    progress={progress} />
            </Dialog>
        </Box >
    )
}
export default CompanyTable