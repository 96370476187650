import { Box, Typography, Grid } from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Ranking = ({ rank }) => {

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ justifyContent: "center", mt: "-0.75rem", mb: "-0.75rem" }}
    >
      <Box
        sx={{
          display: "flex",
          background: "rgba(42, 153, 255, 0.1)",
          p: "10px",
          borderRadius: "50%",
          width: "36px",
          height: "36px",
        }}
      >
        <TrendingUpIcon
          sx={{ color: "#2A99FF", width: "36px", height: "36px" }}
        />
      </Box>
      <Box sx={{width: "110px", pl: "0.5rem"}}>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            color: "#D0D2D6",
          }}
        >
          Ranking
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "Montserrat",
            fontWeight: "600",
            color: "#D0D2D6",
            display: "inline-block",
          }}
        >
          {rank}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: "600",
            color: "#D0D2D6",
            display: "inline-block",
          }}
        >
          &nbsp;/&nbsp;100
        </Typography>
      </Box>
    </Grid>
  );
};

export default Ranking;