import { Box } from "@mui/material";


const RiskSafetyAssessment = () => {

    return(
        <Box sx={{color: '#fff'}}>
            RiskSafetyAssessment
        </Box>
    )
}

export default RiskSafetyAssessment;