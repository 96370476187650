import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const ImgBlock = ({ ColorButton, imageSrc, mapTitle, pathname }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if(pathname) {
            navigate(pathname, { replace: true })
        }
    }

    return (
        <Box sx={{ background: "#253042", borderRadius: "6px", height: "168px", p:"1rem" }}>
            <Grid container sx={{ height:"100%" }}>
                <Grid item xs={6} sx={{ height:"100%" }}>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '18px', textAlign:"left", height:"70%" }}>{mapTitle}</Typography>
                    <Box sx={{ textAlign: "left", height:"30%" }}>
                        <ColorButton variant="contained" onClick={handleClick} >View Dashboard</ColorButton>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{textAlign:"center"}}>
                    <img height='168px' src={imageSrc}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ImgBlock;