import { Box, Typography } from "@mui/material";
import React from "react";
import LineChart from "./lineChart";



const PortfolioChartBlock = ({ investmentData }) => {

    const [dataChart, setDataChart] = React.useState([]);
    const [labelChart, setLabelChart] = React.useState([{ name: 'Selected Portfolio', data: [0] }]);

    React.useEffect(() => {
        if (investmentData) {
            setLabelChart(investmentData['Portfolio Chart'].Dates)
            let data = [];
            investmentData['Portfolio Chart']['percentage_increase'].forEach(el => {
                data.push(Math.ceil(el))
            })
            setDataChart([{ name: 'Selected Portfolio', data: data }])
        }
    }, [investmentData])

    return (
        <Box
            sx={{
                width: "inherit",
                background: "rgb(35, 48, 68)",
                borderRadius: "7px",
                p: "1.5rem",
                boxShadow: "8px 5px 20px 0px rgb(0 0 0 / 13%)",
            }}>
            <Box>
                <Typography>Portfolio Chart</Typography>
                {investmentData && <LineChart series={dataChart} labels={labelChart} />}
            </Box>
        </Box>
    )
}

export default PortfolioChartBlock;