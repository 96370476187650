import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

import BriefCase from '../../../assets/svg/briefcase.svg'
import GridViewIcon from '@mui/icons-material/GridView';
import cube from '../../../assets/cubeBlue.svg';
import fundingRounds from '../../../assets/fundingRoundsNew.svg'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import { api } from '../../api/apiMegadash';

// <iframe
//     allowtransparency
//     frameBorder="no"
//     title="iframe"
//     width="100%"
//     height="200"
//     src="http://localhost:3000/parameters"
// >
// </iframe>

const parametersData = [
    {
        titleParam: 'Industries',
        icon: <img src={BriefCase} width="25" alt='svg' />,
        counts: 18
    },
    {
        titleParam: 'Locations',
        icon: <LocationOnOutlinedIcon sx={{ fill: '#6DEDE3' }} />,
        counts: 123
    },
    {
        titleParam: 'Technologies',
        icon: <img src={fundingRounds} width="25" alt='svg' />,
        counts: 1594
    },
    {
        titleParam: 'Companies & Investors',
        icon: <GridViewIcon sx={{ fill: '#6A63CE' }} />,
        counts: 246
    },
    {
        titleParam: 'Indicators',
        icon: <img src={cube} width="25" alt='svg' />,
        counts: 6574
    },
    {
        titleParam: 'Data Points',
        icon: <GridViewIcon sx={{ fill: '#6DEDE3' }} />,
        counts: 4892093
    },
]

const Params = () => {

    const [parametersDataState, setParametersDataState] = React.useState(parametersData);

    React.useEffect(() => {
        const params = ['companies', 'investors', 'indicators']
        api.getParams({ params })
            .then(res => {
                parametersData.forEach(el => {
                    if (el.titleParam === 'Indicators ') {
                        el.counts = res.data.indicators;
                    } else if (el.titleParam === 'Companies & Investors') {
                        el.counts = res.data.investors + res.data.companies;
                    }
                    setParametersDataState([...parametersData])
                })
            })
            .catch(err => { console.log(err) })
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: '10px',
            m: '2rem',
            borderRadius: '4px',
            backgroundColor: '#283046',
            alignItems: 'center',
            color: '#d0d2d6'
        }}>
            <Grid container>
            {parametersDataState && parametersDataState.map(i => (
                <Grid sx={{ justifyContent: 'center', p:'0.2rem' }} key={i.titleParam} item xs={6} md={4} xl={2}>
                    <Box key={i.titleParam}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box sx={{
                                borderRadius: '6px',
                                background: "rgba(115, 103, 240, 0.2)",
                                width: "100px",
                                display: 'flex',
                                height: '40px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                pl: "1.5rem",
                                pr: "1.5rem"
                            }}>
                                <Box sx={{
                                    borderRadius: '50%',
                                    mr: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#ea5455'
                                }}>
                                    {i.icon}
                                </Box>
                                <Box sx={{pl: '0.6rem', verticalAlign: 'center'}}>
                                    <Typography sx={{
                                        fontWeight: '600',
                                        margin: "auto",
                                        lineHeight: '.7',
                                    }}>{i.counts}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            pt: '0.2rem'
                        }} >
                            <Typography sx={{ fontSize: '0.8rem' }}>{i.titleParam}</Typography>
                        </Box>
                    </Box>
                </Grid>
            ))
            }
            </Grid>
        </Box>
    )
}

export default Params;