import React from 'react';
import { Box } from '@mui/material';
import DigitalMain from './fieldContainer/digitalMain';
import Companies from './fieldContainer/companies';
import Investors from './fieldContainer/investors';
import Statistics from './fieldContainer/statistics';
import Competitors from './fieldContainer/competitors';
import Contacts from './fieldContainer/contacts';
import Metodology from './fieldContainer/metodology';
import Downloadable from './fieldContainer/downloadable';
import Mindmap from './fieldContainer/mindmap';
import PortfolioConstructor from './fieldContainer/portfolioConstructor';
import Comparison from './fieldContainer/comparison';


const RenderField = ({ type, industry, location, continent, sector }) => {
    const [dashboard, setDashboard] = React.useState();

    React.useEffect(() => {
        if (industry === 'SpaceTech') {
            setDashboard('spacetech-dashboard')
        }
    }, [industry])


    const renderField = (type) => {
        switch (type) {
            case 'DigitalMain':
                return (
                    <DigitalMain sector={sector} location={location} />
                );
            case 'Downloadable':
                return (
                    <Downloadable />
                );
            case 'Mindmaps':
                return (
                    <Mindmap industry={industry} />
                );
            case 'Companies':
                return (
                    <Companies industry={industry} dashboard={dashboard} location={location} continent={continent} sector={sector}  />
                );
            case 'Investors':
                return (
                    <Investors industry={industry} dashboard={dashboard} location={location} continent={continent} sector={sector} />
                );
            case 'Chart Builder':
                return (
                    <Statistics dashboard={dashboard} industry={industry} />
                );
            case 'Competitors':
                return (
                    <Competitors dashboard={dashboard} />
                );
            case 'Contacts':
                return (
                    <Contacts />
                );
            case 'Metodology':
                return (
                    <Metodology />
                )
            case 'Portfolio Constructor':
                return (
                    <PortfolioConstructor />
                )
            case 'Comparison':
                return (
                    <Comparison industry={industry} location={location} />
                )
            default:
                break;
        }
    }

    return (
        <Box >
            {renderField(type)}
        </Box>
    )
}

export default RenderField;