import React from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layouts';


import PersonIcon from '@mui/icons-material/Person';
import GridViewIcon from '@mui/icons-material/GridView';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import targetIcon from '../../../assets/svg/targetNew.svg'

import RenderField from './renderField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const items = {
    menuItem: [
        {
            urlMenu: '',
            title: 'Created Main Dashboard',
            titleLink: 'DigitalMain',
            active: true
        }
    ],
    itemsList: [
        {
            title: 'Data',
            links: [{
                urlImg: <WorkOutlineIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Companies',
                href: '#',
                active: false
            }, {
                urlImg: <AttachMoneyIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Investors',
                href: '#',
                active: false
            }, {
                urlImg: <PersonIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Public companies',
                href: '#',
                active: false
            }, {
                urlImg: <img width='25px' src={targetIcon} alt='Funding rounds' />,
                titleLink: 'Funding rounds',
                href: '#',
                active: false
            }]
        },
        {
            title: 'Tools',
            links: [{
                urlImg: <WorkOutlineIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Portfolio Constructor',
                href: '#',
                active: false
            }, {
                urlImg: <img width='25px' src={targetIcon} alt='Predictions' />,
                titleLink: 'Predictions',
                href: '#',
                active: false
            }, {
                urlImg: <ViewColumnIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Competitors',
                href: '#',
                active: false
            }, {
                urlImg: <DnsOutlinedIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Comparative',
                href: '#',
                active: false
            }, {
                urlImg: <ShareOutlinedIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Network Diagrams',
                href: '#',
                active: false
            }, {
                urlImg: <GridViewIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Mindmaps',
                href: '#',
                active: false
            }, {
                urlImg: <BarChartIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Statistics',
                href: '#',
                active: false
            }]
        },]
}

const itemsSpaceTech = {
    itemsList: [
        {
            title: 'Reports',
            links: [{
                urlImg: <DescriptionIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Downloadable',
                href: '#',
                active: false
            }, {
                urlImg: <DescriptionIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Metodology',
                href: '#',
                active: false
            }]
        },
        {
            title: 'Data',
            links: [{
                urlImg: <WorkOutlineIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Companies',
                href: '#',
                active: false
            }, {
                urlImg: <AttachMoneyIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Investors',
                href: '#',
                active: false
            }]
        },
        {
            title: 'Tools',
            links: [{
                urlImg: <BarChartIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Chart Builder',
                href: '#',
                active: false
            }, {
                urlImg: <WorkOutlineIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Portfolio Constructor',
                href: '#',
                active: false
            }, {
                urlImg: <ViewColumnIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Competitors',
                href: '#',
                active: false
            }, {
                urlImg: <DnsOutlinedIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Comparison',
                href: '#',
                active: false
            }, {
                urlImg: <GridViewIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Mindmaps',
                href: '#',
                active: false
            },]
        },
        {
            title: 'none',
            links: [{
                urlImg: <BarChartIcon sx={{ fill: '#445161' }} />,
                titleLink: 'Contacts',
                href: '#',
                active: false
            }]
        }]
}

const continentsData = {
    Asia: {
        data: ["AE", "AF", "BD", "BH", "BN", "BT", "CC", "CN", "HK", "ID", "IL", "IN", "IO", "IQ", "IR", "JO", "JP", "KG", "KP", "KR", "KW", "KZ", "LA", "LB", "LK", "MM", "MN", "MV", "MY", "NP", "OM", "PH", "PK", "PS", "QA", "SA", "SG", "SY", "TH", "TJ", "TL", "TM", "UZ", "VN", "YE"],
        color: "rgba(100, 201, 223, 0.24)",
    },
    Europe: {
        data: ["AD", "AL", "AM", "AT", "AZ", "BA", "BE", "BG", "BY", "CH", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FO", "FR", "GB", "GE", "GR", "HR", "HU", "IE", "IS", "IT", "JE", "LI", "LT", "LU", "LV", "MD", "ME", "MK", "MT", "NL", "NO", "PL", "PT", "RO", "RS", "SE", "SI", "SJ", "SK", "SM", "TR", "VA"],
        color: "rgba(72, 191, 227, 0.24)",
    },
    'United Kingdom': {
        data: ["GB"],
        color: "rgba(72, 191, 227, 0.24)",
    }
}

const CreatedDash = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [industry, setIndustry] = React.useState(searchParams.get('Industry'));
    const [sector, setSector] = React.useState(searchParams.get('Sector'));
    const [continent, setContinent] = React.useState(searchParams.get('Continent'));
    const [location, setLocation] = React.useState()

    const navigate = useNavigate();

    const [title, setTitle] = React.useState([])
    const [itemsAsia, setItemsAsia] = React.useState();
    const [itemsListAsia, setItemsListAsia] = React.useState();
    const [activeLink, setActiveLink] = React.useState('DigitalMain');

    React.useEffect(() => {
        if (!industry && !continent) {
            navigate('/')
        }
    })

    React.useEffect(() => {
        setItemsListAsia(items.menuItem);
        if (industry === 'SpaceTech') {
            setItemsAsia(itemsSpaceTech.itemsList)
        } else {
            setItemsAsia(items.itemsList);
        }
    }, []);
    const handleClickArrowBack = () => {
        navigate('/', { replace: true })
    }

    const handleClickOnLinkMenu = (item) => {
        const arrItems = itemsListAsia;

        const arrItemsAsia = itemsAsia;
        arrItemsAsia.forEach(i => {
            i.links.forEach(k => {
                k.active = false;
            })
        });

        arrItems.forEach(i => {
            if (i.titleLink == item) {
                i.active = true;
                setActiveLink(item)
            }
        });

        setItemsAsia([...arrItemsAsia])
        setItemsListAsia([...arrItems])
    }

    const handleClickOnLink = (item) => {
        const arrItems = itemsAsia;
        arrItems.forEach(i => {
            i.links.forEach(k => {
                k.active = false;
            })
        });

        arrItems.forEach(i => {
            i.links.forEach(k => {
                if (k.titleLink == item.titleLink) {
                    k.active = true;
                    setActiveLink(item.titleLink)
                }
            })
        });

        setItemsAsia([...arrItems])
    }

    React.useEffect(() => {
        if (continent === 'undefined' || continent === 'Global') {
            setContinent(undefined);
        }
        if (industry === 'undefined') {
            setIndustry(undefined);
        }
        if (sector === 'undefined') {
            setSector(undefined)
        } else {
            setSector(sector.split(','))
        }
    }, [])

    React.useEffect(() => {
        if (continent !== 'undefined' && continent !== 'Global' && continent) {
            setLocation(continentsData[continent].data)
        }
    }, [continent])

    return (
        <Box sx={{ minHeight: '100vh' }}>
            {!industry && !continent ? '' : <Helmet>
                <title>
                    Created Dashboard&nbsp;
                    {
                        industry ?
                            continent ? `${industry} industry in ${continent}` : `${industry} industry`
                            : continent
                    }
                </title>
            </Helmet>}
            <MainLayout
                handleClickOnLink={handleClickOnLink}
                handleClickOnLinkMenu={handleClickOnLinkMenu}
                items={itemsAsia}
                itemsListAsia={itemsListAsia}
                title={title}
                continent={continent}
                industry={industry}
                setActiveLink={setActiveLink}
                boolCovidDash={false}
                createdDash={true}
                handleClickArrowBack={handleClickArrowBack} >
                <RenderField type={activeLink} industry={industry} location={location} continent={continent} sector={sector} />
            </MainLayout>
        </Box>
    )
}

export default CreatedDash;