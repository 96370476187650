import { Box, Checkbox, Grid, List, ListItem, styled, Typography } from "@mui/material"
import React from "react";


const BpIcon = styled('span')(({ theme }) => ({
    border: '1px #fff solid',
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,#8ab1ff,hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    border: '1px #fff solid',
    borderRadius: '50%',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

const SectorsRectangle = ({
    checkState,
    setCheckState,
    selectIndustry,
    sectorDataArr,
    sectorIndustryDataArr,
    setSelectSector,
    sectorArr,
    selectSector }) => {
    const chekbox = {}

    sectorDataArr.forEach(el => {
        chekbox[el] = true;
    })

    const [heigthBlock, setHeigthBlock] = React.useState(150)


    const boxRectangle = React.useRef();

    React.useEffect(() => {
        const boxWidth = ((boxRectangle.current.offsetWidth) / 3);
        const boxHeight = boxRectangle.current.offsetHeight / Math.ceil(sectorDataArr.length / 3)
        setHeigthBlock(boxHeight)
        sectorDataArr.forEach(el => {
            chekbox[el] = true;
        })
        setCheckState(chekbox)
    }, [selectIndustry, heigthBlock])

    const clickCheckboxHandle = (el) => {
        const check = checkState;
        if (check[el] === true) {
            if (selectSector.length > 1) {
                check[el] = false
                sectorArr.forEach((k, keyk) => {
                    if (k === el)
                        sectorArr.splice(keyk, 1);
                })
            }
        } else {
            sectorArr.push(el);
            check[el] = true
        }
        setCheckState({ ...check });

        setSelectSector([...sectorArr])
    }
    const label = { inputProps: { 'aria-label': 'Checkbox' } };

    return (
        <Box ref={boxRectangle} sx={{ cursor: 'pointer', height: '100%', display: 'flex', flexWrap: 'wrap' }}>
            {console.log(sectorDataArr.length % 3)}
            <Grid container spacing={1}
                sx={{
                    height: `calc(${heigthBlock}px + 1rem)`,
                    '.MuiGrid-item:last-child': {
                        maxWidth: sectorDataArr.length % 3 === 0 ? '33.33333%' : sectorDataArr.length % 3 === 2 ? '50%' : '100%',
                        flexBasis: sectorDataArr.length % 3 === 0 ? '33.33333%' : sectorDataArr.length % 3 === 2 ? '50%' : '100%',
                    }
                }}>
                {sectorDataArr && sectorDataArr.map((i, key) => (
                    <Grid item xs={(sectorDataArr.length / 3 - Math.floor(sectorDataArr.length / 3) > 0.5) && key >= sectorDataArr.length - 2 ? 6 : (sectorDataArr.length / 3 - Math.floor(sectorDataArr.length / 3) < 0.5) && sectorDataArr.length / 3 - Math.floor(sectorDataArr.length / 3) >= sectorDataArr.length - 1 ? 12 : 4} key={i}>
                        <Box sx={{
                            p: '1rem',
                            background: checkState[i] ? 'linear-gradient(#25A18E, #1F8777)' : 'linear-gradient(#404C6B, #343E57)',
                            height: 'calc(100% - 2rem)'
                        }}
                            onClick={() => clickCheckboxHandle(i)}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{
                                    overflow: 'hidden',
                                    lineHeight: '1',
                                    fontSize: { md: '0.8rem', xl: '1rem' },
                                    pb: '5px'
                                }}>{i}</Typography>
                                <Checkbox
                                    checkedIcon={<BpCheckedIcon />}
                                    icon={<BpIcon />} {...label}
                                    sx={{
                                        '.Mui-checked': {
                                            color: '#fff',
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                    checked={checkState[i]}
                                    name={i} />

                            </Box>
                            <Box sx={{
                                height: 'calc(100% - 2rem)',
                                overflow: 'hidden'
                            }}>
                                {/* <Typography sx={{
                                    overflow: 'hidden',
                                    lineHeight: '1',
                                    pb: '5px'
                                }}>{i}</Typography> */}
                                <List disablePadding
                                    sx={{
                                        p: '0 10px',
                                        height: '80%',
                                        '& li': {
                                            listStyleType: '"–"',
                                            listStylePosition: 'outside',
                                            listStyleImage: 'none',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            opacity: '0.8',
                                            textAlign: 'left',
                                            // p: sectorIndustryDataArr[i].length <= 3 ? '0.4rem 0' : '0'
                                        },
                                    }} >
                                    {sectorIndustryDataArr[i] && sectorIndustryDataArr[i].map(k => (
                                        <li key={k}>
                                            {k}
                                        </li>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default SectorsRectangle;