import React from 'react';
import { Box } from '@mui/material';
import DigitalMain from './fieldContainer/digitalMain';
import Companies from './fieldContainer/companies';
import Investors from './fieldContainer/investors';
import Statistics from './fieldContainer/statistics';
import Competitors from './fieldContainer/competitors';
import Contacts from './fieldContainer/contacts';
import Metodology from './fieldContainer/metodology';
import Downloadable from './fieldContainer/downloadable';
import Mindmap from './fieldContainer/mindmap';
import PortfolioConstructor from './fieldContainer/portfolioConstructor';
import Comparison from './fieldContainer/comparison';
import VenturePartners from './fieldContainer/venturePartners';


const RenderField = ({ type, industry, location }) => {
    const [dashboard, setDashboard] = React.useState();

    React.useEffect(() => {
        if (industry === 'SpaceTech') {
            setDashboard('spacetech-dashboard')
        }
    }, [industry])


    const renderField = (type) => {
        switch (type) {
            case 'DigitalMain':
                return (
                    <DigitalMain />
                );
            case 'Downloadable':
                return (
                    <Downloadable />
                );
            case 'Mindmaps':
                return (
                    <Mindmap />
                );
            case 'Companies':
                return (
                    <Companies industry={industry} dashboard={dashboard} location={location} />
                );
            case 'Investors':
                return (
                    <Investors dashboard={dashboard} />
                );
            case 'Venture Partners':
                return (
                    <VenturePartners />
                )
            case 'Chart Builder':
                return (
                    <Statistics />
                );
            case 'Competitors':
                return (
                    <Competitors />
                );
            case 'Contacts':
                return (
                    <Contacts />
                );
            case 'Metodology':
                return (
                    <Metodology />
                )
            case 'Portfolio Constructor':
                return (
                    <PortfolioConstructor />
                )
            case 'Comparison':
                return (
                    <Comparison industry={industry} location={location} />
                )
            default:
                break;
        }
    }

    return (
        <Box >
            {renderField(type)}
        </Box>
    )
}

export default RenderField;