import { Box } from '@mui/material';
import React, { Suspense } from 'react';


const RenderField = (type) => {

    const renderField = (type) => {
        switch (type.type) {
            case 'AsiaDigitalMain':
                const AsiaDigitalMain = React.lazy( () => import('./fieldConteiner/asiaDigitalMain'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <AsiaDigitalMain/>
                    </Suspense>
                );
            case 'Downloadable':
                const Downloadable = React.lazy( () => import('./fieldConteiner/downloadable'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Downloadable/>
                    </Suspense>
                );
            case 'Mindmaps':
                const Mindmaps = React.lazy( () => import('./fieldConteiner/mindmaps'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Mindmaps/>
                    </Suspense>
                );
            case 'Companies':
                const Companies = React.lazy( () => import('./fieldConteiner/companies'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Companies/>
                    </Suspense>
                );
            case 'Investors':
                const Investors = React.lazy( () => import('./fieldConteiner/investors'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Investors/>
                    </Suspense>
                );
            case 'Financial Deals':
                const FinancialDeals = React.lazy( () => import('./fieldConteiner/financialDeals'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <FinancialDeals/>
                    </Suspense>
                );
            case 'Statistics':
                const Statistics = React.lazy( () => import('./fieldConteiner/statistics'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Statistics/>
                    </Suspense>
                );
            case 'Competitors':
                const Competitors = React.lazy( () => import('./fieldConteiner/competitors'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Competitors/>
                    </Suspense>
                );
            case 'Contacts':
                const Contacts = React.lazy( () => import('./fieldConteiner/contacts'));
                return (
                    <Suspense fallback={<div> ...loading</div>}>
                        <Contacts/>
                    </Suspense>
                );
            default:
                break;
        }
    }

    return (
        <Box sx={{pt: '0.5rem'}} >
            {renderField(type)}
        </Box>
    )
}

export default RenderField;