import { Box } from "@mui/material"
import React from "react";
import Chart from 'react-apexcharts';

const SectorGraph = ({ data }) => {

    const chart = React.useRef()
    const [series, setSeries] = React.useState([2, 2, 2,])
    const [options, setOptions] = React.useState({
        legend: {
            show: false,
            position: 'bottom'
        }
    })

    React.useEffect(() => {
        let graphData = [];
        let labels = [];
        if (data) {
            data.forEach(el => {
                graphData.push(el.value)
                labels.push(el.country)
            });
        }
        setSeries([...graphData])
        setOptions({
            labels: labels,
            legend: {
                show: true,
                position: 'right',
                fontSize: '10px',
                width: '30px',
                labels: {
                    colors: '#FFF',
                }
            },
            stroke: {
                show: true,
                colors: ['#9104FF', '#D82992', '#77B529', '#DCCA27', '#A14400', '#0743F5']
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                colors: ['#9104FF33', '#D8299233', '#77B52933', '#DCCA2733', '#A1440033', '#0743F533']
            }
        })
    }, [data])


    return (
        <Box sx={{maxHeight: {md: '194px', xl: '264px'}, minHeight: {md: '194px', xl: '264px'}}}>
            <Chart ref={chart} options={options} series={series} type="pie" height={ window.innerWidth < 1536 ? 200 : 265} />
        </Box>
    )
}


export default SectorGraph;