import { Grid } from "@mui/material";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import DeepKnowledgeAnalytics from './deepKnowledgeAnalytics';
import SpaceTechAnalytics from './spaceTechAnalytics';
import Footer from './footer';

const DeepAnalytics = () => {

    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        fontSize: "14px",
        padding: '8px 12px',
        height: '36px',
        fontFamily: "Montserrat",
        backgroundColor: "#5383D4",
        '&:hover': {
          backgroundColor: "#5383D4",
        },
      }));

    return(
        <Grid item container xs={12} >
            <DeepKnowledgeAnalytics ColorButton={ColorButton}/>
            <SpaceTechAnalytics ColorButton={ColorButton}/>
        </Grid>
    )
}

export default DeepAnalytics;