
import { createTheme } from '@mui/material/styles';
const theme = createTheme({
    palette: {
      background: {
        paper: '#283046',
      },
      primary: {
        main: 'rgb(64, 122, 214)',
        borderColor: 'rgb(64, 122, 214)'
      },
      secondary: {
        light: '#0066ff',
        main: '#7367F0',
        contrastText: '#ffcc00',
      },
    },
    typography: {
      color: '#d0d2d6',
      fontFamily: [
        'Montserrat',
      ].join(','),
    },
  });
export default theme