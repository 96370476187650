import { Box, Grid, Typography } from '@mui/material';

const Parameters = ({ parametersDataState, megadash }) => {



    return (
        <Grid container spacing={1}>
            {parametersDataState && parametersDataState.map(i => (
                <Grid sx={{ justifyContent: 'center' }} key={i.titleParam} item xs={12} md={megadash ? 2 : 6} xl={2}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }} key={i.titleParam}>
                        <Box sx={{
                            width: { xs: megadash ? 'auto' : '50%', xl: 'auto' },
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                borderRadius: '50%',
                                background: i.color,
                                width: '48px',
                                height: '48px',
                                mr: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#ea5455'
                            }}>
                                {i.icon}
                            </Box>
                            <Box sx={{ pl: '0.6rem', verticalAlign: 'center' }}>
                                <Typography sx={{
                                    fontWeight: '600',
                                    lineHeight: '.7',
                                    paddingTop: '8.6px'
                                }}>{i.counts}</Typography>
                                <Typography sx={{ fontSize: '0.9rem', whiteSpace: 'nowrap' }}>{i.titleParam}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            ))
            }
        </Grid >
    )
}


export default Parameters;