import { MenuItem, Select } from "@mui/material";

const SelectBlock = ({ country, countrySelect, handleCountryChange }) => {

  return (
    <Select
      defaultValue=""
      color="secondary"
      sx={{
        "& .MuiMenuItem-root": { background: "#283046" },
        width: "100%",
        color: "#b4b7bd",
        background: "#283046",
      }}
      value={countrySelect}
      onChange={handleCountryChange}
      name="country"
      inputProps={{ "aria-label": "Without label" }}
    >
      {country &&
        country.map((i) => (
          <MenuItem
            key={i}
            sx={{
              width: "100%",
              color: "#b4b7bd",
              background: "#283046",
              ".MuiList-root": { backgroundColor: "#1a2646" },
              "&:hover": { backgroundColor: "#1a2646" },
              "& .Mui-selected": {
                backgroundColor: "rgb(0, 160, 250)",
              },
            }}
            value={i}
          >
            {i}
          </MenuItem>
        ))}
    </Select>
  );
};

export default SelectBlock;