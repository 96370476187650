import React from "react";
import { Grid } from "@mui/material";
import OneCategoryBlock from './oneCategoryBlock';
import dataImmunizationDPT from "../../../../../assets/json/fivePagesCategories/vaccinationRate/immunizationDPT.json";
import dataImmunizationHepB3 from "../../../../../assets/json/fivePagesCategories/vaccinationRate/immunizationHepB3.json";
import dataImmunizationMeasles from "../../../../../assets/json/fivePagesCategories/vaccinationRate/immunizationMeasles.json";
import dataMortalityFromCVD from "../../../../../assets/json/fivePagesCategories/vaccinationRate/mortalityFromCVD.json";
import dataPrevalenceOfHIVTotal from "../../../../../assets/json/fivePagesCategories/vaccinationRate/prevalenceOfHIVTotal.json";
import dataProbabilityOfDying from "../../../../../assets/json/fivePagesCategories/vaccinationRate/probabilityOfDying.json";
import dataAirTransportPassengersCarried from "../../../../../assets/json/fivePagesCategories/quarantineEfficiency/airTransportPassengersCarried.json";
import dataGiniIndex from "../../../../../assets/json/fivePagesCategories/quarantineEfficiency/giniIndex.json";
import dataHospitalBeds from "../../../../../assets/json/fivePagesCategories/quarantineEfficiency/hospitalBeds.json";
import dataPopulationDensity from "../../../../../assets/json/fivePagesCategories/quarantineEfficiency/populationDensity.json";
import dataPopulationInUrbanAgglomerations from "../../../../../assets/json/fivePagesCategories/quarantineEfficiency/populationInUrbanAgglomerations.json";
import dataStringencyIndex from "../../../../../assets/json/fivePagesCategories/quarantineEfficiency/stringencyIndex.json";
import dataCurrentHealthExpenditurePerCapita from "../../../../../assets/json/fivePagesCategories/healthcareManagement/currentHealthExpenditurePerCapita.json";
import dataGhsIndexScore from "../../../../../assets/json/fivePagesCategories/healthcareManagement/ghsIndexScore.json";
import dataHealthExpenditure from "../../../../../assets/json/fivePagesCategories/healthcareManagement/healthExpenditure.json";
import dataLifeExpectancy from "../../../../../assets/json/fivePagesCategories/healthcareManagement/lifeExpectancy.json";
import dataMedianPopulationAge from "../../../../../assets/json/fivePagesCategories/healthcareManagement/medianPopulationAge.json";
import dataSizeOfElderlyPopulation from "../../../../../assets/json/fivePagesCategories/healthcareManagement/sizeOfElderlyPopulation.json";
import dataEaseOfDoingBusiness from "../../../../../assets/json/fivePagesCategories/economyResilience/easeOfDoingBusiness.json";
import dataGdpGrowthAnnualized from "../../../../../assets/json/fivePagesCategories/economyResilience/gdpGrowthAnnualized.json";
import dataGdpPerCapita from "../../../../../assets/json/fivePagesCategories/economyResilience/gdpPerCapita.json";
import dataGlobalInnovationIndex from "../../../../../assets/json/fivePagesCategories/economyResilience/globalInnovationIndex.json";
import dataHumanCapitalIndex from "../../../../../assets/json/fivePagesCategories/economyResilience/humanCapitalIndex.json";
import dataInflationRate from "../../../../../assets/json/fivePagesCategories/economyResilience/inflationRate.json";
import dataCorruptionIndexScore from "../../../../../assets/json/fivePagesCategories/governmentEfficiency/corruptionIndexScore.json";
import dataEGovernmentDevelopmentIndex from "../../../../../assets/json/fivePagesCategories/governmentEfficiency/eGovernmentDevelopmentIndex.json";
import dataEParticipationIndex from "../../../../../assets/json/fivePagesCategories/governmentEfficiency/eParticipationIndex.json";
import dataGovernmentEffectiveness from "../../../../../assets/json/fivePagesCategories/governmentEfficiency/governmentEffectiveness.json";
import dataLiberalDemocracyIndex from "../../../../../assets/json/fivePagesCategories/governmentEfficiency/liberalDemocracyIndex.json";
import dataSustainableDevelopmentGoals from "../../../../../assets/json/fivePagesCategories/governmentEfficiency/sustainableDevelopmentGoals.json";

const SixCategoriesBlock = ({pageName, iso3Select, setSelectedCategory}) => {

  const [selectBorders, setSelectBorders] = React.useState([1,0,0,0,0,0]);

  let names = [];
  let data = [];

  if(pageName === "Economy Resilience"){
    names = ["GDP Growth Annualized", "GDP per capita", "Inflation Rate", "Ease of Doing Business", "Global Innovation Index", "Human Capital Index"]
    data = [dataGdpGrowthAnnualized, dataGdpPerCapita, dataInflationRate, dataEaseOfDoingBusiness, dataGlobalInnovationIndex, dataHumanCapitalIndex];
  }
  else if(pageName === "Healthcare Management"){
    names = ["Current health expenditure per capita", "Health Expenditure", "Median Population Age", "Size Of Elderly Population", "Life Expectancy", "GHS Index Score"]
    data = [dataCurrentHealthExpenditurePerCapita, dataHealthExpenditure, dataMedianPopulationAge, dataSizeOfElderlyPopulation, dataLifeExpectancy, dataGhsIndexScore];
  }
  else if(pageName === "Government Efficiency"){
    names = ["Government effectiveness", "Liberal Democracy Index", "E-Government Development Index", "E-Participation Index", "Corruption Index Score", "Sustainable Development Goals"]
    data = [dataGovernmentEffectiveness, dataLiberalDemocracyIndex, dataEGovernmentDevelopmentIndex, dataEParticipationIndex, dataCorruptionIndexScore, dataSustainableDevelopmentGoals];
  }
  else if(pageName === "Quarantine Efficiency"){
    names = ["Population Density", "Stringency Index", "Air transport, passengers carried", "Hospital beds", "Gini index", "Population in urban agglomerations"]
    data = [dataPopulationDensity, dataStringencyIndex, dataAirTransportPassengersCarried, dataHospitalBeds, dataGiniIndex, dataPopulationInUrbanAgglomerations, ];
  }
  else if(pageName === "Vaccination Rate"){
    names = ["Immunization, DPT", "Prevalence of HIV, total", "Immunization, HepB3", "Immunization, measles", "Mortality from CVD, cancer, diabetes or CRD", "Probability of dying among youth ages"];
    data = [dataImmunizationDPT, dataPrevalenceOfHIVTotal, dataImmunizationHepB3, dataImmunizationMeasles, dataMortalityFromCVD, dataProbabilityOfDying];
  }

  let colors = ["#7B61FF", "#FF9F43", "#00CFE8", "#2A99FF", "#DB6AB5", "#28C7AA"];

  let selectedData = [];
  let check = false;
  let buf = {};
  
  data.forEach((i) => {
    buf = {};
    check = false;
    i.forEach((j) => {
      if (j["Country Code"] === iso3Select) {
        check = true;
        buf = j;
      }
    });
    if (check === true){
      selectedData.push(buf);
    }
    else
    {
      selectedData.push("");
    }
  });

  let values = [];
  let series = [];

  selectedData.forEach((i) => {
    let keys = Object.keys(i);
    if (i[keys[keys.length - 1]]){
      values.push(i[keys[keys.length - 1]])
    }
    else{
      values.push("")
    }
    let seriesTemp = "";
    let num = 4;
    if (keys.length !== 0){
      while (num <= keys.length && seriesTemp === "") { 
        seriesTemp = i[keys[keys.length - num]]
        num = num + 1;
      }
    }
    if (seriesTemp){
      series.push(seriesTemp)
    }
    else{
      series.push("")
    }
  });
  

  React.useEffect(() => {
    setSelectedCategory({
      name: names[0],
      data: selectedData[0]
    })
    setSelectBorders([1,0,0,0,0,0])
  }, [iso3Select]);

  let borders = [1,0,0,0,0,0];

  const handleClick = (i) => {
    setSelectedCategory({
      name: names[i],
      data: selectedData[i]
    })
  
    for (let j = 0; j <= 5; j++) { 
      if (j === i){
        borders[j] = 1
      }
      else{
        borders[j] = 0
      }
    }
    setSelectBorders(borders)
  }


  return (
    <Grid item container sx={{ p: {md:"0 0 2rem 2rem", xs: "0"} }} xs={12} md={5.5} >
      <Grid item container xs={12}>
        <Grid item xs={6} sx={{ pr: "1rem" }}>
          <OneCategoryBlock handleClick={handleClick} selectBorders={selectBorders[0]} colors = {colors[0]} names = {names[0]} values = {values[0]} series = {series[0]} i = {0}/>
        </Grid>

        <Grid item xs={6} sx={{ pl: "1rem", pr: "0" }}>
          <OneCategoryBlock handleClick={handleClick} selectBorders={selectBorders[1]} colors = {colors[1]} names = {names[1]} values = {values[1]} series = {series[1]} i = {1}/>
        </Grid>
      </Grid>

      <Grid item container xs={12} sx={{ mt: "1.5rem" }}>
        <Grid item xs={6} sx={{ pr: "1rem" }}>
          <OneCategoryBlock handleClick={handleClick} selectBorders={selectBorders[2]} colors = {colors[2]} names = {names[2]} values = {values[2]} series = {series[2]} i = {2}/>
        </Grid>

        <Grid item xs={6} sx={{ pl: "1rem", pr: "0" }}>
          <OneCategoryBlock handleClick={handleClick} selectBorders={selectBorders[3]} colors = {colors[3]} names = {names[3]} values = {values[3]} series = {series[3]} i = {3}/>
        </Grid>
      </Grid>

      <Grid item container xs={12} sx={{ mt: "1.5rem" }}>
        <Grid item xs={6} sx={{ pr: "1rem" }}>
          <OneCategoryBlock handleClick={handleClick} selectBorders={selectBorders[4]} colors = {colors[4]} names = {names[4]} values = {values[4]} series = {series[4]} i = {4}/>
        </Grid>

        <Grid item xs={6} sx={{ pl: "1rem", pr: "0" }}>
          <OneCategoryBlock handleClick={handleClick} selectBorders={selectBorders[5]} colors = {colors[5]} names = {names[5]} values = {values[5]} series = {series[5]} i = {5}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SixCategoriesBlock;