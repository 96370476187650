import { Box } from "@mui/material";

import Page from "./fivePagesComponents/index"


const QuarantineEfficiency = () => {

    return(
        <Box>
            <Page pageName="Quarantine Efficiency"/>
        </Box>
    )
}

export default QuarantineEfficiency;