import { Avatar, Box, Link, Popover, Typography } from "@mui/material";
import React from "react";
import { api } from "../api/apiAuthorization";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CircleIcon from '@mui/icons-material/Circle';
import { useAuth0 } from '@auth0/auth0-react';


const UserMenu = () => {
    const { user } = useAuth0();
    const { logout } = useAuth0()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userName, setUserName] = React.useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (user) {
            setUserName(user.name)
        };
      });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box>
            <Box aria-describedby={id}
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    cursor: "pointer"
                }}>
                <Typography sx={{ mr: '0.5rem', color: '#d0d2d6' }}>{userName}</Typography>
                <Avatar alt="Avatar" src="" />
                <CircleIcon sx={{
                    position: 'absolute',
                    fill: '#28C76F',
                    right: '0',
                    bottom: '-4px',
                    border: '1px #000 solid',
                    borderRadius: '50%',
                    fontSize: '13px'
                }} />
            </Box>
            <Popover
                sx={{
                    '.MuiPopover-paper': {
                        backgroundColor: '#283046',
                        boxShadow: '0 4px 24px 0 rgb(0 0 0 / 24%)',
                        borderRadius: '10px',
                        zIndex: '1000'
                    }
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '0 1rem',
                    zIndex: '3',
                    cursor: "pointer"
                }} onClick={() => { logout() }}>
                    <PowerSettingsNewIcon sx={{ fill: '#b4b7bd' }} />
                    <Link sx={{ textDecoration: 'none' }}  >
                        <Typography sx={{ p: '0.5rem', color: '#b4b7bd', pr: '2rem' }}>LogOut</Typography>
                    </Link>
                </Box>
            </Popover >
        </Box >
    )
}


export default UserMenu;