import * as React from 'react';
import { Box, Grid, Button, Popover, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import cube from '../../assets/cube.svg';




export default function DropdownDashboard({ itemsListAsia, handleClickOnLinkMenu, activeMenu, spacetech }) {
    const [anchorEl, setAnchorEl] = React.useState(true);

    const useStyles = makeStyles({
        arrowRight: {
            transform: 'rotate(270deg)'
        },
        activeMenuItem: {
            background: 'rgb(30, 41, 58)',
            borderRadius: '4px',
            margin: spacetech ? '' : '0 1rem',
            padding: '10px',
            alignItems: 'center'
        }
    });

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(!anchorEl);
    };

    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                backgroundColor: spacetech ? 'rgb(35, 48, 68)' : '#0C102A',
                borderRadius: spacetech ? '' : '6px',
                margin: spacetech ? '' : '1rem',
                cursor: 'pointer',
                justifyContent: 'space-between',
                transition: 'padding-left 0.25s ease-in-out',
                '&:hover': {
                    p: '10px 10px 10px 15px',
                    transition: 'padding-left 0.25s ease-in-out',
                    backgroundColor: spacetech ? 'rgba(0, 0, 0, 0.08)' : ''
                },
                pl: activeMenu ? '' : '22%'
            }}
                onClick={handleClick}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: activeMenu ? 'left' : 'center' }}>
                    <HomeIcon sx={{ fill: '#fff', mr: { md: '5px', xl: '15px' } }} />
                    {activeMenu && <Typography className='App' sx={{ color: '#b4b7bd', lineHeight: '1.45rem' }}>Dashboards</Typography>}
                </Box>
                {activeMenu && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ borderRadius: '50%', background: 'rgba(107, 104, 222, 0.25)', mr: '5px' }}>
                        <Typography sx={{ padding: '2px 9px', fontSize: '.9rem', color: '#fff', ml: '-2px' }}>{itemsListAsia && itemsListAsia.length}</Typography>
                    </Box>
                    <KeyboardArrowDownIcon className={anchorEl == false ? classes.arrowRight : ''} sx={{ fill: '#d0d2d6', fontSize: '21px' }} />
                </Box>}
            </Box>
            {anchorEl ?
                itemsListAsia && itemsListAsia.map(i => (
                    <Link key={i.title} sx={{ textDecoration: 'none' }}
                        onClick={() => handleClickOnLinkMenu(i)}
                        href='#'>
                        <Box className={i.active ? classes.activeMenuItem : ''} sx={{
                            display: 'flex',
                            borderRadius: '4px',
                            margin: spacetech ? '0' : '0 1rem',
                            padding: '10px',
                            alignItems: 'center',
                            transition: 'padding-left 0.25s ease-in-out',
                            '&:hover': {
                                p: '10px 15px',
                                transition: 'padding-left 0.25s ease-in-out',
                                backgroundColor: spacetech ? 'rgba(0, 0, 0, 0.08)' : ''
                            },
                            justifyContent: activeMenu ? 'left' : 'center'
                        }}>
                            <Box>
                                <img src={cube} />
                            </Box>
                            {activeMenu && <Typography sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                ml: '10px',
                                color: '#fff'
                            }}>{i.title}</Typography>}
                        </Box>
                    </Link>
                )) : ''
            }
        </div>
    );
}