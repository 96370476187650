import React from "react";
import {
    Box,
    Button,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles'
import DateAdapter from '@mui/lab/AdapterDateFns';
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider, DatePicker } from '@mui/lab';

import DialogBox from "../../../../dialog";
import { countries } from "country-data-list";


const useStyles = makeStyles((theme) => createStyles({
    datePicker: {
        '& .MuiCalendarPicker-root': {
            backgroundColor: '#283046 !important',
            color: '#d0d2d6 !important'
        },
        '& .MuiTypography-root': {
            color: '#d0d2d6 !important'
        },
        '& .MuiPickersDay-root': {
            color: '#d0d2d6 !important'
        },
        '& .MuiButtonBase-root': {
            color: '#615AC8'
        },
        '& .MuiButtonBase-root': {
            color: '#d0d2d6 !important'
        },
        '& .MuiSvgIcon-root': {
            color: '#d0d2d6 !important'
        },
    },
    Dialog: {
        '& .MuiDialog-paperFullScreen': {
            boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
        }
    }

}));


const DialogsFilters = ({
    handleClose,
    setFilterData,
    covidDataDate,
    selectCountry,
    setSelectCountry,
    setLocation
}) => {

    const [valueDataPicker, setValueDataPicker] = React.useState(null);
    const [valueDataPickerEnd, setValueDataPickerEnd] = React.useState(new Date());
    const [minDatePickerOne, setMinDatePickerOne] = React.useState(new Date('2020-01-22'))
    const [maxDate, setMaxDate] = React.useState();
    const [minDate, setMinDate] = React.useState();
    const [showAs, setShowAs] = React.useState('person');


    React.useEffect(() => {
        if (new Date(valueDataPickerEnd) > new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)) {
            setMaxDate(new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 1))
        } else {
            setMaxDate(new Date(valueDataPickerEnd))
        }
        setMinDate(valueDataPicker)
    }, [valueDataPickerEnd, valueDataPicker]);

    React.useEffect(() => {
        setFilterData({ showAs: showAs, startDate: valueDataPicker, endDate: valueDataPickerEnd })
        if (covidDataDate) {
            setValueDataPicker(new Date(covidDataDate[0].date))
        }
    }, [covidDataDate])

    const handleCountryChange = (event) => {
        setSelectCountry(event.target.value);
        setLocation(event.target.value);
    }

    const handleShowFiltredData = () => {
        setFilterData({ showAs: showAs, startDate: valueDataPicker, endDate: valueDataPickerEnd })
    }

    const handleRadioChange = (event) => {
        setShowAs(event.target.value)
    }

    const { datePicker } = useStyles();

    return (
        <Box>
            <DialogBox title='Filters'
                handleClose={handleClose}>
                <Box>
                    <Typography sx={{ color: '#d0d2d6' }}>Show as:</Typography>
                    <RadioGroup
                        sx={{ display: 'flex', flexDirection: 'row' }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="showAs"
                        color="secondary"
                        value={showAs}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel sx={{ color: '#6E6B7B' }}
                            value="person" color="secondary"
                            control={<Radio color="secondary" />}
                            label="Persons" />
                        <FormControlLabel sx={{ color: '#6E6B7B' }}
                            value="persent" color="secondary"
                            control={<Radio color="secondary" />}
                            label="Percent" />
                    </RadioGroup>
                </Box>
                <Box sx={{ p: '1rem 0' }}>
                    <Typography sx={{ color: '#d0d2d6', pb: '1rem' }}>Date range:</Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ p: '0 1rem 0 0' }}>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DatePicker
                                    label="From"
                                    color="secondary"
                                    name='start'
                                    maxDate={maxDate}
                                    minDate={minDatePickerOne}
                                    value={valueDataPicker}
                                    onChange={(newValue) => {
                                        setValueDataPicker(newValue);
                                    }}
                                    DialogProps={{ className: datePicker }}
                                    renderInput={(params) => <TextField
                                        color="secondary"
                                        sx={{
                                            svg: { color: '#d0d2d6' },
                                            input: { color: '#d0d2d6' },
                                            label: { color: '#d0d2d6' }
                                        }} {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DatePicker
                                    label="To"
                                    color="secondary"
                                    name='end'
                                    maxDate={new Date()}
                                    minDate={minDate}
                                    value={valueDataPickerEnd}
                                    DialogProps={{ className: datePicker }}
                                    onChange={(newValue) => {
                                        setValueDataPickerEnd(newValue);
                                    }}
                                    renderInput={(params) => <TextField
                                        color="secondary" sx={{
                                            svg: { color: '#d0d2d6' },
                                            input: { color: '#d0d2d6' },
                                            label: { color: '#d0d2d6' }
                                        }} {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ p: '0 0 1rem 0' }}>
                    <Select
                        displayEmpty
                        color="secondary"
                        sx={{
                            '& .MuiMenuItem-root': { background: '#283046' },
                            width: '100%',
                            color: '#b4b7bd',
                            background: '#283046',
                        }}
                        value={selectCountry}
                        onChange={handleCountryChange}
                        name='industry'
                        inputProps={{ "aria-label": "Without label" }}>
                        <MenuItem
                            sx={{
                                width: '100%',
                                color: '#b4b7bd',
                                background: '#283046',
                                '.MuiList-root': { backgroundColor: '#1a2646' },
                                '&:hover': { backgroundColor: '#1a2646' },
                                '& .Mui-selected': { backgroundColor: 'rgb(0, 160, 250)' }
                            }} disabled value="OWID_WRL">Select Country</MenuItem>
                        {countries && countries.all.map(i => (
                            <MenuItem key={i.name} sx={{
                                width: '100%',
                                color: '#b4b7bd',
                                background: '#283046',
                                '.MuiList-root': { backgroundColor: '#1a2646' },
                                '&:hover': { backgroundColor: '#1a2646' },
                                '& .Mui-selected': { backgroundColor: 'rgb(0, 160, 250)' }
                            }} value={i.alpha3}>{i.name}</MenuItem>
                        ))}
                    </Select>
                </Box>
                <Button sx={{
                    background: '#615AC8',
                    color: '#d0d2d6',
                    '&:hover': { background: '#615ac87b' }
                }}
                    onClick={() => {
                        handleShowFiltredData()
                        handleClose()
                    }}>
                    Show results
                </Button>
            </DialogBox>
        </Box>
    )
}

export default DialogsFilters;