import axios from 'axios';
import qs from "qs";

let urlBase = `${process.env.REACT_APP_URL_NEW}`
if (process.env.REACT_APP_URL.indexOf(window.location.host) != -1) {
    urlBase = `${process.env.REACT_APP_URL}`
}
const instance = axios.create({
    baseURL: urlBase,
});

export const api = {
    getPartfolioTickers: async ({ dashboard }) => {
        return (
            await instance.get('/dashboards/api/get-portolio-tickers/', {
                params: { dashboard, requ: 'app' },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                },
            })
        ).data;
    },
    getChartAnalysis: async ({ tickers }) => {
        return (
            await instance.get('/dashboards/api/chart-analysis/', {
                params: { tickers, requ: 'app' },
            })
        ).data;
    },
    getInvestmentHistoricalData: async ({ ticker, purchaseDate, sellDate }) => {
        return (
            await instance.get('/dashboards/api/investment/historical_data/', {
                params: { ticker, purchaseDate, sellDate, requ: 'app' },
            })
        ).data;
    },
    getInvestment: async ({ tickers, purchaseDate, sellDate, portfolio_type, amount, risk_percentage, profit_percentage, custom_weights, ef }) => {
        console.log(purchaseDate, sellDate,  tickers, portfolio_type)
        return (
            await instance.get('/dashboards/api/investment/', {
                params: { tickers, purchaseDate, sellDate, portfolio_type, amount, risk_percentage, profit_percentage, custom_weights, ef, requ: 'app' },
            })
        ).data;
    },
    getVenturePartners: async () => {
        return (
            await instance.get('/dashboards/api/get-spacetech-venture-partners', {
                params: { requ: 'app' },
            })
        ).data;
    },
}