import { Box } from "@mui/material";
import BlockChartNew from "../charts/blockChartNew";

const ChartBlock = ({ series, height, colorStroke, toGradientStrokeColor }) => {

    const options = {
        grid: {
            show: false,
        },

        chart: {
            toolbar: {
                show: false,
            },
            height: "100%",
            type: "area",
        },

        dataLabels: {
            enabled: false,
        },

        stroke: {
            show: true,
            colors: [colorStroke],
            curve: "smooth",
            width: 2,
        },

        tooltip: {
            enabled: false,
        },

        fill: {
            colors: [colorStroke],
            type: "gradient",
            gradient: {
                shade: "dark",
                gradientToColors: [toGradientStrokeColor],
                shadeIntensity: 1,
                type: "vertical",
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },

        yaxis: {
            show: false,
            min: 6,
        },

        xaxis: {
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
    };

    return (
        <Box>
            <BlockChartNew options={options} series={series} height={height} />
        </Box>
    );
};

export default ChartBlock;