import { Box, Divider, Grid, Link, Typography } from "@mui/material"
import MainDarkBox from "../../../../mainDarkBox";




const TableVenturePartners = ({ companyList, bgcolor, page }) => {

    console.log(companyList)

    return (
        <Box>
            <Box>
                <MainDarkBox bgcolor={bgcolor} >
                    <Box sx={{ ml: '15px', pb: '30px' }}>
                        <Grid container spacing={2} alignItems="center" sx={{ background: 'rgb(20 33 50)', pb: '10px' }}>
                            <Grid item xs={1}>
                                <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>#</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>COMPANY NAME</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>PARTNER</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={{ color: '#d0d2d6', pr: '10px', fontSize: '0.857rem' }}>ROLE</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ color: '#d0d2d6', pr: '10px', fontSize: '0.857rem' }}>LINKEDIN</Typography>
                            </Grid>
                        </Grid>
                        {companyList && companyList.map((item, key) => (
                            <Box>
                                <Grid key={item.id} container spacing={2} alignItems="center">
                                    <Grid item xs={1}>
                                        <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>{(page * 20) - (19 - key)}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>{item.company}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{ color: '#b4b7bd', mt: '10px', fontSize: '1rem' }}>{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={{ color: '#b4b7bd', mt: '10px', fontSize: '1rem' }}>{item.role}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%',
                                            whiteSpace: 'nowrap',
                                            color: '#b4b7bd',
                                            mt: '10px',
                                            fontSize: '1rem',
                                            textDecoration: 'none'
                                        }}>
                                            <Link href={item.linkedin}
                                                sx={{
                                                    color: 'rgb(64, 122, 214)',
                                                    mt: '10px',
                                                    fontSize: '1rem',
                                                    textDecoration: 'none',
                                                    ':hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}>
                                                {item.linkedin}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {key !== (companyList.length - 1) && <Divider sx={{ background: '#3b4253', m: '10px 0px' }} />}
                            </Box>
                        ))
                        }
                    </Box>
                </MainDarkBox>
            </Box >
        </Box>
    )
}


export default TableVenturePartners;