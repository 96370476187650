import { Box, Dialog, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { api } from "../../../../api/apiChart";
import CompanyTable from "../../../../companies/CompanyTable";
import DialogBox from "../../../../dialog"
import MainDarkBox from "../../../../mainDarkBox";
import PaginationDark from "../../../../pagination";



const DialogCompanies = ({
    setCompanyList,
    companyList,
    getCompanies,
    setPage,
    page,
    handleClose,
    form,
    barClickParams,
    setCompanyDetail,
    setAnchorElDetail,
    barClickParamsLabel,
    barDataSetsParams,
    barDataSetsParamsLabel }) => {

    const handleChange = (event, value) => {
        setPage(value);
        getCompanies()
    };
    const prevPage = () => {
        if (page !== 1) setPage(prev => prev - 1);
        getCompanies(page - 1)
    };
    const nextPage = () => {
        setPage(prev => prev + 1);
        getCompanies(page + 1)
    };
    const firstPage = () => {
        setPage(1);
        let category;
        let params;
        let yearVal,
            fund_revVal,
            fund_lastVal,
            fund_rangeVal,
            countryVal,
            category_29Val,
            staffVal;
        if (barDataSetsParamsLabel) {
            switch (barDataSetsParamsLabel) {
                case 'year':
                    yearVal = barDataSetsParams;
                    break;

                case 'fund_rev':
                    fund_revVal = barDataSetsParams
                    break;

                case 'fund_last':
                    fund_lastVal = barDataSetsParams
                    break;

                case 'fund_range':
                    fund_rangeVal = barDataSetsParams
                    break;

                case 'country':
                    countryVal = barDataSetsParams
                    break;

                case 'staff':
                    staffVal = barDataSetsParams
                    break;

                case 'category_29':
                    category_29Val = barDataSetsParams
                    break;

                default:
            }
        }
        if (barClickParams) {
            category = barClickParams;
        }

        switch (barClickParamsLabel) {
            case 'year':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: category,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: staffVal,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'fund_rev':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    fund_rev: category,
                    year: yearVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    staff: staffVal,
                    category_2: category_29Val,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'fund_last':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: staffVal,
                    fund_last: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'fund_range':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: staffVal,
                    fund_range: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'country':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    staff: staffVal,
                    category_2: category_29Val,
                    country: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'staff':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category_29Val,
                    staff: category,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            case 'category_28':
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_2: category,
                    staff: staffVal,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
                break;

            default:
                params = {
                    dashboard: 'asia',
                    page: 1,
                    per_page: 50,
                    year: yearVal,
                    fund_rev: fund_revVal,
                    fund_last: fund_lastVal,
                    fund_range: fund_rangeVal,
                    country: countryVal,
                    category_1: category,
                    category_2: category_29Val,
                    staff: staffVal,
                    cat1: form.cat1,
                    cat2: form.cat2,
                    mode: form.typeOfValue,
                    country: form.country,
                    q: form.q
                }
        }
        api.companiList(params)
            .then(res => {
                setCompanyList(res)
            })
            .catch(err => {
                console.log(err)
            })
    };

    const handleClickCompany = (slug) => {

        setAnchorElDetail(true)
        api.companiDetails({ slug })
            .then(res => {
                setCompanyDetail(res)
            })
            .catch(err => {
                console.log(err)
            })

    }

    return (
        <Box sx={{ height: '100%' }}>
            <DialogBox
                title={barClickParams}
                handleClose={handleClose}>
                <Box>
                    <MainDarkBox >
                        <Box sx={{ ml: '15px', pb: '30px' }}>
                            <Grid container spacing={2} alignItems="center" sx={{ background: '#343d55', pb: '10px' }}>
                                <Grid item xs={1}>
                                    <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>#</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>COMPANY</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography sx={{ color: '#d0d2d6', fontSize: '0.857rem' }}>DESCRIPTION</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ color: '#d0d2d6', pr: '10px', fontSize: '0.857rem' }}>FOUNDATION YEAR</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ color: '#d0d2d6', pr: '10px', fontSize: '0.857rem' }}>COUNTRY</Typography>
                                </Grid>
                            </Grid>
                            {companyList && companyList.data.map((item, key) => (
                                <Box>
                                    <Grid key={item.id} container spacing={2} alignItems="center">
                                        <Grid item xs={1}>
                                            <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>{50 * (page - 1) + (key + 1)}</Typography>
                                        </Grid>
                                        <Grid sx={{ cursor: 'pointer' }} item xs={2} onClick={() => handleClickCompany(item.slug)}>
                                            <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>{item.title}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography sx={{ color: '#b4b7bd', mt: '10px', fontSize: '1rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.description.length > 15 ? item.description.substring(0, 30) + '...' : item.description}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>
                                                {item.year}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{ color: '#d0d2d6', fontSize: '1rem' }}>
                                                {item.country}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {key !== (companyList.data.length - 1) && <Divider sx={{ background: '#3b4253', m: '10px 0px' }} />}
                                </Box>
                            ))
                            }
                        </Box>
                    </MainDarkBox>
                </Box >
                {companyList && <PaginationDark
                    page={page}
                    count={companyList.pager.last}
                    firstPage={firstPage}
                    prevPage={prevPage}
                    handleChange={handleChange}
                    nextPage={nextPage}
                />}

            </DialogBox>

        </Box>
    )
}

export default DialogCompanies;