import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { api } from "../../../../api/apiChart";
import React from "react";
import MatcingTool from "../../../dashboardAsia/fieldConteiner/companies/matcingTool";


const CompanyInfoPage = ({ setSlug, slug, dashboard }) => {
    const [companyDetails, setCompanyDetails] = React.useState();
    const [fields, setFields] = React.useState([]);
    const [marketing, setMarketing] = React.useState();
    const [legal, setLegal] = React.useState();
    const [financialServices, setFinancialServices] = React.useState();
    const [software, setSoftware] = React.useState();
    const [hardware, setHardware] = React.useState();
    const [progress, setProgress] = React.useState(true);

    React.useEffect(() => {
        console.log(slug)
        if (slug) {
            api.companiDetails({ slug })
                .then((res) => {
                    setCompanyDetails(res)
                    if (res.categories['Industry Groups'])
                        handleMatcingTool(res.categories['Industry Groups'])
                })
                .catch((err) => { console.log(err) })
        }
    }, [slug])

    function generate_matching_list(main_category, list_name) {
        if (!main_category)
            return;

        let filters = {
            dashboard: 'asia',
            section: 'companies',
            per_page: '10',
            sort: '-fund_total',
            category_1: main_category
        };
        switch (list_name) {
            case 'marketing':
                filters['category_2'] = [
                    "Administrative Services",
                    "Advertising",
                    "Clothing and Apparel",
                    "Community and Lifestyle",
                    "Content and Publishing",
                    "Design",
                    "Events",
                    "Professional Services",
                    "Sales and Marketing"
                ];
                break;
            case 'legal':
                filters['category_2'] = 'Legal';
                break;
            case 'financial':
                filters['category_2'] = [
                    "Financial Services",
                    "Payments",
                    "Professional Services"
                ];
                break;
            case 'software':
                filters['category_2'] = [
                    "Apps",
                    "Artificial Intelligence",
                    "Data and Analytics",
                    "Information Technology",
                    "Internet Services",
                    "Mobile",
                    "Navigation and Mapping",
                    "Platforms",
                    "Privacy and Security",
                    "Professional Services",
                    "Software"
                ];
                break;
            case 'hardware':
                filters['category_2'] = [
                    "Consumer Electronics",
                    "Hardware",
                    "Manufacturing"
                ];
                break;
        }
        if (!filters['category_2'])
            return;
        api.companiList({ section: 'companies', dashboard: dashboard ? dashboard : 'spacetech-dashboard', per_page: 10, sort: '-fund_total', category_1: main_category, category_2: filters['category_2'] })
            .then(res => {
                switch (list_name) {
                    case 'marketing':
                        setMarketing(res)
                        break;
                    case 'legal':
                        setLegal(res)
                        break;
                    case 'financial':
                        setFinancialServices(res)
                        break;
                    case 'software':
                        setSoftware(res)
                        break;
                    case 'hardware':
                        setHardware(res)
                        setProgress(false)
                        break;
                }
            })
            .catch(err => {
                console.log(err)
            })
    };
    const handleMatcingTool = (items) => {
        let main_category
        const main_categories_list = [
            "Agriculture and Farming",
            "Biotechnology",
            "Commerce and Shopping",
            "Education",
            "Energy",
            "Financial Services",
            "Food and Beverage",
            "Government and Military",
            "Health Care",
            "Manufacturing",
            "Media and Entertainment",
            "Messaging and Telecommunications",
            "Natural Resources",
            "Real Estate",
            "Science and Engineering",
            "Sustainability",
            "Transportation",
            "Travel and Tourism"
        ];
        main_categories_list.forEach(i => {
            items.forEach(k => {
                if (i == k) main_category = k
            })
        })
        generate_matching_list(main_category, 'marketing');
        generate_matching_list(main_category, 'legal');
        generate_matching_list(main_category, 'financial');
        generate_matching_list(main_category, 'software');
        generate_matching_list(main_category, 'hardware');
    }

    const handleBack = () => {
        setSlug()
    }
    console.log(companyDetails)



    React.useEffect(() => {
        let data = [];
        if (companyDetails) {
            for (let i in companyDetails.fields) {
                data.push(i)
            }
            setFields([...data])
        }
    }, [companyDetails])

    return (
        <Box>
            {companyDetails &&
                <Box sx={{ p: '1rem' }}>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12}>
                            <Typography mb={2} sx={{ color: '#b4b7bd', fontSize: '1.286rem' }}>{companyDetails.title}</Typography>
                            <Box sx={{ border: '1px solid #3b4253', borderRadius: '4px' }}>
                                {fields.length > 0 && fields.map(el => {
                                    if (companyDetails.fields[el].value !== null && companyDetails.fields[el].value) {
                                        let valueFields = companyDetails.fields[el];
                                        valueFields.value += ''
                                        console.log(valueFields.value, valueFields.value.indexOf('http'))
                                        if (el !== 'description' && el !== 'logo') {
                                            if (valueFields.value.indexOf('http') !== -1) {
                                                return (
                                                    <Box key={el}>
                                                        <Divider sx={{ background: '#3b4253' }} />
                                                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                                            <Typography sx={{ color: '#25A18E', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>{valueFields.title}</Typography>
                                                            <Link href={companyDetails.fields[el].value} target="_blank" sx={{ textDecoration: 'none' }}>
                                                                <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{valueFields.value}</Typography>
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                )
                                            } else {
                                                return (
                                                    <Box key={el}>
                                                        <Divider sx={{ background: '#3b4253' }} />
                                                        <Box sx={{ display: 'flex', padding: '0.75rem 1.25rem', '&:hover': { background: '#161d31' } }}>
                                                            <Typography sx={{ color: '#25A18E', fontSize: '14px', mr: '10px', textTransform: 'uppercase' }}>{valueFields.title}</Typography>
                                                            <Typography sx={{ color: '#b4b7bd', fontSize: '14px' }}>{valueFields.value}</Typography>
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        }
                                    }
                                })}
                            </Box>
                            <Typography mt={2} sx={{ color: '#b4b7bd', fontSize: '14px' }}>{companyDetails.fields.description.value}</Typography>
                        </Grid>
                    </Grid>
                </Box>}
        </Box >
    )
}

export default CompanyInfoPage;