import { Box } from "@mui/material";

import Page from "./fivePagesComponents/index"


const GovernmentEfficiency = () => {

    return(
        <Box>
            <Page pageName="Government Efficiency"/>
        </Box>
    )
}

export default GovernmentEfficiency;