import { Box, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import DoubleBars from '../charts/doubleBars';

const ByIndicatorGroup = ({ countrySelect , categoriesData, categoriesList}) => {

  return (
    <Box sx={{height: {md:"680px"}}}>
      <Typography
        color="#D0D2D6 !important"
        sx={{
          mt: "-0.5rem",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: "500",
        }}
      >
        By Indicator Group
      </Typography>

      <Box sx={{ mt: "0.5rem" }}>
        <Typography
          color="#00CFE8 !important"
          sx={{
            ml: "-0.5rem",
            fontSize: "12px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            display: "inline-block",
          }}
        >
          <CircleIcon sx={{ height: "10px" }} />
          {countrySelect}
        </Typography>
        <Typography
          color="#6A7693 !important"
          sx={{
            ml: "1rem",
            fontSize: "12px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            display: "inline-block",
          }}
        >
          <CircleIcon sx={{ height: "10px" }} />
          World
        </Typography>
      </Box>
            {categoriesData[0] && categoriesList[0] && <DoubleBars data = {categoriesData[0]} name = {categoriesList[0]} />}
            {categoriesData[1] && categoriesList[1] && <DoubleBars data = {categoriesData[1]} name = {categoriesList[1]} />}
            {categoriesData[2] && categoriesList[2] && <DoubleBars data = {categoriesData[2]} name = {categoriesList[2]} />}
            {categoriesData[3] && categoriesList[3] && <DoubleBars data = {categoriesData[3]} name = {categoriesList[3]} />}
            {categoriesData[4] && categoriesList[4] && <DoubleBars data = {categoriesData[4]} name = {categoriesList[4]} />}
            {categoriesData[5] && categoriesList[5] && <DoubleBars data = {categoriesData[5]} name = {categoriesList[5]} />}
    </Box>
  );
};

export default ByIndicatorGroup;