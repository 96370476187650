import React from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layouts';

import RenderField from './renderField';
import { Helmet } from 'react-helmet-async';

const AllDashboards = () => {
    
    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Helmet>
                <title>DeepTech Analytics Platform</title>
            </Helmet>
            <MainLayout 
                allDashes = {true}
                title="DeepTech Analytics Platform"
                >
                <RenderField />
            </MainLayout>
        </Box>
    )
}

export default AllDashboards;