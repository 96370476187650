import { Box, Grid, Typography } from "@mui/material"


const CovidHeader = ({ parameters }) => {

    return (
        <Box>
            <Grid sx={{ ml: '0', mt: '0', width: '100%', p: { xs: '0 1rem', xl: '1rem' } }} container >
                {parameters && parameters.map(i => (
                    <Grid key={i.titleParams} xs={6} xl={3} item
                        sx={{
                            p: { xs: '1rem 1rem 0 1rem', xl: '0 1rem' }
                        }}>
                        <Box sx={{
                            background: '#2A3044',
                            p: '2rem',
                            borderRadius: '.4rem',
                            display: 'flex',
                            justifyContent: 'center',
                            boxShadow: '5px 6px 8px 8px rgb(0 0 0 / 13%)',
                            border: '1px solid rgb(0 0 0 / 13%)'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                mr: '1rem',
                                background: i.bgColor,
                                p: '10px',
                                borderRadius: '50%'
                            }}>{i.iconParams}</Box>
                            <Box>
                                <Typography sx={{
                                    color: '#D0D2D6',
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '24px'
                                }}>{i.counts}</Typography>
                                <Typography sx={{ color: '#D0D2D6', whiteSpace: 'nowrap' }}>{i.titleParams}</Typography>
                            </Box>
                        </Box>
                    </Grid>))}
            </Grid>
        </Box>
    )
}

export default CovidHeader;