import { Box } from "@mui/material";
import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ series, labels, colors }) => {




    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            show: false
        },
        grid: {
            show: true
        },
        legend: {
            display: true,
            labels: {
                colors: ['#fff'],
            }
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const data = series[seriesIndex][dataPointIndex];
                const label = w.globals.seriesNames[seriesIndex];
                return `<Box style='padding: 0.4rem 0.5rem; background: #000'> ${label}: ${data}</Box>`
            },
        },
        xaxis: {
            tickAmount: 26,
            categories: labels,
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            show: true,
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                },
            },
        },
    }

    return (
        <Box sx={{ height: { md: "300px", xl: "400px", xs: "300px" } }}>
            <Box sx={{ height: { md: "300px", xl: "400px", xs: "300px" } }}>
                <Chart options={options} series={series} type="line" height={"100%"} />
            </Box>
        </Box>
    );
};

export default LineChart;

