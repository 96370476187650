import { MenuItem, Select } from "@mui/material"


const SelectBox = ({ labels, selectValue, setSelectValue }) => {


    const handleChange = (e) => {
        setSelectValue(e.target.value)
    }

    return (
        <Select
            value={selectValue}
            onChange={handleChange}
            variant='outlined'
            sx={{
                '.MuiSelect-outlined': { backgroundColor: 'rgb(35, 48, 68)' },
                width: '100%',
                color: '#b4b7bd',
                border: '#fff',
                'svg': { color: '#fff' },
            }}>
            {labels.map(el => (
                <MenuItem key={el} sx={{
                    background: 'rgb(35, 48, 68)',
                    color: '#fff',
                    opacity: '0.6',
                    '.MuiList-root': { backgroundColor: 'rgb(35, 48, 68)' },
                    '&:hover': { backgroundColor: 'rgb(35, 48, 68)' },
                    '.Mui-selected': { backgroundColor: 'rgb(35, 48, 68)' }
                }}
                    value={el}>
                    {el}
                </MenuItem>
            )
            )}

        </Select>
    )
}

export default SelectBox;