import React from "react";
import { Box, Grid, Typography, } from "@mui/material";
import MainDarkBox from "../../../../mainDarkBox";
import {byCountry} from 'country-code-lookup'
import dataJson from "../../../../../assets/json/economyRegionalSafetyRanking.json";
import TopCountries from "./topCountries.js"
import DoubleRadialChart from '../charts/doubleRadialChart';
import LeftBlock from './leftBlock';
import SixCategoriesBlock from './sixCategoriesBlock';
import LineChartBlock from './lineChartBlock';

const Page = ({pageName}) => {
    const [countrySelect, setCountrySelect] = React.useState("Argentina");
    const [doubleRadialBarData, setDoubleRadialBarData] = React.useState([]);
    const [countrySelectData, setCountrySelectData] = React.useState({});
    const [iso3Select, setIso3Select] = React.useState("ARG");
    const [date, setDate] = React.useState(new Date("2015"));
    const [date2, setDate2] = React.useState(new Date("2022"));
    const [selectedCategory, setSelectedCategory] = React.useState({
      name: "",
      data: []
    });
    const [series, setSeries] = React.useState([]);
    const [years, setYears] = React.useState([]);
    
    const [categoriesData, setCategoriesData] = React.useState([])
    const [categoriesList, setCategoriesList] = React.useState([])

    const [oneBarTwoColorsData, setOneBarTwoColorsData] = React.useState([
      {
      country: "",
      data: 0,
      color: "",
    }])

    const handleCountryChange = (event) => {
      setCountrySelect(event.target.value);
      setIso3Select(byCountry(event.target.value).iso3)
    }

    const handleSetDate = (event) => {
      if(event > date2){
        setDate(date2)
      }
      else{
        setDate(event)
      }
    }

    const handleSetDate2 = (event) => {
      if(event < date){
        setDate2(date)
      }
      else{
        setDate2(event)
      }
    }

    let country = []
    dataJson.forEach((i) => {
      country.push(i.Country);
    });

    const getUnique = (arr) => {
      let i = 0,
        current,
        length = arr.length,
        unique = [];
      for (i; i < length; i++) {
        current = arr[i];
        if (!~unique.indexOf(current)) {
          unique.push(current);
        }
      }
      return unique.sort();
    };
    
    country = getUnique(country);

    React.useEffect(() => {
      let countryData = [];
      let worldData = [];

      let firstCategoryData = [];
      let secondCategoryData = [];
      let thirdCategoryData = [];
      let fourthCategoryData = [];
      let fifthCategoryData = [];
      let sixthCategoryData = [];

      let firstCategoryDataAverage = [];
      let secondCategoryDataAverage = [];
      let thirdCategoryDataAverage = [];
      let fourthCategoryDataAverage = [];
      let fifthCategoryDataAverage = [];
      let sixthCategoryDataAverage = [];

      let selectedCountry = {};

      let currentCategoryData = [];
      let countriesData = [];
      let color = "";
      let currentCategory = 0;
      let categories = []

      if (pageName === "Economy Resilience"){
        categories = ["Coronavirus Domestic Economic Impact", "Economy Vitality", "Economy Rescue Package", "Economic Impact Of Tourism Decrease", "Market Attractiveness", "Wealth Indicator"]
      }
      else if (pageName === "Government Efficiency"){
        categories = ["Surveillance Practices", "Public Involvement", "Satisfaction With Government Performance", "E-Readiness", "Emergency Response Mechanism"]
      }
      else if (pageName === "Healthcare Management"){
        categories = ["E-Health Score", "Health System Resources", "Healthcare Covid Responce", "Population Vulnerability", "Public Health"]
      }
      else if (pageName === "Quarantine Efficiency"){
        categories = ["Prevention Of Covid-19 Spread", "Covid-19 Detection", "City Preparedness", "Post-Covid Strategy", "Containment Policy"]
      }
      else if (pageName === "Vaccination Rate"){
        categories = ["Affordability Of Covid-19 Vaccines", "Deployment Of Covid-19 Vaccines", "Vaccine Allocation", "Vaccine Development And Production"]
      }

      setCategoriesList(categories);

      country.forEach((i) => {
        dataJson.forEach((j) => {
          if (j.Country === i) {
            currentCategoryData.push(parseFloat(j[pageName]));
          }
        });

        currentCategory = currentCategoryData.reduce((a, b) => a + b) / currentCategoryData.length;

        if (i === countrySelect) {
          color = "rgba(115, 103, 240, 0.4)";
        } else {
          color = "";
        }

        countriesData.push({
          country: i,
          data: currentCategory.toFixed(2),
          color: color,
        });

        currentCategory = 0;
        currentCategoryData = [];
      });

      countriesData.sort((a, b) => (parseFloat(a.data)) < (parseFloat(b.data))? 1 : -1)
  
      setOneBarTwoColorsData(countriesData)

      dataJson.forEach((i) => {
        worldData.push(parseFloat(i[pageName]));
        if (i.Country === countrySelect) {
          countryData.push(parseFloat(i[pageName]));
          selectedCountry = {
            rank: i.Rank,
            country: i.Country,
            countryScore: parseFloat(i["Overall Score"]),
          };

          firstCategoryData.push(parseFloat(i[categories[0]]));
          secondCategoryData.push(parseFloat(i[categories[1]]));
          thirdCategoryData.push(parseFloat(i[categories[2]]));
          fourthCategoryData.push(parseFloat(i[categories[3]]));
          fifthCategoryData.push(parseFloat(i[categories[4]]));
          sixthCategoryData.push(parseFloat(i[categories[5]]));
        }

        firstCategoryDataAverage.push(parseFloat(i[categories[0]]));
        secondCategoryDataAverage.push(parseFloat(i[categories[1]]));
        thirdCategoryDataAverage.push(parseFloat(i[categories[2]]));
        fourthCategoryDataAverage.push(parseFloat(i[categories[3]]));
        fifthCategoryDataAverage.push(parseFloat(i[categories[4]]));
        sixthCategoryDataAverage.push(parseFloat(i[categories[5]]));
      });

      let countryDataAverage = countryData.reduce((a, b) => a + b) / countryData.length;
      let worldDataAverage = worldData.reduce((a, b) => a + b) / worldData.length;
        
      let firstCategory = firstCategoryData.reduce((a, b) => (a + b)) / firstCategoryData.length;
      let secondCategory = secondCategoryData.reduce((a, b) => (a + b)) / secondCategoryData.length;
      let thirdCategory = thirdCategoryData.reduce((a, b) => (a + b)) / thirdCategoryData.length;
      let fourthCategory = fourthCategoryData.reduce((a, b) => (a + b)) / fourthCategoryData.length;
      let fifthCategory = fifthCategoryData.reduce((a, b) => (a + b)) / fifthCategoryData.length;
      let sixthCategory = sixthCategoryData.reduce((a, b) => (a + b)) / sixthCategoryData.length;
        
      let firstCategoryAverage = firstCategoryDataAverage.reduce((a, b) => (a + b)) / firstCategoryDataAverage.length;
      let secondCategoryAverage = secondCategoryDataAverage.reduce((a, b) => (a + b)) / secondCategoryDataAverage.length;
      let thirdCategoryAverage = thirdCategoryDataAverage.reduce((a, b) => (a + b)) / thirdCategoryDataAverage.length;
      let fourthCategoryAverage = fourthCategoryDataAverage.reduce((a, b) => (a + b)) / fourthCategoryDataAverage.length;
      let fifthCategoryAverage = fifthCategoryDataAverage.reduce((a, b) => (a + b)) / fifthCategoryDataAverage.length;
      let sixthCategoryAverage = sixthCategoryDataAverage.reduce((a, b) => (a + b)) / sixthCategoryDataAverage.length;

      setCategoriesData([[firstCategory.toFixed(2), firstCategoryAverage.toFixed(2)], [secondCategory.toFixed(2), secondCategoryAverage.toFixed(2)], [thirdCategory.toFixed(2), thirdCategoryAverage.toFixed(2)], [fourthCategory.toFixed(2), fourthCategoryAverage.toFixed(2)], [fifthCategory.toFixed(2), fifthCategoryAverage.toFixed(2)], [sixthCategory.toFixed(2), sixthCategoryAverage.toFixed(2)]]);

      setCountrySelectData(selectedCountry);
      setDoubleRadialBarData([countryDataAverage, worldDataAverage]);
    }, [countrySelect]);
    
    React.useEffect(() => {

      let series = []
      let years = []
  
      for (let i = date.getFullYear(); i <= date2.getFullYear(); i++) {
        if (selectedCategory.data[i]) {
          series.push(parseFloat(selectedCategory.data[i]).toFixed(2))
          years.push(i)
        }
      }

      setSeries(series);
      setYears(years);
    }, [selectedCategory, date, date2, countrySelect])

    return (
      <Box>
        <Grid container sx={{ p: "2rem" }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <MainDarkBox>
              <Box sx={{ mt: "-0.5rem", mb: "-0.5rem" }}>
                <Typography sx={{fontSize:"18px", color:"#FFFFFF", fontFamily:"Montserrat"}}>{pageName}</Typography>
              </Box>
            </MainDarkBox>
          </Grid>

          <LeftBlock
            country={country}
            countrySelect={countrySelect}
            handleCountryChange={handleCountryChange}
            countrySelectData={countrySelectData}
            categoriesData={categoriesData}
            categoriesList={categoriesList}
          />

          <Grid item container xs={12} md={9.5}>
            <Grid item container xs={12}>
              <DoubleRadialChart
                countrySelect={countrySelect}
                series={doubleRadialBarData}
              />
              <TopCountries
                pageName={pageName}
                dataAverage={doubleRadialBarData[1]}
                series={oneBarTwoColorsData}
              />
            </Grid>

            <Grid item container xs={12}>
              <LineChartBlock
                date={date}
                date2={date2}
                handleSetDate={handleSetDate}
                handleSetDate2={handleSetDate2}
                name={selectedCategory.name}
                series={series}
                years={years}
              />

              <SixCategoriesBlock
                pageName={pageName}
                iso3Select={iso3Select}
                setSelectedCategory={setSelectedCategory}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
}

export default Page;