import axios from 'axios';

let urlBase = `${process.env.REACT_APP_URL_NEW}`
if (process.env.REACT_APP_URL.indexOf(window.location.host) != -1){
    urlBase = `${process.env.REACT_APP_URL}`
}
const instance = axios.create({
    baseURL: urlBase,
});

export const api = {
    signIn: async ({ login, password }) => {
        return (
            await instance.post('/accounts/login/', {
                login,
                password,
            })
        ).data;
    },
    // logOut: async ({ login, password }) => {
    //     return (
    //         await instance.post('/accounts/logout/', {
    //             login,
    //             password,
    //         })
    //     ).data;
    // },
    checkUser: async () => {
        return (
            await instance.get('/dashboards/api/get-user-if-logged')
        ).data;
    },
}
